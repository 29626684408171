import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from '../../hooks/useTranslate';
import { isNotDef } from '../../utils/common';

const useStyles = makeStyles(() => ({
    data: {
        marginTop: '4px',
        marginBottom: '0px',
    },
}));

const EnhancedDateTimePicker = ({
    value,
    onChange,
    label,
    formatView,
    formatData,
    readOnly,
    minDate,
    minDateMessage,
    maxDate,
    maxDateMessage,
    required,
    inputVariant,
}) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
                className={classes.data}
                fullWidth
                ampm={false}
                allowKeyboardControl
                label={translate(label)}
                invalidDateMessage="Formato data non valido"
                cancelLabel="Annulla"
                format={formatView}
                minDate={minDate}
                minDateMessage={minDateMessage}
                maxDate={maxDate}
                maxDateMessage={maxDateMessage}
                clearLabel="Cancella"
                size="small"
                autoOk
                clearable
                readOnly={readOnly}
                InputProps={{
                    readOnly,
                    // disableUnderline: readOnly,
                }}
                inputVariant={readOnly ? 'standard' : inputVariant}
                value={value}
                required={required}
                onChange={newValue => {
                    if (newValue?.isValid()) {
                        onChange(newValue.format(formatData));
                    } else if (isNotDef(newValue)) {
                        onChange(newValue);
                    }
                }}
                minutesStep={15}
            />
        </MuiPickersUtilsProvider>
    );
};

EnhancedDateTimePicker.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    formatView: PropTypes.string,
    formatData: PropTypes.string,
    readOnly: PropTypes.bool,
    minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    minDateMessage: PropTypes.string,
    maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    maxDateMessage: PropTypes.string,
    required: PropTypes.bool,
    inputVariant: PropTypes.string,
};

EnhancedDateTimePicker.defaultProps = {
    value: null,
    label: null,
    formatView: 'DD.MM.YYYY HH:mm',
    formatData: 'YYYY-MM-DD HH:mm:ss',
    readOnly: undefined,
    minDate: undefined,
    minDateMessage: '',
    maxDate: undefined,
    maxDateMessage: '',
    required: undefined,
    inputVariant: 'outlined',
};

export default EnhancedDateTimePicker;
