import { Avatar, Box, Divider, useMediaQuery, useTheme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Mail, People, Phone } from '@material-ui/icons';
import { ShieldAccount } from 'mdi-material-ui';
import PropTypes from 'prop-types';
import React from 'react';
import SimpleBar from 'simplebar-react';
import FlexColumn from '../../../components/flex/FlexColumn';
import FlexRow from '../../../components/flex/FlexRow';
import SimpleCard from '../../../components/SimpleCard';
import LoadingFullScreen from '../../../components/utilities/LoadingFullScreen';
import { buildUrl } from '../../../utils/common';
import useTranslate from '../../../hooks/useTranslate';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'simplebar/dist/simplebar.min.css';

const SiteDashboardContracts = ({ contacts, isLoading }) => {
    const translate = useTranslate();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    if (isLoading)
        return (
            <SimpleCard icon={People} title="contacts" height={matches ? 404 : 0}>
                <LoadingFullScreen message="" height={320} />
            </SimpleCard>
        );
    return (
        <SimpleCard icon={People} title="contacts" height={matches ? 404 : 0}>
            <SimpleBar style={{ height: matches ? 320 : '100%', width: '100%' }}>
                {contacts.length ? (
                    contacts.slice(0, 2).map((contact, index) => {
                        return (
                            <FlexRow key={contact.personId} pb={index < 1 ? 3 : 0}>
                                <Box pr={2}>
                                    <Avatar src={buildUrl(contact.avatarUrl)} style={{ height: 56, width: 56 }} />
                                </Box>
                                <FlexColumn flex={1}>
                                    <Box color={grey[400]} fontSize={16}>
                                        {translate(contact.projectFunction?.toLowerCase())}
                                    </Box>
                                    <Box fontSize={20} fontWeight={500} pb={1}>{`${contact.firstName} ${contact.lastName}`}</Box>
                                    <Divider />

                                    <FlexRow alignItems="center" pt={1}>
                                        <ShieldAccount style={{ fontSize: 16 }} />
                                        <Box pl={1}>{contact.companyName}</Box>
                                    </FlexRow>
                                    <FlexRow alignItems="center" pt={1}>
                                        <Mail style={{ fontSize: 16 }} />
                                        <Box pl={1}>{contact.email}</Box>
                                    </FlexRow>
                                    <FlexRow alignItems="center" pt={1}>
                                        <Phone style={{ fontSize: 16 }} />
                                        <Box pl={1}>{contact.telephoneNumber}</Box>
                                    </FlexRow>
                                </FlexColumn>
                            </FlexRow>
                        );
                    })
                ) : (
                    <Box
                        height={1}
                        width={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        fontSize={16}
                        fontWeight="fontWeightMedium"
                        color="#999"
                    >
                        {translate('contacts_not_present')}
                    </Box>
                )}
            </SimpleBar>
        </SimpleCard>
    );
};

SiteDashboardContracts.propTypes = {
    contacts: PropTypes.array,
    isLoading: PropTypes.bool,
};
SiteDashboardContracts.defaultProps = {
    contacts: [],
    isLoading: undefined,
};

export default SiteDashboardContracts;
