import { List, ListItemText } from '@material-ui/core';
import { Description } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from '../../hooks/useTranslate';
import EnhancedListItem from './EnhancedListItem';

const EnhancedList = ({ id, value, onChange, getOptionId, getOptionUrl, getOptionLabel, getOptionIcon, messageNoItems }) => {
    const translate = useTranslate();
    return (
        <List dense disablePadding>
            {value.map(item => (
                <EnhancedListItem
                    key={getOptionId(item)}
                    field={id}
                    id={getOptionId(item)}
                    getOptionId={getOptionId}
                    icon={getOptionIcon(item) || Description}
                    label={getOptionLabel(item)}
                    url={getOptionUrl(item)}
                    onChange={onChange}
                />
            ))}
            {value.length === 0 && <ListItemText primary={translate(messageNoItems)} />}
        </List>
    );
};

EnhancedList.propTypes = {
    id: PropTypes.string.isRequired,
    getOptionIcon: PropTypes.func.isRequired,
    getOptionId: PropTypes.func.isRequired,
    getOptionLabel: PropTypes.func.isRequired,
    getOptionUrl: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array,
    messageNoItems: PropTypes.string,
};
EnhancedList.defaultProps = {
    value: [],
    messageNoItems: 'document_not_present',
};

export default EnhancedList;
