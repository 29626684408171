import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    wrapper: {
        marginTop: theme.spacing(1),
        position: 'relative',
        width: '100%',
    },
    buttonProgress: {
        color: theme.palette.secondary,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const LoadingButton = ({ onClick, isLoading, label, fullWidth, disable }) => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <Button
                disabled={isLoading || disable}
                onClick={event => onClick(event.target.value)}
                fullWidth={fullWidth}
                variant="contained"
                color="primary"
            >
                {label}
            </Button>
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    );
};

LoadingButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    fullWidth: PropTypes.bool,
    disable: PropTypes.bool,
};

LoadingButton.defaultProps = {
    isLoading: false,
    fullWidth: true,
    disable: undefined,
};

export default LoadingButton;
