import ability from './ability';
import {
    ADMIN,
    CLIENT,
    HR,
    ASSOCIATE,
    LIST_ALL_EMPLOYEE,
    NO_LEAVE,
    SHOW_HR_MODULE,
    LIST_FILE,
    EDIT_INVENTORY,
    EMPLOYEE,
    WORKER,
    INTERIM,
    STAMPINGS_LOG_MANAGEMENT,
    SHOW_EDEMAR,
    SHOW_METALPIEGA,
    SHOW_SITAF,
    SHOW_TIGESTIM,
    SHOW_METALSKY,
    SHOW_TMWOOD,
    SHOW_ELECTROGESTIM,
    ALL_CHECKLISTS,
} from '../constants/options';

const roleMap = {
    [ADMIN]: [
        { action: 'read', subject: 'sites' },
        { action: 'update', subject: 'sites' },
        { action: 'update', subject: 'hr' },
        { action: 'read', subject: 'allEmployee' },
        { action: 'read', subject: 'allSites' },
        { action: 'read', subject: 'admin' },
        { action: 'update', subject: 'admin' },
        { action: 'read', subject: 'files' },
        { action: 'update', subject: 'files' },
        { action: 'update', subject: 'favorites' },
        { action: 'update', subject: 'profile' },
        { action: 'read', subject: 'sensibleData' },
        { action: 'update', subject: 'quickSalId' },
        { action: 'update', subject: 'stampingsLog' },
        { action: 'read', subject: 'allChecklists' },
    ],
    [HR]: [
        { action: 'read', subject: 'sites' },
        { action: 'update', subject: 'hr' },
        { action: 'read', subject: 'admin' },
        { action: 'update', subject: 'admin' },
        { action: 'read', subject: 'files' },
        { action: 'update', subject: 'files' },
        { action: 'update', subject: 'favorites' },
        { action: 'update', subject: 'profile' },
        { action: 'update', subject: 'stampingsLog' },
        { action: 'read', subject: 'sensibleData' },
    ],
    [INTERIM]: [{ action: 'read', subject: 'sites' }],
    [EMPLOYEE]: [
        { action: 'read', subject: 'sites' },
        { action: 'read', subject: 'sensibleData' },
        { action: 'update', subject: 'favorites' },
    ],
    [WORKER]: [
        { action: 'read', subject: 'sites' },
        { action: 'read', subject: 'sensibleData' },
        { action: 'update', subject: 'favorites' },
    ],
    [CLIENT]: [
        { action: 'read', subject: 'onlySubcontractorsFiles' },
        { action: 'read', subject: 'onlySitesFav' },
        { action: 'read', subject: 'sensibleDataClient' },
    ],
    // ASSOCIATE has the same permissions as CLIENT
    get [ASSOCIATE]() {
        return this[CLIENT];
    },
    [LIST_ALL_EMPLOYEE]: [
        { action: 'read', subject: 'hr' },
        { action: 'read', subject: 'allEmployee' },
    ],
    [NO_LEAVE]: [{ action: 'read', subject: 'leave' }],
    [SHOW_HR_MODULE]: [{ action: 'update', subject: 'hr' }],
    [STAMPINGS_LOG_MANAGEMENT]: [{ action: 'update', subject: 'stampingsLog' }],
    [EDIT_INVENTORY]: [{ action: 'read', subject: 'tool-manager' }],
    [LIST_FILE]: [{ action: 'read', subject: 'files' }],
    [SHOW_TIGESTIM]: [{ action: 'read', subject: 'tigestim-company' }],
    [SHOW_METALPIEGA]: [{ action: 'read', subject: 'metalpiega-company' }],
    [SHOW_METALSKY]: [{ action: 'read', subject: 'metalsky-company' }],
    [SHOW_SITAF]: [{ action: 'read', subject: 'sitaf-company' }],
    [SHOW_EDEMAR]: [{ action: 'read', subject: 'edemar-company' }],
    [SHOW_TMWOOD]: [{ action: 'read', subject: 'tmwood-company' }],
    [SHOW_ELECTROGESTIM]: [{ action: 'read', subject: 'electrogestim-company' }],
    [ALL_CHECKLISTS]: [{ action: 'read', subject: 'allChecklists' }],
    default: [{ action: 'login', subject: 'app' }],
};

const abilityUpdate = (userRights = []) => {
    const rules = userRights
        .map(role => roleMap[role] || roleMap.default)
        .filter(role => role)
        .flat();

    ability.update(rules);
};

export default abilityUpdate;
