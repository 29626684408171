import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import useFetch from '../../hooks/useFetch';
import useFilters from '../../hooks/useFilters';
import usePagination from '../../hooks/usePagination';
import useSorting from '../../hooks/useSorting';
import { setOrder } from '../../store/slices/sliceFiltering';
import { invokeOrReturn } from '../../utils/common';
import Actions from '../Actions';
import Filters from '../Filters';
import Search from '../Search';
import EnhancedTable from '../table/EnhancedTable';
import EnhancedCard from '../utilities/EnhancedCard';
import EnhancedPagination from '../utilities/EnhancedPagination';


const TableView = ({ actions, columns, proxy, params, handleResponse, onItemClick, keyComposition, id, defaultValue, searchable, filters }) => {
    const dispatch = useDispatch();
    const { pageNumber, pageSize } = usePagination(proxy.name);
    const { sortBy, sortDirection } = useSorting(proxy.name);
    const [filter, setFilter] = useFilters(proxy);

    const [response, isLoading] = useFetch(
        proxy,
        {
            pageNumber: pageNumber - 1,
            pageSize,
            orderBy: sortBy,
            orderDirection: sortDirection,
            ...invokeOrReturn(params, id),
            ...filter,
        },
        [pageNumber, pageSize, sortBy, sortDirection, filter],
        [],
        defaultValue,
        handleResponse,
        true
    );

    return (
        <Box height={1} width={1}>
            <Box display="flex" justifyContent="space-between">
                <Box pb={searchable || filters ? 2 : 0}>
                    <Grid container spacing={1}>
                        {searchable && (
                            <Grid item>
                                <Search onChange={(search) => {
                                    setFilter(prev => ({...prev, search}));
                                }} value={filter.search} />
                            </Grid>
                        )}
                        {filters && (
                            <Grid item>
                                <Filters filter={filter} onChange={(filter) => {
                                    setFilter(filter)
                                }} filters={filters} />
                            </Grid>
                        )}
                    </Grid>
                </Box>
                {actions && <Actions actions={actions} />}
            </Box>

            <EnhancedCard pb={1} ComponentBottom={<EnhancedPagination name={proxy.name} pages={response.totalPages} />}>
                <EnhancedTable
                    items={response.data}
                    columns={columns}
                    isLoading={isLoading}
                    onItemClick={onItemClick && (item => onItemClick(item, dispatch))}
                    keyComposition={keyComposition}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    handleRequestSort={order => {
                        dispatch(setOrder({ view: proxy.name, order }));
                    }}
                />
            </EnhancedCard>
        </Box>
    );
};

TableView.propTypes = {
    actions: PropTypes.array,
    columns: PropTypes.array,
    defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    filters: PropTypes.array,
    handleResponse: PropTypes.func,
    id: PropTypes.number,
    keyComposition: PropTypes.func,
    onItemClick: PropTypes.func,
    params: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    proxy: PropTypes.object,
    searchable: PropTypes.bool,
};
TableView.defaultProps = {
    id: undefined,
    actions: undefined,
    filters: undefined,
    columns: [],
    proxy: {},
    params: () => {},
    onItemClick: undefined,
    handleResponse: undefined,
    keyComposition: undefined,
    searchable: undefined,
    defaultValue: {},
};

export default TableView;
