import { Apartment, ArrowBackIos } from '@material-ui/icons';
import { FilePdfBox } from 'mdi-material-ui';
import { v4 as uuid } from 'uuid';
import i from '../../ability/ability';
import history from '../../components/routings/history';
import FormView from '../../components/view/FormView';
import TableView from '../../components/view/TableView';
import { companies } from '../../constants/options';
import {
    getChecklist,
    getChecklistComposite,
    getChecklistRequests,
    getChecklistsSite,
    getJournal,
    getSite,
    getSiteContacts,
    getSites,
    saveSite
} from '../../store/proxies/tigestimProxies';
import { openDialog } from '../../store/slices/sliceDialog';
import { generateCompaniesString, isEmpty } from '../../utils/common';
import ChecklistDocument from '../../view/checklist/export/ChecklistDocument';
import SiteCalendar from '../../view/sites/SiteCalendar';
import SiteCheckListsTableView from '../../view/sites/SiteChecklist/SiteCheckListsTableView';
import SiteContactsTableView from '../../view/sites/SiteContacts/SiteContactsTableView';
import SiteDashboard from '../../view/sites/SiteDashboard/SiteDashboard';
import SiteFilesTableView from '../../view/sites/SiteFiles/SiteFilesTableView';
import SiteJournal from '../../view/sites/SiteJournal/SiteJournal';
import SiteJournalEdit from '../../view/sites/SiteJournal/SiteJournalEdit';
import SiteJournalView from '../../view/sites/SiteJournal/SiteJournalView';
import SitesTableOnlyFav from '../../view/sites/SitesTableOnlyFav';
import SitesTableView from '../../view/sites/SitesTableView';
import {
    newEventAction,
    siteContactsActions,
    siteContactsEditActions,
    siteDashboardActions,
    siteJournalEditActions,
    siteJournalNewActions,
    siteJournalViewActions
} from '../actions';
import { checklistColumns, checklistRequestsColumns, siteContactsColumns, sitesColumns, sitesColumnsSensible } from '../columns';
import { requestChecklistForm, siteChecklistForm, siteEditForm, siteJournalForm } from '../forms';

const role = () => i.can('read', 'sites') || i.can('read', 'onlySitesFav');

export default {
    title: 'construction_sites',
    key: uuid(),
    icon: Apartment,
    path: 'sites',
    to: 'sites/sites',
    role,
    children: [
        {
            title: 'construction_sites_list',
            key: uuid(),
            path: 'sites',
            to: 'sites/sites',
            tabs: [
                {
                    label: 'list',
                    key: uuid(),
                    showTabs: false,
                    component: SitesTableView,
                    role: () => i.can('read', 'sites') && i.can('read', 'allSites'),
                    componentProps: {
                        columns: i.can('read', 'sensibleData') ? sitesColumnsSensible : sitesColumns,
                        proxy: getSites,
                        onItemClick: ({ constructionSiteId }) => history.push(`sites/${constructionSiteId}/dashboard`),
                        filters: [
                            {
                                placeholder: 'companyName',
                                options: companies,
                                param: 'companyName',
                            },
                        ],
                    },
                },
                {
                    label: 'list',
                    key: uuid(),
                    showTabs: false,
                    component: SitesTableView,
                    role: () => i.can('read', 'sites') && i.cannot('read', 'allSites'),
                    componentProps: (ids, user) => ({
                        columns: i.can('read', 'sensibleData') ? sitesColumnsSensible : sitesColumns,
                        proxy: getSites,
                        onItemClick: ({ constructionSiteId }) => history.push(`sites/${constructionSiteId}/dashboard`),
                        params: {
                            companyName: generateCompaniesString(user.companyName, companies, i),
                        },
                    }),
                },
                {
                    label: 'list',
                    key: uuid(),
                    showTabs: false,
                    role: () => i.can('read', 'onlySitesFav'),
                    component: SitesTableOnlyFav,
                    componentProps: {
                        columns: i.can('read', 'sensibleData') ? sitesColumnsSensible : sitesColumns,
                        proxy: getSites,
                        onItemClick: ({ constructionSiteId }) => history.push(`sites/${constructionSiteId}/dashboard`),
                    },
                },
                {
                    label: 'edit_site',
                    key: uuid(),
                    path: ':id/edit',
                    showTabs: false,
                    hideTab: true,
                    component: FormView,
                    componentProps: {
                        get: { proxy: getSite },
                        save: {
                            proxy: saveSite,
                            success: 'site_saved_successfully',
                        },
                        title: 'edit_site',
                        form: siteEditForm,
                    },
                },
                {
                    label: 'dashboard',
                    key: uuid(),
                    path: ':id/dashboard',
                    component: SiteDashboard,
                    componentProps: {
                        actions: siteDashboardActions,
                    },
                },
                {
                    label: 'journal',
                    key: uuid(),
                    path: ':id/journal',
                    component: SiteJournal,
                },
                {
                    label: 'journal',
                    key: uuid(),
                    showTabs: false,
                    hideTab: true,
                    path: ':siteId/journal/new',
                    component: SiteJournalEdit,
                    componentProps: {
                        actions: siteJournalNewActions,
                        form: siteJournalForm,
                    },
                },
                {
                    label: 'journal',
                    key: uuid(),
                    showTabs: false,
                    hideTab: true,
                    path: ':siteId/journal/:journalId/edit',
                    component: SiteJournalEdit,
                    componentProps: {
                        proxy: getJournal,
                        actions: siteJournalEditActions,
                        form: siteJournalForm,
                    },
                },
                {
                    label: 'journal',
                    key: uuid(),
                    showTabs: false,
                    hideTab: true,
                    path: ':siteId/journal/:journalId/view',
                    component: SiteJournalView,
                    componentProps: {
                        proxy: getJournal,
                        actions: siteJournalViewActions,
                    },
                },

                {
                    label: 'calendar',
                    key: uuid(),
                    path: ':id/calendar',
                    component: SiteCalendar,
                    componentProps: {
                        actions: newEventAction,
                    },
                },
                {
                    label: 'files',
                    key: uuid(),
                    path: ':id/files',
                    role: () => i.can('read', 'files') || i.can('read', 'onlySubcontractorsFiles'),
                    component: SiteFilesTableView,
                },
                {
                    label: 'contacts',
                    key: uuid(),
                    path: ':id/contacts',
                    component: TableView,
                    componentProps: {
                        actions: siteContactsActions,
                        columns: siteContactsColumns,
                        keyComposition: ({ workTeamId }) => workTeamId,
                        proxy: getSiteContacts,
                        params: id => ({
                            filter: 'forSiteId',
                            id,
                        }),
                    },
                },
                {
                    label: 'contacts',
                    key: uuid(),
                    path: ':id/contacts/edit',
                    hideTab: true,
                    showTabs: false,
                    component: SiteContactsTableView,
                    componentProps: {
                        actions: siteContactsEditActions,
                    },
                },

                {
                    label: 'checklists',
                    key: uuid(),
                    path: ':id/checklists',

                    component: SiteCheckListsTableView,
                    componentProps: ({ id }) => ({
                        columns: checklistColumns,
                        proxy: getChecklistsSite,
                        params: { isTemplate: 0, siteId: id },
                        onItemClick: ({ id }) => history.push(`checklists/${id}/fill`),
                    }),

                    // component: TableView,
                    // componentProps: ({ id }) => ({
                    //     proxy: getChecklistsSite,
                    //     params: {
                    //         isTemplate: 0,
                    //         siteId: id,
                    //     },
                    //     columns: checklistColumns,
                    //     onItemClick: ({ id }) => history.push(`checklists/${id}/fill`),
                    // }),
                },
                {
                    label: 'checklist',
                    key: uuid(),
                    path: ':siteId/checklists/:id/fill',
                    showTabs: false,
                    hideTab: true,
                    component: FormView,
                    componentProps: {
                        title: 'view_checklist',
                        form: siteChecklistForm,
                        readOnly: true,
                        get: { proxy: getChecklist },
                    },
                },
            ],
        },
        {
            title: 'checklist_requests',
            key: uuid(),
            role: () => i.can('read', 'sites') || i.can('read', 'onlySitesFav'),
            path: 'checklist/requests',
            to: 'sites/checklist/requests/list',
            tabs: [
                {
                    title: 'checklist/requests',
                    key: uuid(),
                    path: 'list',
                    showTabs: false,
                    component: TableView,
                    role: () => i.can('read', 'sites') || i.can('read', 'onlySitesFav'),
                    componentProps: {
                        columns: checklistRequestsColumns,
                        proxy: getChecklistRequests,
                        searchable: true,
                        onItemClick: ({ checklistworkerId }) => history.push(`${checklistworkerId}/view`),
                    },
                },
                {
                    label: 'checklist_detail',
                    key: uuid(),
                    path: ':id/view',
                    showTabs: false,
                    hideTab: true,
                    component: FormView,
                    componentProps: {
                        title: 'view_checklist',
                        form: requestChecklistForm,
                        readOnly: true,
                        get: { proxy: getChecklistComposite },
                        actions: [
                            {
                                label: 'export',
                                icon: FilePdfBox,
                                valid: data => !isEmpty(data),
                                onClick: data =>
                                    openDialog({
                                        cancelLabel: 'close',
                                        component: ChecklistDocument,
                                        fullScreen: true,
                                        data,
                                    }),
                            },
                            { label: 'go_back', icon: ArrowBackIos, onClick: () => history.goBack() },
                        ],
                    },
                },
            ],
        },
    ],
};
