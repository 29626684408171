import { Grid } from '@material-ui/core';
import { Edit, Event } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { v4 as uuid } from 'uuid';
import Actions from '../../components/Actions';
import DisplayList from '../../components/DisplayList';
import DisplayTable from '../../components/DisplayTable';
import FlexColumn from '../../components/flex/FlexColumn';
import history from '../../components/routings/history';
import { legalDocumentsColumns } from '../../configuration/columns';
import { breakTimeOptionsMap, countriesMap, CLIENT, NO_STAMPING_LOG } from '../../constants/options';
import useFetch from '../../hooks/useFetch';
import { getPerson } from '../../store/proxies/tigestimProxies';
import { buildUrl } from '../../utils/common';
import { formatDate, formatGender, formatHours, formatPercent } from '../../utils/formats';
import UserAvatar from '../UserAvatar';

const basicDataItems = [
    { label: 'firstName', id: 'firstName' },
    { label: 'lastName', id: 'lastName' },
    { label: 'birthday', id: 'birthday', format: formatDate },
    { label: 'gender', id: 'gender', format: formatGender },
    { label: 'zip', id: 'zip' },
    { label: 'city', id: 'city' },
    { label: 'address', id: 'address' },
    { label: 'country', id: 'countryId', format: (value, translate) => translate(countriesMap[value])},
    { label: 'account', id: 'email' },
    { label: 'personal_mobile_number', id: 'mobile' },
    { label: 'personal_email', id: 'personalMail' },
    { label: 'personal_home_number', id: 'home' },
    { label: 'office_email', id: 'officeEmail' },
    { label: 'work_mobile_number', id: 'workmobile' },
    { label: 'company', id: 'companyName' },
    { label: 'work_office_number', id: 'workoffice' },
    { label: 'role', id: 'role' },
    { label: 'work_c_3_x_number', id: 'workc3x' },
    { label: 'badge_id', id: 'badgeId' },
    { label: 'work_percentage', id: 'workPercentage', format: formatPercent },
    { label: 'hired_at', id: 'hiredat', format: formatDate },
    { label: 'terminated_at', id: 'terminatedat', format: formatDate },
    { label: 'holidays_count_days', id: 'holidaysCount', unit: 'days' },
];

const workingHoursItems = [
    { label: 'break_time_h', id: 'breakTime', format: (value) => breakTimeOptionsMap[value] },
    { id: uuid() },
    { label: 'winter_start', id: 'winterstarthr', format: formatHours },
    { label: 'winter_end', id: 'winterendthr', format: formatHours },
    { label: 'summer_start', id: 'summerstarthr', format: formatHours },
    { label: 'summer_end', id: 'summerendthr', format: formatHours },
];

const handleResponse = response => {
    const { users = {}, employees = [], legalDocuments = [], signedDocuments = [] } = response || {};
    const [employee] = employees;

    return {
        person: {
            ...(users.phoneNumberList || []).reduce(
                (accu, {type, number}) => ({ ...accu, [`${type}`.toLowerCase()]: number }),
                {}
            ),
            ...employee,
            ...users,
            breakTime: Number.parseFloat(employee?.breakTime, 10),
        },
        legalDocuments,
        signedDocuments,
    };
};

const actions = [ {
    label: "edit_user",
    icon: Edit,
    role: (i) => i.can('update', 'hr'),
    onClick: () => {
        history.push(`edit`);
    }}
]

const UserProfileView = ({ id }) => {
    const [{ person, legalDocuments }, isLoading] = useFetch(getPerson, id, [id], [id], {}, handleResponse);

    return (
        <FlexColumn flex={1} width={1}>
            <Actions actions={actions}/>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <UserAvatar data={person} />
                        </Grid>
                        {person?.role !== CLIENT && !person?.capabilities?.includes(NO_STAMPING_LOG)  && ( 
                            <Grid item xs={12}>
                                <DisplayList
                                    isLoading={isLoading}
                                    title="working_hours"
                                    icon={Event}
                                    items={workingHoursItems}
                                    data={person}
                                    columns={2}
                                />
                            </Grid> )}

                    </Grid>
                </Grid>

                <Grid item xs={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DisplayList isLoading={isLoading} title="basic_data" icon={Event} items={basicDataItems} data={person} columns={2} />
                        </Grid>

                        <Grid item xs={12}>
                            <DisplayTable
                                name="userLegalDocuments"
                                icon={Event}
                                title="legal_documents"
                                items={legalDocuments}
                                columns={legalDocumentsColumns}
                                messageNoItems="document_not_present"
                                onItemClick={({ url }) => window.open(buildUrl(url))}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FlexColumn>
    );
};

UserProfileView.propTypes = {
    id: PropTypes.number,
};
UserProfileView.defaultProps = {
    id: undefined,
};

export default UserProfileView;
