import { Tabs } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { MAX_WIDTH_CONTENT } from '../../constants/dimensionConstants';
import EnhancedTab from './EnhancedTab';

const EnhancedTabsHeader = ({ tabs, tabPath, setPath }) => {
    return (
        <Box bgcolor="primary.main" display="flex" justifyContent="center">
            <Box maxWidth={MAX_WIDTH_CONTENT} width={1} color="white">
                <Tabs
                    value={tabPath}
                    onChange={(event, value) => {
                        setPath(value);
                    }}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {tabs.map(({ key, label, hideTab, disabled, path = '', id }) => {
                        return <EnhancedTab key={key} hideTab={hideTab} label={label} value={id || path.replace(':id/', '')} disabled={disabled} />;
                    })}
                </Tabs>
            </Box>
        </Box>
    );
};

EnhancedTabsHeader.propTypes = {
    tabs: PropTypes.array.isRequired,
    tabPath: PropTypes.string.isRequired,
    setPath: PropTypes.func.isRequired,
};

EnhancedTabsHeader.defaultProps = {};

export default memo(EnhancedTabsHeader);
