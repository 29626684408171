import { createSlice, createAction } from '@reduxjs/toolkit';

const sliceSession = createSlice({
    name: 'session',
    initialState: {},
    reducers: {
        setSession: (state, { payload }) => {
            state.username = payload.username;
            state.sessionId = payload.sessionId;
        },
        receiveUser: (state, { payload }) => {
            state.user = payload;
        },
    },
});

export default sliceSession;
// ACTIONS
export const requestAuth = createAction('REQUEST_AUTH');
export const requestWhoami = createAction('REQUEST_WHOAMI');
export const { setSession, receiveUser } = sliceSession.actions;

// SELECTORS
export const selectUsername = state => state.session.username;
export const selectUser = state => state.session.user || {};
export const selectUserId = state => state.session.user?.id;
