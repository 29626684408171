import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from '../../hooks/useTranslate';
import ChecklistQuestion from './ChecklistQuestion';

const ChecklistQuestions = ({ value, onChange, readOnly }) => {
    const translate = useTranslate();
    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                {translate('questions')}
            </Typography>
            {value?.map(({ id: questionId, title, items, images, attachment }, index) => (
                <ChecklistQuestion
                    key={questionId}
                    questionId={questionId}
                    title={title || `${index + 1}. `}
                    items={items}
                    images={images}
                    attachment={attachment}
                    onChange={onChange}
                    readOnly={readOnly}
                />
            ))}
        </Box>
    );
};

ChecklistQuestions.propTypes = {
    onChange: PropTypes.any.isRequired,
    readOnly: PropTypes.bool,
    value: PropTypes.array,
};
ChecklistQuestions.defaultProps = {
    value: [],
    readOnly: undefined,
};

export default ChecklistQuestions;
