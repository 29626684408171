import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import i from '../../ability/ability';
import { editEventForm, editStampingLogForm } from '../../configuration/forms';
import { deleteStampingLog, getHrPersonalCalendar, getSiteCalendar, saveEvent, saveStampingLog } from '../../store/proxies/tigestimProxies';
import { openDialog } from '../../store/slices/sliceDialog';
import { refetch } from '../../store/slices/sliceFetching';
import { setErrorNotification, setSuccessNotification } from '../../store/slices/sliceNotification';
import { formValidator, internalInvokeDelete } from '../../utils/common';
import EditDialog from '../dialog/EditDialog';

const useStyles = makeStyles(() => ({
    appointment: {
        borderRadius: 2,
        opacity: 1,
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
            opacity: 0.8,
        },
    },
    text: {
        color: '#FFF',
    },
}));

export const Appointment = ({ data, ...props }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    switch (data.eventType) {
        case 'stampingLogs':
            return (
                <Appointments.Appointment
                    data={data}
                    {...props}
                    className={classes.appointment}
                    style={{ backgroundColor: data.color }}
                    onClick={() => {
                        return i.can('update', 'stampingsLog') && dispatch(
                            openDialog({
                                fullWidth: true,
                                component: EditDialog,
                                componentProps: {
                                    form: editStampingLogForm,
                                    title: 'edit_stamping_log',
                                },
                                data: data.item,
                                cancelLabel: 'cancel',
                                confirmationLabel: 'save',
                                confirmationEnable: (data) => formValidator(editStampingLogForm, data),
                                confirmationCallback: async value => {
                                    try {
                                        await saveStampingLog.call(value.id, value);
                                        dispatch(refetch(getHrPersonalCalendar.name));
                                        return setSuccessNotification('stamping_log_saved_successfully');
                                    } catch (e) {
                                        return setErrorNotification(`${e?.response?.status} : ${e?.response?.statusText}`);
                                    }
                                },
                                negationLabel: 'remove',
                                negationCallback: () => internalInvokeDelete(deleteStampingLog, data.item, { onSuccess: () => dispatch(refetch(getHrPersonalCalendar.name)) }),
                            })
                        );
                    }}
                />
            );
        case 'events':
            return (
                <Appointments.Appointment
                    data={data}
                    {...props}
                    className={classes.appointment}
                    style={{ backgroundColor: data.color }}
                    onClick={() => {
                        const deltaMinutes = moment(data.endDate).diff(moment(data.startDate), 'minutes');
                        let minutes = deltaMinutes % 60;
                        let hours = Math.floor(deltaMinutes / 60)
                        if (hours > 8) {
                            minutes = undefined;
                            hours = undefined;
                        }
                        return dispatch(
                            openDialog({
                                fullWidth: true,
                                component: EditDialog,
                                componentProps: {
                                    form: editEventForm,
                                    title: 'edit_event',
                                },
                                data: {...data, hours, minutes},
                                cancelLabel: 'cancel',
                                confirmationLabel: 'save',
                                confirmationEnable: (data) => formValidator(editEventForm, data),
                                confirmationCallback: async value => {
                                    try {
                                        const { constructionId, title, description, place, hours, duration, isPublic, isFestivity } = value;
                                        const eventId = value.id.split('_')[0];
                                        const minutes = value.minutes + hours * 60;
                                        const endDate = duration ? `${moment(value.startDate).add( minutes, 'minutes').format('yyyy-MM-DD HH:mm:ss')}` : moment(value.endDate).format('yyyy-MM-DD HH:mm:ss')
                                        const startDate = `${moment(value.startDate).format('yyyy-MM-DD HH:mm:ss')}`;
                                        const payload = {
                                            id: eventId,
                                            constructionId,
                                            description,
                                            startDate,
                                            endDate,
                                            isFestivity,
                                            isPublic,
                                            place,
                                            title,
                                        };
                                        await saveEvent.call(payload.id, payload);
                                        dispatch(refetch(getSiteCalendar.name));
                                        return setSuccessNotification('event_saved_successfully');
                                    } catch (e) {
                                        return setErrorNotification(`${e?.response?.status} : ${e?.response?.statusText}`);
                                    }
                                },
                            })
                        );
                    }}
                />
            );
        default:
            return <Appointments.Appointment data={data} {...props} className={classes.appointment} style={{ backgroundColor: data.color }} />;
    }
};

Appointment.propTypes = {
    data: PropTypes.object,
};
Appointment.defaultProps = {
    data: {},
};

export default Appointment;
