import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EnhancedTable from '../../components/table/EnhancedTable';
import EnhancedCard from '../../components/utilities/EnhancedCard';
import EnhancedPagination from '../../components/utilities/EnhancedPagination';
import useFetch from '../../hooks/useFetch';
import usePagination from '../../hooks/usePagination';
import useSorting from '../../hooks/useSorting';
import { getSitesFav } from '../../store/proxies/tigestimProxies';
import { selectUserId } from '../../store/slices/sliceAuth';
import { setOrder, setPage } from '../../store/slices/sliceFiltering';
// eslint-disable-next-line
const SitesTableOnlyFav = ({ actions, columns, proxy, params, handleResponse, onItemClick, keyComposition, id, defaultValue }) => {
    const dispatch = useDispatch();
    const { pageNumber: pageNumberFav, pageSize: pageSizeFav } = usePagination(getSitesFav.name);
    const { sortBy: sortByFav, sortDirection: sortDirectionFav } = useSorting(getSitesFav.name);
    const userId = useSelector(selectUserId);

    const [responseFavorites, isLoadingFavorites] = useFetch(
        getSitesFav,
        {
            pageNumber: pageNumberFav - 1,
            pageSize: pageSizeFav,
            orderBy: sortByFav,
            orderDirection: sortDirectionFav,
            userId,
            filter: 'forUser',
        },
        [pageNumberFav, pageSizeFav, sortByFav, sortDirectionFav, userId],
        [userId],
        defaultValue,
        handleResponse
    );

    useEffect(() => {
        dispatch(setPage({ view: proxy.name, page: { pageNumber: 1 } }));
    }, [dispatch, proxy.name]);

    return (
        <Box height={1} width={1}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <EnhancedCard
                        pb={1}
                        title="construction_sites"
                        ComponentBottom={<EnhancedPagination name={getSitesFav.name} pages={responseFavorites.totalPages} forcedPageSize={15} />}
                    >
                        <EnhancedTable
                            items={responseFavorites.data}
                            columns={columns}
                            isLoading={isLoadingFavorites}
                            onItemClick={onItemClick && (item => onItemClick(item, dispatch))}
                            keyComposition={({ favouriteSiteId }) => favouriteSiteId}
                            sortBy={sortByFav}
                            sortDirection={sortDirectionFav}
                            handleRequestSort={order => {
                                dispatch(setOrder({ view: getSitesFav.name, order }));
                            }}
                        />
                    </EnhancedCard>
                </Grid>
            </Grid>
        </Box>
    );
};

SitesTableOnlyFav.propTypes = {
    id: PropTypes.number,
    actions: PropTypes.array,
    columns: PropTypes.array,
    proxy: PropTypes.object,
    params: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onItemClick: PropTypes.func,
    handleResponse: PropTypes.func,
    keyComposition: PropTypes.func,
    defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
SitesTableOnlyFav.defaultProps = {
    id: undefined,
    actions: [],
    columns: [],
    proxy: {},
    params: () => {},
    onItemClick: undefined,
    handleResponse: undefined,
    keyComposition: undefined,
    defaultValue: {},
};

export default SitesTableOnlyFav;
