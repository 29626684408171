import { Box, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import EnhancedAutocomplete from './utilities/EnhancedAutocomplete';

const Filters = ({ filters, filter, onChange }) => {
    return (
        <Box display="flex">
            {filters.map(({ param, options, optionsFetch, placeholder, defaultValue }) => (
                <Box width={220} key={param} pr={1}>
                    <Paper>
                        <EnhancedAutocomplete
                            label={null}
                            value={filter[param]}
                            onChange={value => onChange(prev => ({ ...prev, [param]: value || defaultValue }))}
                            options={options}
                            optionsFetch={optionsFetch}
                            variant="outlined"
                            inputPadding="none"
                            placeholder={placeholder}
                        />
                    </Paper>
                </Box>
            ))}
        </Box>
    );
};

Filters.propTypes = {
    onChange: PropTypes.func.isRequired,
    filter: PropTypes.object,
    filters: PropTypes.array,
};

Filters.defaultProps = {
    filters: [],
    filter: {},
};

export default Filters;
