import { v4 as uuid } from 'uuid';

export const mockScheduleCatagories = {
    data: [
        {
            id: 1,
            categoryName: 'Categoria 1',
        },
        {
            id: 2,
            categoryName: 'Categoria 2',
        },
        {
            id: 3,
            categoryName: 'Categoria 3',
        },
        {
            id: 4,
            categoryName: 'Categoria 4',
        },
    ],
    totalPages: 1,
};

export const mockReports = [
    {
        value: 1,
        label: 'Report 1',
    },
    {
        value: 2,
        label: 'Report 2',
    },
    {
        value: 3,
        label: 'Report 3',
    },
    {
        value: 4,
        label: 'Report 4',
    },
];

export const mockSchedules = {
    data: [
        {
            id: 1,
            title: `Titolo dell'attività 1`,
            description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
            workers: [
                {
                    id: 456,
                    firstName: 'Maurizio',
                    lastName: 'Pavesi',
                },
                {
                    id: 438,
                    firstName: 'Franco',
                    lastName: 'Cerutti',
                },
                {
                    id: 440,
                    firstName: 'Mario',
                    lastName: 'Rossi',
                },
            ],
            constructionSiteId: 4,
            constructionSiteTitle: 'Cantiere Bioggio',
            categoryId: 1,
            startAt: '2021-04-01',
            endAt: '2021-04-07',
            reportId: 1,
            reportUrl: undefined,
            notes: 'Note aggiuntive. Anche qui c’è tutto lo spazio quindi si può scendere ad un dettaglio molto particolareggiato.',
            tasks: [
                {
                    id: uuid(),
                    title: 'Task 1',
                    workers: [
                        {
                            id: 456,
                            firstName: 'Maurizio',
                            lastName: 'Pavesi',
                        },
                        {
                            id: 438,
                            firstName: 'Franco',
                            lastName: 'Cerutti',
                        },
                    ],
                    activities:
                        'Descrizione dell’attività nel dettaglio da svolgere. Anche qui c’è tutto lo spazio quindi si può scendere ad un dettaglio molto particolareggiato. ',
                },
                {
                    id: uuid(),
                    title: 'Task 2',
                    workers: [
                        {
                            id: 440,
                            firstName: 'Mario',
                            lastName: 'Rossi',
                        },
                    ],
                    activities:
                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vestibulum ante eget nisi pellentesque, eget finibus metus vulputate. Duis scelerisque eu velit et ultricies. Duis vel viverra magna ',
                },
            ],
        },
        {
            id: 2,
            title: `Titolo dell'attività 2`,
            description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
            workers: [
                {
                    id: 436,
                    firstName: 'Tonno',
                    lastName: 'Scontento',
                },
                {
                    id: 439,
                    firstName: 'Gianfranco',
                    lastName: 'Cuordileone',
                },
            ],
            constructionSiteId: 5,
            constructionSiteTitle: 'Cantiere Mendrisio',
            categoryId: 2,
            startAt: '2021-04-07',
            endAt: '2021-04-14',
            reportId: undefined,
            reportUrl: undefined,
            notes: 'Note aggiuntive. Anche qui c’è tutto lo spazio quindi si può scendere ad un dettaglio molto particolareggiato.',
            tasks: [
                {
                    id: uuid(),
                    title: 'Task 1',
                    workers: [
                        {
                            id: 436,
                            firstName: 'Tonno',
                            lastName: 'Scontento',
                        },
                    ],
                    activities:
                        'Descrizione dell’attività nel dettaglio da svolgere. Anche qui c’è tutto lo spazio quindi si può scendere ad un dettaglio molto particolareggiato. ',
                },
                {
                    id: uuid(),
                    title: 'Task 2',
                    workers: [
                        {
                            id: 439,
                            firstName: 'Gianfranco',
                            lastName: 'Cuordileone',
                        },
                    ],
                    activities:
                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vestibulum ante eget nisi pellentesque, eget finibus metus vulputate. Duis scelerisque eu velit et ultricies. Duis vel viverra magna ',
                },
            ],
        },
    ],
    totalPages: 1,
};

export const festivities = [
    {
        title: 'Epifania',
        description: 'Festività regionale',
        place: 'Grigioni, Svitto, Ticino, Uri',
        startDate: '2020-01-06 00:00:00',
        endDate: '2020-01-07 00:00:00',
    },
    {
        title: 'Corpus Domini',
        description: 'Festività regionale',
        place: 'Argovia, Appenzello Interno, Friburgo, Neuchâtel, Giura, Lucerna, Nidvaldo, Obvaldo, Soletta, Svitto, Ticino, Uri, Vallese, Zugo',
        startDate: '2020-06-11 00:00:00',
        endDate: '2020-06-12 00:00:00',
    },
    {
        title: 'Festa Nazionale Svizzera',
        description: 'Festività Svizzera',
        place: 'Svizzera',
        startDate: '2020-08-01 00:00:00',
        endDate: '2020-08-02 00:00:00',
    },
    {
        title: 'Jeune Genevois',
        description: 'Ginevra',
        place: 'Ginevra',
        startDate: '2020-09-10 00:00:00',
        endDate: '2020-09-11 00:00:00',
    },
    {
        title: 'Sechseläute',
        description: 'Zurigo',
        place: 'Zurigo',
        startDate: '2021-04-19 00:00:00',
        endDate: '2021-04-20 00:00:00',
    },
    {
        title: 'Pentecoste',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Neuchâtel, Ginevra, Giura',
        startDate: '2021-05-23 00:00:00',
        endDate: '2021-05-24 00:00:00',
    },
    {
        title: 'Lunedi di Pentecoste',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Ginevra, Giura',
        startDate: '2021-05-24 00:00:00',
        endDate: '2021-05-25 00:00:00',
    },
    {
        title: 'Immacolata Concezione',
        description: 'Festività regionale',
        place: 'Argovia, Appenzello Interno, Friburgo, Grigioni, Lucerna, Nidvaldo, Obvaldo, Svitto, Ticino, Uri, Vallese, Zugo',
        startDate: '2021-12-08 00:00:00',
        endDate: '2021-12-09 00:00:00',
    },
    {
        title: 'Giorno di Natale',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Neuchâtel, Ginevra, Giura',
        startDate: '2021-12-25 00:00:00',
        endDate: '2021-12-26 00:00:00',
    },
    {
        title: 'Giorno di Santo Stefano',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vallese, Neuchâtel',
        startDate: '2021-12-26 00:00:00',
        endDate: '2021-12-27 00:00:00',
    },
    {
        title: 'Restaurazione della Repubblica',
        description: 'Ginevra',
        place: 'Ginevra',
        startDate: '2021-12-31 00:00:00',
        endDate: '2022-01-01 00:00:00',
    },
    {
        title: 'Festa del Lavoro',
        description: 'Festività regionale',
        place: 'Argovia, Basilea Campagna, Basilea Città, Friburgo, Giura, Sciaffusa, Soletta, Turgovia, Ticino, Zurigo',
        startDate: '2022-05-01 00:00:00',
        endDate: '2022-05-02 00:00:00',
    },
    {
        title: 'Digiuno federale',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Neuchâtel, Giura',
        startDate: '2022-09-18 00:00:00',
        endDate: '2022-09-19 00:00:00',
    },
    {
        title: 'Restaurazione della Repubblica',
        description: 'Ginevra',
        place: 'Ginevra',
        startDate: '2022-12-31 00:00:00',
        endDate: '2023-01-01 00:00:00',
    },
    {
        title: 'Capodanno',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Neuchâtel, Ginevra, Giura',
        startDate: '2020-01-01 00:00:00',
        endDate: '2020-01-02 00:00:00',
    },
    {
        title: 'Immacolata Concezione',
        description: 'Festività regionale',
        place: 'Argovia, Appenzello Interno, Friburgo, Grigioni, Lucerna, Nidvaldo, Obvaldo, Svitto, Ticino, Uri, Vallese, Zugo',
        startDate: '2020-12-08 00:00:00',
        endDate: '2020-12-09 00:00:00',
    },
    {
        title: 'Vigilia di Natale',
        description: 'Festività Svizzera',
        place: 'Svizzera',
        startDate: '2020-12-24 00:00:00',
        endDate: '2020-12-25 00:00:00',
    },
    {
        title: 'Giorno della Repubblica',
        description: 'Neuchâtel',
        place: 'Neuchâtel',
        startDate: '2021-03-01 00:00:00',
        endDate: '2021-03-02 00:00:00',
    },
    {
        title: 'Lunedi di Pasqua',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Ginevra, Giura',
        startDate: '2021-04-05 00:00:00',
        endDate: '2021-04-06 00:00:00',
    },
    {
        title: 'Venerdì Santo',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Vaud, Neuchâtel, Ginevra, Giura',
        startDate: '2022-04-15 00:00:00',
        endDate: '2022-04-16 00:00:00',
    },
    {
        title: 'Domenica di Pasqua',
        description: 'Festività Svizzera',
        place: 'Svizzera',
        startDate: '2022-04-17 00:00:00',
        endDate: '2022-04-18 00:00:00',
    },
    {
        title: 'Knabenschiesse',
        description: 'Zurigo',
        place: 'Zurigo',
        startDate: '2022-09-11 00:00:00',
        endDate: '2022-09-12 00:00:00',
    },
    {
        title: 'Knabenschiesse',
        description: 'Zurigo',
        place: 'Zurigo',
        startDate: '2022-09-12 00:00:00',
        endDate: '2022-09-13 00:00:00',
    },
    {
        title: 'Lunedì del digiuno federale',
        description: 'Vaud',
        place: 'Vaud',
        startDate: '2022-09-19 00:00:00',
        endDate: '2022-09-20 00:00:00',
    },
    {
        title: 'Giorno della Repubblica',
        description: 'Neuchâtel',
        place: 'Neuchâtel',
        startDate: '2020-03-01 00:00:00',
        endDate: '2020-03-02 00:00:00',
    },
    {
        title: 'Knabenschiesse',
        description: 'Zurigo',
        place: 'Zurigo',
        startDate: '2020-09-12 00:00:00',
        endDate: '2020-09-13 00:00:00',
    },
    {
        title: 'Knabenschiesse',
        description: 'Zurigo',
        place: 'Zurigo',
        startDate: '2020-09-14 00:00:00',
        endDate: '2020-09-15 00:00:00',
    },
    {
        title: 'Capodanno',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Neuchâtel, Ginevra, Giura',
        startDate: '2021-01-01 00:00:00',
        endDate: '2021-01-02 00:00:00',
    },
    {
        title: 'Epifania',
        description: 'Festività regionale',
        place: 'Grigioni, Svitto, Ticino, Uri',
        startDate: '2021-01-06 00:00:00',
        endDate: '2021-01-07 00:00:00',
    },
    {
        title: 'Domenica di Pasqua',
        description: 'Festività Svizzera',
        place: 'Svizzera',
        startDate: '2021-04-04 00:00:00',
        endDate: '2021-04-05 00:00:00',
    },
    {
        title: 'Assunzione di Maria',
        description: 'Festività regionale',
        place: 'Argovia, Appenzello Interno, Friburgo, Grigioni, Giura, Lucerna, Nidvaldo, Obvaldo, Soletta, Svitto, Ticino, Uri, Vallese, Zugo',
        startDate: '2021-08-15 00:00:00',
        endDate: '2021-08-16 00:00:00',
    },
    {
        title: 'San Giuseppe',
        description: 'Festività regionale',
        place: 'Lucerna, Nidvaldo, Soletta, Svitto, Ticino, Uri, Vallese',
        startDate: '2022-03-19 00:00:00',
        endDate: '2022-03-20 00:00:00',
    },
    {
        title: 'Näfelser Fahrt',
        description: 'Glarona',
        place: 'Glarona',
        startDate: '2022-04-07 00:00:00',
        endDate: '2022-04-08 00:00:00',
    },
    {
        title: 'Vigilia di Natale',
        description: 'Festività Svizzera',
        place: 'Svizzera',
        startDate: '2022-12-24 00:00:00',
        endDate: '2022-12-25 00:00:00',
    },
    {
        title: 'Näfelser Fahrt',
        description: 'Glarona',
        place: 'Glarona',
        startDate: '2020-04-02 00:00:00',
        endDate: '2020-04-03 00:00:00',
    },
    {
        title: 'Sechseläute',
        description: 'Zurigo',
        place: 'Zurigo',
        startDate: '2020-04-20 00:00:00',
        endDate: '2020-04-21 00:00:00',
    },
    {
        title: 'Lunedi di Pentecoste',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Ginevra, Giura',
        startDate: '2020-06-01 00:00:00',
        endDate: '2020-06-02 00:00:00',
    },
    {
        title: 'Giorno di Santo Stefano',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vallese, Neuchâtel',
        startDate: '2020-12-26 00:00:00',
        endDate: '2020-12-27 00:00:00',
    },
    {
        title: 'San Basilio',
        description: 'Festività regionale',
        place: 'Argovia, Berna, Friburgo, Glarona, Giura, Lucerna, Neuchâtel, Obvaldo, Sciaffusa, Soletta, Turgovia, Vaud, Zugo, Zurigo, Nidvaldo',
        startDate: '2021-01-02 00:00:00',
        endDate: '2021-01-03 00:00:00',
    },
    {
        title: 'San Giuseppe',
        description: 'Festività regionale',
        place: 'Lucerna, Nidvaldo, Soletta, Svitto, Ticino, Uri, Vallese',
        startDate: '2021-03-19 00:00:00',
        endDate: '2021-03-20 00:00:00',
    },
    {
        title: 'Nicola di Flüe',
        description: 'Obvaldo',
        place: 'Obvaldo',
        startDate: '2021-09-25 00:00:00',
        endDate: '2021-09-26 00:00:00',
    },
    {
        title: 'Vigilia di Natale',
        description: 'Festività Svizzera',
        place: 'Svizzera',
        startDate: '2021-12-24 00:00:00',
        endDate: '2021-12-25 00:00:00',
    },
    {
        title: 'Epifania',
        description: 'Festività regionale',
        place: 'Grigioni, Svitto, Ticino, Uri',
        startDate: '2022-01-06 00:00:00',
        endDate: '2022-01-07 00:00:00',
    },
    {
        title: 'Commemorazione del plebiscito',
        description: 'Giura',
        place: 'Giura',
        startDate: '2022-06-23 00:00:00',
        endDate: '2022-06-24 00:00:00',
    },
    {
        title: 'Festa Nazionale Svizzera',
        description: 'Festività Svizzera',
        place: 'Svizzera',
        startDate: '2022-08-01 00:00:00',
        endDate: '2022-08-02 00:00:00',
    },
    {
        title: 'Ognissanti',
        description: 'Festività regionale',
        place: 'Argovia, Appenzello Interno, Friburgo, Glarona, Giura, Lucerna, Nidvaldo, Obvaldo, San Gallo, Soletta, Svitto, Ticino, Uri, Vallese, Zugo',
        startDate: '2022-11-01 00:00:00',
        endDate: '2022-11-02 00:00:00',
    },
    {
        title: 'Giorno di Natale',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Neuchâtel, Ginevra, Giura',
        startDate: '2022-12-25 00:00:00',
        endDate: '2022-12-26 00:00:00',
    },
    {
        title: 'Vigilia di Capodanno',
        description: 'Festività Svizzera',
        place: 'Svizzera',
        startDate: '2022-12-31 00:00:00',
        endDate: '2023-01-01 00:00:00',
    },
    {
        title: 'Commemorazione del plebiscito',
        description: 'Giura',
        place: 'Giura',
        startDate: '2020-06-23 00:00:00',
        endDate: '2020-06-24 00:00:00',
    },
    {
        title: 'Lunedì del digiuno federale',
        description: 'Vaud',
        place: 'Vaud',
        startDate: '2020-09-21 00:00:00',
        endDate: '2020-09-22 00:00:00',
    },
    {
        title: 'Giorno di Natale',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Neuchâtel, Ginevra, Giura',
        startDate: '2020-12-25 00:00:00',
        endDate: '2020-12-26 00:00:00',
    },
    {
        title: 'Ascensione',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Neuchâtel, Ginevra, Giura',
        startDate: '2021-05-13 00:00:00',
        endDate: '2021-05-14 00:00:00',
    },
    {
        title: 'Corpus Domini',
        description: 'Festività regionale',
        place: 'Argovia, Appenzello Interno, Friburgo, Neuchâtel, Giura, Lucerna, Nidvaldo, Obvaldo, Soletta, Svitto, Ticino, Uri, Vallese, Zugo',
        startDate: '2021-06-03 00:00:00',
        endDate: '2021-06-04 00:00:00',
    },
    {
        title: 'Festa Nazionale Svizzera',
        description: 'Festività Svizzera',
        place: 'Svizzera',
        startDate: '2021-08-01 00:00:00',
        endDate: '2021-08-02 00:00:00',
    },
    {
        title: 'Knabenschiesse',
        description: 'Zurigo',
        place: 'Zurigo',
        startDate: '2021-09-12 00:00:00',
        endDate: '2021-09-13 00:00:00',
    },
    {
        title: 'San Maurizio',
        description: 'Festività regionale',
        place: 'Appenzello Interno, Soletta',
        startDate: '2021-09-22 00:00:00',
        endDate: '2021-09-23 00:00:00',
    },
    {
        title: 'Vigilia di Capodanno',
        description: 'Festività Svizzera',
        place: 'Svizzera',
        startDate: '2021-12-31 00:00:00',
        endDate: '2022-01-01 00:00:00',
    },
    {
        title: 'Assunzione di Maria',
        description: 'Festività regionale',
        place: 'Argovia, Appenzello Interno, Friburgo, Grigioni, Giura, Lucerna, Nidvaldo, Obvaldo, Soletta, Svitto, Ticino, Uri, Vallese, Zugo',
        startDate: '2022-08-15 00:00:00',
        endDate: '2022-08-16 00:00:00',
    },
    {
        title: 'San Maurizio',
        description: 'Festività regionale',
        place: 'Appenzello Interno, Soletta',
        startDate: '2022-09-22 00:00:00',
        endDate: '2022-09-23 00:00:00',
    },
    {
        title: 'Venerdì Santo',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Vaud, Neuchâtel, Ginevra, Giura',
        startDate: '2020-04-10 00:00:00',
        endDate: '2020-04-11 00:00:00',
    },
    {
        title: 'Lunedi di Pasqua',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Ginevra, Giura',
        startDate: '2020-04-13 00:00:00',
        endDate: '2020-04-14 00:00:00',
    },
    {
        title: 'Festa del Lavoro',
        description: 'Festività regionale',
        place: 'Argovia, Basilea Campagna, Basilea Città, Friburgo, Giura, Sciaffusa, Soletta, Turgovia, Ticino, Zurigo',
        startDate: '2020-05-01 00:00:00',
        endDate: '2020-05-02 00:00:00',
    },
    {
        title: 'Restaurazione della Repubblica',
        description: 'Ginevra',
        place: 'Ginevra',
        startDate: '2020-12-31 00:00:00',
        endDate: '2021-01-01 00:00:00',
    },
    {
        title: 'Commemorazione del plebiscito',
        description: 'Giura',
        place: 'Giura',
        startDate: '2021-06-23 00:00:00',
        endDate: '2021-06-24 00:00:00',
    },
    {
        title: 'Digiuno federale',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Neuchâtel, Giura',
        startDate: '2021-09-19 00:00:00',
        endDate: '2021-09-20 00:00:00',
    },
    {
        title: 'Capodanno',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Neuchâtel, Ginevra, Giura',
        startDate: '2022-01-01 00:00:00',
        endDate: '2022-01-02 00:00:00',
    },
    {
        title: 'San Basilio',
        description: 'Festività regionale',
        place: 'Argovia, Berna, Friburgo, Glarona, Giura, Lucerna, Neuchâtel, Obvaldo, Sciaffusa, Soletta, Turgovia, Vaud, Zugo, Zurigo, Nidvaldo',
        startDate: '2022-01-02 00:00:00',
        endDate: '2022-01-03 00:00:00',
    },
    {
        title: 'Lunedi di Pasqua',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Ginevra, Giura',
        startDate: '2022-04-18 00:00:00',
        endDate: '2022-04-19 00:00:00',
    },
    {
        title: 'Ascensione',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Neuchâtel, Ginevra, Giura',
        startDate: '2022-05-26 00:00:00',
        endDate: '2022-05-27 00:00:00',
    },
    {
        title: 'Pentecoste',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Neuchâtel, Ginevra, Giura',
        startDate: '2022-06-05 00:00:00',
        endDate: '2022-06-06 00:00:00',
    },
    {
        title: 'Nicola di Flüe',
        description: 'Obvaldo',
        place: 'Obvaldo',
        startDate: '2022-09-25 00:00:00',
        endDate: '2022-09-26 00:00:00',
    },
    {
        title: 'San Basilio',
        description: 'Festività regionale',
        place: 'Argovia, Berna, Friburgo, Glarona, Giura, Lucerna, Neuchâtel, Obvaldo, Sciaffusa, Soletta, Turgovia, Vaud, Zugo, Zurigo, Nidvaldo',
        startDate: '2020-01-02 00:00:00',
        endDate: '2020-01-03 00:00:00',
    },
    {
        title: 'Domenica di Pasqua',
        description: 'Festività Svizzera',
        place: 'Svizzera',
        startDate: '2020-04-12 00:00:00',
        endDate: '2020-04-13 00:00:00',
    },
    {
        title: 'Pentecoste',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Neuchâtel, Ginevra, Giura',
        startDate: '2020-05-31 00:00:00',
        endDate: '2020-06-01 00:00:00',
    },
    {
        title: 'Digiuno federale',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Neuchâtel, Giura',
        startDate: '2020-09-20 00:00:00',
        endDate: '2020-09-21 00:00:00',
    },
    {
        title: 'San Maurizio',
        description: 'Festività regionale',
        place: 'Appenzello Interno, Soletta',
        startDate: '2020-09-22 00:00:00',
        endDate: '2020-09-23 00:00:00',
    },
    {
        title: 'Nicola di Flüe',
        description: 'Obvaldo',
        place: 'Obvaldo',
        startDate: '2020-09-25 00:00:00',
        endDate: '2020-09-26 00:00:00',
    },
    {
        title: 'Ognissanti',
        description: 'Festività regionale',
        place: 'Argovia, Appenzello Interno, Friburgo, Glarona, Giura, Lucerna, Nidvaldo, Obvaldo, San Gallo, Soletta, Svitto, Ticino, Uri, Vallese, Zugo',
        startDate: '2020-11-01 00:00:00',
        endDate: '2020-11-02 00:00:00',
    },
    {
        title: 'Festa del Lavoro',
        description: 'Festività regionale',
        place: 'Argovia, Basilea Campagna, Basilea Città, Friburgo, Giura, Sciaffusa, Soletta, Turgovia, Ticino, Zurigo',
        startDate: '2021-05-01 00:00:00',
        endDate: '2021-05-02 00:00:00',
    },
    {
        title: 'SS. Pietro e Paolo',
        description: 'Festività regionale',
        place: 'Ticino, Grigioni, Soletta',
        startDate: '2021-06-29 00:00:00',
        endDate: '2021-06-30 00:00:00',
    },
    {
        title: 'Knabenschiesse',
        description: 'Zurigo',
        place: 'Zurigo',
        startDate: '2021-09-11 00:00:00',
        endDate: '2021-09-12 00:00:00',
    },
    {
        title: 'Knabenschiesse',
        description: 'Zurigo',
        place: 'Zurigo',
        startDate: '2021-09-13 00:00:00',
        endDate: '2021-09-14 00:00:00',
    },
    {
        title: 'Ognissanti',
        description: 'Festività regionale',
        place: 'Argovia, Appenzello Interno, Friburgo, Glarona, Giura, Lucerna, Nidvaldo, Obvaldo, San Gallo, Soletta, Svitto, Ticino, Uri, Vallese, Zugo',
        startDate: '2021-11-01 00:00:00',
        endDate: '2021-11-02 00:00:00',
    },
    {
        title: 'Giorno della Repubblica',
        description: 'Neuchâtel',
        place: 'Neuchâtel',
        startDate: '2022-03-01 00:00:00',
        endDate: '2022-03-02 00:00:00',
    },
    {
        title: 'Sechseläute',
        description: 'Zurigo',
        place: 'Zurigo',
        startDate: '2022-04-25 00:00:00',
        endDate: '2022-04-26 00:00:00',
    },
    {
        title: 'Lunedi di Pentecoste',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Ginevra, Giura',
        startDate: '2022-06-06 00:00:00',
        endDate: '2022-06-07 00:00:00',
    },
    {
        title: 'Immacolata Concezione',
        description: 'Festività regionale',
        place: 'Argovia, Appenzello Interno, Friburgo, Grigioni, Lucerna, Nidvaldo, Obvaldo, Svitto, Ticino, Uri, Vallese, Zugo',
        startDate: '2022-12-08 00:00:00',
        endDate: '2022-12-09 00:00:00',
    },
    {
        title: 'Giorno di Santo Stefano',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vallese, Neuchâtel, Neuchâtel',
        startDate: '2022-12-26 00:00:00',
        endDate: '2022-12-27 00:00:00',
    },
    {
        title: 'San Giuseppe',
        description: 'Festività regionale',
        place: 'Lucerna, Nidvaldo, Soletta, Svitto, Ticino, Uri, Vallese',
        startDate: '2020-03-19 00:00:00',
        endDate: '2020-03-20 00:00:00',
    },
    {
        title: 'Ascensione',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Ticino, Vaud, Vallese, Neuchâtel, Ginevra, Giura',
        startDate: '2020-05-21 00:00:00',
        endDate: '2020-05-22 00:00:00',
    },
    {
        title: 'SS. Pietro e Paolo',
        description: 'Festività regionale',
        place: 'Ticino, Grigioni, Soletta',
        startDate: '2020-06-29 00:00:00',
        endDate: '2020-06-30 00:00:00',
    },
    {
        title: 'Assunzione di Maria',
        description: 'Festività regionale',
        place: 'Argovia, Appenzello Interno, Friburgo, Grigioni, Giura, Lucerna, Nidvaldo, Obvaldo, Soletta, Svitto, Ticino, Uri, Vallese, Zugo',
        startDate: '2020-08-15 00:00:00',
        endDate: '2020-08-16 00:00:00',
    },
    {
        title: 'Knabenschiesse',
        description: 'Zurigo',
        place: 'Zurigo',
        startDate: '2020-09-13 00:00:00',
        endDate: '2020-09-14 00:00:00',
    },
    {
        title: 'Vigilia di Capodanno',
        description: 'Festività Svizzera',
        place: 'Svizzera',
        startDate: '2020-12-31 00:00:00',
        endDate: '2021-01-01 00:00:00',
    },
    {
        title: 'Jeune Genevois',
        description: 'Ginevra',
        place: 'Ginevra',
        startDate: '2021-09-09 00:00:00',
        endDate: '2021-09-10 00:00:00',
    },
    {
        title: 'Lunedì del digiuno federale',
        description: 'Vaud',
        place: 'Vaud',
        startDate: '2021-09-20 00:00:00',
        endDate: '2021-09-21 00:00:00',
    },
    {
        title: 'Corpus Domini',
        description: 'Festività regionale',
        place: 'Argovia, Appenzello Interno, Friburgo, Neuchâtel, Giura, Lucerna, Nidvaldo, Obvaldo, Soletta, Svitto, Ticino, Uri, Vallese, Zugo',
        startDate: '2022-06-16 00:00:00',
        endDate: '2022-06-17 00:00:00',
    },
    {
        title: 'SS. Pietro e Paolo',
        description: 'Festività regionale',
        place: 'Ticino, Grigioni, Soletta',
        startDate: '2022-06-29 00:00:00',
        endDate: '2022-06-30 00:00:00',
    },
    {
        title: 'Jeune Genevois',
        description: 'Festività Ginevra',
        place: 'Ginevra',
        startDate: '2022-09-08 00:00:00',
        endDate: '2022-09-09 00:00:00',
    },
    {
        title: 'Venerdì Santo',
        description: 'Festività regionale',
        place: 'Zurigo, Berna, Lucerna, Uri, Svitto, Obvaldo, Nidvaldo, Glarona, Zugo, Friburgo, Soletta, Basilea Città, Basilea Campagna, Sciaffusa, Appenzello Esterno, Appenzello Interno, San Gallo, Argovia, Grigioni, Turgovia, Vaud, Neuchâtel, Ginevra, Giura',
        startDate: '2021-04-02 00:00:00',
        endDate: '2021-04-03 00:00:00',
    },
    {
        title: 'Näfelser Fahrt',
        description: 'Glarona',
        place: 'Glarona',
        startDate: '2021-04-08 00:00:00',
        endDate: '2021-04-09 00:00:00',
    },
    {
        title: 'Knabenschiesse',
        description: 'Zurigo',
        place: 'Zurigo',
        startDate: '2022-09-10 00:00:00',
        endDate: '2022-09-11 00:00:00',
    },
];
