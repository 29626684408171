import Auth from '@aws-amplify/auth';
import { Add, ArrowBackIos, Check, Delete, Edit, Save, Star } from '@material-ui/icons';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import moment from 'moment';
import EditDialog from '../components/dialog/EditDialog';
import history from '../components/routings/history';
import {
    createEvent, createJournal,
    createPerson,
    createSiteFav,
    deleteJournal,
    deleteSiteFav,
    getPerson, getSiteCalendar, getSiteForUser,
    saveJournal,
    savePerson,
    saveUser
} from '../store/proxies/tigestimProxies';
import { openDialog } from '../store/slices/sliceDialog';
import { refetch } from '../store/slices/sliceFetching';
import { setErrorNotification, setSuccessNotification, setWarningNotification } from '../store/slices/sliceNotification';
import { receiveUser } from '../store/slices/sliceSession';
import { formValidator, formValidators, invokeDelete, invokeSave } from '../utils/common';
import { formatServerDate } from '../utils/formats';
import { basicDataForm, editEventForm, siteJournalForm, workingHoursForm } from './forms';
import { handlePhoneNumberList } from './handlers';
import { validationPerson } from './validations';

const ACCESS_KEY_ID = process.env.REACT_APP_COGNITO_ACCESS_KEY_ID || process.env.REACT_APP_ACCESS_KEY_ID;
const ACCESS_SECRET = process.env.REACT_APP_COGNITO_ACCESS_SECRET || process.env.REACT_APP_ACCESS_SECRET;
const REGION = process.env.REACT_APP_COGNITO_REGION || process.env.REACT_APP_REGION;
const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID;

export const siteJournalActions = [{ label: 'new_journal', icon: Add, onClick: () => history.push(`journal/new`) }];

export const siteJournalEditActions = [
    {
        label: 'save_journal',
        icon: Save,
        onClick: async (data, setLoading) => {
            setLoading(true);
            try {
                await saveJournal.call(data.id, {
                    ...data,
                    media: data.journalImageList.map(({ fentryId }) => fentryId),
                });
                setLoading(false);
                history.goBack();
                return setSuccessNotification('journal_saved_successfully');
            } catch (e) {
                setLoading(false);
                return setErrorNotification(e.message);
            }
        },
    },
    {
        label: 'delete_journal',
        icon: Delete,
        color: 'error.main',
        onClick: (data, setLoading) => invokeDelete(deleteJournal, data, setLoading, { successMessage: 'journal_deleted_successfully', back: true }),
    },
    { label: 'discard_changes', icon: ArrowBackIos, onClick: () => history.goBack() },
];
export const siteJournalViewActions = [
    { label: 'edit_post', icon: Edit, onClick: ({ siteId, id }) => history.push(`/app/sites/sites/${siteId}/journal/${id}/edit`) },
    { label: 'go_back', icon: ArrowBackIos, onClick: () => history.goBack() },
];

export const siteJournalNewActions = [
    {
        label: 'create_journal',
        icon: Save,
        validation: siteJournalForm,
        onClick: async ({ journalImageList = [], ...data }, setLoading) => {
            setLoading(true);
            const journal = {
                createdAt: formatServerDate(moment(), 'YYYY-MM-DD HH:mm:ss'),
                isEditable: 1,
                isVisible: 1,
                media: journalImageList.map(({ fentryId }) => fentryId),
                ...data,
            };
            try {
                await createJournal.call(journal);
                setLoading(false);
                history.goBack();
                return setSuccessNotification('journal_saved_successfully');
            } catch (e) {
                setLoading(false);
                return setErrorNotification(e.message);
            }
        },
    },
    { label: 'discard_changes', icon: ArrowBackIos, onClick: () => history.goBack() },
];

export const newEventAction = [
    {
        label: 'new_event',
        icon: Add,
        onClick: (data, isLoading, dispatch) =>
            openDialog({
                fullWidth: true,
                component: EditDialog,
                componentProps: {
                    form: editEventForm,
                    title: 'new_event',
                },
                data: {constructionId: data.id},
                cancelLabel: 'cancel',
                confirmationLabel: 'save',
                confirmationEnable: (data) => formValidator(editEventForm, data),
                confirmationCallback: async value => {
                    const { constructionId, title, description, place, startDate, hours, duration } = value;
                    const minutes = value.minutes + hours * 60;
                    const endDate = duration ? `${moment(startDate, 'YYYY-MM-DD HH:mm:ss').add( minutes, 'minutes').format('yyyy-MM-DD HH:mm:ss')}` : value.endDate;
                    try {
                        await createEvent.call({
                            constructionId,
                            title,
                            description,
                            place,
                            startDate,
                            endDate,
                        });
                        dispatch(refetch(getSiteCalendar.name));
                        return setSuccessNotification('event_created_successfully');
                    } catch (e) {
                        return setErrorNotification(`${e?.response?.status} : ${e?.response?.statusText}`);
                    }
                },
            }),
    },
];

export const siteDashboardActions = [
    // { label: 'notifications_settings', icon: Settings, onClick: () => {} },
    { label: 'edit_site', icon: Edit, onClick: () => history.push(`edit`) },
    {
        role: (i) => i.can('update', 'favorites'),
        label: ({ favouriteSiteId }) => (favouriteSiteId ? 'remove_favorite' : 'add_favorite'),
        icon: Star,
        onClick: async ({ constructionSiteId, userId, favouriteSiteId }, setLoading, dispatch) => {
            setLoading(true);
            try {
                if (favouriteSiteId) {
                    await deleteSiteFav.call(favouriteSiteId);
                    dispatch(refetch(getSiteForUser.name));
                } else {
                    await createSiteFav.call({ constructionSiteId, personId: userId });
                    dispatch(refetch(getSiteForUser.name));
                }

                setLoading(false);
                return setSuccessNotification('site_saved_successfully');
            } catch (e) {
                setLoading(false);
                return setErrorNotification(`${e?.response?.status} : ${e?.response?.statusText}`);
            }
        },
    },
];

export const siteContactsActions = [
    {
        role: (i) => i.can('update', 'sites'),
        label: 'edit_contacts',
        icon: RecentActorsIcon,
        onClick: () => history.push(`contacts/edit`),
    },
];
export const siteContactsEditActions = [
    { role: (i) => i.can('update', 'sites'), label: 'go_back', icon: ArrowBackIos, onClick: () => history.goBack() },
];

export const usersActions = [{ label: 'new_user', icon: Add, role: (i) => i.can('update', 'hr'), onClick: () => history.push('edit') }];

export const userViewActions = [{ label: 'edit_user', icon: Edit, role: (i) => i.can('update', 'hr'), onClick: () => history.push('edit') }];

export const workingHoursListActions = [{ label: 'new_working_hours', icon: Add, onClick: () => history.push('working-hours/new') }];

export const userProfileEditActions = [
    {
        label: 'save',
        icon: Check,
        // fixme: 14.06.2022 Controllare perchè non funziona. In caso refattorizzare la formvalidator
        // valid: (data) => formValidators([basicDataForm, workingHoursForm], data),
        onClick: async (data, setLoading) => {
            const { user, legalDocument = [], newPassword, confirmPassword } = data;

            if (newPassword && confirmPassword && (confirmPassword !== newPassword)) {
                setLoading(false);
                return setWarningNotification('passwords_do_not_match');
            }

            const person = {
                ...data,
                id: undefined,
                user: {
                    ...user,
                    phoneNumberListOriginal: undefined,
                    phoneNumberList: Object.keys(user.phoneNumberList || {}).map(key => {
                        const value = user.phoneNumberList?.[key];
                        if (value.type) return value;
                        return { type: key, ...value };
                    }),
                },
                legalDocument: (legalDocument || []).map(({ created, ...rest }) => {
                    if (created) return { ...rest, id: undefined };
                    return rest;
                }),
            };
            setLoading(true);
            try {
                await savePerson.call(user.id, person);
                if (newPassword) {
                    const cognito = new CognitoIdentityServiceProvider({
                        region: REGION,
                        credentials: {
                            accessKeyId: `${ACCESS_KEY_ID}`,
                            secretAccessKey: `${ACCESS_SECRET}`,
                        },
                    });

                    await cognito.adminSetUserPassword({
                        UserPoolId: USER_POOL_ID,
                        Username: user.email,
                        Password: newPassword,
                        Permanent: true,
                    }).promise();
                }
                setLoading(false);
                history.goBack();
                return setSuccessNotification('user_saved_successfully');
            } catch (e) {
                setLoading(false);
                return setErrorNotification(`${e?.response?.status} : ${e?.response?.statusText}`);
            }
        },
    },
    {
        label: 'deactivate_user',
        icon: Delete,
        color: 'error.main',
        show: ({ user }) => user?.isActive === 1,
        onClick: (data, setLoading) => {
            const { phoneNumberListOriginal, ...rest } = data.user;
            const user = {
                ...rest,
                phoneNumberList: phoneNumberListOriginal,
                isActive: 0,
            };
            return openDialog({
                title: 'confirm_delete',
                message: 'are_you_sure_you_want_to_delete_this_item',
                cancelLabel: 'cancel',
                confirmationLabel: 'delete',
                confirmationCallback: () => invokeSave(saveUser, user, setLoading, 'user_saved_successfully'),
            });
        },
    },
    {
        label: 'activate_user',
        icon: Add,
        color: 'success.main',
        show: ({ user }) => user?.isActive === 0,
        onClick: (data, setLoading) => {
            const { phoneNumberListOriginal, ...rest } = data.user;
            const user = {
                ...rest,
                phoneNumberList: phoneNumberListOriginal,
                isActive: 1,
            };
            return invokeSave(saveUser, user, setLoading, 'user_saved_successfully');
        },
    },
    { label: 'discard_changes', icon: ArrowBackIos, onClick: () => history.goBack() },
];

export const userProfileNewActions = [
    {
        label: 'create',
        icon: Check,
        valid: (data) => validationPerson(data) && formValidators([basicDataForm, workingHoursForm], data),
        onClick: async (data, setLoading) => {
            setLoading(true);
            const { user, employee } = data;

            if (user.confirmPassword !== user.password) {
                setLoading(false);
                return setWarningNotification('passwords_do_not_match');
            }

            const person = {
                ...data,
                user: {
                    ...user,
                    confirmPassword: undefined,
                    birthday: formatServerDate(user?.birthday, 'YYYY-MM-DD'),
                    phoneNumberList: Object.keys(user.phoneNumberList || {}).map(key => {
                        const value = user.phoneNumberList?.[key];
                        if (value.type) return value;
                        return { type: key, ...value };
                    }),
                },
                employee: {
                    ...employee,
                    hiredat: formatServerDate(employee?.hiredat, 'YYYY-MM-DD HH:mm:ss') ?? '1970-1-1 00:00:01',
                    terminatedat: formatServerDate(employee?.terminatedat, 'YYYY-MM-DD HH:mm:ss'),
                },
                legalDocument: (data.legalDocument || []).map(({ id, created, ...rest }) => rest),
            };
            try {
                const response = await createPerson.call(person);
                setLoading(false);
                switch (response.message) {
                    case '"User already exists"':
                        return setWarningNotification('user_already_exists');
                    case '"Catched error when user creating"':
                    case 'Catched error when user creating':
                        return setWarningNotification('Catched error when user creating');
                    default:
                        break;
                }

                if (response.user) {
                    history.goBack();
                    return setSuccessNotification('user_saved_successfully');
                }
                return setErrorNotification(response.message);
            } catch (e) {
                setLoading(false);
                return setErrorNotification(e.message);
            } finally {
                setLoading(false);
            }
        },
    },
    { label: 'discard_changes', icon: ArrowBackIos, onClick: () => history.goBack() },
];

export const userLeavesRequestsActions = [
    {
        label: 'submit_new_leave',
        icon: Add,
        onClick: () => {
            history.push('leaves/new');
        },
    },
];

export const userDailyHoursEntryActions = [
    {
        label: 'submit_new_daily_hours',
        icon: Add,
        onClick: () => {
            history.push('dailyHours/new');
        },
    },
];

export const userExpensesRequestsActions = [
    {
        label: 'submit_new_expense',
        icon: Add,
        onClick: () => {
            history.push(`expenses/new`);
        },
    },
];

export const profileViewActions = () => [{ label: 'edit', icon: Edit, onClick: () => history.push('edit') }];

export const profileEditActions = (data, form) => [
    {
        label: 'save',
        icon: Check,
        validation: form,
        onClick: async (data, setLoading, dispatch) => {
            setLoading(true);
            const { confirmPassword, newPassword, oldPassword, ...user } = data;

            if (confirmPassword && newPassword && oldPassword) {
                if (confirmPassword === newPassword) {
                    try {
                        const cognitoUser = await Auth.currentAuthenticatedUser();
                        await Auth.changePassword(cognitoUser, oldPassword, newPassword);
                        setLoading(false);
                        history.goBack();
                        dispatch(setSuccessNotification('password_saved_successfully'));
                    } catch (e) {
                        setLoading(false);
                        dispatch(setWarningNotification('wrong_password'));
                    }
                } else {
                    setLoading(false);
                    dispatch(setWarningNotification('passwords_do_not_match'));
                }
            }

            try {
                await saveUser.call(user.id, {
                    ...user,
                    phoneNumberList: Object.keys(user.phoneNumberList || {}).map(key => {
                        const value = user.phoneNumberList?.[key];
                        if (value.type) return value;
                        return { type: key, ...value };
                    }),
                });
                const response = await getPerson.call(user.id);
                const {users, employees} = response || {};
                
                const [employee] = employees || [];
                const { summerendthr, summerstarthr, winterendthr, winterstarthr } = employee || {};
        
                dispatch(receiveUser({...handlePhoneNumberList(users), summerendthr, summerstarthr, winterendthr, winterstarthr}));
                setLoading(false);

                history.goBack();
                return setSuccessNotification('user_saved_successfully');
            } catch (e) {
                setLoading(false);
                return setErrorNotification(e.message);
            }
        },
    },
    { label: 'discard_changes', icon: ArrowBackIos, onClick: () => history.push('dashboard') },
];