import { StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { MATERNITY_LEAVE, MEDICAL_EXAMINATION, MILITARY_SERVICE, NON_PROFESSIONAL_ACCIDENT, OTHER_REASON, PROFESSIONAL_ACCIDENT, SICKNESS_LEAVE, VACATION, WEATHER_CONDITION } from '../../../constants/statesConstants';
import useTranslate from '../../../hooks/useTranslate';
import { formatDuration } from '../../../utils/formats';

const styles = StyleSheet.create({
    root: {
        fontSize: 11,
        borderLeft: 1,
        borderTop: 1,
        borderRight: 1,
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
    },  
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    cell: {
        borderBottom: 1,
        minHeight: 30,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 8,
    },
    cellNoPAdding: {
        borderBottom: 1,
        minHeight: 30,
    },
    line: {
        flex: 1,
        borderBottom: 1,
        marginRight: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    bold: {
        fontWeight: 700,
    },
    title: {
        fontSize: 20,
    },
    center: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    centerVertical: {
        alignItems: 'center'
    },
    column1: {
        width: 170,
        marginRight: 8
    },
    column2: {
        width: 100,
        marginRight: 8,
        textAlign: 'right'
    },
    column22: {
        width: 40,
        marginRight: 8,
        textAlign: 'right'
    },
    paddingLeft: {
        paddingLeft: 16
    },
    paddingTop: {
        paddingTop: 16
    },
    paddingBottom: {
        marginTop: 8,
        marginBottom: 8,
    },
    checkBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        borderTop: 1,
        borderLeft: 1,
        borderRight: 1,
        marginRight: 8,
        width: 20,
        height: 20
    },
    checkBoxLast: {
        borderBottom: 1
    },
});

const PageTable = ({ data }) => {
    const translate = useTranslate()
    const { personFirstName, personLastName, type, description, duration, startAt, endAt, approvedFirstName, approvedLastName, approvedDate } = data;
    const reasonType = Number(type);

    return (
        <View style={[styles.root, styles.column]}>
            <View style={[styles.row, styles.cellNoPAdding, styles.center]} >
                <Text style={styles.title}>RICHIESTA ASSENZE / VACANZE</Text>
            </View>
            <View style={[styles.row, styles.cell]} >
                <Text style={[styles.bold, styles.column1]}>COGNOME, NOME DIPENDENTE:</Text>
                <View style={styles.line}>
                    <Text>{personFirstName ? `${personLastName} ${personFirstName}` : ''}</Text>
                </View>
                <Text style={[styles.bold, styles.column22]}>FIRMA:</Text>
                <View style={styles.line} />
            </View>
            <View style={[styles.column, styles.cell]} >
                <Text style={styles.bold}>MOTIVO DEL ASSENZA:</Text>
                <View style={[styles.column, styles.paddingLeft, styles.paddingTop]} >
                    <View style={[styles.row, styles.centerVertical]} >
                        <View style={styles.checkBox}>
                            { reasonType === VACATION &&  <Text>X</Text>}
                        </View>
                        <Text>VACANZE/FERIE</Text>
                    </View>
                    <View style={[styles.row, styles.centerVertical]} >
                        <View style={styles.checkBox}>
                            { reasonType === SICKNESS_LEAVE &&  <Text>X</Text>}
                        </View>
                        <Text style={styles.column1}>MALATTIA</Text>
                        <Text>Allegare un certificato medico dal 3° giorno di assenza</Text>
                    </View>
                    <View style={[styles.row, styles.centerVertical]} >
                        <View style={styles.checkBox}>
                            { reasonType === MEDICAL_EXAMINATION &&  <Text>X</Text>}
                        </View>
                        <Text style={styles.column1}>VISITA MEDICA</Text>
                        <Text>Allegare il foglio medico con l&apos;appuntamento</Text>
                    </View>
                    <View style={[styles.row, styles.centerVertical]} >
                        <View style={styles.checkBox}>
                            { reasonType === PROFESSIONAL_ACCIDENT &&  <Text>X</Text>}
                        </View>
                        <Text>INFORTUNIO PROFESSIONALE</Text>
                    </View>
                    <View style={[styles.row, styles.centerVertical]} >
                        <View style={styles.checkBox}>
                            { reasonType === NON_PROFESSIONAL_ACCIDENT &&  <Text>X</Text>}
                        </View>
                        <Text>INFORTUNIO NON PROFESSIONALE</Text>
                    </View>
                    <View style={[styles.row, styles.centerVertical]} >
                        <View style={styles.checkBox}>
                            { reasonType === MILITARY_SERVICE &&  <Text>X</Text>}
                        </View>
                        <Text>SERVIZIO MILITARE</Text>
                    </View>
                    <View style={[styles.row, styles.centerVertical]} >
                        <View style={styles.checkBox}>
                            { reasonType === WEATHER_CONDITION &&  <Text>X</Text>}
                        </View>
                        <Text>CONDIZIONI METEO</Text>
                    </View>
                    <View style={[styles.row, styles.centerVertical]} >
                        <View style={styles.checkBox}>
                            { reasonType === MATERNITY_LEAVE &&  <Text>X</Text>}
                        </View>
                        <Text>MATERNITA</Text>
                    </View>
                    <View style={[styles.row, styles.centerVertical]} >
                        <View style={[styles.checkBox, styles.checkBoxLast]} >
                            { reasonType === OTHER_REASON &&  <Text>X</Text>}
                        </View>
                        <Text style={styles.column1}>ALTRO:</Text>
                        <View style={styles.line}>
                            <Text>{reasonType === OTHER_REASON ? description : ''}</Text>
                        </View>
                    </View>
                </View>
            </View>
            <View style={[styles.column, styles.cell]} >
                <Text style={styles.bold}>PERIODO DEL ASSENZA:</Text>
                <View style={[styles.row, styles.paddingBottom, styles.paddingTop]} >
                    <Text style={[styles.paddingLeft, styles.column1]}>1° GIORNO DI ASSENZA:</Text>
                    <View style={styles.line}>
                        <Text>{moment(startAt).format('DD.MM.YYYY')}</Text>
                    </View>
                    <Text style={[styles.column2]}>ULTIMO GIORNO:</Text>
                    <View style={styles.line}>
                        <Text>{moment(endAt).format('DD.MM.YYYY')}</Text>
                    </View>
                </View>
                <View style={[styles.row, styles.paddingBottom]} >
                    <Text style={[styles.paddingLeft, styles.column1]}>TOTALE ASSENZA:</Text>
                    <View style={styles.line}>
                        <Text>{formatDuration(duration, {}, translate)}</Text>
                    </View>
                </View>
                <View style={[styles.row, styles.paddingBottom]} >
                    <Text style={[styles.paddingLeft, styles.column1]}>DALLE ORE:</Text>
                    <View style={styles.line}>
                        <Text>{moment(startAt).format('HH:mm')}</Text>
                    </View>
                    <Text style={[styles.column2]}>ALLE ORE:</Text>
                    <View style={styles.line}>
                        <Text>{moment(endAt).format('HH:mm')}</Text>
                    </View>
                </View>
                <View style={[styles.row, styles.paddingBottom]} >
                    <Text style={[styles.paddingLeft, styles.column1]}>OSSERVAZIONI:</Text>
                    <View style={styles.line}>
                        <Text>{description}</Text>
                    </View>
                </View>
            </View>
            <View style={[styles.row, styles.cell]} >
                <Text style={[styles.bold, styles.column1]}>APPROVATO DAL SUPERIORE:</Text>
                <View style={styles.line}>
                    <Text>{approvedFirstName ? `${approvedLastName} ${approvedFirstName}` : ''}</Text>
                </View>
                <Text style={[styles.bold, styles.column22]}>DATA:</Text>
                <View style={styles.line}>
                    <Text>{approvedDate ? moment(approvedDate).format('DD.MM.YYYY') : ''}</Text>
                </View>
            </View>
        </View>
    );
};

PageTable.propTypes = {
    data: PropTypes.object,
};

PageTable.defaultProps = {
    data: {},
};

export default PageTable;
