import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    container: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        position: 'absolute',
        top: 0,
        left: 0,
    },
    logotype: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(12),
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    logotypeText: {
        fontWeight: 500,
        marginLeft: theme.spacing(2),
    },
    logotypeIcon: {
        width: 70,
        marginRight: theme.spacing(2),
    },
    paperRoot: {
        borderStyle: 'outset',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        maxWidth: 404,
    },
    textRow: {
        marginBottom: theme.spacing(10),
        textAlign: 'center',
    },
    errorCode: {
        marginBottom: theme.spacing(10),
        textAlign: 'center',
        fontSize: 148,
        fontWeight: 600,
    },
    safetyText: {
        fontWeight: 300,
        color: theme.palette.text.hint,
    },
    backButton: {
        textTransform: 'none',
        fontSize: 22,
    },
}));

const ErrorPage = () => {
    const classes = useStyles();
    return (
        <Grid container className={classes.container}>
            <Paper classes={{ root: classes.paperRoot }}>
                <Typography variant="h1" color="primary" className={classes.errorCode}>
                    404
                </Typography>
                <Typography variant="h5" color="primary" className={classes.textRow}>
                    Oops. La pagina che stai cercando non esiste
                </Typography>
                <Button variant="contained" color="primary" component={Link} to="/" size="large" className={classes.backButton}>
                    Torna indietro
                </Button>
            </Paper>
        </Grid>
    );
};

ErrorPage.propTypes = {};

ErrorPage.defaultProps = {};

export default ErrorPage;
