import { Add, ArrowBackIos, People } from '@material-ui/icons';
import { Export } from 'mdi-material-ui';
import { v4 as uuid } from 'uuid';
import i from '../../ability/ability';
import EditDialog from '../../components/dialog/EditDialog';
import history from '../../components/routings/history';
import FormView from '../../components/view/FormView';
import TableView from '../../components/view/TableView';
import { companies, roles, rolesEmployee } from '../../constants/options';
import {
    createExpense,
    createFestivity,
    createLeave,
    createStampingLog,
    createWorkingHours,
    dailyHoursService,
    deleteFestivity,
    deleteLeave,
    getExpense,
    getFestivities,
    getFestivity,
    getHrExpenses,
    getHrLeaveRequests,
    getHrPersonalCalendar,
    getLeave,
    getPerson,
    getPersons,
    getWorkingHours,
    getWorkingHoursList,
    saveExpense,
    saveFestivity,
    saveLeave,
    saveStampingLog,
    saveWorkingHours,
} from '../../store/proxies/tigestimProxies';
import { openDialog } from '../../store/slices/sliceDialog';
import { refetch } from '../../store/slices/sliceFetching';
import { setErrorNotification, setSuccessNotification } from '../../store/slices/sliceNotification';
import { formValidator, generateCompaniesString } from '../../utils/common';
import RequestExpensesDocument from '../../view/hr/export/RequestExpensesDocument';
import StampingsLogDocument from '../../view/hr/export/StampingsLogDocument';
import UserCalendarView from '../../view/hr/UserCalendarView';
import UserDailyHoursCalendarView from '../../view/hr/UserDailyHoursCalendarView';
import UserExpensesView from '../../view/hr/UserExpensesView';
import UserLeavesView from '../../view/hr/UserLeavesView';
import UserProfileEdit from '../../view/hr/UserProfileEdit';
import UserDailyHoursView from '../../view/sites/UserDailyHoursView';
import {
    userDailyHoursEntryActions,
    userExpensesRequestsActions,
    userLeavesRequestsActions,
    userProfileEditActions,
    userProfileNewActions,
    usersActions,
    userViewActions,
    workingHoursListActions,
} from '../actions';
import { allExpensesRequestsColumns, allLeavesRequestsColumns, festivitiesColumns, usersColumns, workingHoursColumns } from '../columns';
import {
    addWorkingHoursForm,
    editStampingLogForm,
    editWorkingHourForm,
    festivityForm,
    userAddDailyHoursForm,
    userExpenseEditForm,
    userExpenseNewForm,
    userLeaveEditForm,
    userLeaveNewForm,
} from '../forms';
import { handlePersonsResponse } from '../handlers';

const role = () => i.can('read', 'hr') || i.can('update', 'hr') || i.can('update', 'stampingsLog');

export default {
    title: 'hr',
    key: uuid(),
    icon: People,
    path: 'hr',
    to: 'hr/users/list',
    selectable: false,
    role,
    children: [
        {
            title: 'menu.employees',
            key: uuid(),
            path: 'users',
            to: 'hr/users/list',
            tabs: [
                {
                    label: 'list',
                    path: 'list',
                    key: uuid(),
                    showTabs: false,
                    hideTab: true,
                    role: () => i.can('read', 'allEmployee'),
                    component: TableView,
                    componentProps: {
                        actions: usersActions,
                        columns: usersColumns,
                        proxy: getPersons,
                        onItemClick: ({ id }) => history.push(`${id}/calendar`),
                        handleResponse: handlePersonsResponse,
                        searchable: true,
                        params: {
                            companyName: 'all',
                        },
                        filters: [
                            {
                                placeholder: 'companyName',
                                options: companies,
                                param: 'companyName',
                                defaultValue: 'all',
                            },
                            {
                                placeholder: 'role',
                                options: rolesEmployee,
                                param: 'role',
                            },
                        ],
                    },
                },
                {
                    label: 'list',
                    path: 'list',
                    key: uuid(),
                    showTabs: false,
                    hideTab: true,
                    component: TableView,
                    role: () => i.cannot('read', 'allEmployee'),
                    componentProps: (ids, user) => ({
                        actions: usersActions,
                        columns: usersColumns,
                        proxy: getPersons,
                        onItemClick: ({ id }) => history.push(`${id}/calendar`),
                        handleResponse: handlePersonsResponse,
                        searchable: true,
                        params: {
                            companyName: generateCompaniesString(user.companyName, companies, i),
                        },
                        filters: [
                            {
                                placeholder: 'role',
                                options: rolesEmployee,
                                param: 'role',
                            },
                        ],
                    }),
                },
                {
                    label: 'profile',
                    key: uuid(),
                    path: ':id/profile',
                    to: 'profile',
                    component: UserProfileEdit,
                    componentProps: {
                        readOnly: true,
                        proxy: getPerson,
                        actions: userViewActions,
                    },
                },
                {
                    label: 'edit',
                    showTabs: false,
                    hideTab: true,
                    key: uuid(),
                    path: ':id/edit',
                    role: () => i.can('update', 'hr'),
                    component: UserProfileEdit,
                    componentProps: {
                        proxy: getPerson,
                        actions: userProfileEditActions,
                    },
                },
                {
                    label: 'new',
                    showTabs: false,
                    hideTab: true,
                    key: uuid(),
                    path: 'edit',
                    role: () => i.can('update', 'hr'),
                    component: UserProfileEdit,
                    componentProps: {
                        actions: userProfileNewActions,
                    },
                },
                {
                    label: 'calendar',
                    key: uuid(),
                    path: ':id/calendar',
                    role: () => i.can('update', 'hr') || i.can('update', 'stampingsLog') || i.can('read', 'hr'),
                    component: UserCalendarView,
                    componentProps: {
                        actions: [
                            {
                                label: 'new_stamping_log',
                                icon: Add,
                                role: () => i.can('update', 'hr') || i.can('update', 'stampingsLog'),
                                onClick: (data, isLoading, dispatch) =>
                                    openDialog({
                                        fullWidth: true,
                                        component: EditDialog,
                                        componentProps: {
                                            form: editStampingLogForm,
                                            title: 'new_stamping_log',
                                        },
                                        data,
                                        cancelLabel: 'cancel',
                                        confirmationLabel: 'save',
                                        confirmationEnable: data => formValidator(editStampingLogForm, data),
                                        confirmationCallback: async value => {
                                            try {
                                                const response = await createStampingLog.call({
                                                    description: value.description,
                                                    personId: value.id,
                                                });

                                                await saveStampingLog.call(response.id, {
                                                    personId: value.id,
                                                    ...response,
                                                    type: value.type,
                                                    description: value.description,
                                                    place: value.place,
                                                    timestamp: value.timestamp,
                                                });
                                                dispatch(refetch(getHrPersonalCalendar.name));
                                                return setSuccessNotification('stamping_log_created_successfully');
                                            } catch (e) {
                                                return setErrorNotification(`${e?.response?.status} : ${e?.response?.statusText}`);
                                            }
                                        },
                                    }),
                            },
                            {
                                label: 'export_stamping_logs',
                                icon: Export,
                                role: () => i.can('update', 'hr') || i.can('update', 'stampingsLog'),
                                onClick: data =>
                                    openDialog({
                                        cancelLabel: 'close',
                                        component: StampingsLogDocument,
                                        fullScreen: true,
                                        data,
                                    }),
                            },
                            {
                                label: 'menu.employees',
                                icon: ArrowBackIos,
                                onClick: () => history.push('/app/hr/users/list'),
                            },
                        ],
                    },
                },
                {
                    label: 'leaves',
                    key: uuid(),
                    path: ':id/leaves',
                    role: () => i.can('update', 'hr'),
                    component: UserLeavesView,
                    componentProps: {
                        actions: userLeavesRequestsActions,
                    },
                },
                {
                    label: 'leaves',
                    key: uuid(),
                    path: ':userId/leaves/:id/edit',
                    showTabs: false,
                    hideTab: true,
                    role: () => i.can('update', 'hr'),
                    component: FormView,
                    componentProps: {
                        get: { proxy: getLeave },
                        save: { proxy: saveLeave, success: 'leave_saved_successfully' },
                        delete: { proxy: deleteLeave, success: 'leave_delete_successfully' },
                        title: 'edit_leave',
                        form: userLeaveEditForm,
                    },
                },
                {
                    label: 'leaves',
                    key: uuid(),
                    path: ':userId/leaves/new',
                    showTabs: false,
                    hideTab: true,
                    component: FormView,
                    role: () => i.can('update', 'hr'),
                    componentProps: ({ userId }) => ({
                        create: { proxy: createLeave, success: 'leave_saved_successfully' },
                        title: 'new_leave',
                        form: userLeaveEditForm,
                        successMessage: 'leave_saved_successfully',
                        defaultValue: {
                            personId: userId,
                        },
                    }),
                },
                {
                    label: 'expenses',
                    key: uuid(),
                    path: ':id/expenses',
                    role: () => i.can('update', 'hr'),
                    component: UserExpensesView,
                    componentProps: {
                        actions: [
                            ...userExpensesRequestsActions,
                            {
                                label: 'export_expense_month',
                                icon: Export,
                                role: () => i.can('update', 'hr') || i.can('update', 'stampingsLog'),
                                onClick: data =>
                                    openDialog({
                                        cancelLabel: 'close',
                                        component: RequestExpensesDocument,
                                        fullScreen: true,
                                        data,
                                    }),
                            },
                        ],
                    },
                },
                {
                    label: 'expenses',
                    key: uuid(),
                    path: ':userId/expenses/:id/edit',
                    showTabs: false,
                    hideTab: true,
                    role: () => i.can('update', 'hr'),
                    component: FormView,
                    componentProps: {
                        get: { proxy: getExpense },
                        save: { proxy: saveExpense, filter: ({ recieptImage, ...rest }) => rest, success: 'expense_saved_successfully' },
                        title: 'edit_expense',
                        form: userExpenseEditForm,
                        defaultValue: {
                            currency: 'CHF',
                            isEquipment: 0,
                        },
                    },
                },
                {
                    label: 'expenses',
                    key: uuid(),
                    path: ':userId/expenses/new',
                    showTabs: false,
                    hideTab: true,
                    role: () => i.can('update', 'hr'),
                    component: FormView,
                    componentProps: ({ userId }) => ({
                        create: { proxy: createExpense, filter: ({ recieptImage, ...rest }) => rest, success: 'expense_saved_successfully' },
                        title: 'new_expense',
                        form: userExpenseEditForm,
                        defaultValue: {
                            personId: userId,
                            currency: 'CHF',
                            isEquipment: 0,
                        },
                    }),
                },
                {
                    label: 'daily_hours',
                    key: uuid(),
                    path: ':id/dailyHours',
                    component: UserDailyHoursCalendarView,
                    componentProps: {
                        actions: userDailyHoursEntryActions,
                    },
                },
                {
                    label: 'daily_hours',
                    key: uuid(),
                    path: ':userId/dailyHours/new',
                    showTabs: false,
                    hideTab: true,
                    component: FormView,
                    role: () => i.can('update', 'hr'),
                    componentProps: ({ userId }) => ({
                        create: { proxy: dailyHoursService.create, success: 'daily_hours_saved_successfully' },
                        title: 'new_daily_hours',
                        form: userAddDailyHoursForm,
                        successMessage: 'daily_hours_saved_successfully',
                        defaultValue: {
                            personId: userId,
                        },
                    }),
                },
            ],
        },
        {
            title: 'all_leave_requests',
            key: uuid(),
            path: 'leave-requests',
            role: () => i.can('update', 'hr'),
            to: 'hr/leave-requests/list',
            tabs: [
                {
                    title: 'all_leave_requests',
                    key: uuid(),
                    path: 'list',
                    showTabs: false,
                    component: TableView,
                    role: () => i.can('read', 'allEmployee'),
                    componentProps: {
                        searchable: true,
                        filters: [
                            {
                                placeholder: 'companyName',
                                options: companies,
                                param: 'companyName',
                                defaultValue: 'all',
                            },
                            {
                                placeholder: 'role',
                                options: roles,
                                param: 'role',
                            },
                        ],
                        actions: [{ label: 'new_leave', icon: Add, onClick: () => history.push('new') }],
                        columns: allLeavesRequestsColumns,
                        proxy: getHrLeaveRequests,
                        onItemClick: ({ personId }) => {
                            history.push(`/app/hr/users/${personId}/leaves`);
                        },
                    },
                },
                {
                    title: 'all_leave_requests',
                    key: uuid(),
                    path: 'list',
                    showTabs: false,
                    component: TableView,
                    role: () => i.cannot('read', 'allEmployee'),
                    componentProps: (ids, user) => ({
                        searchable: true,
                        params: {
                            companyName: generateCompaniesString(user.companyName, companies, i),
                        },
                        filters: [
                            {
                                placeholder: 'role',
                                options: roles,
                                param: 'role',
                            },
                        ],
                        actions: [{ label: 'new_leave', icon: Add, onClick: () => history.push('new') }],
                        columns: allLeavesRequestsColumns,
                        proxy: getHrLeaveRequests,
                        onItemClick: ({ personId }) => {
                            history.push(`/app/hr/users/${personId}/leaves`);
                        },
                    }),
                },
                {
                    label: 'leaves',
                    key: uuid(),
                    path: 'new',
                    showTabs: false,
                    component: FormView,
                    componentProps: ({ userId }) => ({
                        create: { proxy: createLeave, success: 'leave_saved_successfully' },
                        title: 'new_leave',
                        form: userLeaveNewForm,
                        successMessage: 'leave_saved_successfully',
                        defaultValue: {
                            personId: userId,
                        },
                    }),
                },
            ],
        },
        {
            title: 'all_expense_requests',
            key: uuid(),
            path: 'expense-requests',
            role: () => i.can('update', 'hr'),
            to: 'hr/expense-requests/list',
            tabs: [
                {
                    key: uuid(),
                    path: 'list',
                    label: 'list',
                    showTabs: false,
                    component: TableView,
                    role: () => i.can('read', 'allEmployee'),
                    componentProps: {
                        searchable: true,
                        filters: [
                            {
                                placeholder: 'companyName',
                                options: companies,
                                param: 'companyName',
                                defaultValue: 'all',
                            },
                            {
                                placeholder: 'role',
                                options: roles,
                                param: 'role',
                            },
                        ],
                        actions: [{ label: 'new_expense', icon: Add, onClick: () => history.push('new') }],
                        columns: allExpensesRequestsColumns,
                        proxy: getHrExpenses,
                        onItemClick: ({ personId }) => {
                            history.push(`/app/hr/users/${personId}/expenses`);
                        },
                    },
                },
                {
                    key: uuid(),
                    path: 'list',
                    label: 'list',
                    showTabs: false,
                    component: TableView,
                    role: () => i.cannot('read', 'allEmployee'),
                    componentProps: (ids, user) => ({
                        searchable: true,
                        params: {
                            companyName: generateCompaniesString(user.companyName, companies, i),
                        },
                        filters: [
                            {
                                placeholder: 'role',
                                options: roles,
                                param: 'role',
                            },
                        ],
                        actions: [{ label: 'new_expense', icon: Add, onClick: () => history.push('new') }],
                        columns: allExpensesRequestsColumns,
                        proxy: getHrExpenses,
                        onItemClick: ({ personId }) => {
                            history.push(`/app/hr/users/${personId}/expenses`);
                        },
                    }),
                },
                {
                    label: 'expenses',
                    key: uuid(),
                    path: 'new',
                    showTabs: false,
                    component: FormView,
                    componentProps: () => ({
                        create: { proxy: createExpense, filter: ({ recieptImage, ...rest }) => rest, success: 'expense_saved_successfully' },
                        title: 'new_expense',
                        form: userExpenseNewForm,
                        defaultValue: {
                            currency: 'CHF',
                            isEquipment: 0,
                        },
                    }),
                },
            ],
        },
        {
            title: 'all_festivities',
            key: uuid(),
            path: 'festivities',
            to: 'hr/festivities/list',
            role: () => i.can('update', 'hr'),
            tabs: [
                {
                    key: uuid(),
                    label: 'list',
                    path: 'list',
                    showTabs: false,
                    component: TableView,
                    componentProps: {
                        actions: [
                            { label: 'add_festivity', icon: Add, onClick: () => history.push('new') },
                            // {
                            //     label: 'add_all',
                            //     icon: Add,
                            //     onClick: async () => {
                            //         await Promise.all(
                            //             festivities.map(async (item, index) => {
                            //                 await sleep(500 * index);
                            //                 await createFestivity.call(item);
                            //                 console.log('DONE: ', item);
                            //             })
                            //         );
                            //     },
                            // },
                        ],
                        proxy: getFestivities,
                        columns: festivitiesColumns,
                        onItemClick: item => history.push(`${item.id}/edit`),
                        defaultValue: {},
                        params: {
                            filter: 'forYear',
                        },
                    },
                },
                {
                    label: 'new',
                    path: 'new',
                    showTabs: false,
                    hideTab: true,
                    key: uuid(),
                    component: FormView,
                    componentProps: {
                        create: { proxy: createFestivity, success: 'festivity_saved_successfully' },
                        title: 'new_festivity',
                        form: festivityForm,
                    },
                },
                {
                    path: ':id/edit',
                    showTabs: false,
                    hideTab: true,
                    key: uuid(),
                    component: FormView,
                    componentProps: {
                        title: 'edit_festivity',
                        form: festivityForm,
                        get: { proxy: getFestivity },
                        save: {
                            proxy: saveFestivity,
                            success: 'festivity_saved_successfully',
                            error: 'festivity_not_saved',
                        },
                        delete: {
                            proxy: deleteFestivity,
                            success: 'festivity_deleted_successfully',
                            error: 'festivity_not_deleted',
                        },
                    },
                },
            ],
        },
        {
            title: 'all_working_hours',
            key: uuid(),
            role: () => i.can('update', 'hr'),
            path: 'working-hours',
            to: 'hr/working-hours',
            tabs: [
                {
                    key: uuid(),
                    showTabs: false,
                    component: TableView,
                    componentProps: {
                        actions: workingHoursListActions,
                        columns: workingHoursColumns,
                        proxy: getWorkingHoursList,
                        onItemClick: item => history.push(`working-hours/${item.id}/edit`, item),
                    },
                },
                {
                    path: 'new',
                    showTabs: false,
                    hideTab: true,
                    key: uuid(),
                    component: FormView,
                    componentProps: {
                        create: { proxy: createWorkingHours, success: 'working_hours_saved_successfully' },
                        title: 'new_working_hours',
                        form: addWorkingHoursForm,
                    },
                },
                {
                    path: ':id/edit',
                    showTabs: false,
                    hideTab: true,
                    key: uuid(),
                    component: FormView,
                    componentProps: {
                        title: 'edit_working_hours',
                        form: editWorkingHourForm,
                        get: { proxy: getWorkingHours },
                        save: {
                            filter: ({ summerendthr, summerstarthr, winterendthr, winterstarthr, personId }) => ({
                                id: '',
                                summerendthr,
                                summerstarthr,
                                winterendthr,
                                winterstarthr,
                                userList: [personId],
                            }),
                            proxy: saveWorkingHours,
                            success: 'working_hours_saved_successfully',
                            error: 'working_hours_not_saved',
                        },
                    },
                },
            ],
        },
    ],
};
