import { Add, ArrowBackIos, Delete, Edit, Settings } from '@material-ui/icons';
import { FilePdfBox } from 'mdi-material-ui';
import { v4 as uuid } from 'uuid';
import i from '../../ability/ability';
import history from '../../components/routings/history';
import FormView from '../../components/view/FormView';
import TableView from '../../components/view/TableView';
import { companies, roles } from '../../constants/options';
import {
    createChecklist,
    createExpense,
    createLeave,
    createTool,
    createToolsCategory,
    deleteChecklist,
    deleteTool,
    deleteToolsCategory,
    getChecklist,
    getChecklists,
    getChecklistsTemplate,
    getExpense,
    getLeave,
    getPerson,
    getTool,
    getTools,
    getToolsCategories,
    getToolsCategory,
    getToolsQuantity,
    getUsers,
    saveChecklist,
    saveExpense,
    saveLeave,
    saveTool,
    saveToolsCategory
} from '../../store/proxies/tigestimProxies';
import { openDialog } from '../../store/slices/sliceDialog';
import { invokeDelete, isEmpty } from '../../utils/common';
import { clearChecklistForPost, clearChecklistForPut, clearChecklistTemplateForPost } from '../../view/checklist/checklistUtils';
import ChecklistDocument from '../../view/checklist/export/ChecklistDocument';
import UserCalendarView from '../../view/hr/UserCalendarView';
import UserExpensesView from '../../view/hr/UserExpensesView';
import UserLeavesView from '../../view/hr/UserLeavesView';
import UserProfileEdit from '../../view/hr/UserProfileEdit';
import UserProfileView from '../../view/hr/UserProfileView';
import { userExpensesRequestsActions, userLeavesRequestsActions, userProfileEditActions, userProfileNewActions, usersActions } from '../actions';
import {
    checklistInstanceColumns,
    checklistTemplateColumns,
    toolsCategoriesColumns,
    toolsColumns,
    toolsQuantityColumns,
    usersColumnsNoEdit
} from '../columns';
import { checklistForm, checklistTemplateForm, toolForm, toolsCategoryForm, userExpenseEditForm, userLeaveEditForm } from '../forms';
import { handlePersonsResponse } from '../handlers';

const role = () => i.can('read', 'admin') || i.can('read', 'allChecklists');

export default {
    title: 'settings',
    key: uuid(),
    path: 'admin',
    to: 'admin/users/list',
    icon: Settings,
    selectable: false,
    role,
    children: [
        {
            title: 'users',
            key: uuid(),
            path: 'users',
            role: () => i.can('read', 'admin'),
            to: 'admin/users/list',
            tabs: [
                {
                    label: 'list',
                    path: 'list',
                    key: uuid(),
                    showTabs: false,
                    hideTab: true,
                    component: TableView,
                    componentProps: {
                        actions: usersActions,
                        columns: usersColumnsNoEdit,
                        proxy: getUsers,
                        onItemClick: ({ id }) => history.push(`${id}/profile`),
                        handleResponse: handlePersonsResponse,
                        searchable: true,
                        params: {
                            companyName: 'all',
                        },
                        filters: [
                            {
                                placeholder: 'companyName',
                                options: companies,
                                param: 'companyName',
                                defaultValue: 'all',
                            },
                            {
                                placeholder: 'role',
                                options: roles,
                                param: 'role',
                            },
                        ],
                    },
                },
                {
                    label: 'profile',
                    key: uuid(),
                    path: ':id/profile',
                    to: 'profile',
                    component: UserProfileView,
                },
                {
                    label: 'edit',
                    showTabs: false,
                    hideTab: true,
                    key: uuid(),
                    path: ':id/edit',
                    component: UserProfileEdit,
                    componentProps: {
                        proxy: getPerson,
                        actions: userProfileEditActions,
                    },
                },
                {
                    label: 'new',
                    showTabs: false,
                    hideTab: true,
                    key: uuid(),
                    path: 'edit',
                    component: UserProfileEdit,
                    componentProps: {
                        actions: userProfileNewActions,
                    },
                },
                {
                    label: 'calendar',
                    key: uuid(),
                    path: ':id/calendar',
                    component: UserCalendarView,
                },
                {
                    label: 'leaves',
                    key: uuid(),
                    path: ':id/leaves',
                    component: UserLeavesView,
                    componentProps: {
                        actions: userLeavesRequestsActions,
                    },
                },
                {
                    label: 'leaves',
                    key: uuid(),
                    path: ':userId/leaves/:id/edit',
                    showTabs: false,
                    hideTab: true,
                    component: FormView,
                    componentProps: {
                        get: { proxy: getLeave },
                        save: { proxy: saveLeave, success: 'leave_saved_successfully' },
                        title: 'edit_leave',
                        form: userLeaveEditForm,
                    },
                },
                {
                    label: 'leaves',
                    key: uuid(),
                    path: ':userId/leaves/new',
                    showTabs: false,
                    hideTab: true,
                    component: FormView,
                    componentProps: ({ userId }) => ({
                        create: { proxy: createLeave, success: 'leave_saved_successfully' },
                        title: 'new_leave',
                        form: userLeaveEditForm,
                        successMessage: 'leave_saved_successfully',
                        defaultValue: {
                            personId: userId,
                        },
                    }),
                },
                {
                    label: 'expenses',
                    key: uuid(),
                    path: ':id/expenses',
                    component: UserExpensesView,
                    componentProps: {
                        actions: userExpensesRequestsActions,
                    },
                },
                {
                    label: 'expenses',
                    key: uuid(),
                    path: ':userId/expenses/:id/edit',
                    showTabs: false,
                    hideTab: true,
                    component: FormView,
                    componentProps: {
                        get: { proxy: getExpense },
                        save: { proxy: saveExpense, filter: ({ recieptImage, ...rest }) => rest, success: 'expense_saved_successfully' },
                        title: 'edit_expense',
                        form: userExpenseEditForm,
                        defaultValue: {
                            currency: 'CHF',
                            isEquipment: 0,
                        },
                    },
                },
                {
                    label: 'expenses',
                    key: uuid(),
                    path: ':userId/expenses/new',
                    showTabs: false,
                    hideTab: true,
                    component: FormView,
                    componentProps: ({ userId }) => ({
                        create: { proxy: createExpense, filter: ({ recieptImage, ...rest }) => rest, success: 'expense_saved_successfully' },
                        title: 'new_expense',
                        form: userExpenseEditForm,
                        defaultValue: {
                            personId: userId,
                            currency: 'CHF',
                            isEquipment: 0,
                        },
                    }),
                }
            ],
        },
        {
            title: 'tool_manager',
            key: uuid(),
            path: 'tool-manager',
            role: () => i.can('read', 'admin'),
            to: 'admin/tool-manager/list',
            tabs: [
                {
                    label: 'list',
                    key: uuid(),
                    component: TableView,
                    path: `list`,
                    componentProps: {
                        actions: [{ label: 'add_new_tool', icon: Add, onClick: () => history.push('new') }],
                        columns: toolsColumns,
                        proxy: getTools,
                        onItemClick: ({ id }) => history.push(`${id}/edit`),
                    },
                },
                {
                    label: 'quantity',
                    key: uuid(),
                    showTabs: true,
                    path: `quantity`,
                    component: TableView,
                    componentProps: {
                        actions: [{ label: 'add_new_tool', icon: Add, onClick: () => history.push('new') }],
                        columns: toolsQuantityColumns,
                        proxy: getToolsQuantity,
                        keyComposition: ({ toolCode }) => toolCode,
                    },
                },
                {
                    label: 'categories',
                    key: uuid(),
                    showTabs: true,
                    path: `categories`,
                    component: TableView,
                    componentProps: {
                        actions: [{ label: 'add_tools_category', icon: Add, onClick: () => history.push('categories/new') }],
                        columns: toolsCategoriesColumns,
                        proxy: getToolsCategories,
                        onItemClick: ({ id }) => history.push(`categories/${id}/edit`),
                    },
                },
                {
                    label: 'categories_new',
                    key: uuid(),
                    path: 'categories/new',
                    showTabs: false,
                    hideTab: true,
                    component: FormView,
                    componentProps: {
                        title: 'add_new_category',
                        form: toolsCategoryForm,
                        create: { proxy: createToolsCategory, success: 'category_saved_successfully' },
                    },
                },
                {
                    label: 'categories_edit',
                    key: uuid(),
                    path: 'categories/:id/edit',
                    showTabs: false,
                    hideTab: true,
                    component: FormView,
                    componentProps: {
                        proxy: getToolsCategory,
                        title: 'edit_category',
                        form: toolsCategoryForm,
                        get: { proxy: getToolsCategory },
                        save: {
                            proxy: saveToolsCategory,
                            success: 'category_saved_successfully',
                            error: 'category_not_saved',
                        },
                        delete: {
                            proxy: deleteToolsCategory,
                            success: 'category_deleted_successfully',
                            error: 'category_not_deleted',
                        },
                    },
                },
                {
                    label: 'new',
                    key: uuid(),
                    path: 'new',
                    showTabs: false,
                    hideTab: true,
                    component: FormView,
                    componentProps: {
                        title: 'add_new_tool',
                        form: toolForm,
                        create: { proxy: createTool, success: 'tool_saved_successfully' },
                    },
                },
                {
                    label: 'edit',
                    key: uuid(),
                    path: ':id/edit',
                    showTabs: false,
                    hideTab: true,
                    component: FormView,
                    componentProps: {
                        title: 'edit_tool',
                        form: toolForm,
                        get: { proxy: getTool },
                        save: {
                            proxy: saveTool,
                            success: 'tool_saved_successfully',
                            error: 'tool_not_saved',
                        },
                        delete: {
                            proxy: deleteTool,
                            success: 'tool_deleted_successfully',
                            error: 'tool_not_deleted',
                        },
                    },
                },
            ],
        },
        {
            title: 'all_checklists',
            key: uuid(),
            path: 'checklists',
            role: () => i.can('read', 'allChecklists'),
            to: 'admin/checklists/list',
            tabs: [
                {
                    label: 'lists',
                    key: uuid(),
                    component: TableView,
                    path: 'list',
                    componentProps: {
                        searchable: true,
                        actions: [{ label: 'add_checklist', icon: Add, onClick: () => history.push('new') }],
                        proxy: getChecklists,
                        params: {
                            isTemplate: 0,
                        },
                        columns: checklistInstanceColumns,
                        onItemClick: ({ id }) => history.push(`${id}/view`),
                    },
                },
                {
                    label: 'new',
                    key: uuid(),
                    path: 'new',
                    showTabs: false,
                    hideTab: true,
                    component: FormView,
                    componentProps: {
                        title: 'new_checklist',
                        form: checklistForm,
                        create: {
                            proxy: createChecklist,
                            filter: checklist => clearChecklistForPost(checklist),
                            success: 'checklist_saved_successfully',
                        },
                    },
                },
                {
                    label: 'new',
                    key: uuid(),
                    path: ':id/new',
                    showTabs: false,
                    hideTab: true,
                    component: FormView,
                    componentProps: {
                        get: { proxy: getChecklist },
                        create: {
                            proxy: createChecklist,
                            filter: checklist => clearChecklistForPost(checklist),
                            success: 'checklist_saved_successfully',
                        },
                        title: 'new_checklist',
                        form: checklistForm,
                    },
                },
                {
                    label: 'view',
                    key: uuid(),
                    path: ':id/view',
                    showTabs: false,
                    hideTab: true,
                    component: FormView,
                    componentProps: {
                        title: 'view_checklist',
                        readOnly: true,
                        form: checklistForm,
                        proxy: getChecklist,
                        actions: [
                            { label: 'edit', icon: Edit, onClick: () => history.push('edit') },
                            {
                                label: 'export',
                                icon: FilePdfBox,
                                valid: data => !isEmpty(data),
                                onClick: data =>
                                    openDialog({
                                        cancelLabel: 'close',
                                        component: ChecklistDocument,
                                        fullScreen: true,
                                        data,
                                    }),
                            },
                            {
                                label: 'delete',
                                icon: Delete,
                                color: 'error.main',
                                onClick: (value, setLoading) =>
                                    invokeDelete(deleteChecklist, value, {
                                        setLoading,
                                        successMessage: 'checklist_deleted_successfully',
                                        errorMessage: 'checklist_not_deleted',
                                        back: true,
                                    }),
                            },
                            { label: 'go_back', icon: ArrowBackIos, onClick: () => history.goBack() },
                        ],
                    },
                },
                {
                    label: 'edit',
                    key: uuid(),
                    path: ':id/edit',
                    showTabs: false,
                    hideTab: true,
                    component: FormView,
                    componentProps: {
                        title: 'edit_checklist',
                        form: checklistForm,
                        get: { proxy: getChecklist },
                        save: {
                            proxy: saveChecklist,
                            filter: checklist => clearChecklistForPut(checklist),
                            success: 'checklist_saved_successfully',
                            error: 'checklist_not_saved',
                        },
                        // delete: {
                        //     proxy: deleteChecklist,
                        //     success: 'checklist_deleted_successfully',
                        //     error: 'checklist_not_deleted',
                        // },
                    },
                },
                {
                    label: 'templates',
                    key: uuid(),
                    showTabs: true,
                    path: `templates`,
                    component: TableView,
                    componentProps: {
                        actions: [{ label: 'add_checklist_template', icon: Add, onClick: () => history.push('new-template') }],
                        proxy: getChecklistsTemplate,
                        params: {
                            isTemplate: 1,
                        },
                        columns: checklistTemplateColumns,
                        onItemClick: ({ id }) => history.push(`${id}/edit-template`),
                    },
                },
                {
                    label: 'new_template',
                    key: uuid(),
                    path: 'new-template',
                    showTabs: false,
                    hideTab: true,
                    component: FormView,
                    componentProps: {
                        create: {
                            proxy: createChecklist,
                            filter: checklist => clearChecklistTemplateForPost(checklist),
                            success: 'checklist_template_saved_successfully',
                        },
                        title: 'new_checklist_template',
                        form: checklistTemplateForm,
                    },
                },
                {
                    label: 'edit_checklist_template',
                    key: uuid(),
                    path: ':id/edit-template',
                    showTabs: false,
                    hideTab: true,
                    component: FormView,
                    componentProps: {
                        get: { proxy: getChecklist },
                        save: {
                            proxy: saveChecklist,
                            filter: checklist => clearChecklistForPut(checklist),
                            success: 'checklist_template_saved_successfully',
                            error: 'checklist_template_not_saved',
                        },
                        delete: {
                            proxy: deleteChecklist,
                            success: 'checklist_template_deleted_successfully',
                            error: 'checklist_template_not_deleted',
                        },

                        title: 'edit_checklist_template',
                        form: checklistTemplateForm,
                    },
                },
            ],
        },
    ],
};
