import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
    formControl: {
        width: '100%',
    },
    form: { marginTop: '0px', marginBottom: '0px' },
    select: {
        color: props => props.color,
        '&:before': {
            display: props => (props.hideLine ? 'none' : '?'),
            borderColor: props => props.color,
        },
        '&:after': {
            display: 'none',
            borderColor: props => props.color,
        },
    },
    icon: {
        fill: props => props.color,
    },
    input: {
        padding: props => (props.hideLine ? '0px' : '3px 0px 7px'),
    },
    label: {
        color: props => props.color,
        minWidth: '130px',
    },
}));

const EnhancedSelect = ({
    options,
    value,
    label,
    placeholder,
    onChange,
    getOptionLabel,
    getOptionValue,
    getOptionReturnValue,
    getOptionKey,
    input,
    disabled,
    error,
    helperText,
    color,
    hideLine,
    clearable,
}) => {
    const classes = useStyles({ color, hideLine });

    return (
        <div className={classes.form}>
            <FormControl className={classes.formControl} fullWidth>
                {label && (
                    <InputLabel error={error} className={classes.label}>
                        {label}
                    </InputLabel>
                )}
                <Select
                    label=""
                    className={classes.select}
                    inputProps={{
                        classes: {
                            icon: classes.icon,
                        },
                        className: classes.input,
                    }}
                    fullWidth
                    input={input}
                    error={error}
                    disabled={disabled || options.length === 0}
                    value={options.length ? value : ''}
                    placeholder={label || placeholder}
                    onChange={event => {
                        const { value } = event.target;
                        if (getOptionReturnValue) {
                            onChange(getOptionReturnValue(options.find(item => getOptionValue(item) === value)));
                        } else {
                            onChange(value);
                        }
                    }}
                >
                    {clearable && (
                        <MenuItem value="">
                            <em>Nessuno</em>
                        </MenuItem>
                    )}
                    {options.map(item => (
                        <MenuItem key={getOptionKey(item)} value={getOptionValue(item)}>
                            {getOptionLabel(item)}
                        </MenuItem>
                    ))}
                </Select>
                {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
            </FormControl>
        </div>
    );
};

EnhancedSelect.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number, PropTypes.object]),
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    getOptionLabel: PropTypes.func,
    getOptionValue: PropTypes.func,
    getOptionReturnValue: PropTypes.func,
    getOptionKey: PropTypes.func,
    input: PropTypes.node,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    color: PropTypes.string,
    hideLine: PropTypes.bool,
    clearable: PropTypes.bool,
};

EnhancedSelect.defaultProps = {
    value: '',
    label: undefined,
    placeholder: undefined,
    getOptionLabel: ({ label }) => label,
    getOptionKey: ({ id }) => id,
    getOptionValue: ({ value }) => value,
    getOptionReturnValue: undefined,
    input: undefined,
    disabled: false,
    error: undefined,
    helperText: undefined,
    color: undefined,
    hideLine: undefined,
    clearable: false,
};

export default EnhancedSelect;
