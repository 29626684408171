import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { footerHeight, headerHeight } from '../../constants/dimensionConstants';
import logoDark from '../../resources/LogoTigestimDark.png';
import logoLight from '../../resources/LogoTigestimLight.png';
import { selectDarkMode } from '../../store/slices/sliceAppearance';
import Footer from '../dashboard/Footer';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 0.3,
        top: 0,
        left: 0,
        overflow: 'hidden',
    },
}));

const HomePage = () => {
    const classes = useStyles();
    const darkMode = useSelector(selectDarkMode);
    return (
        <Box width={1} height={1} flex={1}>
            <Box
                display="flex"
                justifyContent="center"
                style={{
                    overflow: 'auto',
                    height: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
                }}
                className={classes.container}
            >
                <img className={classes.logotypeIcon} src={darkMode ? logoDark : logoLight} alt="logo" />
            </Box>
            <Footer />
        </Box>
    );
};

HomePage.propTypes = {};

HomePage.defaultProps = {};

export default HomePage;
