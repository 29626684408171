const fr = {
    // General
    name: `Nom`,
    identity: `Identité`,
    user_profile: `Profil de l'utilisateur`,
    username: `Nom d'utilisateur`,

    password: `Mot de passe`,
    confirm_password: `Mot de passe Conferma`,
    change_password: `Changer le mot de passe`,
    login: `Connexion`,
    logout: `Se déconnecter`,
    sign_in: `Connexion`,
    remember_me: `Souviens-toi de moi`,
    forgotten_password: `Mot de passe oublié`,
    project_manager: `Gestionnaire de projet`,
    admin: `Administrateur`,
    action: `action`,
    rows: `Lignes`,
    male: `Homme`,
    neutral: `Neutre`,
    female: `Femme`,
    loading: `Chargement....`,
    go_back: `Retourner`,
    remove: `Supprimer`,
    add: `Ajouter`,
    enter: `Entrer`,
    exit: `Sortir`,
    // Menu
    construction_sites: `Sites de construction`,
    construction_site: `Site de construction`,
    construction_sites_list: 'Liste des chantiers',
    dashboard: `Tableau de bord`,
    journal: `Journal`,
    calendar: `Calendrier`,
    files: `Des dossiers`,
    contacts: `Contacts`,
    hr: `HR`,
    HR: `HR`,
    profile: `Profil`,
    edit: `Éditer`,
    new: `Nouveau`,
    leaves: `feuilles`,
    expenses: `les dépenses`,
    checklist_requests : "Demandes de liste de contrôle",
    // Action
    'menu.collapse': `Fermer le menu`,
    'menu.employees': `Liste des employés`,
    'menu.employees.beta': `Liste des employés (bêta)`,
    grid_display: `Affichage de grille`,
    list_display: `Affichage de la liste`,
    new_site: `Créer un nouveau site`,
    new_user: `Créer un nouvel utilisateur`,
    new_working_hours: `Ajouter des heures de travail`,
    save_working_hours: `Économisez des heures de travail`,
    create_working_hours: `Créer des heures de travail`,
    add_favorite: `Ajouter aux Favoris`,
    notifications_settings: `Notifications et paramètres`,
    mandatory_notification: 'Compilation obligatoire avec notifications',
    // edit: `Éditer`,
    ///  Sites
    quickcomId: `Quick Id`,
    title: `Titre`,
    address: `Adresse`,
    city: `Ville`,
    zip: `Zipper`,
    country: `De campagne`,
    createdAt: `Créé à`,
    favorite: `Favori`,
    // Tab
    list: `Lister`,
    quick: `Données rapides`,
    // journal: `Journal`,
    // calendar: `Calendrier`,
    // files: `Des documents`,
    // contacts: `Contacts`,
    all_leave_requests: `Toutes les demandes de congé`,
    all_expense_requests: `Toutes les demandes de dépenses`,
    all_festivities: `Toutes les festivités`,
    all_working_hours: `Toutes les heures de travail`,
    // admin: `Administrateur`,
    tool_manager: `Gestionnaire d'outils`,
    all_schedules: `Tous les horaires`,
    all_checklists: `Toutes les listes de contrôle`,
    permission: `Autorisation`,
    file_log: `Journal des fichiers`,
    notification: `Notification`,
    ///  Users list
    lastName: `Nom de famille`,
    firstName: `Nom`,
    phoneNumberList: `Numéro de téléphone`,
    email: `E-mail`,
    role: `Rôle`,
    // Leaves list
    user: `Utilisateur`,
    type: `Taper`,
    startAt: `Commencer à`,
    endAt: `Fin à`,
    duration: `Durée`,
    status: `Statut`,
    days: `Jours`,
    approve: `Approuvé`,
    approved: `Approuvé`,
    rejecte: `Rejeté`,
    rejected: `Rejeté`,
    pending: `En attente`,

    holiday: `Holiday`,
    military: `Militaire`,
    sickness: `Maladie`,
    maternity: `Maternité`,
    medical: `Médical`,
    professionalAccident: `Accident professionnel`,
    nonProfessionalAccident: `Accident non professionnel`,
    training: `Formation`,
    weather: `Temps`,
    others: `Autres`,

    // Expenses
    new_expense: `Nouvelle dépense`,
    edit_expense: `Modifier la dépense`,
    amount: `Quantité`,
    date: `Date`,
    reason: `Raison`,
    place: `Lieu`,
    // Working hours
    winter: `Hiver`,
    summer: `Été`,
    // Profile
    edit_user: `Modifier l'utilisateur`,
    // profile: `Profil`,
    identify: `Identité`,
    personal_calendar: `Calendrier personnel`,
    // expenses: `Dépenses`,
    leaves_and_holidays: `Congés et jours fériés`,
    upload_file: `Choisir le fichier`,
    file_name: `Nom de fichier`,
    // Profile Edit
    discard_changes: `Annuler les modifications`,
    deactivate_user: `Désactiver l'utilisateur`,

    personal_contacts: `Contacts personnels`,
    personal_address: `Adresse personnelle`,
    personal_e_mail: `Email personnel`,
    personal_phone_number: `Numéro de téléphone personnel`,
    birth_date: `Date de naissance`,
    work_contacts: `Contacts de travail`,
    work_email: `Email de travail`,
    work_phone_number: `Numéro de téléphone professionnel`,
    change_picture: `Changer l'image`,

    appearance: `Personnalisation`,
    notifications: `Notifications`,
    'switches.dark_mode': `Passer en mode sombre`,
    'switches.light_mode': `Passer en mode lumière`,

    // others: `Autres`,
    // User Profile HR
    hr_data: `Données RH`,
    badge_id: `ID du badge`,
    civil_status: `État civil`,
    children_number: `Numéro enfants`,
    mother_language: `Langue maternelle`,
    company: `Entreprise`,
    associated_company: `Société associée`,
    hired_at: `Embauché à`,
    terminated_at: `Terminé à`,
    //
    basic_data: `Donnee de base`,
    birthday: `Date d'anniversaire`,
    gender: `Le genre`,
    personal_email: `Email personnel`,
    personal_mobile_number: `Numéro de portable personnel`,
    personal_home_number: `Numéro de domicile personnel`,
    work_mobile_number: `Numéro de portable professionnel`,
    work_office_number: `Numéro du bureau de travail`,
    work_c_3_x_number: `Numéro de travail 3CX`,
    //
    working_hours: `Heures d'ouverture`,
    work_percentage: `Pourcentage de travail`,
    holidays_count_days: `Les vacances comptent`,
    break_time_h: `Pause`,
    day: `journée`,
    winter_start: `Début d'hiver`,
    winter_end: `Fin d'hiver`,
    summer_start: `Début d'été`,
    summer_end: `Fin d'été`,
    //
    legal_documents: `Documents le'gaux`,
    edit_legal_document: `Modifier le document juridique`,
    new_legal_document: `Nouveau document juridique`,
    expireat: `Date d'expiration`,
    numberDoc: `Noter`,
    //
    signed_documents: `Documents signés`,
    document_not_present: `Il n'y a pas de documents`,
    at_least_one_document: `Il doit y avoir au moins un document *`,
    edit_signed_document: `Modifier le document signé`,
    new_signed_document: `Nouveau document signé`,

    the_search_did_not_return_any_results: `La recherche n'a renvoyé aucun résultat`,

    // User Leaves HR
    // leaves: `Feuilles`,
    new_leave: `Nouveau congé`,
    edit_leave: `Modifier congé`,
    leaves_request: `Demande de congé`,
    my_leaves_request: `Ma demande de congé`,
    request_reimbursement_expenses: `Demande de remboursement de frais`,
    export_my_expense: `Exporter ma note de frais`,
    holidays_allowance: `Indemnité de vacances`,
    remaining: `Restant`,
    taken: `Pris`,
    pending_requests: `Requêtes en attente`,
    // sickness: `Maladie`,
    // military: `Militaire`,
    paternity: `Paternité`,
    medical_examination: `Examen médical`,
    professional_accident: `Accident professionnel`,
    non_professional_accident: `Accident non professionnel`,
    training_course: `Formation`,
    weather_conditions: `Conditions météorologiques`,
    other: `Autre`,

    from: `De`,
    to: `À`,
    // type: `Taper`,
    description: `Description`,
    // duration: `Durée`,
    // status: `Statut`,
    // User Expenses HR

    expenses_request: `Demande de frais`,
    my_expenses_request: `Ma demande de dépenses`,
    submit_new_leave: `Soumettre un nouveau congé`,
    submit_new_expense: `Soumettre une nouvelle dépense`,

    currency: `Devise`,
    changedChf: `Modifié`,
    quantity: `Quantité`,
    isEquipment: `Est l'équipement`,
    whoApproveId: `Qui a approuvé`,
    chf: `CHF`,
    usd: `USD`,
    // legalMaritalStatus
    married: `Marié`,
    widowed: `Veuf`,
    separated: `Séparé`,
    divorced: `Divorcé`,
    single: `Seul`,
    // Languages
    german: `Allemand`,
    french: `français`,
    italian: `italien`,
    english: `Anglais`,
    // Companies
    tigestim: `Tigestim`,
    metalsky: `Metalsky`,
    sitaf: `Sitaf`,
    metalpiega_color: `Metal Piega & Color`,
    edemar: `Edemar`,
    tmwood: `Tm Wood`,
    electrogestim: `Electrogestim`,
    // Messages
    user_saved_successfully: `L'utilisateur a bien été enregistré`,
    working_hours_saved_successfully: `Heures de travail économisées avec succès`,
    uploaded_successfully: `Téléversement réussi`,
    upload_failed: `Le téléchargement a échoué`,
    user_already_exists: `L'utilisateur existe déjà`,
    passwords_do_not_match: `Les mots de passe ne correspondent pas`,
    // Calendar
    'date.from': `De:`,
    'date.to': `À:`,
    'filters.all': `Voir tout`,
    'filters.festivity': `Festivités`,
    'filters.events': `Événements`,
    'eventTypes.festivity': `Festivité`,
    'eventTypes.events': `Événement`,
    'eventTypes.reports': `Signaler`,
    'eventTypes.personRequests': `Quitter`,
    'eventTypes.stampingLogs': `Timbratura`,
    // Validation
    validationMessagePassword: `Contient au moins: 8 caractères alphanumériques, 1 chiffre, 1 caractère minuscule (a-z), 1 caractère majuscule (A-Z). Le mot de passe ne peut contenir que ces caractères spéciaux! @ # $% ^ &`,
    validationMessageEmail: `Email invalide`,

    // Journals
    load_more_journal_posts: `Chargement...`,
    Journal: `Journal`,
    edit_post: `Modifier le message`,
    new_journal: `Nouvelle entrée de journal`,
    create_journal: `Nouvelle entrée de journal`,
    save_journal: `Sauvegarder`,
    delete_journal: `Effacer`,
    see_all_comments: `Voir tous les commentaires`,
    see_all: `Voir tous`,
    like: `Aimer`,
    add_comment: `Ajouter un commentaire`,
    share: `Partager`,
    add_new_journal_entry: `Ajouter une nouvelle entrée de journal`,
    drag_and_drop_an_image_here_or_click: `"Glissez-déposez une image ici ou cliquez sur"`,
    edit_journal_entry: `Modifier l'entrée de journal`,
    message: `Un message`,
    thumbnail: `La vignette`,
    upload_picture: `Charger une photo`,
    iva: `IVA`,
    es_3_15: `Es. 3,15%`,
    journal_saved_successfully: `Heures de journal enregistrées avec succès`,

    // Contact
    edit_contact: `Modifier les contacts`,

    // Right
    all_rights_reserved: `Tous les droits sont réservés`,
    support: `Soutien`,
    contact: `Contacter`,
    version: `Version`,
    journal_new_user: 'Nouvel utilisateur',
    journal_auto_description: `L'utilisateur <USER> vient d'être ajouté au site 🥳`    
};

export default fr;
