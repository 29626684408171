import cookie from 'react-cookies';
import { v4 as uuid } from 'uuid';

const name = `TIGESTIM_LOGIN_${process.env.NODE_ENV.toLocaleUpperCase()}`;

const save = () => cookie.save(name, uuid(), { path: '/' });

const revoke = () => cookie.remove(name, { path: '/' });

const present = () => cookie.load(name, { path: '/' });

export default { save, revoke, present };
