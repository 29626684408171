import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTheme } from '@material-ui/core/styles';

const EmailCell = ({ item, id }) => {
    const theme = useTheme();
    return (
        <a href={`mailto:${item[id]}`} style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
            <Box>{item[id]}</Box>
        </a>
    );
};

EmailCell.propTypes = {
    item: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
};
EmailCell.defaultProps = {};

export default EmailCell;
