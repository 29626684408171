import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '../../hooks/useTranslate';
import {
    closeDialog,
    selectDialogCancelCallback,
    selectDialogCancelLabel,
    selectDialogComponent,
    selectDialogComponentProps,
    selectDialogConfirmationCallback,
    selectDialogConfirmationEnable,
    selectDialogConfirmationLabel,
    selectDialogData,
    selectDialogFullScreen,
    selectDialogFullWidth,
    selectDialogMaxWidth,
    selectDialogMessage,
    selectDialogNegationCallback,
    selectDialogNegationLabel,
    selectDialogOpen,
    selectDialogTitle
} from '../../store/slices/sliceDialog';
import EnhancedCheckbox from '../utilities/EnhancedCheckbox';

const EnhancedDialog = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();

    const open = useSelector(selectDialogOpen);
    const data = useSelector(selectDialogData);
    const title = useSelector(selectDialogTitle);

    const Component = useSelector(selectDialogComponent);
    const componentProps = useSelector(selectDialogComponentProps);
    const message = useSelector(selectDialogMessage);

    const cancelLabel = useSelector(selectDialogCancelLabel);
    const cancelCallback = useSelector(selectDialogCancelCallback);
    const confirmationLabel = useSelector(selectDialogConfirmationLabel);
    const confirmationCallback = useSelector(selectDialogConfirmationCallback);
    const negationLabel = useSelector(selectDialogNegationLabel);
    const negationCallback = useSelector(selectDialogNegationCallback);
    const confirmationEnable = useSelector(selectDialogConfirmationEnable);
    const fullScreen = useSelector(selectDialogFullScreen);
    const fullWidth = useSelector(selectDialogFullWidth);
    const maxWidth = useSelector(selectDialogMaxWidth);
    const [enableNegation, setEnableNegation] = useState()

    return (
        <Dialog
            open={open}
            scroll="paper"
            fullScreen={fullScreen}
            disableBackdropClick
            fullWidth={fullWidth}
            maxWidth={Component ? maxWidth : undefined}
            onClose={() => dispatch(closeDialog())}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {title && <DialogTitle id="alert-dialog-title">{translate(title)}</DialogTitle>}
            {Component && <Component {...componentProps} />}
            {message && (
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{translate(message) || ''}</DialogContentText>
                </DialogContent>
            )}
            <DialogActions>
                <Box width={1} display='flex' justifyContent="space-between">
                    {negationLabel && (
                        <Box display='flex'>
                            <EnhancedCheckbox label="non_reversible_operation" value={enableNegation} onChange={setEnableNegation}/>
                            <Button
                                disabled={!enableNegation}
                                onClick={() => {
                                    dispatch(closeDialog());
                                    if (negationCallback) {
                                        const value = negationCallback(data) || {};
                                        if (value.type) dispatch(value);
                                    }
                                }}
                            >
                                {translate(negationLabel)}
                            </Button>
                        </Box>
                    )}
                </Box>
                <Box display='flex'>
                    {cancelLabel && (
                        <Button
                            onClick={() => {
                                dispatch(closeDialog());

                                if (cancelCallback) {
                                    const value = cancelCallback() || {};
                                    if (value.type) dispatch(value);
                                }
                            }}
                        >
                            {translate(cancelLabel)}
                        </Button>
                    )}
                    {confirmationLabel && (
                        <Button
                            disabled={!confirmationEnable(data)}
                            onClick={async () => {
                                dispatch(closeDialog());
                                if (confirmationCallback) {
                                    const value = await confirmationCallback(data);
                                    if (value?.type) dispatch(value);
                                }
                            }}
                            variant="text"
                        // color="primary"
                        >
                            {translate(confirmationLabel)}
                        </Button>
                    )}
                </Box>
            </DialogActions>
        </Dialog>
        
    );
};

EnhancedDialog.propTypes = {};

export default EnhancedDialog;
