import { createSlice } from '@reduxjs/toolkit';

const sliceSideBar = createSlice({
    name: 'sideBar',
    initialState: {
        open: true,
    },
    reducers: {
        toggleSideBar: state => {
            state.open = !state.open;
        },
        openSideBar: (state, { payload }) => {
            state.open = payload;
        },
    },
});

export default sliceSideBar;

// ACTIONS
export const { toggleSideBar, openSideBar } = sliceSideBar.actions;

export const selectSideBarOpen = state => state.sideBar.open;
