import { Box, Button, Divider } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Apartment, Room } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Geocode from "react-geocode";
import { useDispatch } from 'react-redux';
import FlexColumn from '../../../components/flex/FlexColumn';
import FlexRow from '../../../components/flex/FlexRow';
import SimpleCard from '../../../components/SimpleCard';
import EnhancedMap from '../../../components/utilities/EnhancedMap';
import useTranslate from '../../../hooks/useTranslate';
import { openDialog } from '../../../store/slices/sliceDialog';
import { buildUrl } from '../../../utils/common';
import { formatCurrency } from '../../../utils/formats';
import i from '../../../ability/ability'

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.setLanguage(localStorage.getItem('locale') || "it");

const isLatitude = num => Number.isFinite(num) && Math.abs(num) <= 90;
const isLongitude = num => Number.isFinite(num) && Math.abs(num) <= 180;

const SiteDashboardQuickData = ({ site }) => {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const [position, setPostilion] = useState({})
    const totalCost = (site.laborCost || 0)+(site.supplierCost || 0)+(site.othersCost || 0)+(site.goodsCost || 0)
    const totalCostDifferences = (site.offer || 0) - totalCost
    useEffect(() => {
        if (site.address)
            Geocode.fromAddress(site.address).then(
                (response) => {
                    setPostilion(response.results[0].geometry.location)
                },
                () => {
                    setPostilion({lat: site.latitude, lng: site.longitude })
                }
            );
        else {
            setPostilion({lat: site.latitude, lng: site.longitude })
        }
    }, [site])

    return (
        <SimpleCard icon={Apartment} title="quick" height={727}>
            <FlexColumn>
                <img src={buildUrl(site.avatarUrl)} alt={site.avatarUrl} height={170} style={{ objectFit: 'cover' }} />
                <Box color={grey[400]} fontSize={14} py={1}>
                    {site.quickcomId}
                </Box>
                <Box fontSize={20} fontWeight={500}>
                    {site.title}
                </Box>
                <FlexRow py={0.5}>
                    <Button
                        size="small"
                        disabled={!isLatitude(position.lat) || !isLongitude(position.lng)}
                        onClick={() => {
                            dispatch(
                                openDialog({
                                    title: site.address,
                                    fullWidth: true,
                                    cancelLabel: 'close',
                                    component: EnhancedMap,
                                    componentProps: {
                                        center: position,
                                        position,
                                    },
                                })
                            );
                        }}
                    >
                        <Box height={1} display="flex" justifyContent="center" alignItems="center" color={grey[400]} fontSize={14} pr={1}>
                            <Room />
                        </Box>
                        <Box height={1} display="flex" justifyContent="center" alignItems="center">
                            {site.address}
                        </Box>
                    </Button>
                </FlexRow>
                <Divider />
                <FlexRow py={0.5} justifyContent="space-between" alignItems="center">
                    <Box>{translate('quickcomId')}</Box>
                    <Box color={grey[400]} fontSize={14}>
                        {site.quickcomId}
                    </Box>
                </FlexRow>
                <Divider />
                <FlexRow py={0.5} justifyContent="space-between" alignItems="center">
                    <Box>{translate('subject')}</Box>
                    <Box color={grey[400]} fontSize={14}>
                        {site.subject}
                    </Box>
                </FlexRow>
                <Divider />
                <FlexRow py={0.5} justifyContent="space-between" alignItems="center">
                    <Box>{translate('status')}</Box>
                    <Box color={grey[400]} fontSize={14}>
                        {site.status}
                    </Box>
                </FlexRow>
                <Divider />
                <FlexRow py={0.5} justifyContent="space-between" alignItems="center">
                    <Box>{translate('category')}</Box>
                    <Box color={grey[400]} fontSize={14}>
                        {site.category}
                    </Box>
                </FlexRow>
                {(i.can('read', 'sensibleData') || i.can('read', 'sensibleDataClient')) && (
                    <>
                        <Divider />
                        <FlexRow py={0.5} justifyContent="space-between" alignItems="center">
                            <Box><strong>{translate('offer')}</strong></Box>
                            <Box color={grey[400]} fontSize={14}>
                                {`CHF ${formatCurrency(site.total)}`}
                            </Box>
                        </FlexRow>
                        <FlexRow py={0.5} justifyContent="space-between" alignItems="center">
                            <Box>{translate('requiredAdvances')}</Box>
                            <Box color={grey[400]} fontSize={14}>
                                {`CHF ${formatCurrency(site.requiredAdvances)}`} 
                            </Box>
                        </FlexRow>
                        <FlexRow py={0.5} justifyContent="space-between" alignItems="center">
                            <Box>{translate('cashedAdvances')}</Box>
                            <Box color={grey[400]} fontSize={14}>
                                {`CHF ${formatCurrency(site.cashedAdvances)}`}
                            </Box>
                        </FlexRow>
                        <FlexRow py={0.5} justifyContent="space-between" alignItems="center">
                            <Box>{translate('balance')}</Box>
                            <Box color={grey[400]} fontSize={14}>
                                {`CHF ${formatCurrency(site.balance)}`}
                            </Box>
                        </FlexRow>
                    </>
                )}
                {i.can('read', 'sensibleData') && (
                    <>
                        <Divider />
                        <FlexRow py={0.5} justifyContent="space-between" alignItems="center">
                            <Box>{translate('laborCost')}</Box>
                            <Box color={grey[400]} fontSize={14}>
                                {`CHF ${formatCurrency(site.laborCost)}`}
                            </Box>
                        </FlexRow>
                        <FlexRow py={0.5} justifyContent="space-between" alignItems="center">
                            <Box>{translate('supplierCost')}</Box>
                            <Box color={grey[400]} fontSize={14}>
                                {`CHF ${formatCurrency(site.supplierCost)}`}
                            </Box>
                        </FlexRow>
                        <FlexRow py={0.5} justifyContent="space-between" alignItems="center">
                            <Box>{translate('othersCost')}</Box>
                            <Box color={grey[400]} fontSize={14}>
                                {`CHF ${formatCurrency(site.othersCost)}`}
                            </Box>
                        </FlexRow>
                        <FlexRow py={0.5} justifyContent="space-between" alignItems="center">
                            <Box>{translate('goodsCost')}</Box>
                            <Box color={grey[400]} fontSize={14}>
                                {`CHF ${formatCurrency(site.goodsCost)}`}
                            </Box>
                        </FlexRow>
                        <Divider />
                        <FlexRow py={0.5} justifyContent="space-between" alignItems="center">&nbsp;</FlexRow>
                        <Divider />
                        <FlexRow py={0.5} justifyContent="space-between" alignItems="center">
                            <Box><strong>{translate('totalCost')}</strong></Box>
                            <Box color={grey[400]} fontSize={14}>
                                {`CHF ${formatCurrency(totalCost)}`}
                            </Box>
                        </FlexRow>
                        <Divider />
                        <FlexRow py={0.5} justifyContent="space-between" alignItems="center">
                            <Box><strong>{translate('totalCostDifferences')}</strong></Box>
                            <Box color={grey[400]} fontSize={14}>
                                {`CHF ${formatCurrency(totalCostDifferences)}`}
                            </Box>
                        </FlexRow>
                    </>
                )}
            </FlexColumn>
        </SimpleCard>
    );
};

SiteDashboardQuickData.propTypes = {
    site: PropTypes.object,
};
SiteDashboardQuickData.defaultProps = {
    site: {},
};

export default SiteDashboardQuickData;
