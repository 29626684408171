import { useCallback, useContext } from 'react';
import localeContext from '../context/localeContext';
import it from '../i18n/it';
// import { capitalizeFirstLetter } from '../utils/common';
// const object = {};

const useTranslate = () => {
    const { dictionary } = useContext(localeContext);

    const translate = useCallback(
        value => {
            if (dictionary?.[value]) return dictionary[value];
            if (it[value]) return it[value];

            // if (value) {
            //     object = { ...object, [value]: capitalizeFirstLetter(value.replace(/_/g, ' ')) };
            //     // eslint-disable-next-line no-console
            //     console.log('file: useTranslate.js ~ line 20 ~ object', object);
            // }
            return value;
        },
        [dictionary]
    );

    return translate;
};
export default useTranslate;
