/* eslint-disable react/prop-types */
import React from 'react';

const authContext = React.createContext({});

export default authContext;

const { Provider } = authContext;

export const AuthProvider = ({ auth, children }) => {
    return <Provider value={auth}>{children}</Provider>;
};
