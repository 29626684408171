import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from '../../hooks/useTranslate';
import ScheduleTask from './ScheduleTask';

const ScheduleTasks = ({ value, onChange, readOnly }) => {
    const translate = useTranslate();
    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                {translate('tasks')}
            </Typography>
            {value.map(({ id: taskId, title, workers, activities }, index) => (
                <ScheduleTask
                    key={taskId}
                    taskId={taskId}
                    index={`${index + 1}. `}
                    title={title}
                    workers={workers}
                    activities={activities}
                    onChange={onChange}
                    readOnly={readOnly}
                />
            ))}
        </Box>
    );
};

ScheduleTasks.propTypes = {
    onChange: PropTypes.any.isRequired,
    readOnly: PropTypes.bool,
    value: PropTypes.array,
};
ScheduleTasks.defaultProps = {
    value: [],
    readOnly: undefined,
};

export default ScheduleTasks;
