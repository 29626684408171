import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';

const ViewColumn = ({ children, ...props }) => {
    return <View style={{ flexDirection: 'column', width: '100%', ...props }}>{children}</View>;
};

ViewColumn.propTypes = {
    children: PropTypes.node.isRequired,
    props: PropTypes.object,
};
ViewColumn.defaultProps = {
    props: {},
};

export default ViewColumn;
