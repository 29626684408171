import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Action from './Action';
import i from '../ability/ability'

const Actions = ({ actions, data, pb }) => {
    return (
        <Box display="flex" flexDirection="row-reverse" pb={pb} gridGap={8}>
            {actions.filter(({ role , show }) => (role?.(i) ?? true) && (show?.(data) ?? true)).map(action => (
                <Action key={`action-${action.label}`} data={data} padding="small" {...action} />
            ))}
        </Box>
    );
};

Actions.propTypes = {
    actions: PropTypes.array,
    data: PropTypes.object,
    pb: PropTypes.number,
};
Actions.defaultProps = {
    actions: [],
    data: {},
    pb: 2,
};

export default Actions;
