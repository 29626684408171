import { StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import PageTableQuestion from './PageTableQuestion';

const styles = StyleSheet.create({
    root: {
        fontSize: 5.5,
        borderLeft: 1,
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
    },  
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    headerCell: {
        paddingHorizontal: 2,
        borderTop: 1,
        borderRight: 1,
        borderBottom: 1,
        borderColor: 'black',
        fontWeight: 700,
    }, 
    cell: {
        paddingHorizontal: 2,
        borderRight: 1,
        borderBottom: 1,
        borderColor: 'black',
    }, 
    border: {
        borderRight: 1,
        borderBottom: 1,
        borderColor: 'black',
    },
    dotted: {
        flex: 1, 
        paddingHorizontal: 2, 
        borderStyle: 'dotted'
    },
});

const PageTable = ({ data, persons }) => {

    const { questions } = data;
    
    return (
        <View style={[styles.root, styles.column]}>
            <View fixed style={styles.row}>
                <Text style={[ styles.headerCell, { width: "4mm"}]}>N.</Text>
                <Text style={[ styles.headerCell, { width: "65mm"}]}>Domanda</Text>
                <Text style={[ styles.headerCell, { width: "37mm"}]}>Punti da verificare</Text>
                <Text style={[ styles.headerCell, { width: "37mm"}]}>Osservazioni</Text>
                <Text style={[ styles.headerCell, { width: "04mm"}]}>OK</Text>
                <Text style={[ styles.headerCell, { width: "09mm"}]}>Non OK</Text>
                <Text style={[ styles.headerCell, { width: "20mm"}]}>Chi?</Text>
                <Text style={[ styles.headerCell, { width: "11mm"}]}>Entro</Text>
                <Text style={[ styles.headerCell, { width: "08mm" }]}>Evaso</Text>
            </View>
            <View>
                {(questions || []).map(({ id, title, items, images  }, index) => {
                    return <PageTableQuestion key={id} index={index} title={title} items={items} images={images} persons={persons} />;
                })}
            </View>
        </View>
    );
};

PageTable.propTypes = {
    data: PropTypes.object,
    persons: PropTypes.object,
};

PageTable.defaultProps = {
    data: {},
    persons: {},
};

export default PageTable;
