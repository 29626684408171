import { createSlice } from '@reduxjs/toolkit';

const sliceNotification = createSlice({
    name: 'notification',
    initialState: {
        message: '',
        open: false,
        variant: 'success',
        autoHideDuration: 5000,
    },
    reducers: {
        toggleNotification: state => {
            state.open = !state.open;
        },
        setSuccessNotification: (state, { payload }) => {
            state.message = payload;
            state.open = true;
            state.variant = 'success';
        },
        setWarningNotification: (state, { payload }) => {
            state.message = payload;
            state.open = true;
            state.variant = 'warning';
        },
        setErrorNotification: (state, { payload }) => {
            state.message = payload;
            state.open = true;
            state.variant = 'error';
        },
    },
});

export default sliceNotification;

// ACTIONS

export const { setSuccessNotification, setWarningNotification, setErrorNotification, toggleNotification } = sliceNotification.actions;

// SELECTORS
export const selectNotificationOpen = state => state.notification.open;
export const selectNotificationMessage = state => state.notification.message;
export const selectNotificationVariant = state => state.notification.variant;
export const selectNotificationAutoHideDuration = state => state.notification.autoHideDuration;
