import { StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';

const width = 150;
const styles = StyleSheet.create({
    root: {
        fontSize: 9,
        borderRight: 1,
        borderBottom: 1,
    },
    section: {
        // flexGrow: 1,
        fontSize: 9,
    },
    header: {
        flexDirection: 'row',
        alignContent: 'center',
    },
    footer: {
        flexDirection: 'row',
        alignContent: 'center',
    },
    row: {
        flexDirection: 'row',
        alignContent: 'center',
    },
    cell: {
        paddingHorizontal: 4,
        borderLeft: 1,
        borderTop: 1,
        borderColor: 'black',
        // fontWeight: 700,
        width,
    },
    cellBold: {
        paddingHorizontal: 4,
        borderLeft: 1,
        borderTop: 1,
        borderColor: 'black',
        fontWeight: 700,
        width,
    },
    cellRight: {
        paddingHorizontal: 4,
        borderLeft: 1,
        borderTop: 1,
        borderColor: 'black',
        // fontWeight: 700,
        width,
        textAlign: 'right',
    },
    cellRightBold: {
        paddingHorizontal: 4,
        borderLeft: 1,
        borderTop: 1,
        borderColor: 'black',
        fontWeight: 700,
        width,
        textAlign: 'right',
    },
    cellCenter: {
        paddingHorizontal: 4,
        borderLeft: 1,
        borderTop: 1,
        borderColor: 'black',
        // fontWeight: 700,
        width,
        textAlign: 'center',
    },
    cellBackground:{
        backgroundColor: '#C4BD97',
        textAlign: 'center', 
        width: '25%'
    }
});

const RequestExoensesPageTable = ({ data }) => {
    const { date, expenses = [] } = data || {};

    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const items = expenses.filter((item) => {
        const dateItem = new Date(item.date);
        return item.status === "APPROVED" && month === dateItem.getMonth() + 1 && year === dateItem.getFullYear();
    }).map((item) => ({
        amount: item.amount,
        currency: item.currency,
        companyName: item.companyName,
        date: item.date,
        constructionSiteTitle: item.constructionSiteTitle,
        place: item.place,
        reason: item.reason,
        unit: item.unit
    }))

    return (
        <View style={styles.root}>
            <View style={{ ...styles.header }}>
                <Text style={{ ...styles.cellBold, width: '75%', textAlign: 'center' }}>Descrizione Spese</Text>
                <Text style={{ ...styles.cellRightBold, textAlign: 'center'}}>Data</Text>
                <Text style={{ ...styles.cellBold, textAlign: 'center'}}>Importo CHF</Text>
                <Text style={{ ...styles.cellBold, textAlign: 'center'}}>Importo Euro</Text>
                <Text style={{ ...styles.cellBold, textAlign: 'center', width: '25%'}}>Conversione CHF</Text>
                <Text style={{ ...styles.cellBold, ...styles.cellBackground}}>CONTO CONTABILE</Text>
            </View>
            <View>
                {items.map((element) => {
                    return (
                        <View key={date} style={styles.row}>
                            <Text style={{ ...styles.cell, width: '75%' }}>{element.reason.trim()} - {element.place.trim()}</Text>
                            <Text style={styles.cellRight}>{element.date}</Text>
                            <Text style={styles.cellCenter}>{element.currency} {element.amount}</Text>
                            <Text style={styles.cellCenter}>{}</Text>
                            <Text style={{ ...styles.cellCenter, width: '25%'}}>{}</Text>
                            <Text style={{ ...styles.cellRight , ...styles.cellBackground}}>{}</Text>
                        </View>
                    )})
                }
            </View>
        </View>
    );
};

RequestExoensesPageTable.propTypes = {
    data: PropTypes.object,
    // translate: PropTypes.func.isRequired
};

RequestExoensesPageTable.defaultProps = {
    data: {},
};

export default RequestExoensesPageTable;
