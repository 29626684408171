import { v4 as uuid } from 'uuid';
import { Export  } from 'mdi-material-ui';
import { openDialog } from '../store/slices/sliceDialog';
import i from '../ability/ability';
import FormView from '../components/view/FormView';
import { createExpense, createLeave, getExpense, getLeave, saveExpense, saveLeave } from '../store/proxies/tigestimProxies';
import UserCalendarView from '../view/hr/UserCalendarView';
import ProfileEdit from '../view/profile/ProfileEdit';
import ProfileExpensesView from '../view/profile/ProfileExpensesView';
import ProfileLeavesView from '../view/profile/ProfileLeavesView';
import SettingsView from '../view/profile/SettingsView';
import { profileEditActions, profileViewActions, userExpensesRequestsActions, userLeavesRequestsActions } from './actions';
import { editUserProfilePasswordForm, userExpenseEditForm, userLeaveEditForm, userProfileForm } from './forms';
import RequestExpensesDocument from '../view/hr/export/RequestExpensesDocument';

const routesProfile = [
    {
        title: 'Profile',
        key: uuid(),
        path: 'profile',
        tabs: [
            {
                key: uuid(),
                label: 'profile',
                path: 'dashboard',
                component: ProfileEdit,
                componentProps: {
                    readOnly: true,
                    actions: profileViewActions,
                    form: userProfileForm,
                },
            },
            {
                key: uuid(),
                label: 'profile',
                path: 'edit',
                showTabs: false,
                hideTab: true,
                component: ProfileEdit,
                componentProps: {
                    readOnly: false,
                    actions: profileEditActions,
                    form: i.can('update', 'profile') ? userProfileForm : editUserProfilePasswordForm,
                },
            },
            {
                key: uuid(),
                label: 'personal_calendar',
                path: 'calendar',
                component: UserCalendarView,
            },
            {
                key: uuid(),
                label: 'expenses',
                path: 'expenses',
                component: ProfileExpensesView,
                componentProps: {
                    actions: [
                        ...userExpensesRequestsActions, 
                        {
                            label: 'export_my_expense',
                            icon: Export,
                            // role: () => i.can('update', 'hr') || i.can('update', 'stampingsLog'),
                            onClick: data =>
                                openDialog({
                                    cancelLabel: 'close',
                                    component: RequestExpensesDocument,
                                    fullScreen: true,
                                    data,
                                }),
                        },
                    ],
                },
            },
            {
                label: 'expenses',
                key: uuid(),
                path: 'expenses/:id/edit',
                showTabs: false,
                hideTab: true,
                component: FormView,
                componentProps: {
                    get: {
                        proxy: getExpense,
                        success: 'expense_saved_successfully',
                    },
                    save: {
                        proxy: saveExpense,
                        filter: ({ recieptImage, ...rest }) => rest,
                        success: 'expense_saved_successfully',
                    },
                    title: 'edit_expense',
                    form: userExpenseEditForm,
                    defaultValue: {
                        currency: 'CHF',
                        isEquipment: 0,
                    },
                },
            },
            {
                label: 'expenses',
                key: uuid(),
                path: 'expenses/new',
                showTabs: false,
                hideTab: true,
                component: FormView,
                componentProps: (ids, user) => ({
                    create: {
                        proxy: createExpense,
                        filter: ({ recieptImage, ...rest }) => rest,
                        success: 'expense_saved_successfully',
                    },
                    title: 'new_expense',
                    form: userExpenseEditForm,
                    defaultValue: {
                        personId: user.id,
                        currency: 'CHF',
                        isEquipment: 0,
                    },
                }),
            },
            {
                label: 'leaves',
                key: uuid(),
                path: 'leaves',
                component: ProfileLeavesView,
                componentProps: {
                    actions: userLeavesRequestsActions,
                },
            },
            {
                label: 'leaves',
                key: uuid(),
                path: 'leaves/:id/edit',
                showTabs: false,
                hideTab: true,
                component: FormView,
                componentProps: {
                    get: { proxy: getLeave },
                    save: { proxy: saveLeave, success: 'leave_saved_successfully' },
                    title: 'edit_leave',
                    form: userLeaveEditForm,
                },
            },
            {
                label: 'leaves',
                key: uuid(),
                path: 'leaves/new',
                showTabs: false,
                hideTab: true,
                component: FormView,
                componentProps: (ids, user) => ({
                    create: { proxy: createLeave, success: 'leave_saved_successfully' },
                    title: 'new_leave',
                    form: userLeaveEditForm,
                    defaultValue: {
                        personId: user?.id,
                    },
                }),
            },

            {
                key: uuid(),
                label: 'settings',
                path: 'settings',
                component: SettingsView,
            },
        ],
    },
];

export default routesProfile;
