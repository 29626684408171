import { ShieldAccountVariant } from 'mdi-material-ui';
import { v4 as uuid } from 'uuid';
import i from '../../ability/ability';
import history from '../../components/routings/history';
import FormView from '../../components/view/FormView';
import TableView from '../../components/view/TableView';
import { companies, roles } from '../../constants/options';
import { getUser, getUsers, saveUser } from '../../store/proxies/tigestimProxies';
import { permissionsColumns } from '../columns';
import { permissionForm } from '../forms';

const role = () => i.can('update', 'admin');

export default {
    title: 'permission',
    key: uuid(),
    path: 'permission',
    to: 'permission/list',
    role,
    icon: ShieldAccountVariant,
    tabs: [
        {
            label: 'list',
            path: 'list',
            key: uuid(),
            showTabs: false,
            hideTab: true,
            component: TableView,
            componentProps: {
                columns: permissionsColumns,
                proxy: getUsers,
                onItemClick: ({ id }) => history.push(`${id}/edit`),
                searchable: true,
                params: {
                    companyName: 'all',
                },
                filters: [
                    {
                        placeholder: 'companyName',
                        options: companies,
                        param: 'companyName',
                        defaultValue: 'all',
                    },
                    {
                        placeholder: 'role',
                        options: roles,
                        param: 'role',
                    },
                ],
            },
        },
        {
            label: 'edit',
            showTabs: false,
            hideTab: true,
            key: uuid(),
            path: ':id/edit',
            component: FormView,
            componentProps: {
                get: { proxy: getUser },
                save: { proxy: saveUser, success: 'user_saved_successfully' },
                title: 'edit_permission',
                form: permissionForm,
            },
        },
    ],
};
