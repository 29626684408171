import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import React from 'react';
import { buildUrl } from '../../utils/common';

const useStyles = makeStyles(() => ({
    root: {
        paddingLeft: 0,
        paddingTop: 0,
    },
}));

const EnhancedListItem = ({ field, id, getOptionId, icon: Icon, label, url, onChange }) => {
    const classes = useStyles();
    return (
        <ListItem
            button
            dense
            classes={classes}
            onClick={() => {
                window.open(buildUrl(url));
            }}
        >
            <ListItemIcon>
                <Icon />
            </ListItemIcon>
            <ListItemText primary={label} />
            <ListItemSecondaryAction>
                <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                        onChange(prev => ({
                            ...prev,
                            [field]: prev[field].filter(item => getOptionId(item) !== id),
                        }));
                    }}
                >
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

EnhancedListItem.propTypes = {
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    getOptionId: PropTypes.func.isRequired,
    icon: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
EnhancedListItem.defaultProps = {};

export default EnhancedListItem;
