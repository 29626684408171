import { ViewState } from '@devexpress/dx-react-scheduler';
import {
    AllDayPanel,
    Appointments,
    AppointmentTooltip,
    DateNavigator,
    DayView,
    MonthView,
    Scheduler,
    TodayButton,
    Toolbar,
    ViewSwitcher,
    WeekView,
} from '@devexpress/dx-react-scheduler-material-ui';
import Paper from '@material-ui/core/Paper';
import { Event } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import localeContext from '../../context/localeContext';
import useTranslate from '../../hooks/useTranslate';
import SimpleCard from '../SimpleCard';
import { Appointment } from './Appointment';
import AppointmentContent from './AppointmentContent';
import ToolbarContent from './ToolbarContent';
import TooltipContent from './TooltipContent';
import { TooltipHeader } from './TooltipHeader';

const schedulerHeaderHeight = 18; // static value
const schedulerHeight = 800; // value can be calculated aftrer first render

const TimeTableCell = props => {
    return <MonthView.TimeTableCell {...props} style={{ height: `${(schedulerHeight - schedulerHeaderHeight) / 6}px` }} />;
};

const EnhancedCalendar = ({
    icon,
    title,
    defaultViewName,
    availableViews,
    schedulerData,
    selectedDay,
    defaultDayHours,
    defaultWeekHours,
    onChange,
    isLoading,
}) => {
    const translate = useTranslate();
    const [dataSource, setDataSource] = useState(schedulerData);
    const [filter, setFilter] = useState('all');
    const [eventTypes, setEventTypes] = useState([]);

    const { locale } = useContext(localeContext);

    useEffect(() => {
        setEventTypes(Array.from(new Set(schedulerData.map(t=>t.eventType).filter(t=>!!t))));
    }, [schedulerData]);

    useEffect(() => {
        if (filter === 'all') {
            setDataSource(schedulerData);
        } else {
            setDataSource(schedulerData.filter(({ eventType }) => eventType === filter));
        }
    }, [filter, schedulerData]);

    return (
        <SimpleCard icon={icon} title={title} isLoading={isLoading}>
            <Paper>
                <Scheduler data={dataSource} locale={locale} firstDayOfWeek={1}>
                    <ViewState currentDate={selectedDay} defaultCurrentViewName={defaultViewName} onCurrentDateChange={onChange} />
                    {availableViews.includes('Day') && (
                        <DayView startDayHour={defaultDayHours[0]} endDayHour={defaultDayHours[1]} displayName={translate('day')} />
                    )}
                    {availableViews.includes('Week') && (
                        <WeekView startDayHour={defaultWeekHours[0]} endDayHour={defaultWeekHours[1]} displayName={translate('week')} />
                    )}
                    {availableViews.includes('Month') && <MonthView displayName={translate('month')} timeTableCellComponent={TimeTableCell} />}
                    (<Toolbar flexibleSpaceComponent={() => ToolbarContent({ filter, setFilter, eventTypes })} />)
                    <DateNavigator />
                    <TodayButton messages={{ today: translate('today') }} />
                    {availableViews.length > 1 && <ViewSwitcher />}
                    <Appointments appointmentComponent={Appointment} appointmentContentComponent={AppointmentContent} />
                    <AllDayPanel messages={{ allDay: translate('all_day') }} />
                    <AppointmentTooltip showCloseButton headerComponent={TooltipHeader} contentComponent={TooltipContent} />
                </Scheduler>
            </Paper>
        </SimpleCard>
    );
};

EnhancedCalendar.propTypes = {
    icon: PropTypes.object,
    title: PropTypes.string,
    defaultViewName: PropTypes.string,
    availableViews: PropTypes.array,
    schedulerData: PropTypes.array,
    selectedDay: PropTypes.object,
    defaultDayHours: PropTypes.array,
    defaultWeekHours: PropTypes.array,
    onChange: PropTypes.func,
    isLoading: PropTypes.bool,
};

EnhancedCalendar.defaultProps = {
    icon: Event,
    title: 'calendar',
    defaultViewName: 'Month',
    availableViews: ['Month', 'Week', 'Day'],
    schedulerData: [],
    selectedDay: new Date(),
    defaultDayHours: [7, 20],
    defaultWeekHours: [7, 20],
    onChange: undefined,
    isLoading: undefined,
};
export default EnhancedCalendar;
