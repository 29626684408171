import PropTypes from 'prop-types';
import { Box, Link } from '@material-ui/core';
import * as React from 'react';
import { Skeleton } from '@material-ui/lab';
import FlexRow from '../../../components/flex/FlexRow';
import useTranslate from '../../../hooks/useTranslate';
import history from '../../../components/routings/history';

const CardBadge = ({ icon: Icon, title, to, quantity }) => {
    const translate = useTranslate();
    return (
        <Box height={1}>
            <Link
                to={to}
                onClick={event => {
                    event.preventDefault();
                    history.push(to);
                }}
            >
                <Box display="flex" flexDirection="row" justifyContent="space-between" width={1}>
                    <Box color="textPrimary" fontWeight={500} fontSize={18}>
                        {translate(title)}
                    </Box>
                </Box>
            </Link>

            <FlexRow justifyContent="space-between" alignItems="flex-end" maxWidth={1}>
                <Box fontSize={29} fontWeight={500}>
                    {quantity ?? <Skeleton width={16} />}
                </Box>
                <Box color="primary.lightest" display="flex" height={1} alignContent="center" justifyContent="center" pb={0.5}>
                    <Icon style={{ fontSize: 30 }} />
                </Box>
            </FlexRow>
        </Box>
    );
};

CardBadge.propTypes = {
    icon: PropTypes.object.isRequired,
    quantity: PropTypes.number,
    title: PropTypes.any.isRequired,
    to: PropTypes.any,
};

CardBadge.defaultProps = {
    to: undefined,
    quantity: undefined,
};

export default CardBadge;
