import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';

const ViewRow = ({ children, ...props }) => {
    return <View style={{ flexDirection: 'row', ...props }}>{children}</View>;
};

ViewRow.propTypes = {
    children: PropTypes.node.isRequired,
    props: PropTypes.object,
};
ViewRow.defaultProps = {
    props: {},
};

export default ViewRow;
