import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { buildUrl } from '../../utils/common';

const useStyles = makeStyles(() => ({
    image: {},
}));

const ImageCell = ({ value }) => {
    const classes = useStyles();

    const [src, setSrc] = useState('');

    useEffect(() => {
        setSrc(value);
        return () => {
            setSrc('');
        };
    }, [value]);

    return (
        <Box width={1} height={32} display="flex" alignItems="center" justifyContent="center">
            <img alt={value} src={buildUrl(src)} className={classes.image} width="60" height="24" />
        </Box>
    );
};

ImageCell.propTypes = {
    value: PropTypes.string,
};
ImageCell.defaultProps = {
    value: '',
};

export default ImageCell;
