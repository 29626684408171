import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import EnhancedCell from './EnhancedCell';
import { invoke, invokeOrReturn } from '../../utils/common';

const useStyles = makeStyles(theme => ({
    row: {
        height: rowHeight => theme.spacing(rowHeight),
        '& img': {
            cursor: 'default',
        },
    },
}));

const EnhancedRow = ({ item, columns, height, selected, invalidated, invalidatedColor, onItemClick, padding, hasPointer }) => {
    const classes = useStyles(height);
    const dispatch = useDispatch();
    return (
        <TableRow hover selected={selected} className={classes.row} style={{ cursor: hasPointer === true ? 'pointer' : 'default' }}>
            {columns.map(column => {
                const { component: CustomCell, onClick, componentProps, ...props } = column;
                if (CustomCell) {
                    return (
                        <TableCell key={`cell-${item.id}-${column.id}-${column.label || ''}`} align={column.align || 'left'}>
                            <CustomCell
                                id={column.id}
                                invalidated={invalidated}
                                invalidatedColor={invalidatedColor}
                                key={`cell-${item.id}-${column.id}`}
                                column={column}
                                item={item}
                                value={item[column.id]}
                                onItemClick={value => {
                                    if (onItemClick) invoke(() => onItemClick(value), dispatch);
                                }}
                                {...invokeOrReturn(componentProps, item)}
                            />
                        </TableCell>
                    );
                }
                return (
                    <EnhancedCell
                        invalidated={invalidated}
                        invalidatedColor={invalidatedColor}
                        key={`cell-${item.id}-${column.id}`}
                        column={column}
                        item={item}
                        onItemClick={value => {
                            if (onItemClick) invoke(() => onItemClick(value), dispatch);
                        }}
                        padding={padding}
                        {...props}
                    />
                );
            })}
        </TableRow>
    );
};

EnhancedRow.propTypes = {
    item: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    onItemClick: PropTypes.func,
    selected: PropTypes.bool,
    invalidated: PropTypes.bool,
    invalidatedColor: PropTypes.string,
    height: PropTypes.number,
    padding: PropTypes.string,
    hasPointer: PropTypes.bool,
};

EnhancedRow.defaultProps = {
    onItemClick: () => {},
    selected: false,
    invalidated: false,
    invalidatedColor: undefined,
    height: 4,
    padding: 0,
    hasPointer: undefined,
};

export default EnhancedRow;
