import { Box, Card, useMediaQuery, useTheme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Add, ImportContacts, MenuBook } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import Action from '../../../components/Action';
import EnhancedCarousel from '../../../components/EnhancedCarousel';
import FlexRow from '../../../components/flex/FlexRow';
import history from '../../../components/routings/history';
import SimpleCard from '../../../components/SimpleCard';
import useTranslate from '../../../hooks/useTranslate';
import { formatDate } from '../../../utils/formats';
import LoadingFullScreen from '../../../components/utilities/LoadingFullScreen';

const SiteDashboardJournals = ({ journal, isLoading }) => {
    const translate = useTranslate();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('lg'));
    if (isLoading)
        return (
            <SimpleCard icon={ImportContacts} title="journal" height={matches ? 727 : 0}>
                <LoadingFullScreen message="" height={296} />
            </SimpleCard>
        );
    return (
        <SimpleCard icon={ImportContacts} title="journal" height={matches ? 727 : 0}>
            <Box display="flex" flexDirection="column" height={1}>
                <Box height={matches ? 614 : 0}>
                    {journal.length ? (
                        journal.map(journal => (
                            <Box pt={1} pb={2} key={journal.id}>
                                <Card>
                                    <Box display="flex" flexDirection="column">
                                        <EnhancedCarousel items={journal.journalImageList} getItemUrl={({ journalUrl }) => journalUrl} height={196} />
                                        <FlexRow alignItems="center" justifyContent="space-between" py={1}>
                                            <Box color={grey[400]} fontSize={14}>{`${journal.firstName} ${journal.lastName}`}</Box>
                                            <Box color={grey[400]} fontSize={14}>
                                                {formatDate(journal.createdAt)}
                                            </Box>
                                        </FlexRow>

                                        <Box fontSize={14}>{journal.title}</Box>
                                    </Box>
                                </Card>
                            </Box>
                        ))
                    ) : (
                        <Box
                            height={1}
                            width={1}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={16}
                            fontWeight="fontWeightMedium"
                            color="#999"
                        >
                            {translate('journal_not_present')}
                        </Box>
                    )}
                </Box>
                <FlexRow justifyContent="space-between" mx={-1} mb={-1}>
                    <Action icon={MenuBook} onClick={() => history.push('journal')} label="see_all" />
                    <Action icon={Add} onClick={() => history.push('journal/new')} label="new_journal" />
                </FlexRow>
            </Box>
        </SimpleCard>
    );
};

SiteDashboardJournals.propTypes = {
    isLoading: PropTypes.bool,
    journal: PropTypes.array,
};
SiteDashboardJournals.defaultProps = {
    journal: [],
    isLoading: undefined,
};

export default SiteDashboardJournals;
