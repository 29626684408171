import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../components/Actions';
import useFetch from '../../../hooks/useFetch';
import { getChecklists, getJournals, getSite, getSiteContacts, getSiteForUser } from '../../../store/proxies/tigestimProxies';
import { selectUserId } from '../../../store/slices/sliceAuth';
import { setItem } from '../../../store/slices/sliceNavigation';
import { bucketList } from '../../../utils/bucketUtils';
import SiteDashboardCalendar from './SiteDashboardCalendar';
import SiteDashboardContracts from './SiteDashboardContracts';
import SiteDashboardInfo from './SiteDashboardInfo';
import SiteDashboardJournals from './SiteDashboardJournals';
import SiteDashboardQuickData from './SiteDashboardQuickData';

const schedules = 2; // TODO

const SiteDashboard = ({ id, actions }) => {
    const dispatch = useDispatch();
    const userId = useSelector(selectUserId);
    const [files, setFiles] = useState();

    const [site] = useFetch(getSite, id, [id], [id], {});

    useEffect(() => {
        dispatch(setItem(site));
    }, [dispatch, site]);

    const [favorite] = useFetch(
        getSiteForUser,
        {
            id,
            filter: 'forUserId',
            userId,
        },
        [id],
        [id, userId],
        {}
    );

    const [journal, isLoadingJournal] = useFetch(
        getJournals,
        {
            id,
            filter: 'forSiteId',
            orderBy: 'createdAt',
            orderDirection: 'DESC',
            pageNumber: 0,
            pageSize: 2,
        },
        [id],
        [id],
        {}
    );

    const [contacts, isLoadingContracts] = useFetch(
        getSiteContacts,
        {
            id,
            filter: 'forSiteId',
            orderBy: 'id',
            orderDirection: 'ASC',
            pageNumber: 0,
            pageSize: -1,
        },
        [id],
        [id],
        {}
    );

    const [checklists] = useFetch(
        getChecklists,
        {
            siteId: id,
            orderBy: 'id',
            orderDirection: 'ASC',
            pageNumber: 0,
            pageSize: 1,
        },
        [id],
        [id],
        {}
    );

    useEffect(() => {
        const fetchBucket = async () => {
            try {
                const { KeyCount } = await bucketList({ bucketSite: site?.fileBucket, path: site?.filePath });
                setFiles(KeyCount);
            } catch (err) {
                setFiles(0);
            }
        };
        if (site) {
            if (site.filePath) {
                fetchBucket();
            } else setFiles(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [site]);

    return (
        <Box height={1}>
            <Actions actions={actions} data={{ ...site, userId, favouriteSiteId: favorite.userConstructionSite?.favouriteSiteId }} />
            <Grid container spacing={2}>
                <Grid item sm={12} md={6} lg={4}>
                    <SiteDashboardQuickData site={site} />
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <SiteDashboardContracts contacts={contacts.data} isLoading={isLoadingContracts} />
                        </Grid>
                        <Grid item sm={12}>
                            <SiteDashboardInfo contacts={contacts} journal={journal} files={files} checklists={checklists} schedules={schedules} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={12} md={12} lg={4}>
                    <SiteDashboardJournals journal={journal.data} isLoading={isLoadingJournal} />
                </Grid>
                <Grid item sm={12}>
                    <SiteDashboardCalendar site={site} />
                </Grid>
            </Grid>
        </Box>
    );
};

SiteDashboard.propTypes = {
    actions: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired,
};
SiteDashboard.defaultProps = {};

export default SiteDashboard;
