import Amplify, { Auth } from 'aws-amplify';
import axios from 'axios';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import cookie from '../../components/routings/cookie';
import history from '../../components/routings/history';
import EnhancedError from '../../components/utilities/EnhancedError';
import config from '../../configuration/amplify';
import { routes } from '../../configuration/routes';
import { baseUrlApp, baseUrlAuth } from '../../constants/originUrlConstants';
import abilityUpdate from '../../ability/abilityUpdate';
import { findFirstClickablePath } from '../../utils/menu';
import { receiveLogin, requestLogin, requestLogout } from '../slices/sliceAuth';
import { clearError, receiveError } from '../slices/sliceError';
import { setIsLoading } from '../slices/sliceFetching';
import { setErrorNotification } from '../slices/sliceNotification';
import { selectUser } from '../slices/sliceSession';

Amplify.configure(config);

function* afterLogin(response) {
    const firstPath = findFirstClickablePath(routes);

    if (firstPath) {
        cookie.save();
        history.push(`${baseUrlApp}/${firstPath}`);
    } else {
        throw new EnhancedError('Non si dispone dei diritti necessari per visualizzare i menu');
    }

    yield put(receiveLogin(response));

    // yield fork(sagasInitialization);
}

function* login({ payload }) {
    try {
        yield put(setIsLoading({ name: 'login', loading: true }));
        yield put(clearError());

        const data = yield call([Auth, Auth.signIn], payload);
        const { jwtToken } = data?.signInUserSession?.idToken || {};
        axios.defaults.headers.common = {
            Authorization: `Bearer ${jwtToken}`,
        };
        if (data) yield call(afterLogin, data);
        yield put(setIsLoading({ name: 'login', loading: undefined }));
    } catch (error) {
        yield put(setErrorNotification(error.message));
        yield put(setIsLoading({ name: 'login', loading: undefined }));
        yield put(receiveError(error));
        yield call([Auth, Auth.signOut]);
        cookie.revoke();
    }
}

function* logout() {
    try {
        cookie.revoke();
        yield call([Auth, Auth.signOut]);
        yield call(abilityUpdate, []);
        const user = yield select(selectUser)
        history.push(`${baseUrlAuth}/login?companyName=${encodeURIComponent(user.companyName)}`);
        // yield put(resetAll());
    } catch (error) {
        cookie.revoke();
        const user = yield select(selectUser)
        history.push(`${baseUrlAuth}/login?companyName=${encodeURIComponent(user.companyName)}`);
        yield put(receiveError(error));
    }
}

export default function* sagasAuthentication() {
    yield takeEvery(requestLogin, login);
    yield takeEvery(requestLogout, logout);
}
