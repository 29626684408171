import { createSlice } from '@reduxjs/toolkit';

const sliceInternalization = createSlice({
    name: 'internalization',
    initialState: {},
    reducers: {
        insertInternalization: (state, { payload }) => {
            const { key, value } = payload;
            state[key] = value;
        },
    },
});

export default sliceInternalization;

// ACTIONS
export const { insertInternalization } = sliceInternalization.actions;
