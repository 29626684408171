import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { CheckBox, FolderOpen, MenuBook, PhoneSharp } from '@material-ui/icons';
import { PencilRuler } from 'mdi-material-ui';
import PropTypes from 'prop-types';
import React from 'react';
import SimpleCard from '../../../components/SimpleCard';
import { CUSTOMER } from '../../../constants/options';
import { isNotDef } from '../../../utils/common';
import CardBadge from './CardBadge';

const getSize = ({ items, include, exclude }) => {
    return items.data?.filter(
        ({ projectFunction }) => (isNotDef(include) || include.includes(projectFunction)) && (isNotDef(exclude) || !exclude.includes(projectFunction))
    ).length;
};

export const customer = [CUSTOMER];

const SiteDashboardInfo = ({ contacts, journal, files, checklists, schedules }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <SimpleCard height={matches ? 304 : 0}>
            <Box>
                <Grid container spacing={3} alignItems="flex-end" justify="flex-end">
                    <Grid item xs={5}>
                        <CardBadge title="contacts" icon={PhoneSharp} quantity={getSize({ items: contacts })} to="contacts" />
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={5}>
                        {/* <CardBadge
                            title="subcontractors"
                            icon={PhoneSharp}
                            quantity={getSize({ items: contacts, include: customer })}
                            to="contacts"
                        /> */}
                    </Grid>
                    <Grid item xs={5}>
                        <CardBadge title="files" icon={FolderOpen} quantity={files} to="files" />
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={5}>
                        <CardBadge title="journal" icon={MenuBook} quantity={journal.totalElements} to="journal" />
                    </Grid>
                    <Grid item xs={5}>
                        <CardBadge title="all_checklists" icon={CheckBox} quantity={checklists.totalElements} to="checklists" />
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={5}>
                        <CardBadge title="schedules" icon={PencilRuler} quantity={schedules} to="schedules" />
                    </Grid>
                </Grid>
            </Box>
        </SimpleCard>
    );
};

SiteDashboardInfo.propTypes = {
    checklists: PropTypes.object,
    contacts: PropTypes.object,
    files: PropTypes.number,
    journal: PropTypes.object,
    schedules: PropTypes.number,
};
SiteDashboardInfo.defaultProps = {
    contacts: {},
    journal: {},
    checklists: undefined,
    schedules: undefined,
    files: undefined,
};

export default SiteDashboardInfo;
