const it = {
    // General
    id: 'Id',
    name: `Nome`,
    identity: `Identità`,
    user_profile: `Profilo utente`,
    username: `Nome utente`,

    password: `Password`,
    confirm_password: `Conferma password`,
    new_password: `Nuova password`,
    old_password: `Vecchia password`,
    change_password: `Cambio password`,
    login: `Login`,
    logout: `Esci`,
    sign_in: `Login`,
    remember_me: `Ricordami`,
    forgotten_password: `Password dimenticata`,
    project_manager: `Manager di progetto`,
    admin: `Admin`,
    action: `Azione`,
    rows: `Righe`,
    male: `Maschio`,
    neutral: `Neutro`,
    female: `Femmina`,
    loading: `Caricamento in corso....`,
    go_back: `Torna indietro`,
    remove: `Rimuovi`,
    close: `Chiudi`,
    add: `Inserisci`,
    enter: `Entrata`,
    exit: `Uscita`,
    // Menu
    construction_sites: `Cantieri`,
    construction_sites_list: 'Elenco dei cantieri',
    construction_site: `Cantiere`,
    dashboard: `Dashboard`,
    journal: `Giornale`,
    calendar: `Calendario`,
    file: `File`,
    files: `Files`,
    contacts: `Contatti`,
    hr: `Risorse umane`,
    HR: `Risorse umane`,
    profile: `Profilo`,
    edit: `Modifica`,
    view: 'Visualizza',
    new: `Nuovo`,
    leaves: `Assenze`,
    daily_hours: `Ore manodopera`,
    expenses: `Spese`,
    checklist_requests: 'Richieste di liste di controllo',
    // Action
    'menu.collapse': `Chiudi menu`,
    'menu.employees': `Impiegati`,
    'menu.employees.beta': `Impiegati (beta)`,
    grid_display: `Visualizzazione a griglia`,
    list_display: `Visualizzazione a elenco`,
    new_site: `Crea nuovo sito`,
    new_user: `Crea nuovo utente`,
    add_favorite: `Aggiungi ai preferiti`,
    remove_favorite: `Rimuovi dai preferiti`,
    notifications_settings: `Notifiche e impostazioni`,
    mandatory_notification: 'Compilazione obbligatoria con notifiche',
    // edit: `modificare`,
    ///  Sites
    quickcomId: `Id Quick`,
    title: `Titolo`,
    address: `Indirizzo`,
    city: `Città`,
    zip: `CAP`,
    country: `Nazione`,
    createdAt: `Creato`,
    favorite: `Preferito`,
    // Tab
    list: `Lista`,
    lists: `Liste`,
    quick: `Dati Quick`,
    // journal: `rivista`,
    // calendar: `Calendario`,
    // files: `Documenti`,
    // contacts: `Contatti`,
    all_leave_requests: `Richieste assenze`,
    all_expense_requests: `Richieste nota spese`,
    all_festivities: `Giorni festivi`,
    add_festivity: `Aggiungi giorno festivi`,
    new_festivity: `Nuovo festivo`,
    edit_festivity: `Modifica festivo`,
    festivity_saved_successfully: `Festività salvata con successo`,
    festivity_deleted_successfully: `Festività cancellata con successo`,
    all_events: `Eventi`,
    add_event: `Aggiungi evento`,
    new_event: `Nuovo evento`,
    edit_event: `Modifica evento`,
    event_created_successfully: `Evento creato con successo`,
    event_saved_successfully: `Evento salvato con successo`,
    event_deleted_successfully: `Evento cancellato con successo`,
    all_working_hours: `Impostazioni ore di lavoro`,
    // admin: `Admin`,
    tool_manager: `Magazzino`,
    all_schedules: `Programmi lavoro`,
    all_checklists: `Liste di controllo`,
    permission: `Autorizzazioni`,
    authorization: `Autorizzazioni`,
    file_log: `Registro attività`,
    notification: `Notifica`,
    ///  Users list
    avatar: `Avatar`,
    companyName: `Azienda`,
    lastName: `Cognome`,
    firstName: `Nome`,
    phoneNumberList: `Numero di telefono`,
    email: `E-mail`,
    role: `Ruolo`,
    // Leaves list
    user: `Utente`,
    users: `Utenti`,
    type: `Tipo`,
    requestDate: `Data richiesta`,
    startAt: `Inizio`,
    startDate: `Inizio`,
    endDate: `Fine`,
    endAt: `Fine`,
    duration: `Durata`,
    status: `Stato`,
    days: `Giorni`,
    day: `Giorno`,
    hours: `Ore`,
    and: `e`,
    minutes: `Minuti`,
    hour: `Ora`,
    h: 'h',
    approve: `Approva`,
    approved: `Approvato`,
    reject: `Respingi`,
    rejected: `Respinto`,
    pending: `In attesa`,

    vacation: `Vacanza`,
    holiday: `Giorno festivo`,
    military: `Militare`,
    sickness: `Malattia`,
    maternity: `Maternità`,
    medical: `Medico`,
    professionalAccident: `Infortunio professionale`,
    nonProfessionalAccident: `Infortunio non professionale`,
    training: `Formazione`,
    weather: `Tempo metereologico`,
    others: `Altri`,

    // Expenses
    new_expense: `Nuova spesa`,
    edit_expense: `Modifica spesa`,
    amount: `Importo`,
    date: `Data`,
    reason: `Motivo`,
    place: `Luogo`,
    // Working hours
    winter: `Inverno`,
    summer: `Estate`,
    // Profile
    edit_user: `Modifica utente`,
    // profile: `Profilo`,
    identify: `Identità`,
    personal_calendar: `Calendario`,
    week_summary_stamping_log: `Riassunto settimanale timbrature`,
    dateRange: `Intervallo date`,
    // expenses: `Spese`,
    leaves_and_holidays: `Assenze e vacanze`,
    upload_file: `Scegli il file`,
    file_name: `Nome del file`,
    add_file: `Aggiungi file`,
    rename_file: `Rinmina file`,
    attach_new_file: 'Allega nuovo file',
    attach_file: 'Allega file',
    load_file: 'Carica file',
    show_file: 'Show file',
    file_renamed_successfully: 'File rinominato con successo',
    file_deleted_successfully: 'File cancellato con successo',

    // Profile Edit
    discard_changes: `Annulla`,
    deactivate_user: `Disattiva utente`,
    activate_user: `Attiva utente`,

    personal_contacts: `Contatti personali`,
    personal_address: `Indirizzo personale`,
    personal_e_mail: `E-mail personale`,
    personal_phone_number: `Numero di telefono personale`,
    birth_date: `Data di nascita`,
    work_contacts: `Contatti di lavoro`,
    work_email: `Email aziendale`,
    work_phone_number: `Numero di telefono aziendale`,
    change_picture: `Cambia immagine`,

    appearance: `Apparenza`,
    others_settings: `Altre impostazioni`,
    notification_management: `Gestione Notifiche`,
    stamping_hours: `Ore timbrature`,
    notifications: `Notifiche`,
    'switches.dark_mode': `Passa alla modalità scura`,
    'switches.light_mode': `Passa alla modalità chiara`,

    // others: `Altri`,
    // User Profile HR
    hr_data: `Dati risorse umane`,
    badge_id: `ID badge`,
    civil_status: `Stato civile`,
    children_number: `Numero bambini`,
    mother_language: `Madre lingua`,
    company: `Azienda`,
    associated_company: `Società collegata`,
    hired_at: `Entrata `,
    terminated_at: `Uscita`,
    //
    basic_data: `Anagrafica`,
    birthday: `Compleanno`,
    gender: `Genere`,
    account: `Account`,
    personal_email: `Email personale`,
    office_email: `Email aziendale`,
    personal_mobile_number: `Cellulare privato`,
    personal_home_number: `Telefono casa`,
    work_mobile_number: `Cellulare aziendale`,
    work_office_number: `Numero aziendale`,
    work_c_3_x_number: `Numero aziendale 3CX`,
    //
    working_hours: `Ore lavorative`,
    work_percentage: `Percentuale di lavoro`,
    holidays_count_days: 'Vacanze',
    break_time_h: `Pausa`,
    // day: `Giorno`,
    winter_start: `Inizio inverno`,
    winter_end: `Fine inverno`,
    summer_start: `Inizio estate`,
    summer_end: `Fine estate`,
    //
    legal_documents: `Documenti legali`,
    edit_legal_document: `Modifica documento legale`,
    new_legal_document: `Nuovo documento legale`,
    expireat: `Data di scadenza`,
    numberDoc: `Descrizione`,
    //
    signed_documents: `Documenti firmati`,
    document_not_present: `Non ci sono documenti`,
    press_plus_to_add_a_document: `Premere + per aggiungere un documento`,
    at_least_one_document: `Deve essere presente almeno un documento *`,
    edit_signed_document: `Modifica documento firmato`,
    new_signed_document: `Nuovo documento firmato`,

    the_search_did_not_return_any_results: `La ricerca non ha prodotto risultati`,

    // User Leaves HR
    // leaves: `Assenze`,
    new_leave: `Nuova assenza`,
    new_daily_hours: `Aggiungi ore`,
    edit_leave: `Modifica congedo`,
    leaves_request: `Richiesta di assenza`,
    my_leaves_request: `La mia richiesta di assenza`,
    request_reimbursement_expenses: `Richiesta rimborso spese`,
    export_my_expense: `Esporta la mia nota spesa`,
    holidays_allowance: `Indennità di ferie`,
    total_pending: `Richieste sospese`,
    remaining: `Residui`,
    taken: `Prese`,
    pending_requests: `Richieste in sospeso`,
    requests: `Richieste`,
    // sickness: `Malattia`,
    // military: `Militare`,
    paternity: `Paternità`,
    medical_examination: `Visita medica`,
    professional_accident: `Infortunio professionale`,
    non_professional_accident: `Infortunio non professionale`,
    training_course: `Corso di formazione`,
    weather_conditions: `Condizioni meteo`,
    other: `Altro`,

    from: `A partire dal`,
    to: `Fino a`,
    // type: `genere`,
    description: `Descrizione`,
    // duration: `Durata`,
    // status: `Stato`,
    // User Expenses HR

    expenses_request: `Richieste nota spese`,
    my_expenses_request: `Le mie nota spese`,
    submit_new_leave: `Inserisci assenza`,
    submit_new_daily_hours: `Inserisci ore manodopera`,
    submit_new_expense: `Inserisci spesa`,

    currency: `Valuta`,
    changedChf: `Cambiato`,
    quantity: `Quantità`,
    isEquipment: `Attrezzatura`,
    whoApproveId: `Approvata da`,
    chf: `CHF`,
    usd: `USD`,
    // legalMaritalStatus
    married: `Sposato`,
    widowed: `Vedovo`,
    separated: `Separato`,
    divorced: `Divorziato`,
    single: `Single`,
    // Languages
    german: `Tedesco`,
    french: `Francese`,
    italian: `Italiano`,
    english: `Inglese`,
    // Companies
    tigestim: `Tigestim`,
    metalsky: `Metalsky`,
    sitaf: `Sitaf`,
    metalpiega_color: `Metal Piega & Color`,
    tmwood: `Tm Wood`,
    edemar: `Edemar`,
    electrogestim: `Electrogestim`,
    // Messages
    user_saved_successfully: `Utente salvato con successo`,
    uploaded_successfully: `Caricamento riuscito`,
    upload_failed: `Caricamento fallito`,
    user_already_exists: `L'utente esiste già`,
    passwords_do_not_match: `Le passwords non corrispondono`,
    password_saved_successfully: `Password salvata con successo`,
    wrong_password: `Password sbagliata`,
    leave_saved_successfully: `Assenza salvata con successo`,
    daily_hours_saved_successfully: `Ore aggiunte con successo`,
    leave_delete_successfully: `Assenza eliminata con successo`,
    expense_saved_successfully: `Spesa salvata con successo`,

    // Calendar
    'date.from': `Da:`,
    'date.to': `a:`,
    'filters.all': `Mostra tutto`,
    'filters.festivity': `Festività`,
    'filters.events': `Eventi`,
    'filters.personRequests': `Assenza`,
    'filters.stampingLogs': `Timbrature`,
    'eventTypes.festivity': `Festività`,
    'eventTypes.events': `Evento`,
    'eventTypes.reports': `Rapporto`,
    'eventTypes.personRequests': `Assenza`,
    'eventTypes.stampingLogs': `Timbrature`,

    // Validation
    validationMessagePassword: `Deve contenere almeno: 8 caratteri alfanumerici, 1 numero, 1 carattere minuscolo (a-z), 1 carattere maiuscolo (A-Z). La password può contenere solo questi caratteri speciali! @ # $% ^ &`,
    validationMessageEmail: `Email non valida`,

    // Journals
    load_more_journal_posts: `Caricamento...`,
    edit_post: `Modifica Articolo`,
    view_post: `Visualizza Articolo`,
    new_journal: `Nuovo articolo`,
    create_journal: `Crea articolo`,
    save_journal: `Salva`,
    delete_journal: `Elimina`,
    see_all_comments: `Vedi tutti i commenti`,
    see_all: `Vedi tutti`,
    like: `Like`,
    add_comment: `Aggiungi un commento`,
    share: `Condividere`,
    add_new_journal_entry: `Aggiungi nuovo articolo`,
    drag_and_drop_an_image_here_or_click: `Trascina un'immagine qui o fai clic qui`,
    edit_journal_entry: `Modifica articolo`,
    message: `Messaggio`,
    thumbnail: `Miniatura`,
    upload_picture: `Carica l'immagine`,
    iva: `IVA`,
    es_3_15: `Es. 3,15%`,
    journal_saved_successfully: `Giornale salvate correttamente`,
    journal_deleted_successfully: `Giornale eliminato correttamente`,

    // Contact
    edit_contacts: `Modifica contatti`,
    edit_contact: `Modifica contatto`,
    saved_contact_successfully: `Contatto inserito con successo`,
    removed_contact_successfully: `Contatto rimosso con successo`,
    telephoneNumber: `Numero di telefono`,

    // Right
    all_rights_reserved: `Tutti i diritti riservati`,
    support: `Supporto`,
    contact: `Contatto`,
    version: `Versione`,

    // Tools
    category: `Categoria`,
    tool_name: `Nome strumento`,
    value: `Valore`,
    assignment: `Assegnazione`,
    // status: `Status`,
    period: `Periodo`,
    add_new_tool: `Nuovo strumento`,
    edit_tool: `Modifica strumento`,
    delete_tool: `Elimina strumento`,
    // quantity: `Quantity`,
    create_new_category: `Aggiungi categoria`,
    unit_cost: `Costo unitario`,
    warranty: `Garanzia `,
    documentation: `Documentazione`,
    manual: `Manuale`,
    purchase_receipt: `Scontrino`,
    attach_new_file_or_picture: `Allega documento`,
    attach_new_picture: `Allega foto`,
    delete_attachments: `Elimina allegati`,
    save_changes: `Salva`,
    total_quantity: `Quantità totale`,
    used: `Usato`,
    available: `Disponibile`,
    person: `Persona`,

    tools: `Strumenti`, // 1
    vehicle: `Veicoli`, // 2
    health: `Salute`, // 3
    safety_equipment: `Equipaggiamento di sicurezza`, // 4
    clothing: `Vestiti`, // 5

    tool_saved_successfully: `Strumento salvato con successo`,
    tool_deleted_successfully: `Strumento eliminato con successo`,
    category_name: `Nome categoria`,
    categories: 'Categorie',
    add_tools_category: 'Nuova categoria',
    add_new_category: 'Nuova categoria',
    edit_category: 'Modifica categoria',
    delete_category: 'Elimina categoria',
    category_deleted_successfully: `Categoria eliminato con successo`,
    category_saved_successfully: `Categoria salvata con successo`,
    category_not_deleted: `Categoria non eliminata, è ancora associata ad alcuni strumenti`,
    tool_not_deleted: `Tool non eliminato, è ancora associata ad una persona`,

    // Permissions
    edit_permission: 'Modifica permessi',
    capabilities: `Capacità`,
    list_all_employee: 'Mostra tutti gli impiegati',
    show_edemar: 'Mostra cantieri Edemar',
    show_metalsky: 'Mostra cantieri Metalsky',
    show_sitaf: 'Mostra catieri Sitaf',
    show_metalpiega: 'Mostra cantieri MetalPiega&Color',
    show_tigestim: 'Mostra cantieri Tigestim',
    show_tmwood: 'Mostra cantieri TM Wood',
    show_electrogestim: 'Mostra cantieri Electrogestim',
    no_stampinglog: 'Non timbra',
    no_leave: 'Non richiede assenze',
    show_hr_module: 'Mostra modulo HR',
    stampings_log_management: 'Gestione timbrature',
    edit_inventory: 'Modifica inventario',
    list_file: 'Mostra file',
    list_in_hr_module: 'Elenca in Modulo HR',

    employee: 'Impiegato',
    client: 'Cliente',
    associate: 'Associato',
    customer: 'Cliente',
    documents: 'Documenti',

    create: `Crea`,
    save: 'Salva',
    delete: 'Elimina',
    rename: 'Rinomina',

    clear_assignment: 'Rimuovi assegnazione',
    support_request: 'Richiesta di supporto',
    object: 'Oggetto',
    // message: 'Messaggio',
    send: 'Invia',
    cancel: 'Annulla',
    feedback_sended_successfully: 'Feedback inviato con successo',

    session_ended: 'Sessione scaduta',
    bring_me_to_login: 'Portami al login',
    images: 'Immagini',
    images_not_present: `Non ci sono immagini`,
    confirm_delete: `Conferma elimina`,
    are_you_sure_you_want_to_delete_this_item: `Sei sicuro di voler eliminare questo elemento?`,
    edit_not_allow: 'Modifica non permessa',
    edit_allow_only_on_pending: 'Modifica permessa solo se in attesa di approvazione',
    attach_receipt: 'Allega ricevuta *',

    // Checklists
    checklists: 'Liste di controllo',
    checklist: 'Checklist',
    edit_checklist: 'Modifica checklist',
    view_checklist: 'Visualizza checklist',
    add_checklist: 'Aggiungi checklist',
    new_checklist: 'Nuova checklist',
    add_checklist_template: 'Aggiungi template',
    templates: 'Templates',
    template: 'Template',
    checklist_saved_successfully: 'Checklist salvata con successo',
    checklist_deleted_successfully: 'Checklist eliminata con successo',
    checklist_not_deleted: 'Checklist non eliminata',
    checklist_not_saved: 'Checklist non salvata',
    assign_to_site: 'Assegna cantiere',
    add_new_question: 'Aggiungi domanda *',
    questions: 'Domande',
    question: 'Domanda',
    add_checkbox: 'Aggiungi checkbox',
    add_btn_attach: 'Aggiungi bottone upload',
    remove_question: 'Rimuovi question',
    add_image: 'Aggiungi immagine',
    new_from_template: 'Nuovo da template',
    assigned_construction_sites: 'Cantieri assegnati',
    assigned_construction_site: 'Cantiere assegnato',
    times: 'Volte',
    not_assigned_yet: 'Non ancora assegnato',
    save_as_template: 'Salva come modello',
    edit_checklist_template: 'Modifica template',
    new_checklist_template: 'Nuovo template',
    checklist_template_not_deleted: 'Template checklist non cancellato',
    checklist_template_not_saved: 'Template checklist non salvato',
    checklist_template_saved_successfully: 'Template checklist salvato con successo',
    checklist_template_deleted_successfully: 'Template checklist cancellato con successo',
    observation: 'Osservazione',
    who: 'Chi',
    expiredAt: 'Scade',
    readyAt: 'Pronta a',

    // Schedules
    new_schedule: 'Nuovo programma',
    workers: 'Lavoratori',
    report: 'Rapporto',
    notes: 'Appunti',
    activities: 'Attivita',
    schedules: 'Programmi di lavoro',
    schedule: 'Programma di lavoro',
    edit_schedule: 'Modifica pianificazione',
    add_schedule: 'Aggiungi pianificazione',
    add_schedule_template: 'Aggiungi modello di programmazione.',
    // categories: 'Categorie',
    schedule_saved_successfully: 'Pianificazione salvata con successo',
    schedule_deleted_successfully: 'Pianificazione cancellata con successo',
    schedule_not_deleted: 'Pianificazione non cancellata',
    schedule_not_saved: 'Pianificazione non salvata',
    task: 'Compito',
    tasks: 'Compiti',
    add_task: 'Aggiungi attività',
    remove_task: 'Rimuovi attività',
    edit_schedule_category: 'Modifica categoria',
    new_schedule_category: 'Nuova categoria',
    schedule_category_not_deleted: 'Categoria non cancellata',
    schedule_category_not_saved: 'Categoria non salvata',
    schedule_category_saved_successfully: 'Categoria salvata con successo',
    schedule_category_deleted_successfully: 'Categoria cancellata con successo',
    subcontractors: `Subappaltatori`,
    planimeters: `Planimetrie`,

    // Quick data
    subject: 'Soggetto',
    balance: 'SALDO scaduto',
    totalCost: 'Totale costi',
    total: 'Totale',
    offer: 'OFFERTA',
    totalCostDifferences: 'Utile ( + )/Perdita ( - )',
    laborCost: 'Costo manodopera',
    cashedAdvances: 'Acconti incassati',
    requiredAdvances: 'Acconti richiesti',
    supplierCost: 'Costo fornitori',
    supplierDebit: 'Debito fornitori',
    goodsCost: 'Costo materiali',
    othersCost: 'Costo diversi',

    // Roles
    ADMIN: 'Admin',
    // HR: 'HR',
    EMPLOYEE: 'Impiegato',
    CLIENT: 'Cliente',
    ASSOCIATE: 'Associato',
    INTERIM: 'Iterinali',
    interim: 'Iterinali',

    // Files
    download: 'Download',
    folders_not_present: 'Cartelle non presenti',
    folders: 'Cartelle',
    last_modified: 'Ultima modifica',
    size: 'Dimensione',

    // Countries
    switzerland: 'Svizzera',
    italy: 'Italia',

    search: 'Cerca...',

    all_users: 'Aggiungi gli utenti',
    apply_to: 'Applica',
    user_list: 'Utenti',
    new_working_hours: `Aggiungi orario di lavoro`,
    save_working_hours: `Salva orario di lavoro`,
    create_working_hours: `Crea orario di lavoro`,
    edit_working_hours: 'Modifica orario di lavoro',
    working_hours_not_saved: 'Orario di lavoro non salvato',
    working_hours_saved_successfully: `Orario di lavoro salvato con successo`,

    no_options: 'Nessuna opzione disponibile',
    contacts_not_present: 'Contatti non presenti',
    journal_not_present: 'Giornale non presente',

    // day: 'Day',
    today: 'Oggi',
    month: 'Mese',
    week: 'Settimana',
    all_day: 'Tutto il giorno',
    stampingLog: `Timbratura`,

    total_holidays_accrued: `Totale vacanze maturate`,
    total_holidays_enjoyed: `Totale vacanze godute`,
    holiday_balance: `Saldo vacanze`,

    personal_mobile: `Cellulare privato`,
    work_mobile: `Cellulare lavoro`,
    show_receipt: `Mostra scontrino`,
    show_document: `Mostra Documento`,

    project_function: `Funzione`,
    worker: `Operaio`,
    technician: `Tecnico`,
    foreman: `Caposquadra`,

    favorites: `Preferiti`,
    all: `Tutti`,
    alle: `Tutte`,
    show_image: `Mostra immagine`,
    edit_site: `Modifica cantiere`,
    attach_site_image: `Allega immagine cantiere`,
    site_saved_successfully: `Cantiere salvato con successso`,

    noFilesAvailable: `Non sono presenti files per questo cantiere`,
    folder_created_successfully: `Cartella creata con successso`,
    folder_deleted_successfully: `Cartella cancellata con successso`,
    folder_renamed_successfully: `Cartella rinominata con successso`,
    folder_name: `Nome cartella`,
    new_folder: `Nuova cartella`,
    shared_folder: `Cartella condivisa`,

    show_more_comments: `Mostra altri commenti...`,
    element_copied_in_the_clipboard: `Elemento copiato nella clipboard`,

    meters: 'Metri',
    pieces: 'Pezzi',
    kg: 'Kg',
    liters: 'Litri',
    unit: 'Unità',

    page: 'Pagina',
    january: 'Gennaio',
    february: 'Febbraio',
    march: 'Marzo',
    april: 'Aprile',
    may: 'Maggio',
    june: 'Giugno',
    july: 'Luglio',
    august: 'Agosto',
    september: 'Settembre',
    october: 'Ottobre',
    november: 'Novembre',
    december: 'Dicembre',

    export_stamping_logs: 'Esporta timbrature',
    export_expense_month: 'Esporta spese',
    new_stamping_log: 'Nuova timbratura',
    edit_stamping_log: 'Modifica timbratura',
    timestamp: 'Orario',
    stamping_log_created_successfully: 'Timbratura creata succesivamente',
    stamping_log_saved_successfully: 'Timbratura salvata succesivamente',

    share_copy: 'Copia link nella clipboard',
    share_email: 'Invia link per email',
    share_download_pdf: 'Scarica pdf',

    settings: 'Impostazioni',

    non_reversible_operation: 'Operazione non reversibile',

    quicksalId: 'Quicksal Id',

    functionCompany: 'Funzione',
    job: 'Mansione',
    department: 'Reparto',
    workPercentage: 'Percentuale di lavoro',

    total_month: 'Totale mensile',

    signUrl: 'Firma:',
    view_pdf: 'Visualizza PDF',
    journal_new_user: 'Nuovo utente',
    journal_auto_description: `L'utente <USER> è appena stato aggiunto al cantiere 🥳`
};

export default it;
