import { Box, Divider, Grid } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import EnhancedAutocomplete from '../../components/utilities/EnhancedAutocomplete';
import EnhancedIcon from '../../components/utilities/EnhancedIcon';
import EnhancedTextFiled from '../../components/utilities/EnhancedTextFiled';
import { getAllPersons } from '../../store/proxies/tigestimProxies';

function ScheduleTask({ taskId, onChange, index, title, workers, activities, readOnly }) {
    return (
        <Box pt={1} pb={3}>
            <Box display="flex" pb={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box display="flex">
                            <Box width={24} display="flex" alignItems="center">
                                {index}
                            </Box>
                            <EnhancedTextFiled
                                label="title"
                                value={title}
                                readOnly={readOnly}
                                variant="outlined"
                                onChange={title => {
                                    onChange(prev => ({
                                        ...prev,
                                        tasks: (prev.tasks || []).map(task => {
                                            if (task.id === taskId) return { ...task, title };
                                            return task;
                                        }),
                                    }));
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box pl={3}>
                            <EnhancedAutocomplete
                                value={workers}
                                label="workers"
                                readOnly={readOnly}
                                onChange={workers => {
                                    onChange(prev => ({
                                        ...prev,
                                        tasks: (prev.tasks || []).map(task => {
                                            if (task.id === taskId) return { ...task, workers };
                                            return task;
                                        }),
                                    }));
                                }}
                                multiple
                                getOptionValue={({ id }) => id}
                                getItemValue={({ id }) => id}
                                getOptionReturnValue={({ id }) => ({ id })}
                                getOptionLabel={({ firstName, lastName }) => `${lastName} ${firstName}`}
                                optionsFetch={{
                                    proxy: getAllPersons,
                                    defaultValue: null,
                                    params: {
                                        filter: 'forName',
                                    },
                                    handleResponse: data => data.map(({ id, firstName, lastName }) => ({ id, firstName, lastName })),
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} pl={2}>
                        <Box pl={3}>
                            <EnhancedTextFiled
                                label="activities"
                                value={activities}
                                variant="outlined"
                                multiline
                                rows={readOnly ? undefined : 4}
                                readOnly={readOnly}
                                onChange={activities => {
                                    onChange(prev => ({
                                        ...prev,
                                        tasks: (prev.tasks || []).map(task => {
                                            if (task.id === taskId) return { ...task, activities };
                                            return task;
                                        }),
                                    }));
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>

                {!readOnly && (
                    <Box display="flex" alignItems="center" pl={1}>
                        <EnhancedIcon
                            icon={Close}
                            onClick={() => {
                                onChange(prev => ({ ...prev, tasks: prev.tasks.filter(task => task.id !== taskId) }));
                            }}
                        />
                    </Box>
                )}
            </Box>

            <Box pt={1}>
                <Divider />
            </Box>
        </Box>
    );
}

ScheduleTask.propTypes = {
    activities: PropTypes.string,
    index: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.any,
    workers: PropTypes.array,
};
ScheduleTask.defaultProps = {
    workers: [],
    activities: undefined,
    title: undefined,
    readOnly: undefined,
};

export default ScheduleTask;
