import { Grid } from '@material-ui/core';
import { Event } from '@material-ui/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import Actions from '../../components/Actions';
import DisplayListDivider from '../../components/DisplayListDivider';
import FlexColumn from '../../components/flex/FlexColumn';
import history from '../../components/routings/history';
import TableViewCard from '../../components/view/TableViewCard';
import { leavesRequestsColumns } from '../../configuration/columns';
import useFetch from '../../hooks/useFetch';
import { dailyHoursService, leaveRequestReview, personLeaveRequests } from '../../store/proxies/tigestimProxies';
import { selectItem } from '../../store/slices/sliceNavigation';
import { convertSecondsHours } from '../../utils/common';
import { formatDate } from '../../utils/formats';
import UserAvatar from '../UserAvatar';

const handleResponse = response => {
    console.log('handleResponseLeave', response);
};

const UserDailyHoursView = ({ id, actions }) => {
    const user = useSelector(selectItem);
    const [dailyHours, isLoading] = useFetch(dailyHoursService.get, { id, year: moment().year() }, [id], [id], {}, handleResponse);

    return (
        <FlexColumn flex={1} width={1}>
            <Actions actions={actions} />
            <Grid container spacing={2}>
                {/* <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <UserAvatar data={user} />
                        </Grid>
                        <Grid item xs={12}>
                            <DisplayListDivider title="leaves" items={leavesItems} data={leavesReview} isLoading={isLoading} />
                        </Grid>
                    </Grid>
                </Grid> */}

                {/* <Grid item xs={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TableViewCard
                                title="leaves_request"
                                icon={Event}
                                proxy={personLeaveRequests}
                                params={{
                                    userId: user?.id,
                                    year: moment().year(),
                                }}
                                columns={leavesRequestsColumns}
                                messageNoItems="document_not_present"
                                onItemClick={({ id }) => {
                                    history.push(`leaves/${id}/edit`);
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid> */}
            </Grid>
        </FlexColumn>
    );
};

UserDailyHoursView.propTypes = {
    id: PropTypes.number,
    actions: PropTypes.array,
};
UserDailyHoursView.defaultProps = {
    id: undefined,
    actions: [],
};

export default UserDailyHoursView;
