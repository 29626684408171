import { Box, Fab, makeStyles, Slide, Dialog, Toolbar, IconButton, AppBar } from '@material-ui/core';
import { Fullscreen } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import defaultImage from '../resources/default.png';
import { openDialog } from '../store/slices/sliceDialog';
import { buildUrl } from '../utils/common';
import FullscreenImageDialog from './dialog/FullscreenImageDialog';


const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const fabStyle = {
    position: 'absolute',
    bottom: 46,
    left: 0,
    right: 16,
    zIndex: 1,
    marginLeft: 'auto',
    color: '#eee',
    background: 'rgba(0, 0, 0, 0.5)',
};

const EnhancedCarousel = ({ items, height, getItemUrl, fit }) => {
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(false);
    const classes = useStyles();

    const handleClickOpen = () => {
        setShowDialog(true);
    };

    const handleClickClose = () => {
        setShowDialog(false);
    };

    return items.length ? (
        <React.Fragment>
            <Dialog fullScreen open={showDialog} onClose={handleClickClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClickClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Carousel autoPlay={false} animation="fade" indicators={false} timeout={500} cycleNavigation navButtonsAlwaysVisible>
                    {items.map(item => {
                        const url = buildUrl(getItemUrl(item));
                        return (
                            // <Box width={1} key={item.fentryId}>
                            //     <Box alignContent='center' justifyContent='center' flex >
                            //         <img src={url} alt={url}  height={height} />
                            //     </Box>
                            // </Box>
                            <Box display='flex' justifyContent='center' alignItems='center'  minHeight='100vh'>
                                <img src={url} alt={url} style={{"objectFit":'cover', "max-width":'100%', "max-height": 'calc( 100vh - 130px )', "border":'solid 1px #CCC'}}  />
                            </Box>
                        );
                    })}
                </Carousel>
            </Dialog>
            {!showDialog && (
                <Carousel
                    autoPlay={false}
                    animation="fade"
                    indicators
                    timeout={500}
                    cycleNavigation
                    navButtonsAlwaysVisible={false}
                    navButtonsAlwaysInvisible={false}
                >
                    {items.map(item => {
                        const url = buildUrl(getItemUrl(item));
                        return (
                            <Box width={1} height={height} key={item.fentryId} style={{ overflow: fit.indexOf('cover') > -1 ? 'hidden' : 'visible' }}>
                                <img src={url} alt={url} width="100%" height={height} style={{ objectFit: fit }} />
                                <Fab style={fabStyle} size="small" onClick={handleClickOpen}>
                                    <Fullscreen />
                                </Fab>
                            </Box>
                        );
                    })}
                </Carousel>
            )}
        </React.Fragment>
    ) : (
        <Box width={1} height={height}>
            <img src={defaultImage} alt="placeholder" style={{ width: '100%', height, objectFit: 'cover' }} />
            <Fab
                style={fabStyle}
                size="small"
                onClick={() => {
                    dispatch(openDialog({ fullScreen: true, cancelLabel: 'close', data: defaultImage, component: FullscreenImageDialog }));
                }}
            >
                <Fullscreen />
            </Fab>
        </Box>
    );
};

EnhancedCarousel.propTypes = {
    items: PropTypes.array,
    getItemUrl: PropTypes.func.isRequired,
    height: PropTypes.number.isRequired,
    fit: PropTypes.string,
};
EnhancedCarousel.defaultProps = {
    items: [],
    fit: 'cover',
};

export default EnhancedCarousel;
