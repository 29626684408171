import { Document, Font, Page, PDFViewer, StyleSheet, Image, View, Text } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingFullScreen from '../../../components/utilities/LoadingFullScreen';
import useFetch from '../../../hooks/useFetch';
import gothic from '../../../resources/GOTHIC.TTF';
import gothicBold from '../../../resources/GOTHICB.TTF';
import { getAllPersons } from '../../../store/proxies/tigestimProxies';
import { selectDialogData } from '../../../store/slices/sliceDialog';
import { isEmpty } from '../../../utils/common';
import PageFooter from './PageFooter';
import PageHeader from './PageHeader';
import PageTable from './PageTable';
import { bucketGetObject, DEFAULT_BUCKET } from '../../../utils/bucketUtils';

Font.register({
    family: 'Open Sans',
    fonts: [{ src: gothic }, { src: gothicBold, fontWeight: 700 }],
});

const styles = StyleSheet.create({
    page: {
        padding: 20,
        paddingTop: 25,
        paddingBottom: 50,
        fontFamily: 'Open Sans',
    },
    viewerStyle: {
        width: '100vw',
        height: '100%',
        border: 0,
    },
});

const handleResponse = response => response.reduce((result, { id, firstName, lastName }) => ({ ...result, [id]: `${firstName} ${lastName}` }), {});

const ChecklistDocument = () => {
    const data = useSelector(selectDialogData);

    const [sign, setSign] = useState();

    useEffect(() => {
        const fetch = async (imageId, imageUrl) => {
            // check if there's a folder, if not add public/
            let newImageUrl = imageUrl;
            if (!imageUrl.includes('http') && !imageUrl.includes('/')){
                newImageUrl = `public/${newImageUrl}`;
            }
            const { Body, ContentType } = await bucketGetObject({ Key: newImageUrl, bucketSite: DEFAULT_BUCKET });
            const format = ContentType === 'image/jpeg' || ContentType === 'image/jpg' ? 'jpg' : 'png';
            setSign({ src: { data: Body, format }, imageId });
        };

        if (data.signUrl) {
            fetch(data.signId, data.signUrl);
        }
    }, [data]);

    const [persons] = useFetch(getAllPersons, { filter: 'forName' }, [], [], [], handleResponse);
    if (isEmpty(persons)) return <LoadingFullScreen />;

    return (
        <PDFViewer style={styles.viewerStyle}>
            <Document>
                <Page size="A4" orientation="portrait" style={styles.page} wrap>
                    <PageHeader data={data} />
                    <PageTable data={data} persons={persons} />
                    {sign && (
                        <View
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                paddingTop: 10,
                            }}
                        >
                            <Text style={{ fontSize: 9, paddingRight: 8 }}>Firma:</Text>
                            <Image src={sign.src} key={sign.imageId} style={{ width: '200px', height: '100%', objectFit: 'contain'}} />
                        </View>
                    )}

                    <PageFooter />
                </Page>
            </Document>
        </PDFViewer>
    );
};
ChecklistDocument.propTypes = {};

export default ChecklistDocument;
