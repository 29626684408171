import { Grid } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Actions from '../../../components/Actions';
import EnhancedForm from '../../../components/EnhancedForm';
import FlexColumn from '../../../components/flex/FlexColumn';
import useDataFetch from '../../../hooks/useDataFetch';
import { selectUserId } from '../../../store/slices/sliceAuth';

const SiteJournalEdit = ({ proxy, siteId, journalId, actions, form }) => {
    const [data, onChange, isLoading, setData] = useDataFetch(proxy, journalId, [journalId], [journalId], {});
    const authorId = useSelector(selectUserId);

    useEffect(() => {
        if (siteId && authorId) {
            onChange('siteId', siteId);
            onChange('authorId', authorId);
        }
    }, [authorId, onChange, siteId]);

    return (
        <FlexColumn>
            <Actions actions={actions} data={data} />
            <Grid container spacing={2}>
                <Grid item xs>
                    <EnhancedForm
                        title={journalId ? 'edit_journal_entry' : 'add_new_journal_entry'}
                        icon={Edit}
                        items={form}
                        data={data}
                        onChange={onChange}
                        setData={setData}
                        isLoading={isLoading}
                    />
                </Grid>
            </Grid>
        </FlexColumn>
    );
};

SiteJournalEdit.propTypes = {
    proxy: PropTypes.object,
    siteId: PropTypes.number.isRequired,
    journalId: PropTypes.number,
    actions: PropTypes.array.isRequired,
    form: PropTypes.array.isRequired,
};
SiteJournalEdit.defaultProps = {
    journalId: undefined,
    proxy: undefined,
};

export default SiteJournalEdit;
