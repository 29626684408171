import { TextField, InputAdornment, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import useTranslate from '../../hooks/useTranslate';
import { isDef } from '../../utils/common';

const useStyles = makeStyles(() => ({
    // notchedOutline: {
    //     borderStyle: 'hidden',
    // },
}));

const EnhancedTextFiled = ({
    value: externalValue,
    name,
    id,
    onChange,
    label,
    required,
    variant,
    margin,
    size,
    type,
    disabled,
    unit,
    readOnly,
    multiline,
    rows,
    onKeyPress,
    error,
    helperText,
    autoFocus,
    onBlur,
    autoComplete,
    color,
    ...props
}) => {
    const classes = useStyles();
    const translate = useTranslate();

    const [value, setValue] = useState(externalValue);

    useEffect(() => {
        if (isDef(externalValue)) setValue(externalValue);
        else setValue('');
    }, [externalValue]);

    return (
        <TextField
            id={id}
            required={required}
            label={translate(name) || translate(label)}
            InputProps={{
                readOnly,
                classes,
                endAdornment: unit && <InputAdornment position="end">{translate(unit)}</InputAdornment>,
                type,
                autoComplete,
                // disableUnderline: readOnly,
            }}
            InputLabelProps={{
                shrink: readOnly ? true : undefined,
            }}
            margin={margin}
            error={error}
            helperText={helperText}
            fullWidth
            color={color}
            /// type={type}
            size={size}
            variant={readOnly ? 'standard' : variant}
            disabled={disabled}
            multiline={multiline}
            rows={rows}
            value={value ?? ''}
            onChange={event => {
                const { value: newValue } = event.target;
                setValue(newValue);
                if (onChange) onChange(newValue);
            }}
            onBlur={() => {
                if (onBlur) onBlur(value);
            }}
            onKeyPress={onKeyPress}
            autoFocus={autoFocus}
            {...props}
        />
    );
};

EnhancedTextFiled.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    label: PropTypes.string,
    required: PropTypes.bool,
    id: PropTypes.string,
    size: PropTypes.string,
    margin: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    unit: PropTypes.string,
    readOnly: PropTypes.bool,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    onKeyPress: PropTypes.func,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    name: PropTypes.string,
    autoFocus: PropTypes.bool,
    autoComplete: PropTypes.string,
    color: PropTypes.string,
};
EnhancedTextFiled.defaultProps = {
    id: undefined,
    value: '',
    label: '',
    required: undefined,
    size: 'small',
    margin: 'none',
    type: undefined,
    variant: undefined,
    onChange: undefined,
    onBlur: undefined,
    disabled: undefined,
    unit: undefined,
    readOnly: undefined,
    multiline: undefined,
    rows: undefined,
    onKeyPress: undefined,
    error: undefined,
    helperText: undefined,
    name: undefined,
    autoFocus: undefined,
    autoComplete: 'new-password',
    color: undefined,
};

export default EnhancedTextFiled;
