import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import history from '../components/routings/history';
import reducer from './slices';
import sagas from './sagas';
import interceptors from './axios/interceptors';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer,
    middleware: [sagaMiddleware, routerMiddleware(history)],
    devTools: process.env.NODE_ENV !== 'production',
});

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./slices', () => store.replaceReducer(reducer));
}

sagaMiddleware.run(sagas);

interceptors(store);

export default store;
