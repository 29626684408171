import { Box } from '@material-ui/core';

import { Check, Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { green, red } from '../../constants/colorsConstants';
import {
    APPROVED_LEAVE,
    PENDING_LEAVE,
    APPROVED_EXPENSE,
    REJECTED_EXPENSE,
    PENDING_EXPENSE,
    REJECTED_LEAVE,
    APPROVED_CHECKLIST,
    REJECTED_CHECKLIST,
    PENDING_CHECKLIST,
} from '../../constants/statesConstants';
import useTranslate from '../../hooks/useTranslate';
import Action from '../Action';
import { selectUserId } from '../../store/slices/sliceAuth';

const DecisionCell = ({ item, id, onApprove, onReject }) => {
    const translate = useTranslate();
    const userId = useSelector(selectUserId);
    switch (item[id]) {
        case APPROVED_CHECKLIST:
        case APPROVED_EXPENSE:
        case APPROVED_LEAVE:
            return <Box color={green}>{translate('approved')}</Box>;
        case REJECTED_CHECKLIST:
        case REJECTED_EXPENSE:
        case REJECTED_LEAVE:
            return <Box color={red}>{translate('rejected')}</Box>;
        case PENDING_EXPENSE:
        case PENDING_LEAVE:
        case PENDING_CHECKLIST:
        default:
            return (
                <Box width={1} height={1} display="flex" alignItems="center" justifyContent="space-between">
                    <Action
                        label="approve"
                        icon={Check}
                        color={green}
                        onClick={async (notUsed, setLoading) => {
                            setLoading(true);
                            const response = await onApprove(item, userId);
                            setLoading(false);
                            return response;
                        }}
                        padding="none"
                    />
                    <Action
                        label="reject"
                        icon={Close}
                        color={red}
                        onClick={async (notUsed, setLoading) => {
                            setLoading(true);
                            const response = await onReject(item, userId);
                            setLoading(false);
                            return response;
                        }}
                        padding="none"
                    />
                </Box>
            );
    }
};

DecisionCell.propTypes = {
    item: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    onApprove: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
};
DecisionCell.defaultProps = {};

export default DecisionCell;
