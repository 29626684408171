import { createMuiTheme } from '@material-ui/core/styles';
import { primary, primaryLight } from './palette';

const lightTheme = createMuiTheme({
    spacing: 8,
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        primary: {
            lightest: primaryLight,
            main: primary,
            contrastText: '#fff',
            gray: 'rgba(0, 0, 0, 0.04)',
        },
        background: {
            default: primaryLight,
        },
        secondary: {
            main: '#fff',
            contrastText: '#000',
        },
        menu: {
            background: '#fff',
            icon: 'rgba(0, 0, 0, 0.33)',
            selected: 'rgba(0, 0, 0, 0.04)',
        },
    },
    overrides: {
        MuiTabs: {
            root: {
                minHeight: '32px',
            },
        },
        MuiTab: {
            root: {
                minHeight: '32px',
            },
        },
        MuiTableCell: {
            head: {
                textTransform: 'uppercase',
                lineHeight: 1,
            },
        },
        MuiToolbar: {
            root: {
                // paddingLeft: '24px',
            },
            dense: {
                // minHeight: appBarHeight,
            },
        },
        MuiDrawer: {
            root: {
                backgroundColor: 'white',
            },
        },
        MuiDrawerDocker: {
            background: {
                default: 'white',
            },
        },
        RaMenuItemLink: {
            root: {
                borderLeft: '3px solid #fff',
            },
            active: {},
        },
        MuiPaper: {
            elevation1: {
                boxShadow: 'none',
            },
            // root: {
            //     border: '1px solid #e0e0e3',
            //     backgroundClip: 'padding-box',
            // },
        },
        MuiButton: {
            textPrimary: { color: '#000' },
        },
        // MuiTouchRipple: {
        //     root: {
        //         color: primary,
        //     },
        // },
        // MuiLinearProgress: {
        //     colorPrimary: {
        //         backgroundColor: '#f5f5f5',
        //     },
        //     barColorPrimary: {
        //         backgroundColor: '#d7d7d7',
        //     },
        // },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiAvatar: {
            colorDefault: {
                color: 'white',
            },
        },
        MuiList: {
            padding: {
                paddingTop: '16px',
                paddingBottom: '16px',
            },
        },
    },
});

export default lightTheme;
