import moment from 'moment';
import { genderLabel } from '../constants/options';
import { isNotDef } from './common';

export const formatDate = value => (value ? moment(value).format('DD.MM.YYYY') : '');

export const formatShortDate = value => (value ? moment(value).format('DD.MM.YY') : '');

export const formatHours = value => (value ? moment(value, 'HH:mm:ss').format('HH.mm') : '');

export const formatHour = value => (value ? moment(value).format('HH:mm') : '');

export const formatDurationMsToHHmm = ms => {
    const hoursFloat = ms / (1000.0 * 60.0 * 60.0);
    const hours = Math.floor(hoursFloat);
    const minutes = (hoursFloat - hours) * 60;
    if (!(hours + minutes)) return '';
    return `${Math.floor(hours).toString().padStart(2, '0')}:${Math.floor(minutes).toString().padStart(2, '0')}`;
};

export const formatDurationMsToHHmmExtended = ms => {
    const hoursFloat = ms / (1000.0 * 60.0 * 60.0);
    const hours = Math.floor(hoursFloat);
    const minutes = (hoursFloat - hours) * 60;
    if (!(hours + minutes)) return '';
    return `${Math.floor(hours).toString().padStart(2, '0')} ore ${Math.floor(minutes).toString().padStart(2, '0')} minuti`;
};

export const formatDateAndHours = value => (value ? moment(value).format('DD.MM.YYYY HH.mm') : '');

export const formatList = (value = []) => value.join(', ');

export const formatNumberFixedDecimal = value => (!Number.isNaN(value) ? Number(value).toFixed(2) : value);

export const formatZeroIfEmpty = value => `${value || 0}`;

export const formatCurrency = (value = 0, unit, digit = 2) => {
    return `${new Intl.NumberFormat('it-CH', {
        minimumFractionDigits: digit,
    }).format(value)}${unit ? ` ${unit}` : ''}`;
};

export const formatGender = (value, translate) => translate(genderLabel[value]);

export const formatPercent = value => (value ? `${Number(value).toFixed(0)} %` : '');

export const formatCivilStatus = (value, translate) => translate(value);

export const formatDuration = (value, data, translate) => {
    return `${Math.floor(value / (60.0 * 60.0))} ${translate('hours')}`;
};

export const formatDurationComplete = (value, data, translate) => {
    const dayFloat = value / (60.0 * 60.0 * 8.0);
    const days = Math.floor(dayFloat);
    const hours = Math.floor((dayFloat - days) * 8.0);

    switch (days) {
        case 1:
            return `${days} ${translate('day')} ${hours} ${translate('and')} ${translate('hours')}`;
        case 0:
            return `${hours} ${translate('hours')}`;
        default:
            return `${days} ${translate('days')} ${translate('and')} ${hours} ${translate('hours')}`;
    }
};

export function formatBytes(bytes) {
    if (isNotDef(bytes)) return '';
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const decimals = 0;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

function htmlEscape(str) {
    return str
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;')
        .replace(/\//g, '&#x2F;')
        .replace(/`/g, '&#x60;')
        .replace(/=/g, '&#x3D;');
}

export function formatFilename(value = '') {
    return htmlEscape(value.replace(/^.*[\\/]/, ''));
}

export function formatFolder(value, root) {
    return value.replace(root, '').replaceAll('/', '');
}

export function formatBackFolder(root) {
    const path = root.split('/');
    path.pop();
    path.pop();
    return path.join('/');
}

export function formatLastFolder(root) {
    const [, second] = root.split('/').reverse();
    return second;
}

export const formatFileType = item => {
    if (!item) return '';
    const [type] = item.Key.split('.').reverse();
    return type;
};

export const formatServerDate = (value, format) => (value ? moment(value).format(format) : undefined);

const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

export const formatLiteralDate = (value, translate) => `${translate(months[moment(value).month()])} ${moment(value).year()}`;

export const startMonthsDate = value => (value ? moment().startOf('month').format('DD.MM.YYYY') : '');

export const endMonthsDate = value => (value ? moment().endOf('month').format('DD.MM.YYYY') : '');