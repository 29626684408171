import { Button } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import useTranslate from '../../hooks/useTranslate';

const useStyles = makeStyles(() => ({
    root: {
        height: 40,
    },
}));

const ClearAssignment = ({ onChange }) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <Button
            classes={classes}
            variant="outlined"
            fullWidth
            size="medium"
            onClick={() => onChange({ personId: undefined, startAt: undefined, endAt: undefined })}
        >
            {translate('clear_assignment')}
        </Button>
    );
};

ClearAssignment.propTypes = {
    onChange: PropTypes.func.isRequired,
};
ClearAssignment.defaultProps = {};

export default ClearAssignment;
