import { APPROVED_LEAVE, leaveTypeLabel } from '../constants/statesConstants';

export const handleResponsePerson = response => {
    const { users = {}, employees = [], legalDocuments = [], signedDocuments = [] } = response || {};
    const [employee = {}] = employees;

    return {
        id: users.id,
        user: {
            ...users,
            phoneNumberList: (users.phoneNumberList || []).reduce(
                (accu, phoneNumber) => ({ ...accu, [`${phoneNumber.type}`.toLowerCase()]: phoneNumber }),
                {}
            ),
            phoneNumberListOriginal: users.phoneNumberLis,
        },
        employee: {
            ...employee,
            breakTime: Number.parseFloat(employee.breakTime, 10),
        },
        legalDocument: legalDocuments,
        signedDocument: signedDocuments,
    };
};

export const handlePhoneNumberList = user => {
    return {
        ...user,
        phoneNumberList: (user.phoneNumberList || []).reduce(
            (accu, phoneNumber) => ({ ...accu, [`${phoneNumber.type}`.toLowerCase()]: phoneNumber }),
            {}
        ),
    };
};

export const handleResponseData = response => {
    const { data } = response || {};
    const { totalElements } = response || 0;
    data.totalElements = totalElements;
    return data;
};

export const handleResponsePersonFlat = response => {
    const { users = {}, employees = [] } = response || {};
    const [employee] = employees;

    return {
        ...employee,
        ...users,
    };
};

export const handleResponsePersonCalendar = response => {
    const colors = response.colors.reduce((accumulator, { eventType, color }) => ({ ...accumulator, [eventType]: color }), {});

    const events = (eventType, items) =>
        items
            .map(({ id, title, description = '', startDate, endDate, ...rest }) => ({
                ...rest,
                id: `${id}_${eventType}`,
                startDate: new Date(startDate),
                endDate: endDate ? new Date(endDate) : new Date(startDate),
                eventType,
                title,
                description,
                color: colors[10],
            }))
            .filter(({ startDate, endDate }) => startDate <= endDate);

    const festivity = (eventType, items) =>
        items
            .map(({ id, title, description = '', startDate, endDate, place, ...rest }) => ({
                ...rest,
                id: `${id}_${eventType}`,
                startDate: new Date(startDate),
                endDate: endDate ? new Date(endDate) : new Date(startDate),
                eventType,
                title,
                description,
                place: description !== 'Festività Svizzera' && place !== description ? place : '',
                color: colors[12],
            }))
            .filter(({ startDate, endDate }) => startDate <= endDate);

    const personRequests = (eventType, items) =>
        items
            .filter(({ startAt, status }) => startAt && status === APPROVED_LEAVE) //  null, rejected ? con altri colori
            .map(({ id, description, startAt, endAt, type, ...rest }) => ({
                ...rest,
                id: `${id}_${eventType}`,
                startDate: new Date(startAt),
                endDate: new Date(endAt),
                eventType,
                eventId: id,
                title: leaveTypeLabel(type),
                type,
                color: colors[type],
                description: description || '',
            }));

    const stampingLogs = (eventType, items) => {
        return items.map(item => {
            const startDate = new Date(item.timestamp);
            return {
                id: `${item.id}`,
                startDate,
                endDate: new Date(startDate.getTime() + 1000 * 60 * 1),
                eventType,
                place: item.place,
                title: 'stampingLog',
                color: colors[11],
                item,
            };
        });
    };

    const map = { events, festivity, personRequests, stampingLogs };

    const result = Object.keys(response ?? {})
        .filter(key => map[key])
        .reduce((accu, key) => [...accu, ...map[key](key, response[key])], []);
console.log('calendar',result)
    return result;
};

export const handlePersonsResponse = response => {
    const { data = [], ...others } = response || {};
    return {
        data: data
            .filter(({ id }) => !!id)
            .map(user => ({
                ...user,
                phoneNumberList: (user.phoneNumberList || []).reduce(
                    (accumulator, phoneNumber) => ({ ...accumulator, [`${phoneNumber.type}`.toLowerCase()]: phoneNumber }),
                    {}
                ),
            })),
        ...others,
    };
};
