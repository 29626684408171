/* eslint-disable react/prop-types */
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from '../../hooks/useTranslate';
import { isNotDef } from '../../utils/common';

const EnhancedDatePicker = ({
    value,
    onChange,
    label,
    formatView,
    formatData,
    readOnly,
    minDate,
    minDateMessage,
    maxDate,
    maxDateMessage,
    variant,
    required,
}) => {
    const translate = useTranslate();
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                fullWidth
                label={translate(label)}
                invalidDateMessage="Formato data non valido"
                cancelLabel="Annulla"
                format={formatView}
                minDate={minDate ? moment(minDate) : undefined}
                minDateMessage={minDateMessage}
                maxDate={maxDate ? moment(maxDate) : undefined}
                maxDateMessage={maxDateMessage}
                clearLabel="Cancella"
                size="small"
                autoOk
                required={required}
                clearable
                value={value}
                readOnly={readOnly}
                InputProps={{
                    readOnly,
                    // disableUnderline: readOnly,
                }}
                inputVariant={readOnly ? 'standard' : variant}
                onChange={newValue => {
                    if (newValue?.isValid()) {
                        onChange(newValue.format(formatData));
                    } else if (isNotDef(newValue)) {
                        onChange(newValue);
                    }
                }}
            />
        </MuiPickersUtilsProvider>
    );
};

EnhancedDatePicker.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    formatView: PropTypes.string,
    formatData: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    minDateMessage: PropTypes.string,
    maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    maxDateMessage: PropTypes.string,
    variant: PropTypes.string,
};
EnhancedDatePicker.defaultProps = {
    value: null,
    readOnly: undefined,
    required: undefined,
    label: null,
    formatView: 'DD.MM.YYYY',
    formatData: 'YYYY-MM-DD',
    minDate: undefined,
    minDateMessage: '',
    maxDate: undefined,
    maxDateMessage: '',
    variant: 'outlined',
};

export default EnhancedDatePicker;
