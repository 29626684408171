import { createSlice } from '@reduxjs/toolkit';
import { getHrExpenses, getHrLeaveRequests, getPersons, getSites, getSitesFav, getUsers, getWorkingHours, getChecklistRequests } from '../proxies/tigestimProxies';

const SHARED = 'SHARED';
const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

const initialState = {
    pageSizeOption: [5, 10, 15, 20, 25, 30, 50],
    [getHrExpenses.name]: {
        order: { sortBy: 'id', sortDirection: 'desc' },
        page: { pageSize: 20 },
    },
    [getHrLeaveRequests.name]: {
        order: { sortBy: 'id', sortDirection: 'desc' },
        page: { pageSize: 20 },
    },
    [getPersons.name]: {
        order: { sortBy: 'id', sortDirection: 'desc' },
    },
    [getUsers.name]: {
        order: { sortBy: 'id', sortDirection: 'desc' },
    },
    [getWorkingHours.name]: {
        order: { sortBy: 'id', sortDirection: 'desc' },
    },
    [getSites.name]: {
        order: { sortBy: 'id', sortDirection: 'desc' },
        page: { pageSize: 10 },
    },
    [getSitesFav.name]: {
        order: { sortBy: 'id', sortDirection: 'desc' },
        page: { pageSize: 5 },
    },
    [getChecklistRequests.name]: {
        order: { sortBy: 'createdAt', sortDirection: 'desc' },
        page: { pageSize: 20 },
    },
    files: {
        page: { pageSize: 15 },
    },
};

const sliceFiltering = createSlice({
    name: 'filtering',
    initialState,
    reducers: {
        setOrder: (state, { payload }) => {
            const { order, view = SHARED } = payload;
            state[view] = { ...state[view], order: { ...state[view]?.order, ...order } };
        },
        setPage: (state, { payload }) => {
            const { page, view = SHARED } = payload;
            state[view] = { ...state[view], page: { ...state[view]?.page, ...page } };
        },
        setFilter: (state, { payload }) => {
            const { filter, view = SHARED } = payload;
            state[view] = { ...state[view], filter: { ...state[view]?.filter, ...filter } };
        },
        resetView: (state, { payload }) => {
            const { view = SHARED } = payload;
            state[view] = initialState[view];
        },
        resetAll: () => initialState,
    },
});

export default sliceFiltering;

// ACTIONS
export const { setOrder, setPage, setFilter, resetView, resetAll } = sliceFiltering.actions;

// SELECTORS
export const selectFilter = (view = SHARED) => {
    return state => state.filtering[view]?.filter || EMPTY_OBJECT;
};
export const selectSort =
    (name = SHARED) =>
        state =>
            state.filtering[name]?.order || EMPTY_OBJECT;
export const selectPageSizeOption = state => state.filtering.pageSizeOption || EMPTY_ARRAY;

// Perche non va ?
// export const selectPage = name => state => {
//     const [pageSizeDefault] = selectPageSizeOption(state);
//     const { pageNumber = 1, pageSize = pageSizeDefault } = state.filtering[name]?.page || EMPTY_OBJECT;
//     return { pageNumber, pageSize };
// };

export const selectPageNumber = name => state => {
    const { pageNumber = 1 } = state.filtering[name]?.page || EMPTY_OBJECT;
    return pageNumber;
};

export const selectPageSize = name => state => {
    const [, , pageSizeDefault] = selectPageSizeOption(state);
    const { pageSize = pageSizeDefault } = state.filtering[name]?.page || EMPTY_OBJECT;
    return pageSize;
};

export const selectShowFilters = state => state.filtering?.Reconciliations?.filter?.status?.id !== undefined;
export const selectStatus = state => state.filtering?.Reconciliations?.filter?.status?.id;
