import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { green, red } from '../../constants/colorsConstants';
import { APPROVED_EXPENSE, APPROVED_LEAVE, PENDING_EXPENSE, PENDING_LEAVE, REJECTED_EXPENSE, REJECTED_LEAVE } from '../../constants/statesConstants';
import useTranslate from '../../hooks/useTranslate';

const PendingCell = ({ item, id }) => {
    const translate = useTranslate();
    switch (item[id]) {
        case APPROVED_EXPENSE:
        case APPROVED_LEAVE:
            return <Box color={green}>{translate('approved')}</Box>;
        case REJECTED_EXPENSE:
        case REJECTED_LEAVE:
            return <Box color={red}>{translate('rejected')}</Box>;
        case PENDING_EXPENSE:
        case PENDING_LEAVE:
        default:
            return <Box>{translate('pending')}</Box>;
    }
};

PendingCell.propTypes = {
    item: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
};
PendingCell.defaultProps = {};

export default PendingCell;
