import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useTranslate from '../hooks/useTranslate';
import { invoke, invokeOrReturn, isNotDef } from '../utils/common';

const useStyles = makeStyles(() => ({
    none: {
        padding: 0,
    },
    small: {
        padding: 4,
    },
}));

const Action = ({
    icon: Icon,
    label,
    labelPosition,
    color,
    padding,
    onClick,
    data,
    valid,
    validation,
    disabled,
    minWidth,
    isLoading,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const [loading, setLoading] = useState(isLoading);
    const [disable, setDisable] = useState();

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        if (data && validation) {
            setDisable(
                invokeOrReturn(validation, data).some(({ id, required }) => {
                    return id && invokeOrReturn(required, data) && isNotDef(data[id]);
                })
            );
        }
    }, [data, validation]);

    return (
        <Box color={color} minWidth={minWidth}>
            <Button
                variant="text"
                color="inherit"
                className={classes[padding]}
                disabled={disabled || disable || !valid(data)}
                onClick={() => {
                    invoke(() => {
                        return onClick?.(data, setLoading, dispatch);
                    }, dispatch);
                }}
            >
                {labelPosition === 'left' && (
                    <Box pr={1} fontSize={12} fontWeight="bold" color="inherit">
                        {translate(invokeOrReturn(label, data))}
                    </Box>
                )}
                {Icon && !loading && <Icon />}
                {loading && <CircularProgress size={24} color="inherit" />}
                {labelPosition === 'right' && label && (
                    <Box pl={1} fontSize={12} fontWeight="bold" color="inherit">
                        {translate(invokeOrReturn(label, data))}
                    </Box>
                )}
            </Button>
        </Box>
    );
};

Action.propTypes = {
    color: PropTypes.string,
    data: PropTypes.object,
    disabled: PropTypes.bool,
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    isLoading: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    labelPosition: PropTypes.oneOf(['left', 'right']),
    minWidth: PropTypes.number,
    onClick: PropTypes.func,
    padding: PropTypes.string,
    valid: PropTypes.func,
    validation: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
};

Action.defaultProps = {
    data: {},
    icon: undefined,
    label: undefined,
    labelPosition: 'right',
    color: undefined,
    padding: undefined,
    onClick: undefined,
    valid: () => true,
    validation: undefined,
    minWidth: undefined,
    disabled: undefined,
    isLoading: undefined,
};

export default Action;
