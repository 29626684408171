import { Grid } from '@material-ui/core';
import { Event } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../components/Actions';
import FlexColumn from '../../components/flex/FlexColumn';
import history from '../../components/routings/history';
import TableViewCard from '../../components/view/TableViewCard';
import { profileExpensesRequestsColumns } from '../../configuration/columns';
import { userExpenses } from '../../store/proxies/tigestimProxies';
import { setWarningNotification } from '../../store/slices/sliceNotification';
import { selectUser } from '../../store/slices/sliceSession';
import { isNotDef } from '../../utils/common';
import UserAvatar from '../UserAvatar';

const ProfileExpensesView = ({ actions }) => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const [data, setData] = useState([]);

    const handleResponseExpenses = (response) =>{
        const enhancedData = {
            user,
            expenses : response.data,
            date: new Date()
        }
        if (response) setData(enhancedData);
        return response;
    }

    return (
        <FlexColumn flex={1} width={1}>
            <Actions actions={actions} data={data}/>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <UserAvatar data={user} />
                </Grid>

                <Grid item xs={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TableViewCard
                                icon={Event}
                                title="my_expenses_request"
                                proxy={userExpenses}
                                params={{
                                    userId: user?.id,
                                }}
                                columns={profileExpensesRequestsColumns}
                                handleResponse={handleResponseExpenses}
                                messageNoItems="document_not_present"
                                onItemClick={({ id, status }) => {
                                    if (isNotDef(status)) {
                                        history.push(`expenses/${id}/edit`);
                                    } else {
                                        dispatch(setWarningNotification('edit_allow_only_on_pending'));
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FlexColumn>
    );
};

ProfileExpensesView.propTypes = {
    actions: PropTypes.array,
};
ProfileExpensesView.defaultProps = {
    actions: [],
};

export default ProfileExpensesView;
