import { Cancel, CheckCircle, Close, Edit, Visibility } from '@material-ui/icons';
import { Download, FilePdfBox } from 'mdi-material-ui';
import moment from 'moment';
import AvatarCell from '../components/cells/AvatarCell';
import DecisionCell from '../components/cells/DecisionCell';
import EditContacts from '../components/cells/EditContacts';
import EmailCell from '../components/cells/EmailCell';
import ImageCell from '../components/cells/ImageCell';
import PendingCell from '../components/cells/PendingCell';
import PhoneCell from '../components/cells/PhoneCell';
import PhotoCameraCell from '../components/cells/PhotoCameraCell';
import history from '../components/routings/history';
import EnhancedIcon from '../components/utilities/EnhancedIcon';
import EnhancedIconSelector from '../components/utilities/EnhancedIconSelector';
import { gray } from '../constants/colorsConstants';
import { CHF } from '../constants/options';
import {
    APPROVED_CHECKLIST,
    APPROVED_EXPENSE,
    APPROVED_LEAVE,
    leaveTypeLabel,
    REJECTED_CHECKLIST,
    REJECTED_EXPENSE,
    REJECTED_LEAVE,
} from '../constants/statesConstants';
import {
    deleteSiteContact,
    getChecklistComposite,
    getChecklistRequests,
    getHrExpenses,
    getHrLeaveRequests,
    getSiteContacts,
    leaveRequestReview,
    personLeaveRequests,
    putChecklistRequests,
    savePersonLeave,
    saveUserExpense,
    userExpenses,
} from '../store/proxies/tigestimProxies';
import { openDialog } from '../store/slices/sliceDialog';
import { refetch } from '../store/slices/sliceFetching';
import { setErrorNotification, setSuccessNotification } from '../store/slices/sliceNotification';
import { buildUrl } from '../utils/common';
import { formatCurrency, formatDate, formatDuration, formatHours, formatServerDate, formatShortDate } from '../utils/formats';
import ChecklistDocument from '../view/checklist/export/ChecklistDocument';
import LeaveDocument from '../view/hr/export/LeaveDocument';

export const sitesColumnsSensible = [
    { id: 'avatarUrl', width: 60, component: ImageCell, headerIcon: PhotoCameraCell },
    { id: 'quickcomId', width: 50, align: 'center' },
    { id: 'title' },
    { id: 'companyName' },
    { id: 'city' },
    { id: 'address', label: 'address' },
    { id: 'total', label: 'offer', format: value => formatCurrency(value, CHF, 2), align: 'right' },
    { id: 'createdAt', align: 'right', width: 100 },
];

export const sitesColumns = [
    { id: 'avatarUrl', width: 60, component: ImageCell, headerIcon: PhotoCameraCell },
    { id: 'quickcomId', width: 50, align: 'center' },
    { id: 'title' },
    { id: 'companyName' },
    { id: 'city' },
    { id: 'address', label: 'address' },
    { id: 'status', label: 'status' },
    { id: 'createdAt', align: 'right', width: 100 },
];

export const siteContactsColumns = [
    {
        id: 'avatarUrl',
        component: AvatarCell,
        componentProps: {
            size: 'small',
        },
        sortable: false,
        headerIcon: PhotoCameraCell,
    },
    { id: 'companyName' },
    { id: 'lastName' },
    { id: 'firstName' },
    { id: 'projectFunction', label: 'project_function', format: (value, item, translate) => translate(value?.toLowerCase()) },
    { id: 'telephoneNumber', label: 'telephoneNumber', component: PhoneCell, sortable: false },
    { id: 'officeEmail', label: 'office_email', component: EmailCell, sortable: false },
    {
        id: 'edit',
        align: 'right',
        component: EnhancedIcon,
        componentProps: {
            padding: 'none',
            icon: Edit,
            label: 'edit',
            onClick: async ({ personId }) => {
                history.push(`/app/hr/users/${personId}/edit`);
            },
            color: gray,
        },
        sortable: false,
    },
    {
        id: 'remove',
        align: 'right',
        component: EnhancedIcon,
        componentProps: {
            padding: 'none',
            icon: Close,
            label: 'remove',
            onClick: async ({ workTeamId }, setLoading, dispatch) => {
                setLoading(true);
                try {
                    await deleteSiteContact.call(workTeamId);
                    dispatch(setSuccessNotification('removed_contact_successfully'));
                    setLoading(false);
                    return refetch(getSiteContacts.name);
                } catch (e) {
                    setLoading(false);
                    return setErrorNotification(`${e?.response?.status} : ${e?.response?.statusText}`);
                }
            },
            color: gray,
        },
        sortable: false,
    },
];

export const siteContactsEditColumns = (contacts, id) => [
    {
        id: 'url',
        component: AvatarCell,
        componentProps: {
            size: 'small',
        },
        sortable: false,
        headerIcon: PhotoCameraCell,
    },
    { id: 'id' },
    { id: 'companyName' },
    { id: 'lastName' },
    { id: 'firstName' },
    { id: 'officeEmail', label: 'office_email' },
    { id: 'role', format: (value, data, translate) => translate(value) },
    {
        id: 'action',
        align: 'center',
        width: 220,
        component: EditContacts,
        sortable: false,
        componentProps: {
            contacts,
            siteId: id,
        },
    },
];

export const usersColumns = [
    {
        id: 'url',
        component: AvatarCell,
        componentProps: {
            size: 'small',
        },
        sortable: false,
        headerIcon: PhotoCameraCell,
    },
    { id: 'id' },
    { id: 'companyName' },
    { id: 'firstName' },
    { id: 'lastName' },
    { id: 'personalMail', label: 'personal_email', component: EmailCell, sortable: false },
    { id: 'email', label: 'work_email', component: EmailCell },
    { id: 'phoneNumberList.mobile.number', label: 'personal_mobile', component: PhoneCell, sortable: false },
    { id: 'phoneNumberList.workmobile.number', label: 'work_mobile', component: PhoneCell, sortable: false },
    { id: 'role', format: (value, data, translate) => translate(value) },
    {
        id: 'action',
        align: 'right',
        role: i => i.can('update', 'hr'),
        component: EnhancedIcon,
        componentProps: {
            padding: 'none',
            icon: Edit,
            onClick: ({ id }) => history.push(`${id}/edit`),
            color: gray,
        },
        sortable: false,
    },
];

export const usersColumnsNoEdit = [
    {
        id: 'url',
        component: AvatarCell,
        componentProps: {
            size: 'small',
        },
        sortable: false,
        headerIcon: PhotoCameraCell,
    },
    { id: 'id' },
    { id: 'companyName' },
    { id: 'lastName' },
    { id: 'firstName' },
    { id: 'personalMail', label: 'personal_email', component: EmailCell, sortable: false },
    { id: 'email', label: 'work_email', component: EmailCell },
    { id: 'phoneNumberList.mobile.number', label: 'personal_mobile', component: PhoneCell, sortable: false },
    { id: 'phoneNumberList.workmobile.number', label: 'work_mobile', component: PhoneCell, sortable: false },
    { id: 'role', format: (value, data, translate) => translate(value) },
];

export const allLeavesRequestsColumns = [
    {
        id: 'personUrl',
        sortable: false,
        headerIcon: PhotoCameraCell,
        component: AvatarCell,
        componentProps: {
            size: 'small',
        },
    },
    { id: 'requestDate', format: formatDate },
    { id: 'companyName', label: 'companyName' },
    {
        id: 'personLastName',
        label: 'user',
        format: (value, { personLastName, personFirstName }) => `${personLastName} ${personFirstName}`,
        sortable: false,
    },
    { id: 'type', format: (value, item, translate) => translate(leaveTypeLabel(value)) },
    { id: 'startAt', format: formatDate },
    { id: 'endAt', format: formatDate },
    { id: 'duration', align: 'right', format: formatDuration },
    {
        id: 'download',
        align: 'center',
        label: 'download',
        width: 50,
        component: EnhancedIcon,
        sortable: false,
        componentProps: {
            padding: 'none',
            icon: Download,
            label: 'download',
            onClick: data =>
                openDialog({
                    cancelLabel: 'close',
                    component: LeaveDocument,
                    fullScreen: true,
                    data,
                }),
        },
    },
    {
        id: 'status',
        align: 'center',
        width: 220,
        component: DecisionCell,
        componentProps: {
            onApprove: async ({ id }, whoApprovedId) => {
                await savePersonLeave.call(id, { status: APPROVED_LEAVE, whoApprovedId });
                return refetch(getHrLeaveRequests.name);
            },
            onReject: async ({ id }, whoApprovedId) => {
                await savePersonLeave.call(id, { status: REJECTED_LEAVE, whoApprovedId });
                return refetch(getHrLeaveRequests.name);
            },
        },
    },
];

export const checklistRequestsColumns = [
    {
        id: 'personUrl',
        sortable: false,
        headerIcon: PhotoCameraCell,
        component: AvatarCell,
        componentProps: {
            size: 'small',
        },
    },
    { id: 'createdAt', format: formatDate },
    { id: 'companyName', label: 'companyName' },
    {
        id: 'lastName',
        label: 'user',
        format: (value, { lastName, firstName }) => `${lastName} ${firstName}`,
        sortable: false,
    },
    { id: 'checklistTitle', align: 'center', label: 'checklists' },
    { id: 'quickcomidSite', label: 'quickcomId', sortable: false},
    { id: 'titleSite', label: 'construction_site', sortable: false},
    {
        id: 'view',
        align: 'center',
        label: 'view_pdf',
        width: 50,
        component: EnhancedIcon,
        sortable: false,
        componentProps: {
            padding: 'none',
            icon: Visibility,
            label: 'download',
            onClick: async ({ checklistworkerId }) => {
                const data = await getChecklistComposite.call(checklistworkerId);
                return openDialog({
                    cancelLabel: 'close',
                    component: ChecklistDocument,
                    fullScreen: true,
                    data,
                });
            },
        },
    },
    {
        id: 'status',
        align: 'center',
        width: 220,
        component: DecisionCell,
        componentProps: {
            onApprove: async ({ id }, whoApprovedId) => {
                await putChecklistRequests.call(id, {
                    status: APPROVED_CHECKLIST,
                    whoApprovedId,
                    approvedAt: formatServerDate(moment(), 'YYYY-MM-DD HH:mm:ss'),
                });
                return refetch(getChecklistRequests.name);
            },
            onReject: async ({ id }, whoApprovedId) => {
                await putChecklistRequests.call(id, {
                    status: REJECTED_CHECKLIST,
                    whoApprovedId,
                    approvedAt: formatServerDate(moment(), 'YYYY-MM-DD HH:mm:ss'),
                });
                return refetch(getChecklistRequests.name);
            },
        },
    },
];

export const allExpensesRequestsColumns = [
    {
        id: 'url',
        sortable: false,
        headerIcon: PhotoCameraCell,
        component: AvatarCell,
        componentProps: {
            size: 'small',
        },
    },
    { id: 'companyName', label: 'companyName' },
    { id: 'lastName', label: 'user', format: (value, { lastName, firstName }) => `${lastName} ${firstName}`, sortable: false },
    { id: 'amount', align: 'right', format: value => formatCurrency(value, 'CHF') },
    { id: 'date', format: formatDate },
    { id: 'reason', overflow: true },
    { id: 'place' },
    {
        id: 'status',
        align: 'center',
        width: 220,
        component: DecisionCell,
        componentProps: {
            onApprove: async ({ id }, whoApproveId) => {
                await saveUserExpense.call(id, { status: APPROVED_EXPENSE, whoApproveId });
                return refetch(getHrExpenses.name);
            },
            onReject: async ({ id }, whoApproveId) => {
                await saveUserExpense.call(id, { status: REJECTED_EXPENSE, whoApproveId });
                return refetch(getHrExpenses.name);
            },
        },
    },
];

export const workingHoursColumns = [
    {
        id: 'url',
        sortable: false,
        headerIcon: PhotoCameraCell,
        component: AvatarCell,
        componentProps: {
            size: 'small',
        },
    },
    { id: 'id', label: 'id', width: 20, sortable: false },
    { id: 'companyName', label: 'companyName' },
    { id: 'functionCompany', label: 'functionCompany' },
    { id: 'job', label: 'job' },
    { id: 'department', label: 'department' },
    { id: 'firstName', label: 'firstName' },
    { id: 'lastName', label: 'lastName' },
    { id: 'workPercentage', label: 'workPercentage' },
    {
        id: 'winterstarthr',
        label: 'winter',
        format: (value, { winterstarthr, winterendthr }) => `${formatHours(winterstarthr)} - ${formatHours(winterendthr)}`,
        sortable: false,
    },
    {
        id: 'summerstarthr',
        label: 'summer',
        format: (value, { summerstarthr, summerendthr }) => `${formatHours(summerstarthr)} - ${formatHours(summerendthr)}`,
        sortable: false,
    },
];

export const legalDocumentsColumns = [{ id: 'title', width: '50%' }, { id: 'numberDoc' }, { id: 'expireat', format: formatDate, width: 50 }];
export const signedDocumentsColumns = [{ id: 'title' }, { id: 'date', format: formatDate, width: 50 }];

export const leavesRequestsColumns = [
    { id: 'startAt', format: formatDate },
    { id: 'endAt', format: formatDate },
    { id: 'type', format: (value, item, translate) => translate(leaveTypeLabel(value)) },
    { id: 'description', overflow: true },
    { id: 'duration', align: 'right', format: formatDuration },
    {
        id: 'status',
        align: 'center',
        width: 220,
        component: DecisionCell,
        componentProps: {
            onApprove: async ({ id }, whoApproveIt) => {
                await savePersonLeave.call(id, { status: APPROVED_LEAVE, whoApproveIt });
                return refetch([personLeaveRequests.name, leaveRequestReview.name]);
            },
            onReject: async ({ id, whoApproveIt }) => {
                await savePersonLeave.call(id, { status: REJECTED_LEAVE, whoApproveIt });
                return refetch([personLeaveRequests.name, leaveRequestReview.name]);
            },
        },
    },
];
export const profileLeavesRequestsColumns = [
    { id: 'startAt', format: formatDate },
    { id: 'endAt', format: formatDate },
    { id: 'type', format: (value, item, translate) => translate(leaveTypeLabel(value)) },
    { id: 'description', overflow: true },
    { id: 'duration', align: 'right', format: formatDuration },
    {
        id: 'status',
        align: 'center',
        component: PendingCell,
    },
];

export const expensesRequestsColumns = [
    { id: 'date', format: formatDate },
    { id: 'reason', overflow: true },
    { id: 'place' },
    { id: 'amount', align: 'right', format: value => formatCurrency(value, 'CHF') },
    {
        id: 'status',
        align: 'center',
        width: 220,
        component: DecisionCell,
        componentProps: {
            onApprove: async (item, whoApproveId) => {
                await saveUserExpense.call(item.id, { status: APPROVED_EXPENSE, whoApproveId });
                return refetch(userExpenses.name);
            },
            onReject: async (item, whoApproveId) => {
                await saveUserExpense.call(item.id, { status: REJECTED_EXPENSE, whoApproveId });
                return refetch(userExpenses.name);
            },
        },
    },
];

export const profileExpensesRequestsColumns = [
    { id: 'date', format: formatDate },
    { id: 'reason', overflow: true },
    { id: 'place' },
    { id: 'amount', align: 'right', format: value => formatCurrency(value, 'CHF') },
    {
        id: 'status',
        align: 'center',
        component: PendingCell,
    },
];

export const toolsColumns = [
    // { id: 'id', label: 'id' },
    { id: 'toolName', label: 'tool_name' },
    { id: 'categoryName', label: 'category' }, // , format: formatToolCategory
    {
        id: 'isAvailable',
        label: 'status',
        component: EnhancedIconSelector,
        componentProps: {
            icon: ({ personId }) => (personId ? Cancel : CheckCircle),
            color: 'primary.main',
            disable: ({ personId }) => !!personId,
        },
    },
    {
        id: 'startAt',
        label: 'period',
        sortable: false,
        format: (value, { startAt, endAt }, translate) => {
            if (startAt && endAt) return `${formatShortDate(startAt)} - ${formatShortDate(endAt)}`;
            return translate('available');
        },
        color: (field, { endAt }) => {
            return endAt && moment(endAt).startOf('day').diff(moment().startOf('day')) < 0 ? 'warning.main' : 'text.primary';
        },
    },
    {
        id: 'personFirstName',
        label: 'person',
        sortable: false,
        format: (value, { personFirstName, personLastName }) => (personFirstName && personLastName ? `${personFirstName} ${personLastName}` : ''),
    },
    { id: 'constructionSiteTitle', sortable: false, label: 'construction_site', format: value => value ?? '' },
];

export const toolsQuantityColumns = [
    { id: 'toolName', label: 'toolName', format: value => value.replace(/#(.)*$/, '') },
    { id: 'categoryName', label: 'category' },
    { id: 'quantity', label: 'quantity' },
    { id: 'unavailableQty', label: 'used' },
    { id: 'availableQty', label: 'available' },
];
export const toolsCategoriesColumns = [
    { id: 'id', label: 'id', width: '10px' },
    { id: 'categoryName', label: 'category_name' },
];

export const festivitiesColumns = [
    { id: 'title', label: 'title' },
    { id: 'description', label: 'description' },
    { id: 'startDate', format: formatDate },
    { id: 'endDate', format: formatDate },
    // { id: 'isFestivity', label: 'isFestivity' },
    // { id: 'isPublic', label: 'isPublic' },
];

export const permissionsColumns = [
    {
        id: 'url',
        component: AvatarCell,
        componentProps: {
            size: 'small',
        },
        sortable: false,
        headerIcon: PhotoCameraCell,
    },
    { id: 'id' },
    { id: 'lastName' },
    { id: 'firstName' },
    { id: 'email' },
    { id: 'role', format: (value, data, translate) => translate(value) },
];

export const checklistColumns = [
    { id: 'id', label: 'id', width: 150 },
    { id: 'title', label: 'title' },
    { id: 'date', label: 'date', format: formatDate },
    { id: 'observation', label: 'observation', format: (value, data, translate) => translate(value) },
    {
        id: 'assigned',
        width: 150,
        align: 'right',
        label: 'assigned',
        format: (value, item, translate) => (value ? `${value} ${translate('times')}` : translate('not_assigned_yet')),
    },
];

export const checklistInstanceColumns = [
    { id: 'id', label: 'id' },
    { id: 'title', label: 'title' },
    { id: 'date', label: 'date', format: formatDate },
    { id: 'assignedConstructionSites.0.constructionSiteQuickcomId', label: 'quickcomId', sortable: false},
    { id: 'assignedConstructionSites.0.constructionSiteTitle', label: 'construction_site', sortable: false},
    { id: 'assignedConstructionSites.0.constructionSiteCompanyName', label: 'company', sortable: false},
    { id: 'assignedConstructionSites.0.constructionSiteSubject', label: 'subject', sortable: false},
    { id: 'assignedConstructionSites.0.constructionSiteStatus', label: 'status', sortable: false},
];

export const checklistTemplateColumns = [
    { id: 'typeId', label: 'id', width: 150 },
    { id: 'name', label: 'title' },
    { id: 'date', label: 'date', format: formatDate },
    {
        id: 'assigned',
        width: 150,
        align: 'right',
        label: 'assigned',
        format: (value, item, translate) => (value ? `${value} ${translate('times')}` : translate('not_assigned_yet')),
    },
    // {
    //     id: 'actions',
    //     align: 'right',
    //     width: 200,
    //     component: EnhancedButtonColumn,
    //     componentProps: {
    //         label: 'new_from_template',
    //         icon: Add,
    //         onClick: ({ id }) => history.push(`${id}/new`),
    //         color: gray,
    //     },
    //     sortable: false,
    // },
];

export const schedulesColumns = [
    { id: 'id', label: 'id', width: 150 },
    { id: 'title', label: 'title' },
    { id: 'workers', label: 'workers', format: (workers = []) => workers.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(', ') },
    { id: 'constructionSiteTitle', label: 'construction_site' },
    {
        id: 'startAt',
        label: 'period',
        sortable: false,
        format: (value, { startAt, endAt }) => {
            return `${formatShortDate(startAt)} - ${formatShortDate(endAt)}`;
        },
        color: (field, { endAt }) => {
            return endAt && moment(endAt).startOf('day').diff(moment().startOf('day')) < 0 ? 'warning.main' : 'text.primary';
        },
    },
    {
        id: 'reportUrl',
        label: 'report',
        align: 'right',
        component: EnhancedIcon,
        componentProps: {
            padding: 'none',
            icon: FilePdfBox,
            onClick: ({ reportUrl }) => window.open(buildUrl(reportUrl)),
            disable: ({ reportUrl }) => !reportUrl,
        },
        sortable: false,
    },
];

export const siteSchedulesColumns = [
    { id: 'id', label: 'id', width: 150 },
    { id: 'title', label: 'title' },
    { id: 'workers', label: 'workers', format: (workers = []) => workers.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(', ') },
    {
        id: 'startAt',
        label: 'period',
        sortable: false,
        format: (value, { startAt, endAt }) => {
            return `${formatShortDate(startAt)} - ${formatShortDate(endAt)}`;
        },
        color: (field, { endAt }) => {
            return endAt && moment(endAt).startOf('day').diff(moment().startOf('day')) < 0 ? 'warning.main' : 'text.primary';
        },
    },
    {
        id: 'reportUrl',
        label: 'report',
        align: 'right',
        component: EnhancedIcon,
        componentProps: {
            padding: 'none',
            icon: FilePdfBox,
            onClick: ({ reportUrl }) => window.open(buildUrl(reportUrl)),
            disable: () => true,
        },
        sortable: false,
    },
];

export const scheduleCategoryColumns = [
    { id: 'id', label: 'id', width: '10px' },
    { id: 'categoryName', label: 'category_name' },
];

export const summaryStampingLogColumns = [
    { id: 'week', label: 'week' },
    { id: 'dateRange', label: 'dateRange' },
    { id: 'total', align: 'right', label: 'total' },
];
