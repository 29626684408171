import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from '../../hooks/useTranslate';

const useStyles = makeStyles(() => ({
    root: {
        height: 40,
    },
}));

const EnhancedLink = ({ value, icon: Icon, label, onClick, variant, fullWidth }) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <Button
            disabled={!value}
            startIcon={Icon && <Icon />}
            classes={classes}
            variant={variant}
            fullWidth={fullWidth}
            size="medium"
            onClick={() => onClick(value)}
        >
            {translate(label)}
        </Button>
    );
};

EnhancedLink.propTypes = {
    fullWidth: PropTypes.bool,
    icon: PropTypes.object,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    value: PropTypes.string,
    variant: PropTypes.string,
};
EnhancedLink.defaultProps = {
    variant: 'text',
    icon: undefined,
    value: undefined,
    fullWidth: true,
};

export default EnhancedLink;
