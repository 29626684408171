import { Tab, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import useTranslate from '../../hooks/useTranslate';

const useStyles = makeStyles(() => ({
    hide: {
        display: 'none',
    },
}));

const EnhancedTab = ({ label, hideTab, disabled, value, ...other }) => {
    const translate = useTranslate();

    const classes = useStyles();
    const disable = useSelector(disabled);
    return <Tab className={hideTab ? classes.hide : ''} label={translate(label)} value={value} disabled={disable} {...other} />;
};

EnhancedTab.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    hideTab: PropTypes.bool,
    disabled: PropTypes.func,
};
EnhancedTab.defaultProps = {
    hideTab: false,
    disabled: () => {},
};

export default EnhancedTab;
