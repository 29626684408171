/* eslint-disable no-console */
import { Check, Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { green, red } from '../../constants/colorsConstants';
import { functions } from '../../constants/options';
import { createSiteContact, deleteSiteContact, getSiteContacts ,createJournal } from '../../store/proxies/tigestimProxies';
import { openDialog } from '../../store/slices/sliceDialog';
import { refetch } from '../../store/slices/sliceFetching';
import { setErrorNotification, setSuccessNotification } from '../../store/slices/sliceNotification';
import Action from '../Action';
import EditDialog from '../dialog/EditDialog';
import EnhancedAutocomplete from '../utilities/EnhancedAutocomplete';
import { formatServerDate } from '../../utils/formats';
import useTranslate from '../../hooks/useTranslate';

const EditContacts = ({ item, siteId, contacts }) => {
    const translate = useTranslate();
    const workTeamId = contacts[item.id];
    if (workTeamId)
        return (
            <Action
                label="remove"
                icon={Close}
                color={red}
                onClick={async (notUsed, setLoading, dispatch) => {
                    setLoading(true);
                    try {
                        await deleteSiteContact.call(workTeamId);
                        dispatch(setSuccessNotification('removed_contact_successfully'));
                        setLoading(false);
                        return refetch(getSiteContacts.name);
                    } catch (e) {
                        setLoading(false);
                        return setErrorNotification(`${e?.response?.status} : ${e?.response?.statusText}`);
                    }
                }}
                padding="none"
            />
        );

    return (
        <Action
            label="add"
            icon={Check}
            color={green}
            onClick={async (notUsed, setLoading, dispatch) => {
                return openDialog({
                    data: { ...item, siteId },
                    component: EditDialog,
                    componentProps: {
                        form: [
                            {
                                label: 'project_function',
                                id: 'projectFunction',
                                // required: true,
                                component: EnhancedAutocomplete,
                                componentProps: {
                                    options: functions,
                                },
                            },
                        ],
                        title: 'edit_contact',
                    },
                    cancelLabel: 'cancel',
                    confirmationLabel: 'save',
                    confirmationCallback: async newItem => {
                        const translatedPostDescription = translate('journal_auto_description')
                        const data = {
                            personId: newItem.id,
                            constructionId: newItem.siteId,
                            priorityContact: 1,
                            projectFunction: newItem.projectFunction,
                        };
                        const journal = {
                            createdAt: formatServerDate(moment(), 'YYYY-MM-DD HH:mm:ss'),
                            isEditable: 1,
                            isVisible: 1,
                            media: [],
                            title:translate('journal_new_user'),
                            description: translatedPostDescription.replace('<USER>', `${newItem.firstName} ${newItem.lastName}` ), 
                            authorId:newItem.id,
                            siteId: newItem.siteId
                        };
                        setLoading(true);
                        try {
                            await Promise.all([createSiteContact.call(data), createJournal.call(journal)])
                            setLoading(false);
                            dispatch(setSuccessNotification('saved_contact_successfully'));
                            return refetch(getSiteContacts.name);
                        } catch (e) {
                            setLoading(false);
                            return setErrorNotification(`${e?.response?.status} : ${e?.response?.statusText}`);
                        }
                    },
                });
            }}
            padding="none"
        />
    );
};

EditContacts.propTypes = {
    contacts: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    siteId: PropTypes.number.isRequired,
};
EditContacts.defaultProps = {};

export default EditContacts;
