import { Grid } from '@material-ui/core';
import { Event } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import Actions from '../../components/Actions';
import FlexColumn from '../../components/flex/FlexColumn';
import history from '../../components/routings/history';
import TableViewCard from '../../components/view/TableViewCard';
import { expensesRequestsColumns } from '../../configuration/columns';
import { userExpenses } from '../../store/proxies/tigestimProxies';
import { selectItem } from '../../store/slices/sliceNavigation';
import UserAvatar from '../UserAvatar';

const UserExpensesView = ({ actions }) => {
    const user = useSelector(selectItem);
    const [data, setData] = useState([]);

    const handleResponseExpenses = (response) =>{
        const enhancedData = {
            user,
            expenses : response.data,
            date: new Date()
        }
        if (response) setData(enhancedData);
        return response;
    }

    return (
        <FlexColumn flex={1} width={1}>
            <Actions actions={actions} data={data} />

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <UserAvatar data={user} />
                </Grid>

                <Grid item xs={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TableViewCard
                                icon={Event}
                                title="expenses_request"
                                proxy={userExpenses}
                                params={{
                                    userId: user?.id,
                                }}
                                handleResponse={handleResponseExpenses}
                                columns={expensesRequestsColumns}
                                messageNoItems="document_not_present"
                                onItemClick={({ id }) => {
                                    history.push(`expenses/${id}/edit`);
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FlexColumn>
    );
};

UserExpensesView.propTypes = {
    actions: PropTypes.array,
};
UserExpensesView.defaultProps = {
    actions: [],
};

export default UserExpensesView;
