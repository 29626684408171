import { TableCell, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import useTranslate from '../../hooks/useTranslate';
import { getLastField } from '../../utils/common';

const useStyles = makeStyles(theme => ({
    none: {
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    // default: {
    //     paddingLeft: theme.spacing(2),
    //     paddingRight: theme.spacing(2),
    //     paddingBottom: theme.spacing(1),
    //     paddingTop: theme.spacing(1),
    // },
    text: {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
    },
    nowrap: {
        whiteSpace: 'nowrap',
    },
}));

const EnhancedCell = ({ column, item, onItemClick, invalidated, invalidatedColor, padding }) => {
    const classes = useStyles(padding);
    const [value, setValue] = useState('');
    const [textColor, setTextColor] = useState('');
    const translate = useTranslate();

    useEffect(() => {
        const field = getLastField(item, column.id);
        if (column.format) setValue(column.format(field, item, translate));
        else setValue(field != null && field !== undefined ? `${field}` : '');

        if (column.color) setTextColor(column.color(field, item));
    }, [column, item, translate]);

    return (
        <TableCell className={classes[padding]} onClick={() => onItemClick(item)} align={column.align || 'left'}>
            {column.overflow ? (
                <Tooltip title={value} placement="top-start">
                    <Box className={classes.text} color={invalidated ? invalidatedColor : textColor}>
                        {value || ''}
                    </Box>
                </Tooltip>
            ) : (
                <Box className={column.wrap ? '' : classes.nowrap} color={invalidated ? invalidatedColor : textColor}>
                    {value || ''}
                </Box>
            )}
        </TableCell>
    );
};

EnhancedCell.propTypes = {
    column: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    onItemClick: PropTypes.func,
    invalidated: PropTypes.bool,
    invalidatedColor: PropTypes.string,
    padding: PropTypes.string,
};

EnhancedCell.defaultProps = {
    onItemClick: () => {},
    invalidated: false,
    invalidatedColor: 'none',
    padding: undefined,
};

export default EnhancedCell;
