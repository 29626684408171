import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { bucketGetObject, DEFAULT_BUCKET } from '../../../utils/bucketUtils';
import { formatDate } from '../../../utils/formats';

const styles = StyleSheet.create({
    root: {
        fontSize: 5.5,
        borderLeft: 1,
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    headerCell: {
        paddingHorizontal: 2,
        borderTop: 1,
        borderRight: 1,
        borderBottom: 1,
        borderColor: 'black',
        fontWeight: 700,
    },
    cell: {
        paddingHorizontal: 2,
        borderRight: 1,
        borderBottom: 1,
        borderColor: 'black',
    },
    border: {
        borderRight: 1,
        borderBottom: 1,
        borderColor: 'black',
    },
    dotted: {
        flex: 1,
        paddingHorizontal: 2,
        borderStyle: 'dotted',
    },
});

const PageTableQuestion = ({ index, title, items, images, persons }) => {
    const [imagesFetch, setImagesFetch] = useState();

    useEffect(() => {
        const fetch = () => {
            images.forEach(async ({ imageId, imageUrl }) => {
                const { Body, ContentType } = await bucketGetObject({ Key: imageUrl, bucketSite: DEFAULT_BUCKET });
                const format = ContentType === 'image/jpeg' || ContentType === 'image/jpg' ? 'jpg' : 'png';
                setImagesFetch((prev = []) => [...prev, { src: { data: Body, format }, imageId }]);
            });
        };

        if (images) fetch();
    }, [images]);

    if (images?.length !== imagesFetch?.length) return null;

    return (
        <View style={styles.column}>
            <View style={styles.row}>
                <View style={[styles.cell, { width: '4mm', alignItems: 'center', justifyContent: 'center' }]}>
                    <Text>{index + 1}</Text>
                </View>
                <View style={[styles.cell, { width: '65mm' }]}>
                    <Text>{title}</Text>
                    {imagesFetch?.map(({ src, imageId }) => {
                        return <Image src={src} key={imageId} style={{ marginTop: 5, width: '100%' }} />;
                    })}
                </View>
                <View style={[styles.row, { flex: 1 }]}>
                    <View style={[styles.border, styles.column, { width: '37mm' }]}>
                        {items?.map((item, index) => (
                            <Text key={item.id} style={[styles.dotted, { borderTop: index ? 0.5 : 0 }]}>
                                {item.label}
                            </Text>
                        ))}
                    </View>
                    <View style={[styles.border, { width: '37mm' }]}>
                        {items?.map((item, index) => (
                            <Text key={item.id} style={[styles.dotted, { borderTop: index ? 0.5 : 0 }]}>
                                {item.observation}
                            </Text>
                        ))}
                    </View>
                    <View style={[styles.border, { width: '04mm' }]}>
                        {items?.map((item, index) => (
                            <Text key={item.id} style={[styles.dotted, { borderTop: index ? 0.5 : 0 }]} />
                        ))}
                    </View>
                    <View style={[styles.border, { width: '09mm' }]}>
                        {items?.map((item, index) => (
                            <Text key={item.id} style={[styles.dotted, { borderTop: index ? 0.5 : 0 }]} />
                        ))}
                    </View>
                    <View style={[styles.border, { width: '20mm' }]}>
                        {items?.map((item, index) => (
                            <Text key={item.id} style={[styles.dotted, { borderTop: index ? 0.5 : 0 }]}>
                                {persons[item.personId] || ''}
                            </Text>
                        ))}
                    </View>
                    <View style={[styles.border, { width: '11mm' }]}>
                        {items?.map((item, index) => (
                            <Text key={item.id} style={[styles.dotted, { borderTop: index ? 0.5 : 0 }]}>
                                {formatDate(item.expiredAt)}
                            </Text>
                        ))}
                    </View>
                    <View style={[styles.border, { width: '08mm' }]}>
                        {items?.map((item, index) => (
                            <Text key={item.id} style={[styles.dotted, { borderTop: index ? 0.5 : 0 }]}>
                                {item.evaded ? 'X' : ''}
                            </Text>
                        ))}
                    </View>
                </View>
            </View>
        </View>
    );
};

PageTableQuestion.propTypes = {
    index: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    items: PropTypes.array,
    images: PropTypes.array,
    persons: PropTypes.object,
};

PageTableQuestion.defaultProps = {
    persons: {},
    images: undefined,
    items: [],
};

export default PageTableQuestion;
