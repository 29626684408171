import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const FlexRow = ({ children, ...props }) => {
    return (
        <Box display="flex" flexDirection="row" {...props}>
            {children}
        </Box>
    );
};

FlexRow.propTypes = {
    children: PropTypes.node.isRequired,
};
FlexRow.defaultProps = {};

export default FlexRow;
