import { StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
    footerContainer: {
        position: 'absolute',
        paddingRight: 20,
        paddingLeft: 20,
        paddingVertical: 10,
        fontSize: 9,
        bottom: 10,
        left: 0,
        right: 0,
    },
    footer: {
        fontSize: 9,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    fontSize: {
        fontSize: 9,
    },
});

const PageFooter = ({ translate }) => (
    <View
        style={styles.footerContainer}
        fixed
        render={({ pageNumber, totalPages }) => (
            <View style={styles.footer}>
                <Text style={styles.fontSize}>{moment().format('DD.MM.YYYY')}</Text>
                <Text style={styles.fontSize}>{`${translate('page')} ${pageNumber} di ${totalPages}`}</Text>
            </View>
        )}
    />
);

PageFooter.propTypes = {
    translate: PropTypes.func.isRequired
};

PageFooter.defaultProps = {};

export default PageFooter;
