import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AccessTime from '@material-ui/icons/AccessTime';
import Lens from '@material-ui/icons/Lens';
import { Map } from 'mdi-material-ui';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment'
import useTranslate from '../../../hooks/useTranslate';

export const getAppointmentBackground = (app, propertykey) => {
    let color = 'grey';
    const style = {};

    switch (app?.eventType) {
        case 'festivity':
            color = 'red';
            break;
        case 'events':
            color = 'green';
            break;
        case 'reports':
            color = 'blue';
            break;
        default:
            color = 'grey';
            break;
    }

    style[propertykey] = color;

    return style;
};

const useStyles = makeStyles(() => ({
    root: {
        height: 400,
    },
    textCenter: {
        textAlign: 'center',
    },
    isGrey: {
        color: 'grey',
    },
}));

const SiteDashboardCalendarDialog = ({ item}) => {
    const { title, description, place, startDate, endDate, eventType, color } = item;
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Box m={4} mt={3}>
            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                    <Box fontSize={16} fontWeight='bold'>
                        { translate(title)}
                    </Box>
                </Grid><Grid item xs={2} className={classes.textCenter}>
                    <Lens style={{ color }} />
                </Grid>
                <Grid item xs={9}>
                    {description || ` ${translate(`eventTypes.${eventType}`)}`}
                </Grid>

                {place && (
                    <>
                        <Grid item xs={2} className={classes.textCenter}>
                            <Map className={classes.isGrey} />
                        </Grid>
                        <Grid item xs={9}>
                            {place}
                        </Grid>
                    </>
                )}

                <Grid item xs={2} className={classes.textCenter}>
                    <AccessTime className={classes.isGrey} />
                </Grid>
                {eventType === 'event' && (
                    <Grid item xs={10} container>
                        <Grid item xs={2}>
                            {translate('date.from')}
                        </Grid>
                        <Grid item xs={10}>
                            <Box display="flex" justifyContent="space-beween">
                                {moment(startDate).format("HH:mm:ss")}
                            </Box>
                        </Grid>
                    </Grid>
                )}
                {eventType !== 'event' && (
                    <Grid item xs={10} container>
                        <Grid item xs={2}>
                            {translate('date.from')}
                        </Grid>
                        <Grid item xs={10}>
                            {moment(startDate).format("DD.MM.yyyy HH:mm:ss")}
                          
                        </Grid>
                        <Grid item xs={2}>
                            {translate('date.to')}
                        </Grid>
                        <Grid item xs={10}>
                            {moment(endDate).format("DD.MM.yyyy HH:mm:ss")}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

SiteDashboardCalendarDialog.propTypes = {
    item: PropTypes.object,
};
SiteDashboardCalendarDialog.defaultProps = {
    item: {},
};

export default SiteDashboardCalendarDialog;
