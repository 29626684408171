import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { patchImmer } from '../utils/common';

const useDataSelector = selector => {
    const selectedData = useSelector(selector);
    const [data, setData] = useState();

    useEffect(() => {
        if (selectedData) setData(selectedData);
    }, [selectedData]);

    const onChange = useCallback((path, item) => {
        setData(patchImmer(path, item));
    }, []);

    return [data, onChange];
};

export default useDataSelector;
