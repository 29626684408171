import { Box, CircularProgress, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { invokeOrReturn } from '../../utils/common';

const useStyles = makeStyles(theme => ({
    none: {
        padding: 0,
    },
    margin: {
        margin: -theme.spacing(1),
    },
}));

const EnhancedIcon = ({ item, onClick, invalidated, color, icon: Icon, disable, hide, size, padding }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState();
    return (
        <Box color={color}>
            {!invokeOrReturn(hide, item) && (
                <IconButton
                    color="inherit"
                    className={classes[padding]}
                    disabled={invalidated || disable?.(item)}
                    onClick={async () => {
                        if (onClick) {
                            const result = await onClick(item, setLoading, dispatch);
                            if (result?.type) dispatch(result);
                        }
                    }}
                >
                    {!loading && Icon && <Icon style={{ fontSize: size }} />}
                    {loading && <CircularProgress size={size} color="inherit" />}
                </IconButton>
            )}
        </Box>
    );
};

EnhancedIcon.propTypes = {
    color: PropTypes.string,
    disable: PropTypes.func,
    icon: PropTypes.object.isRequired,
    invalidated: PropTypes.bool,
    item: PropTypes.object,
    onClick: PropTypes.func,
    hide: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    size: PropTypes.number,
    padding: PropTypes.string,
};

EnhancedIcon.defaultProps = {
    disable: undefined,
    onClick: undefined,
    invalidated: false,
    item: undefined,
    color: undefined,
    hide: false,
    size: 24,
    padding: 'margin',
};

export default EnhancedIcon;
