
export const passwordValidation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&/.,\\]{8,}$/;

export const emailValidation = /^\S+@\S+\.\S+$/;

export const validationPerson = state => {
    if (!state) return false;

    return (
        passwordValidation.test(state.user?.password) &&
        emailValidation.test(state.user?.email)
    );
};