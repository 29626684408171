import { Avatar, Box, Divider } from '@material-ui/core';
import { PhotoCamera } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import SimpleCard from '../components/SimpleCard';
import EnhancedFileUpload from '../components/utilities/EnhancedFileUpload';
import useTranslate from '../hooks/useTranslate';
import { buildUrl } from '../utils/common';

const UserAvatar = ({ data, edit, width, onChange }) => {
    const translate = useTranslate();
    const { firstName = '', lastName = '', role = '', companyName = '', badgeId = '', url = null } = data || {};

    return (
        <SimpleCard>
            <Box width={width} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box>
                    <Avatar style={{ width: 100, height: 100 }} src={buildUrl(url)}>
                        {`${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`}
                    </Avatar>
                </Box>
                <Box width={1} py={1}>
                    <Divider />
                </Box>
                <Box fontWeight="bold" fontSize={20}>
                    {`${firstName} ${lastName}`}
                </Box>

                {companyName && (
                    <Box fontWeight="fontWeightLight" fontSize={16}>
                        {`${translate('companyName')}: ${companyName}`}
                    </Box>
                )}
                {badgeId && (
                    <Box fontWeight="fontWeightLight" fontSize={16}>
                        {`${translate('badge_id')}: ${badgeId}`}
                    </Box>
                )}
                {role && (
                    <Box fontWeight="fontWeightLight" fontSize={16}>
                        {`${translate('role')}: ${translate(role)}`}
                    </Box>
                )}
                {edit && (
                    <Box width={1} display="flex" flexDirection="row" justifyContent="center">
                        <EnhancedFileUpload
                            icon={PhotoCamera}
                            label="change_picture"
                            folder="public/profile-pics"
                            data={data}
                            onChange={onChange}
                            saveItem={(uploadedImageId, dataId, uploadedImageUrl) => ({
                                uploadedImageId,
                                uploadedImageUrl,
                            })}
                        />
                    </Box>
                )}
            </Box>
        </SimpleCard>
    );
};

UserAvatar.propTypes = {
    data: PropTypes.object,
    edit: PropTypes.bool,
    width: PropTypes.number,
    onChange: PropTypes.func,
};
UserAvatar.defaultProps = {
    data: {},
    edit: undefined,
    width: 1,
    onChange: undefined,
};

export default UserAvatar;
