import axios from 'axios';
import { Auth } from 'aws-amplify';
import { baseUrlApi } from '../../constants/originUrlConstants';
// import { requestLogout } from '../slices/sliceAuth';
// import { openDialog } from '../slices/sliceDialog';

export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;

// eslint-disable-next-line no-unused-vars
const interceptors = store => {
    axios.defaults.baseURL = `${window.location.origin}${baseUrlApi}`;

    axios.interceptors.request.use(async (request) => {
        // request.headers.Authorization = `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`;
        return request;
    });

    axios.interceptors.response.use(
        response => {
            return response.data;
        },
        async (error) => {
            const { url } = error?.config || {};
            if (url) {
                const { status } = error.response || {};
                if (status === UNAUTHORIZED || status === FORBIDDEN) {
                    // store.dispatch(
                    //     openDialog({ title: 'session_ended', confirmationLabel: 'bring_me_to_login', confirmationCallback: requestLogout })
                    // );
                    const jwtToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
                    axios.defaults.headers.common = {
                        Authorization: `Bearer ${jwtToken}`,
                    };
                    return axios.request(error?.config);
                }
            }
            return Promise.reject(error);
        }
    );
};

export default interceptors;
