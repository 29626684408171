import { Box, Table, TableBody } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useTranslate from '../../hooks/useTranslate';
import { selectIsLoading } from '../../store/slices/sliceFetching';
import { compareValues } from '../../utils/common';
import EnhancedRow from './EnhancedRow';
import EnhancedTableHead from './EnhancedTableHead';
import i from '../../ability/ability'

const EnhancedTable = ({
    keyComposition,
    columns,
    items,
    selectedCriteria,
    invalidatedCriteria,
    invalidatedColor,
    onItemClick,
    p,
    pt,
    pb,
    pr,
    pl,
    handleRequestSort,
    sortBy,
    sortDirection,
    padding,
    urlLoading,
    hasPointer,
    messageNoItems,
    height,
    isLoading,
}) => {
    const translate = useTranslate();
    const loading = useSelector(selectIsLoading(urlLoading));
    const [{ internalSortBy, internalSortDirection }, setSort] = useState({ internalSortBy: sortBy, internalSortDirection: sortDirection });
    const [internalItems, setInternalItems] = useState(items);

    useEffect(() => {
        if (!internalSortBy || handleRequestSort) {
            setInternalItems(items);
        } else {
            setInternalItems([...items.sort(compareValues({ sortBy: internalSortBy, sortDirection: internalSortDirection }))]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    useEffect(() => {
        if (internalSortBy && !handleRequestSort) {
            setInternalItems(prev => [...prev.sort(compareValues({ sortBy: internalSortBy, sortDirection: internalSortDirection }))]);
        }
    }, [handleRequestSort, internalSortBy, internalSortDirection]);

    useEffect(() => {
        if (sortBy) setSort({ internalSortBy: sortBy, internalSortDirection: sortDirection });
    }, [sortBy, sortDirection]);

    const sortLogic = (id, prevId, sort, prevSort) => {
        if (prevId && id === prevId) return sort === 'asc' ? 'desc' : 'asc';
        return prevSort;
    };

    const onRequestSort = (id, order) => {
        const newlSortDirection = sortLogic(id, internalSortBy, order, internalSortDirection);
        setSort({
            internalSortBy: id,
            internalSortDirection: newlSortDirection,
        });
        if (handleRequestSort) handleRequestSort({ sortBy: id, sortDirection: newlSortDirection });
    };

    return (
        <Box flex={1} p={p} pt={pt} pb={pb} pr={pr} pl={pl} style={{ height, overflowX: 'auto' }}>
            <Table padding={padding} size="medium" stickyHeader>
                <colgroup>
                    {columns.filter(({role}) => role?.(i) ?? true).map(({ id, width }) =>
                        width ? <col key={id} style={{ width, minWidth: width, maxWidth: width }} /> : <col key={id} style={{ width: 'auto' }} />
                    )}
                </colgroup>
                <EnhancedTableHead
                    order={internalSortDirection}
                    orderBy={internalSortBy}
                    columns={columns.filter(({role}) => role?.(i) ?? true)}
                    onRequestSort={onRequestSort}
                    items={items}
                    padding={padding}
                    isLoading={loading || isLoading}
                />

                <TableBody>
                    {internalItems.map(item => {
                        return (
                            <EnhancedRow
                                key={`row-${keyComposition(item)}`}
                                item={item}
                                selected={selectedCriteria(item)}
                                invalidated={invalidatedCriteria(item)}
                                invalidatedColor={invalidatedColor}
                                columns={columns.filter(({role}) => role?.(i) ?? true)}
                                onItemClick={onItemClick}
                                padding={padding}
                                hasPointer={hasPointer || !!onItemClick}
                            />
                        );
                    })}
                </TableBody>
            </Table>

            {!(loading || isLoading) && !items.length && (
                <Box
                    pt={0}
                    textAlign="bottom"
                    fontSize={16}
                    fontWeight="fontWeightMedium"
                    color="#999"
                    height={33}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    {translate(messageNoItems)}
                </Box>
            )}
        </Box>
    );
};

EnhancedTable.propTypes = {
    keyComposition: PropTypes.func,
    items: PropTypes.array,
    columns: PropTypes.array,
    onItemClick: PropTypes.func,
    selectedCriteria: PropTypes.func,
    invalidatedCriteria: PropTypes.func,
    invalidatedColor: PropTypes.string,
    p: PropTypes.number,
    pt: PropTypes.number,
    pb: PropTypes.number,
    pr: PropTypes.number,
    pl: PropTypes.number,
    padding: PropTypes.oneOf(['none', 'default']),
    height: PropTypes.any,
    handleRequestSort: PropTypes.func,
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
    urlLoading: PropTypes.string,
    isLoading: PropTypes.bool,
    hasPointer: PropTypes.bool,
    messageNoItems: PropTypes.string,
};

EnhancedTable.defaultProps = {
    items: [],
    columns: [],
    keyComposition: ({ id }) => id,
    onItemClick: undefined,
    selectedCriteria: () => false,
    invalidatedCriteria: () => false,
    invalidatedColor: 'text.disabled',
    p: 0,
    pt: 0,
    pb: 0,
    pr: 0,
    pl: 0,
    height: '100%',
    handleRequestSort: undefined,
    sortBy: '',
    sortDirection: 'asc',
    padding: 'none',
    urlLoading: undefined,
    isLoading: undefined,
    hasPointer: undefined,
    messageNoItems: 'the_search_did_not_return_any_results',
};

export default EnhancedTable;
