import { Add } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import usePagination from '../hooks/usePagination';
import SimpleCard from './SimpleCard';
import EnhancedTable from './table/EnhancedTable';
import EnhancedIcon from './utilities/EnhancedIcon';
import EnhancedPagination from './utilities/EnhancedPagination';

const DisplayTable = ({ name, title, icon, items, response, columns, messageNoItems, pageSize, onItemClick, onAdd, isLoading }) => {
    const { pageNumber = 1 } = usePagination(name);
    const dispatch = useDispatch();

    const { data = [], totalPages } = response || {
        data: items.slice((pageNumber - 1) * pageSize, (pageNumber - 1) * pageSize + pageSize),
        totalPages: Math.ceil(items.length / pageSize),
    };

    return (
        <SimpleCard
            icon={icon}
            title={title}
            p={0}
            ComponentBottom={<EnhancedPagination name={name} pages={totalPages} showRows={false} />}
            ComponentRight={onAdd && <EnhancedIcon icon={Add} onClick={() => dispatch(onAdd)} />}
        >
            <EnhancedTable
                items={data}
                columns={columns}
                padding="default"
                paddingX={2}
                messageNoItems={messageNoItems}
                isLoading={isLoading}
                onItemClick={onItemClick}
            />
        </SimpleCard>
    );
};

DisplayTable.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.object,
    items: PropTypes.array,
    response: PropTypes.object,
    columns: PropTypes.array,
    messageNoItems: PropTypes.string,
    pageSize: PropTypes.number,
    onItemClick: PropTypes.func,
    onAdd: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    isLoading: PropTypes.bool,
};
DisplayTable.defaultProps = {
    icon: undefined,
    items: [],
    response: undefined,
    columns: [],
    messageNoItems: undefined,
    pageSize: 3,
    onItemClick: undefined,
    onAdd: undefined,
    isLoading: undefined,
};

export default DisplayTable;
