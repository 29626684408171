import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import useTranslate from '../../hooks/useTranslate';

const useStyles = makeStyles(() => ({
    with_pointer: {
        cursor: 'pointer',
    },
    icons: {
        verticalAlign: 'middle',
    },
    header_wrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },
    header: {
        fontWeight: 'bold',
        color: 'lightGrey',
    },
}));

export const TooltipHeader = ({ appointmentData, commandButtonComponent: CommandButtonComponent, onHide }) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Grid container className={classes.header_wrapper}>
            <Grid item xs={2} />
            <Grid item xs={8}>
                <h1 className={classes.header}>{translate(appointmentData.title)}</h1>
            </Grid>
            <Grid item xs={2}>
                <CommandButtonComponent id="close" onClick={() => onHide()} />
            </Grid>
        </Grid>
    );
};

TooltipHeader.propTypes = {
    appointmentData: PropTypes.object,
    commandButtonComponent: PropTypes.func.isRequired,
    onHide: PropTypes.func,
};
TooltipHeader.defaultProps = {
    appointmentData: {},
    onHide: () => {},
};

export default TooltipHeader;
