import { Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
    icon: {
        padding: 0,
    },
}));

const EnhancedIconSelector = ({ item, onClick, disable, color, icon }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const Icon = icon(item);
    return (
        <Box color={color}>
            <IconButton
                color="inherit"
                className={classes.icon}
                disabled={disable(item)}
                onClick={() => {
                    if (onClick) {
                        const result = onClick(item);
                        if (result?.type) dispatch(result);
                    }
                }}
            >
                {Icon && <Icon />}
            </IconButton>
        </Box>
    );
};

EnhancedIconSelector.propTypes = {
    icon: PropTypes.func.isRequired,
    disable: PropTypes.func,
    item: PropTypes.object,
    onClick: PropTypes.func,
    color: PropTypes.string,
};

EnhancedIconSelector.defaultProps = {
    onClick: undefined,
    disable: () => undefined,
    item: undefined,
    color: undefined,
};

export default EnhancedIconSelector;
