import { Box } from '@material-ui/core';
import { PhotoCamera } from '@material-ui/icons';
import React from 'react';

const PhotoCameraCell = () => {
    return (
        <Box color="gray" width={1} height={1} display="flex" alignItems="center" justifyContent="flex-start">
            <PhotoCamera />
        </Box>
    );
};

PhotoCameraCell.propTypes = {};
PhotoCameraCell.defaultProps = {};

export default PhotoCameraCell;
