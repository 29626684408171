import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    open: false,
    title: undefined,
    data: undefined,
    message: undefined,
    confirmationLabel: undefined,
    confirmationCallback: undefined,
    confirmationEnable: undefined,
    negationLabel: undefined,
    negationCallback: undefined,
    cancelLabel: undefined,
    cancelCallback: undefined,
    content: undefined,
    fullScreen: false,
    fullWidth: undefined,
    maxWidth: 'lg'
};

const sliceDialog = createSlice({
    name: 'dialog',
    initialState,
    reducers: {
        openDialog: (state, { payload }) => ({
            open: true,
            title: payload.title,
            message: payload.message,
            confirmationLabel: payload.confirmationLabel,
            confirmationCallback: payload.confirmationCallback,
            confirmationEnable: payload.confirmationEnable,
            negationLabel: payload.negationLabel,
            negationCallback: payload.negationCallback,
            cancelLabel: payload.cancelLabel,
            cancelCallback: payload.cancelCallback,
            component: payload.component,
            componentProps: payload.componentProps,
            data: payload.data,
            fullScreen: payload.fullScreen,
            fullWidth: payload.fullWidth,
            maxWidth: payload.maxWidth,
        }),
        openConfirmationDialog: (state, { payload }) => ({
            open: true,
            title: payload.title,
            confirmationLabel: 'Conferma',
            confirmationCallback: payload.confirmationCallback,
            cancelLabel: 'Annulla',
        }),
        closeDialog: () => initialState,
        patchDialogData: (state, { payload }) => {
            state.data = { ...state.data, ...payload };
        },
    },
});

export default sliceDialog;

// ACTIONS

export const { openDialog, openConfirmationDialog, closeDialog, patchDialogData } = sliceDialog.actions;

// SELECTORS
export const selectDialog = state => state.dialog;
export const selectDialogOpen = state => state.dialog.open;
export const selectDialogTitle = state => state.dialog.title;
export const selectDialogMessage = state => state.dialog.message;
export const selectDialogComponent = state => state.dialog.component;
export const selectDialogComponentProps = state => state.dialog.componentProps;
export const selectDialogItem = state => state.dialog.item;
export const selectDialogData = state => state.dialog.data;

export const selectDialogCancelLabel = state => state.dialog.cancelLabel;
export const selectDialogCancelCallback = state => state.dialog.cancelCallback;
export const selectDialogNegationLabel = state => state.dialog.negationLabel;
export const selectDialogNegationCallback = state => state.dialog.negationCallback;
export const selectDialogConfirmationLabel = state => state.dialog.confirmationLabel;
export const selectDialogConfirmationCallback = state => state.dialog.confirmationCallback;
export const selectDialogConfirmationEnable = state => state.dialog.confirmationEnable || (() => true);
export const selectDialogFullScreen = state => state.dialog.fullScreen;
export const selectDialogFullWidth = state => state.dialog.fullWidth;
export const selectDialogMaxWidth = state => state.dialog.maxWidth;
