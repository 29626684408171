import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { buildUrl } from '../../utils/common';
import useTranslate from '../../hooks/useTranslate';

const EnhancedImage = ({ value, height, width, objectFit, label, alignItems, justifyContent }) => {
    const [src, setSrc] = useState('');
    const translate = useTranslate();

    useEffect(() => {
        setSrc(buildUrl(value));
        return () => {
            setSrc();
        };
    }, [value]);

    return (
        <Box width={1} height={height} display="flex" alignItems={alignItems} justifyContent={justifyContent}>
            {label && (
                <Box fontSize={16} pr={2}>
                    {translate(label)}
                </Box>
            )}
            <Box>
                <img alt={src} src={src} width={width} height={height} style={{ objectFit }} />
            </Box>
        </Box>
    );

    // TODO drag background image

    // const ref = useRef(null);
    // const mouseDown = useMouseListener('mousedown', ref);
    // const mouseMove = useMouseListener('mousemove', ref);
    // // const mouseUp = useMouseListener('mouseup', ref);
    // const mouseUp = useMouseListener('mouseup', ref);
    // const mouseenter = useMouseListener('mouseenter', ref);
    // const mouseout = useMouseListener('mouseout', ref);

    // const [start, setStart] = useState();
    // const [inside, setInside] = useState();
    // const [offset, setOffset] = useState([0, 0]);
    // const [x, setX] = useState(0);
    // const [y, setY] = useState(0);

    // useEffect(() => {
    //     setInside(true);
    // }, [mouseenter]);

    // useEffect(() => {
    //     setInside(false);
    // }, [mouseout]);

    // useEffect(() => {
    //     setStart(mouseDown);
    // }, [mouseDown]);

    // useEffect(() => {
    //     if (start && mouseMove && ref) {
    //         // const { clientWidth, clientHeight, offsetTop, offsetLeft } = ref.current;
    //         // console.log('file: EnhancedImage.js ~ line 39 ~ ref', ref);
    //         // console.log('file: EnhancedImage.js ~ line 42 ~ clientWidth, clientHeight', clientWidth, clientHeight);
    //         // console.log('file: EnhancedImage.js ~ line 42 ~ offsetTop, offsetLeft', offsetTop, offsetLeft);

    //         const moveX = offset.x + (start.x - mouseMove.x);
    //         const moveY = offset.y + (start.y - mouseMove.y);
    //         // console.log('file: EnhancedImage.js ~ line 46 ~ moveY', moveX, offsetHeight);
    //         // console.log('file: EnhancedImage.js ~ line 44 ~ moveX', moveY, offsetLeft);

    //         if (moveX >= 0) setX(moveX);
    //         if (moveY >= 0) setY(moveY);
    //     }
    // }, [start, mouseMove, offset]);

    // useEffect(() => {
    //     setStart();
    //     setOffset({ x, y });
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [mouseUp]);

    // return (
    //     <Box width={1} height={height} display="flex" alignItems="center" justifyContent="center">
    //         <div
    //             ref={ref}
    //             style={{
    //                 height: '300px',
    //                 width: '100%',
    //                 backgroundPosition: `${-x}px ${-y}px`,
    //                 backgroundImage: `url("${src}")`,
    //                 // backgroundSize: 'cover',
    //                 backgroundRepeat: 'no-repeat',
    //             }}
    //         />
    //     </Box>
    // );
};

EnhancedImage.propTypes = {
    height: PropTypes.number,
    value: PropTypes.string,
    objectFit: PropTypes.string,
    label: PropTypes.string,
    alignItems: PropTypes.string,
    justifyContent: PropTypes.string,
    width: PropTypes.string,
};
EnhancedImage.defaultProps = {
    value: '',
    height: undefined,
    width: '100%',
    objectFit: 'cover',
    label: undefined,
    alignItems: 'center',
    justifyContent: 'center',
};

export default EnhancedImage;
