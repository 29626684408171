/* eslint-disable no-unused-vars */
import { flatMap } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Route, Switch } from 'react-router-dom';
import BuildComponent from './BuildComponent';
import staticRoutes from '../../configuration/routesProfile';

const buildRoutesLog = (root, routes) => {
    return flatMap(routes, ({ children, tabs, path = '' }) => {
        let paths = [];
        if (children) paths = [...paths, ...buildRoutesLog(path ? `${root}/${path}` : root, children)];
        else if (tabs) paths = [...paths, ...buildRoutesLog(`${root}/${path}`, tabs)];
        else paths.push({ children, tabs, path: `${root}/${path}` });
        return paths;
    });
};

const buildRoutes = (root, routes, rootTabs) => {
    return flatMap(routes, route => {
        const { path = '', key, children, tabs, tabsMenu, ...props } = route;
        if (children) return buildRoutes(path ? `${root}/${path}` : root, children, tabs);
        if (tabs) return buildRoutes(`${root}/${path}`, tabs, tabs);
        return (
            <Route
                exact
                key={key}
                path={`${root}/${path}`}
                render={routerProps => <BuildComponent tabs={rootTabs} root={root} {...props} routerProps={routerProps} />}
            />
        );
    });
};

const EnhancedSwitch = ({ root, routes }) => {
    // console.log(buildRoutesLog(root, routes));
    return (
        <Switch>
            {buildRoutes(root, routes)}
            {buildRoutes(root, staticRoutes)}
        </Switch>
    );
};

EnhancedSwitch.propTypes = {
    root: PropTypes.string.isRequired,
    routes: PropTypes.array.isRequired,
};

EnhancedSwitch.defaultProps = {};

export default memo(EnhancedSwitch);
