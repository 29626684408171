import { createSlice, createAction } from '@reduxjs/toolkit';

const sliceAuth = createSlice({
    name: 'authentication',
    initialState: {
        menu: undefined,
    },
    reducers: {
        receiveLogin: (state, { payload }) => {
            state.username = payload.username;
            state.familyName = payload.attributes.family_name;
            state.givenName = payload.attributes.given_name;
            state.userId = Number(payload.attributes['custom:id']);
        },
        receiveLogout: (state, { payload }) => {
            state.menu = payload;
        },
    },
});

export default sliceAuth;

// ACTIONS
export const requestLogin = createAction('REQUEST_LOGIN');
export const requestLogout = createAction('REQUEST_LOGOUT');
export const { receiveLogin, receiveLogout } = sliceAuth.actions;

// SELECTORS
export const selectUserId = state => state.authentication?.userId;
