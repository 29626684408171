import { Box, Button, Menu, MenuItem, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import useTranslate from '../../hooks/useTranslate';
import { invoke } from '../../utils/common';
import i from '../../ability/ability';

const useStyles = makeStyles(() => ({
    none: {
        padding: 0,
    },
}));

const EnhancedMenu = ({ item, label, icon: Icon, menus, disabled, hide }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    if (hide?.(item) || menus?.filter(({role = () => true}) => role(i)).length === 0) return null;

    return (
        <>
            {label ? (
                <Button variant="text" color="inherit" disabled={disabled} onClick={handleClick}>
                    {Icon && <Icon />}
                    {label && (
                        <Box pl={1} fontSize={12} fontWeight="bold" color="inherit">
                            {translate(label)}
                        </Box>
                    )}
                </Button>
            ) : (
                <IconButton color="inherit" className={classes.none} onClick={handleClick}>
                    <Icon style={{ fontSize: 24 }} />
                </IconButton>
            )}

            <Menu id={id} anchorEl={anchorEl} open={open} onClose={handleClose}>
                {menus
                    .filter(({role = () => true}) => role(i))
                    .map(({ label, onClick }) => (
                        <MenuItem
                            key={`menu-${label}`}
                            onClick={() => {
                                handleClose();
                                invoke(() => onClick(item, dispatch), dispatch);
                            }}
                        >
                            {translate(label)}
                        </MenuItem>
                    ))}
            </Menu>
        </>
    );
};

EnhancedMenu.propTypes = {
    disabled: PropTypes.bool,
    hide: PropTypes.func,
    icon: PropTypes.object,
    item: PropTypes.object,
    label: PropTypes.string,
    menus: PropTypes.array.isRequired,
};

EnhancedMenu.defaultProps = {
    item: undefined,
    icon: undefined,
    label: undefined,
    hide: undefined,
    disabled: false,
};

export default EnhancedMenu;
