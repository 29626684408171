import { Add } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import useFetch from '../../hooks/useFetch';
import usePagination from '../../hooks/usePagination';
import useSorting from '../../hooks/useSorting';
import { setOrder } from '../../store/slices/sliceFiltering';
import SimpleCard from '../SimpleCard';
import EnhancedTable from '../table/EnhancedTable';
import EnhancedIcon from '../utilities/EnhancedIcon';
import EnhancedPagination from '../utilities/EnhancedPagination';

const TableViewCard = ({
    icon,
    title,
    onAdd,
    messageNoItems,
    columns,
    proxy,
    params,
    handleResponse,
    hasPointer,
    onItemClick,
    keyComposition,
    defaultValue,
}) => {
    const dispatch = useDispatch();
    const { pageNumber, pageSize } = usePagination(proxy.name);
    const { sortBy, sortDirection } = useSorting(proxy.name);

    const [response, isLoading] = useFetch(
        proxy,
        { pageNumber: pageNumber - 1, pageSize, orderBy: sortBy, orderDirection: sortDirection, ...params },
        [pageNumber, pageSize, sortBy, sortDirection, ...Object.keys(params).reduce((acc, key) => [...acc, params[key]], [])],
        [...Object.keys(params).reduce((acc, key) => [...acc, params[key]], [])],
        defaultValue,
        handleResponse
    );

    return (
        <SimpleCard
            icon={icon}
            title={title}
            p={0}
            ComponentBottom={<EnhancedPagination name={proxy.name} pages={response.totalPages} />}
            ComponentRight={onAdd && <EnhancedIcon icon={Add} onClick={() => dispatch(onAdd)} />}
        >
            <EnhancedTable
                items={response.data}
                columns={columns}
                isLoading={isLoading}
                onItemClick={item => {
                    if (onItemClick) onItemClick(item, dispatch);
                }}
                keyComposition={keyComposition}
                sortBy={sortBy}
                sortDirection={sortDirection}
                hasPointer={hasPointer || !!onItemClick}
                handleRequestSort={order => {
                    dispatch(setOrder({ view: proxy.name, order }));
                }}
                messageNoItems={messageNoItems}
                padding="default"
            />
        </SimpleCard>
    );
};

TableViewCard.propTypes = {
    columns: PropTypes.array,
    defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    handleResponse: PropTypes.func,
    hasPointer: PropTypes.bool,
    keyComposition: PropTypes.func,
    icon: PropTypes.any,
    messageNoItems: PropTypes.any,
    onAdd: PropTypes.any,
    title: PropTypes.string,
    onItemClick: PropTypes.func,
    params: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    proxy: PropTypes.object,
};
TableViewCard.defaultProps = {
    columns: [],
    proxy: {},
    params: () => {},
    hasPointer: false,
    onItemClick: undefined,
    handleResponse: undefined,
    keyComposition: undefined,
    defaultValue: {},
    icon: undefined,
    messageNoItems: undefined,
    onAdd: undefined,
    title: undefined,
};

export default TableViewCard;
