import { Box, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import useTranslate from '../../hooks/useTranslate';

const useStyles = makeStyles(theme => ({
    card: {
        // overflow: 'visible',
    },
    text: {
        marginBottom: theme.spacing(),
    },
    icon: {
        padding: theme.spacing(0),
    },
    noPadding: {
        padding: 0,
    },
    none: {
        display: 'none',
    },
}));

const EnhancedCard = ({
    title,
    children,
    p,
    pb,
    fullHeight,
    minWidth,
    reference,
    flex,
    hide,
    bgcolor,
    onKeyPress,
    onEnterPress,
    ComponentTopLeft,
    ComponentTopRight,
    ComponentBottom,
    ComponentBottomLeft,
    ComponentBottomRight,
    ...props
}) => {
    const classes = useStyles();
    const translate = useTranslate();

    const handleKeyPress = useCallback(
        event => {
            if (onEnterPress && event.key === 'Enter') onEnterPress();
        },
        [onEnterPress]
    );

    return (
        <Box ref={reference} onKeyPress={onKeyPress || handleKeyPress} flex={flex} {...props} p={0} minWidth={minWidth}>
            <Card className={classes.card}>
                <Box p={p} pb={pb} bgcolor={bgcolor} borderRadius={20} flexGrow={1} height={1}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" flex={1}>
                        <Box display="flex" flexGrow={1} justifyContent="space-between">
                            {title && (
                                <Typography variant="subtitle2" color="primary" gutterBottom={false}>
                                    {translate(title)}
                                </Typography>
                            )}
                            {ComponentTopLeft}
                        </Box>
                        {ComponentTopRight}
                    </Box>

                    {children}

                    {(ComponentBottom || ComponentBottomLeft || ComponentBottomRight) && (
                        <Box
                            display="flex"
                            flexDirection={ComponentBottomLeft ? 'row' : 'row-reverse'}
                            justifyContent={ComponentBottom ? 'center' : 'space-between'}
                            pt={1}
                        >
                            {ComponentBottomLeft && (
                                <Box display="flex" alignItems="flex-end">
                                    {ComponentBottomLeft}
                                </Box>
                            )}
                            {ComponentBottom && (
                                <Box display="flex" alignItems="flex-end">
                                    {ComponentBottom}
                                </Box>
                            )}
                            {ComponentBottomRight && (
                                <Box display="flex" alignItems="flex-end">
                                    {ComponentBottomRight}
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </Card>
        </Box>
    );
};

EnhancedCard.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    p: PropTypes.number,
    pb: PropTypes.number,
    fullHeight: PropTypes.bool,
    reference: PropTypes.func,
    flex: PropTypes.number,
    minWidth: PropTypes.number,
    onKeyPress: PropTypes.func,
    onEnterPress: PropTypes.func,
    hide: PropTypes.bool,
    bgcolor: PropTypes.string,
    ComponentTopLeft: PropTypes.object,
    ComponentTopRight: PropTypes.object,
    ComponentBottom: PropTypes.object,
    ComponentBottomLeft: PropTypes.object,
    ComponentBottomRight: PropTypes.object,
};

EnhancedCard.defaultProps = {
    title: undefined,
    children: undefined,
    fullHeight: false,
    p: 2,
    pb: 0,
    reference: undefined,
    flex: 1,
    minWidth: 0,
    onKeyPress: undefined,
    onEnterPress: undefined,
    hide: false,
    bgcolor: undefined,
    ComponentBottom: undefined,
    ComponentTopLeft: undefined,
    ComponentTopRight: undefined,
    ComponentBottomLeft: undefined,
    ComponentBottomRight: undefined,
};

export default EnhancedCard;
