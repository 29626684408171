/* eslint-disable indent */
import { MoreHoriz } from '@material-ui/icons';
import FileIconCell from '../../../components/cells/FileIconCell';
import EditDialog from '../../../components/dialog/EditDialog';
import EnhancedMenu from '../../../components/utilities/EnhancedMenu';
import { renameFileForm } from '../../../configuration/forms';
import { openDialog } from '../../../store/slices/sliceDialog';
import { refetch } from '../../../store/slices/sliceFetching';
import { setSuccessNotification } from '../../../store/slices/sliceNotification';
import { bucketDeleteDirectory, bucketDeleteObject, bucketGetObject, bucketGetObjectUrl, bucketRenameObject } from '../../../utils/bucketUtils';
import { formatBytes, formatDateAndHours, formatFilename } from '../../../utils/formats';

export const filesColumns = bucketSite => [
    {
        id: 'icon',
        label: '',
        sortable: false,
        component: FileIconCell,
    },
    { id: 'Key', label: 'name', format: formatFilename },
    {
        id: 'type',
        label: 'type',
    },
    { id: 'LastModified', label: 'last_modified', format: formatDateAndHours },
    { id: 'Size', label: 'size', align: 'right', format: formatBytes },
    {
        id: 'actions',
        label: 'actions',
        align: 'right',
        sortable: false,
        component: EnhancedMenu,
        componentProps: ({ type }) => ({
            icon: MoreHoriz,
            hide: ({ type }) => type === 'root',
            menus:
                type === 'folder'
                    ? [
                          // {
                          //     label: 'rename',
                          //     onClick: async ({ Prefix }, dispatch) => {
                          //         console.log('file: columns.js ~ line 576 ~ Prefix', Prefix);
                          //         const split = Prefix.split('/');
                          //         const folderPath = split.slice(0, -2).join('/');
                          //         const [empty, oldFolderName] = split.reverse();
                          //         return openDialog({
                          //             component: EditDialog,
                          //             componentProps: {
                          //                 form: nameFolderForm,
                          //                 title: 'folder_name',
                          //             },
                          //             data: { folderName: oldFolderName },
                          //             cancelLabel: 'cancel',
                          //             confirmationLabel: 'rename',
                          //             confirmationEnable: (data) => formValidator(nameFolderForm, data),
                          //             confirmationCallback: async ({ folderName }) => {
                          //                 await bucketRenameObject({ newKey: `${folderPath}/${folderName}/`, oldKey: Prefix });
                          //                 dispatch(refetch('files'));
                          //                 return setSuccessNotification('folder_renamed_successfully');
                          //             },
                          //         });
                          //     },
                          // },
                          {
                              label: 'delete',
                              role: i => i.can('update', 'files'),
                              onClick: async ({ Prefix }, dispatch) =>
                                  openDialog({
                                      title: 'confirm_delete',
                                      message: 'are_you_sure_you_want_to_delete_this_item',
                                      cancelLabel: 'cancel',
                                      confirmationLabel: 'delete',
                                      confirmationCallback: async () => {
                                          await bucketDeleteDirectory(Prefix, bucketSite);
                                          dispatch(refetch('files'));
                                          return setSuccessNotification('folder_deleted_successfully');
                                      },
                                  }),
                          },
                      ]
                    : [
                          {
                              label: 'rename',
                              role: i => i.can('update', 'files'),
                              onClick: async ({ Key }, dispatch) => {
                                  const split = Key.split('/');

                                  const filePath = split.slice(0, -1).join('/');
                                  const [fileNameExt] = split.reverse();
                                  const [fileName, fileExt] = fileNameExt.split('.');

                                  return openDialog({
                                      component: EditDialog,
                                      componentProps: {
                                          form: renameFileForm,
                                          title: 'rename_file',
                                      },
                                      data: { fileName },
                                      cancelLabel: 'cancel',
                                      confirmationLabel: 'rename',
                                      confirmationEnable: ({ fileName }) => !!fileName && !fileName.includes('/') && !fileName.includes('.'),
                                      confirmationCallback: async ({ fileName }) => {
                                          await bucketRenameObject({
                                              newKey: `${filePath}/${fileName.replace('/', '')}.${fileExt}`,
                                              oldKey: Key,
                                              bucketSite,
                                          });
                                          dispatch(refetch('files'));
                                          return setSuccessNotification('file_renamed_successfully');
                                      },
                                  });
                              },
                          },
                          {
                              label: 'delete',
                              role: i => i.can('update', 'files'),
                              onClick: async ({ Key }, dispatch) =>
                                  openDialog({
                                      title: 'confirm_delete',
                                      message: 'are_you_sure_you_want_to_delete_this_item',
                                      cancelLabel: 'cancel',
                                      confirmationLabel: 'delete',
                                      confirmationCallback: async () => {
                                          await bucketDeleteObject({ Key, bucketSite });
                                          dispatch(refetch('files'));
                                          return setSuccessNotification('file_deleted_successfully');
                                      },
                                  }),
                          },
                          {
                              label: 'download',
                              onClick: async ({ Key }) => {
                                  const { Body, ContentType } = await bucketGetObject({ Key });
                                  const file = new Blob([Body], { type: ContentType });
                                  const url = URL.createObjectURL(file);
                                  const link = document.createElement('a');
                                  link.href = url;
                                  link.download = formatFilename(Key);
                                  document.body.appendChild(link);
                                  link.click();
                                  document.body.removeChild(link);
                              },
                          },
                          {
                              label: 'share_copy',
                              onClick: async ({ Key }, dispatch) => {
                                  const link = await bucketGetObjectUrl({ Key, bucketSite });
                                  navigator.clipboard.writeText(link);
                                  dispatch(setSuccessNotification('element_copied_in_the_clipboard'));
                              },
                          },
                          {
                              label: 'share_email',
                              onClick: async ({ Key }) => {
                                  const link = await bucketGetObjectUrl({ Key, bucketSite });
                                  window.open(`mailto:?subject=${Key}&body=${link}`);
                              },
                          },
                      ],
        }),
    },
];
