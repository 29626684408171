import { Image, StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import PropTypes from 'prop-types';
import { logoSelector } from '../../../utils/common';

const styles = StyleSheet.create({
    pageHeader: {
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        fontSize: 9,
        height: '110px',
    },
});

const PageHeader = ({ companyName }) => {
    return (
        <View style={styles.pageHeader}>
            <View
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    paddingBottom: 10 
                }}
            >
                <Image src={logoSelector(companyName)} style={{ width: '200px'}} />
            </View>
        </View>
    );
};

PageHeader.propTypes = {
    companyName: PropTypes.string.isRequired,
};

export default PageHeader;
