import { Box, Card, Divider, Link, makeStyles, CircularProgress } from '@material-ui/core';
import * as React from 'react';
import PropTypes from 'prop-types';

import useTranslate from '../hooks/useTranslate';

const useStyles = makeStyles(theme => ({
    card: ({ maxWidth, height }) => ({
        minHeight: height,
        borderRadius: 10,
        maxWidth,
        flex: '1',
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
    }),

    divider: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
        height: 3,
    },
}));

const SimpleCardContent = ({ title, icon: Icon, isLoading, noColor }) => {
    const translate = useTranslate();
    return (
        <Box display="flex" flexDirection="row" justifyContent="space-between" py={1} px={2} width={1}>
            <Box color="textPrimary" fontWeight={400} fontSize={24}>
                {translate(title)}
            </Box>

            {Icon && (
                <Box color={noColor ? '' : "primary.lightest"} display="flex" flexDirection="column" justifyContent="center">
                    {isLoading ? <CircularProgress size={26} /> : <Icon style={{ fontSize: 26 }} />}
                </Box>
            )}
        </Box>
    );
};

SimpleCardContent.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    isLoading: PropTypes.bool,
    noColor: PropTypes.bool,
    title: PropTypes.string.isRequired,
};
SimpleCardContent.defaultProps = {
    icon: undefined,
    isLoading: undefined,
    noColor: undefined,
};

const SimpleCard = ({ icon, title, to, children, image, p, height, maxWidth, ComponentLeft, ComponentBottom, ComponentRight, isLoading, noColor }) => {
    const classes = useStyles({ maxWidth, height });

    return (
        <Card className={classes.card}>
            {title && (
                <>
                    {to ? (
                        <Link to={to}>
                            <SimpleCardContent icon={icon} title={title} isLoading={isLoading} noColor={noColor}/>
                        </Link>
                    ) : (
                        <SimpleCardContent icon={icon} title={title} isLoading={isLoading} noColor={noColor}/>
                    )}

                    {image}
                    {children && <Divider className={classes.divider} />}
                </>
            )}

            <Box p={p}>{children}</Box>
            {(ComponentLeft || ComponentBottom || ComponentRight) && (
                <Box mb={-1}>
                    <Divider />
                    <Box width={1} display="flex" justifyContent="space-between" py={1} px={1.5}>
                        <Box width={24}>{ComponentLeft && ComponentLeft}</Box>
                        <Box flexGrow={1} display="flex" justifyContent="center">
                            {ComponentBottom && ComponentBottom}
                        </Box>
                        <Box width={24}>{ComponentRight && ComponentRight}</Box>
                    </Box>
                </Box>
            )}
        </Card>
    );
};

SimpleCard.propTypes = {
    title: PropTypes.string,
    height: PropTypes.number,
    icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    to: PropTypes.string,
    children: PropTypes.node,
    image: PropTypes.object,
    maxWidth: PropTypes.number,
    p: PropTypes.number,
    ComponentLeft: PropTypes.object,
    ComponentBottom: PropTypes.object,
    ComponentRight: PropTypes.object,
    isLoading: PropTypes.bool,
    noColor: PropTypes.bool,
};
SimpleCard.defaultProps = {
    title: undefined,
    height: 56,
    icon: undefined,
    to: undefined,
    children: undefined,
    image: undefined,
    maxWidth: undefined,
    p: 2,
    ComponentLeft: undefined,
    ComponentBottom: undefined,
    ComponentRight: undefined,
    isLoading: undefined,
    noColor: undefined,
};

export default SimpleCard;
