import { Grid } from '@material-ui/core';
import { Event } from '@material-ui/icons';
import { Stamper } from 'mdi-material-ui';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Actions from '../../components/Actions';
import EnhancedCalendar from '../../components/calendar/EnhancedCalendar';
import SimpleCard from '../../components/SimpleCard';
import EnhancedTable from '../../components/table/EnhancedTable';
import { summaryStampingLogColumns } from '../../configuration/columns';
import { handleResponsePersonCalendar } from '../../configuration/handlers';
import useFetch from '../../hooks/useFetch';
import { getHrPersonalCalendar } from '../../store/proxies/tigestimProxies';
import { selectItem } from '../../store/slices/sliceNavigation';
import { selectUser } from '../../store/slices/sliceSession';
import { formatDurationMsToHHmmExtended, formatHour, formatServerDate } from '../../utils/formats';
import useTranslate from '../../hooks/useTranslate';

const calculateDifference = (key, end, start) => {
    if (!end || !start) return 0;
    return moment(`${key} ${end}:00`, 'DD.MM.YYYY HH:mm:ss').valueOf() - moment(`${key} ${start}:00`, 'DD.MM.YYYY HH:mm:ss').valueOf();
};

function getWeekDaysByWeekNumber(weekNumber)
{
    const start = moment().isoWeek(weekNumber || 1).startOf("week"); 
    const end = moment().isoWeek(weekNumber || 1).endOf("week"); 

    return `${start.format('DD.MM.YYYY')} - ${end.format('DD.MM.YYYY')}`;
}

const UserCalendarView = ({ id, actions }) => {
    const user = useSelector(id ? selectItem : selectUser);
    const [data, setData] = useState([]);
    const [date, setDate] = useState(moment());
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [summaryStampingLog, setSummaryStampingLog] = useState([]);
    const translate = useTranslate()

    useEffect(() => {
        if (date) {
            setStartDate(formatServerDate(date.clone().startOf('month').subtract(7, 'days'), 'YYYY-MM-DD'));
            setEndDate(formatServerDate(date.clone().endOf('month').add(7, 'days'), 'YYYY-MM-DD'));
        }
    }, [date]);

    const [response, isLoading] = useFetch(getHrPersonalCalendar, { id: id || user.id, startDate, endDate }, [id || user.id, startDate, endDate], [id || user.id, startDate, endDate], {});

    useEffect(() => {
        if (response.colors) setData(handleResponsePersonCalendar(response));
    }, [response]);

    useEffect(() => {
        if (response.stampingLogs) {
            const { stampingLogs } = response
            const dayMap = stampingLogs.reduce((accumulator, item) => {
                const timestamp = moment(item.timestamp);
                const key = timestamp.format('DD.MM.YYYY');
                return { ...accumulator, [key]: [...(accumulator[key] ?? []), formatHour(item.timestamp)] };
            }, {});

            const weekMap = Object.keys(dayMap).reduce((accumulator, day) => {
                const [first = '', second = '', third = '', fourth = ''] = dayMap[day] || [];
                const duration1 = calculateDifference(day, second, first);
                const duration2 = calculateDifference(day, fourth, third);
                const key = moment(day, 'DD.MM.YYYY').week();
                return { ...accumulator, [key]: [...(accumulator[key] ?? []), duration1 + duration2] };
            }, {});

            let totalMonth = 0;

            const result = Object.keys(weekMap).map(week => {
                const totalWeek  = weekMap[week].reduce((total, entry) => total + entry, 0);
                totalMonth += totalWeek;
                return {
                    week,
                    dateRange: getWeekDaysByWeekNumber(week),
                    total: formatDurationMsToHHmmExtended(totalWeek)
                }
            });
            if (totalMonth)
                result.push({week: '', total: `${translate('total_month')}: ${formatDurationMsToHHmmExtended(totalMonth)}`})
            setSummaryStampingLog(result)
        }
        
    }, [date, response, translate]);

    return (
   
        <Grid container spacing={2}>
            <Grid item xs={12} >
                <Actions actions={actions} data={{ date, id: id || user.id, user, ...response }} pb={0}/>
            </Grid>

            <Grid item xs={12}>
                <EnhancedCalendar
                    icon={Event}
                    isLoading={isLoading}
                    title="personal_calendar"
                    selectedDay={date.toDate()}
                    schedulerData={data}
                    onChange={value => {
                        setDate(moment(value));
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <SimpleCard
                    icon={Stamper}
                    title="week_summary_stamping_log"
                    p={0}
                    pb={2}
                >
                    <EnhancedTable
                        items={summaryStampingLog}
                        columns={summaryStampingLogColumns}
                        isLoading={isLoading}
                        keyComposition={({week}) => week}
                        padding="default"
                    />
                </SimpleCard>
            </Grid>
        </Grid>
    );
};

UserCalendarView.propTypes = {
    actions: PropTypes.array,
    id: PropTypes.number,
};

UserCalendarView.defaultProps = {
    id: 0,
    actions: [],
};

export default UserCalendarView;
