const en = {
    // General
    id: 'Id',
    name: `Name`,
    identity: `Identity`,
    user_profile: `User profile`,
    username: `Username`,
    password: `Password`,
    confirm_password: `Confirm password`,
    new_password: `New password`,
    old_password: `Old password`,
    change_password: `Change password`,
    login: `Login`,
    logout: `Log out`,
    sign_in: `Login`,
    remember_me: `Remember me`,
    forgotten_password: `Forgotten password`,
    project_manager: `Project Manager`,
    admin: `Admin`,
    action: `Action`,
    rows: `Rows`,
    male: `Male`,
    neutral: `Neutral`,
    female: `Female`,
    loading: `Loading....`,
    go_back: `Go back`,
    remove: `Remove`,
    add: `Add`,
    enter: `Enter`,
    exit: `Exit`,
    // Menu
    construction_sites: `Construction Sites`,
    construction_site: `Construction site`,
    construction_sites_list: 'Construction sites list',
    dashboard: `Dashboard`,
    journal: `Journal`,
    calendar: `Calendar`,
    file: `File`,
    files: `Files`,
    contacts: `Contacts`,
    hr: `HR`,
    HR: `HR`,
    profile: `Profile`,
    edit: `Modifica`,
    view: 'Visualizza',
    new: `New`,
    leaves: `Leaves`,
    expenses: `Expenses`,
    checklist_requests: 'Checklist Requests',
    // Action
    'menu.collapse': `Close Menu`,
    'menu.employees': `Employees List`,
    'menu.employees.beta': `Employees List (Beta)`,
    grid_display: `Grid display`,
    list_display: `List display`,
    new_site: `Create new site`,
    new_user: `Create new user`,
    add_favorite: `Add to favorites`,
    remove_favorite: `Remove from favorites`,
    notifications_settings: `Notifications and settings`,
    mandatory_notification: 'Mandatory compilation with notifications',
    // edit: `Edit`,
    ///  Sites
    quickcomId: `Quick id`,
    title: `Title`,
    address: `Address`,
    city: `City`,
    zip: `Zip`,
    country: `Country`,
    createdAt: `Created at`,
    favorite: `Favorite`,
    // Tab
    list: `List`,
    lists: `Lists`,
    quick: `Quick data`,
    // journal: `Journal`,
    // calendar: `Calendar`,
    // files: `Documents`,
    // contacts: `Contacts`,
    all_leave_requests: `All leave requests`,
    all_expense_requests: `All expense requests`,
    all_festivities: `All festivities`,
    add_festivity: `Add festivity`,
    new_festivity: `New festivity`,
    edit_festivity: `Edit festivity`,
    festivity_saved_successfully: `Festivity saved successfully`,
    festivity_deleted_successfully: `Festivity deleted_successfully`,
    festivity_not_deleted: `Edit festivity`,
    add_event: `Add event`,
    new_event: `New event`,
    edit_event: `Edit event`,
    event_created_successfully: `Event created successfully`,
    event_saved_successfully: `Event saved successfully`,
    event_deleted_successfully: `Event deleted_successfully`,
    event_not_deleted: `Edit not deleted`,
    all_working_hours: `All working hours`,
    // admin: `Admin`,
    tool_manager: `Tool manager`,
    all_schedules: `All schedules`,
    all_checklists: `All checklists`,
    permission: `Permission`,
    authorization: `Authorization`,
    file_log: `File log`,
    notification: `Notification`,
    ///  Users list
    avatar: `Avatar`,
    companyName: `Azienda`,
    lastName: `Surname`,
    firstName: `Name`,
    phoneNumberList: `Phone number`,
    email: `Email`,
    role: `Role`,
    // Leaves list
    user: `User`,
    users: `Users`,
    type: `Type`,
    requestDate: `Request date`,
    startAt: `Start At`,
    startDate: `Start At`,
    endAt: `End At`,
    endDate: `End At`,
    duration: `Duration`,
    status: `Status`,
    days: `Days`,
    day: `Day`,
    hours: `Hours`,
    and: `and`,
    minutes: `Minutes`,
    hour: `Hour`,
    h: 'h',
    approve: `Approve`,
    approved: `Approved`,
    reject: `Reject`,
    rejected: `Rejected`,
    pending: `Pending`,

    vacation: `Vacation`,
    holiday: `Holiday`,
    military: `Military`,
    sickness: `Sickness`,
    maternity: `Maternity`,
    medical: `Medical`,
    professionalAccident: `Professional Accident`,
    nonProfessionalAccident: `Non Professional Accident`,
    training: `Training`,
    weather: `Weather`,
    others: `Others`,

    // Expenses
    new_expense: `New expense`,
    edit_expense: `Edit expense`,
    amount: `Amount`,
    date: `Date`,
    reason: `Reason`,
    place: `Place`,
    // Working hours
    winter: `Winter`,
    summer: `Summer`,
    // Profile
    edit_user: `Edit user`,
    // profile: `Profile`,
    identify: `Identity`,
    personal_calendar: `Personal calendar`,
    week_summary_stamping_log: `Week summary stamping logs`,
    dateRange: `Date range`,
    // expenses: `Expenses`,
    leaves_and_holidays: `Leaves and holidays`,
    upload_file: `Choose file`,
    file_name: `File name`,
    add_file: `Add file`,
    rename_file: `Rename file`,
    attach_new_file: 'Attach new file',
    attach_file: 'Attach file',
    load_file: 'Load file',
    show_file: 'Show file',
    file_renamed_successfully: 'File renamed successfully',
    file_deleted_successfully: 'File deleted successfully',

    // Profile Edit
    discard_changes: `Discard changes`,
    deactivate_user: `Deactivade user`,
    activate_user: `Activate user`,

    personal_contacts: `Personal contacts`,
    personal_address: `Personal address`,
    personal_e_mail: `Personal e-mail`,
    personal_phone_number: `Personal phone number`,
    birth_date: `Birth date`,
    work_contacts: `Work contacts`,
    work_email: `Work email`,
    work_phone_number: `Work phone number`,
    change_picture: `Change picture`,

    appearance: `Personalization`,
    others_settings: `Others settings`,
    notification_management: `Notification management`,
    stamping_hours: `Stamping hours`,
    notifications: `Notifications`,
    'switches.dark_mode': `Switch to dark mode`,
    'switches.light_mode': `Switch to light mode`,

    // others: `Others`,
    // User Profile HR
    hr_data: `HR data`,
    badge_id: `Badge ID`,
    civil_status: `Civil status`,
    children_number: `Children number`,
    mother_language: `Mother language`,
    company: `Company`,
    associated_company: `Associated company`,
    hired_at: `Hired at`,
    terminated_at: `Terminated at`,
    //
    basic_data: `Personal data`,
    birthday: `Birthday`,
    gender: `Gender`,
    account: `Account`,
    personal_email: `Personal email`,
    office_email: `Office email`,
    personal_mobile_number: `Personal mobile number`,
    personal_home_number: `Personal home number`,
    work_mobile_number: `Work mobile number`,
    work_office_number: `Work office number`,
    work_c_3_x_number: `Work 3CX number`,
    //
    working_hours: `Working hours`,
    work_percentage: `Work percentage`,
    holidays_count_days: `Holidays count`,
    break_time_h: `Break time`,
    // day: `day`,
    winter_start: `Winter start`,
    winter_end: `Winter end`,
    summer_start: `Summer start`,
    summer_end: `Summer end`,
    //
    legal_documents: `Legal documents`,
    edit_legal_document: `Edit legal document`,
    new_legal_document: `New legal document`,
    expireat: `Expiry Date`,
    numberDoc: `Description`,
    //
    signed_documents: `Signed documents`,
    document_not_present: `There are no documents`,
    press_plus_to_add_a_document: `Press + to add a document`,
    at_least_one_document: `There must be at least one document *`,
    edit_signed_document: `Edit signed document`,
    new_signed_document: `New signed document`,

    the_search_did_not_return_any_results: `The search did not return any results`,

    // User Leaves HR
    // leaves: `Leaves`,
    new_leave: `New leave`,
    new_daily_hours: `Add hours`,
    edit_leave: `Edit leave`,
    leaves_request: `Leaves request`,
    my_leaves_request: `My leaves request`,
    request_reimbursement_expenses: `Expense reimbursement request`,
    export_my_expense: `Export my expense report`,
    holidays_allowance: `Holidays allowance`,
    total_pending: `Pending leaves`,
    remaining: `Remaining`,
    taken: `Taken`,
    pending_requests: `Pending requests`,
    requests: `Requests`,
    // sickness: `Sickness`,
    // military: `Military`,
    paternity: `Paternity`,
    medical_examination: `Medical examination`,
    professional_accident: `Professional accident`,
    non_professional_accident: `Non professional accident`,
    training_course: `Training course`,
    weather_conditions: `Weather conditions`,
    other: `Other`,

    from: `From`,
    to: `To`,
    // type: `Type`,
    description: `Description`,
    // duration: `Duration`,
    // status: `Status`,
    // User Expenses HR

    expenses_request: `Expenses request`,
    my_expenses_request: `My expenses request`,
    submit_new_leave: `Submit new leave`,
    submit_new_daily_hours: `submit daily hours`,
    submit_new_expense: `Submit new expense`,

    currency: `Currency`,
    changedChf: `Changed`,
    quantity: `Quantity`,
    isEquipment: `Is equipment`,
    whoApproveId: `Who approved`,
    chf: `CHF`,
    usd: `USD`,
    // legalMaritalStatus
    married: `Married`,
    widowed: `Widowed`,
    separated: `Separated`,
    divorced: `Divorced`,
    single: `Single`,
    // Languages
    german: `German`,
    french: `French`,
    italian: `Italian`,
    english: `English`,
    // Companies
    tigestim: `Tigestim`,
    metalsky: `Metalsky`,
    sitaf: `Sitaf`,
    metalpiega_color: `Metal Piega & Color`,
    edemar: `Edemar`,
    tmwood: `Tm Wood`,
    electrogestim: `Electrogestim`,
    // Messages
    user_saved_successfully: `User saved successfully`,
    uploaded_successfully: `Upload successful`,
    upload_failed: `Upload failed`,
    user_already_exists: `User already exists`,
    passwords_do_not_match: `Passwords do not match`,
    password_saved_successfully: `Password saved successfully`,
    wrong_password: `Wrong password`,
    leave_saved_successfully: `Leave saved successfully`,
    daily_hours_saved_successfully: `Hours added successfully`,
    leave_delete_successfully: `Leave deleted successfully`,
    expense_saved_successfully: `Expense saved successfully`,

    // Calendar
    'date.from': `From:`,
    'date.to': `To:`,
    'filters.all': `View All`,
    'filters.festivity': `Festivities`,
    'filters.events': `Events`,
    'filters.personRequests': `Leave`,
    'filters.stampingLogs': `Stamping logs`,
    'eventTypes.festivity': `Festivity`,
    'eventTypes.events': `Event`,
    'eventTypes.reports': `Report`,
    'eventTypes.personRequests': `Leave`,
    'eventTypes.stampingLogs': `Stamping logs`,
    // Validation
    validationMessagePassword: `Contain at least: 8 alphanumeric characters,  1 number, 1 lowercase character (a-z), 1 uppercase character (A-Z). The password can contain only these special characters ! @ # $ % ^ &`,
    validationMessageEmail: `Invalid email`,

    // Journals
    load_more_journal_posts: `Loading...`,
    Journal: `Journal`,
    edit_post: `Edit post`,
    view_post: `View post`,
    new_journal: `New journal entry`,
    create_journal: `Create journal entry`,
    save_journal: `Save`,
    delete_journal: `Delete`,
    see_all_comments: `See all comments`,
    see_all: `See all`,
    like: `Like`,
    add_comment: `Add comment`,
    share: `Share`,
    add_new_journal_entry: `Add new journal entry`,
    drag_and_drop_an_image_here_or_click: `"Drag and drop an image here or click"`,
    edit_journal_entry: `Edit journal entry`,
    message: `Message`,
    thumbnail: `Thumbnail`,
    upload_picture: `Upload picture`,
    iva: `IVA`,
    es_3_15: `Es. 3.15%`,
    journal_saved_successfully: `Journal hours saved successfully`,
    journal_deleted_successfully: `Journal deleted successfully`,

    // Contact
    edit_contacts: `Edit contacts`,
    edit_contact: `Edit contact`,
    saved_contact_successfully: `Contact saved successfully`,
    removed_contact_successfully: `Contact removed successfully`,
    telephoneNumber: `Telephone number`,

    // Right
    all_rights_reserved: `All rights reserved`,
    support: `Support`,
    contact: `Contact`,
    version: `Version`,

    // Tools
    category: `Category`,
    tool_name: `Tool name`,
    value: `Value`,
    assignment: `Assignment`,
    // status: `Status`,
    period: `Period`,
    add_new_tool: `Add new tool`,
    edit_tool: `Edit tool`,
    delete_tool: `Delete tool`,
    // quantity: `Quantity`,
    create_new_category: `Create new category`,
    unit_cost: `Unit cost`,
    warranty: `Warranty `,
    documentation: `Documentation`,
    manual: `Manual`,
    purchase_receipt: `Purchase receipt`,
    attach_new_file_or_picture: `Attach new file or picture`,
    attach_new_picture: `Attach new picture`,
    delete_attachments: `Delete attachments`,
    save_changes: `Save changes`,
    total_quantity: `Total quantity`,
    used: `Used`,
    available: `Available`,
    person: `Person`,

    tools: `Tools`, // 1
    vehicle: `Vehicle`, // 2
    health: `Health`, // 3
    safety_equipment: `Safety Equipment`, // 4
    clothing: `Clothing`, // 5

    tool_saved_successfully: `Tool saved successfully`,
    tool_deleted_successfully: `Tool deleted successfully`,
    category_name: `Category name`,
    categories: 'Categories',
    add_tools_category: 'Add tools category',
    add_new_category: 'Add new category',
    edit_category: 'Edit category',
    delete_category: 'Delete category',
    category_deleted_successfully: `Category deleted successfully`,
    category_not_deleted: `Category not deleted, it is still associated with some instruments`,
    tool_not_deleted: `Tool not deleted, it is still associated with some person`,
    category_saved_successfully: `Category saved successfully`,

    // Permissions
    edit_permission: 'Edit permissions',
    capabilities: `Capabilities`,
    list_all_employee: 'List all employee',
    no_stampinglog: 'No stamping log',
    no_leave: 'No leave',
    show_hr_module: 'Show HR module',
    stampings_log_management: 'Stampings log management',
    edit_inventory: 'Edit inventory',
    list_file: 'List file',
    list_in_hr_module: 'List in HR module',
    show_sitaf: 'Show Sitaf Construction Sites',
    show_metalsky: 'Show Metalsky Construction Sites',
    show_metalpiega: 'Show MetalPiega&Color Construction Sites',
    show_edemar: 'Show Edemar Construction Sites',
    show_tigestim: 'Show Tigestim Construction Sites',
    show_tmwood: 'Show TM Wood Construction Sites',
    show_electrogestim : 'Show Electrogestim Construction Sites',


    employee: 'Employee',
    client: 'Client',
    associate: 'Associate',
    customer: 'Customer',
    documents: 'Documents',

    create: `Create`,
    save: 'Save',
    delete: 'Delete',
    rename: 'Rename',

    clear_assignment: 'Clear assignment',
    support_request: 'Support request',
    object: 'Object',
    // message: 'Message',
    send: 'Send',
    cancel: 'Cancel',

    feedback_sended_successfully: 'Feedback sended successfully',

    session_ended: 'Session ended',
    bring_me_to_login: 'Bring me to login',
    images: 'Images',
    images_not_present: `Image not present`,
    confirm_delete: `Confirm delete`,
    are_you_sure_you_want_to_delete_this_item: `Are you sure you want to delete this item?`,
    edit_not_allow: 'Edit not allow',
    edit_allow_only_on_pending: 'Edit allow only on pending request',
    attach_receipt: 'Attach receipt *',

    // Checklist
    checklists: 'Checklists',
    checklist: 'Checklist',
    edit_checklist: 'Edit checklist',
    view_checklist: 'View checklist',
    new_checklist: 'New checklist',
    add_checklist: 'Add checklist',
    add_checklist_template: 'Add checklist template',
    templates: 'Templates',
    template: 'Template',
    checklist_saved_successfully: 'Checklist saved successfully',
    checklist_deleted_successfully: 'Checklist deleted successfully',
    checklist_not_deleted: 'Checklist not deleted',
    checklist_not_saved: 'Checklist not saved',
    assign_to_site: 'Assign to site',
    add_new_question: 'Add new question *',
    questions: 'Questions',
    add_checkbox: 'Add checkbox',
    add_btn_attach: 'Add upload button',
    remove_question: 'Remove question',
    add_image: 'Add image',
    new_from_template: 'New from template',
    assigned_construction_sites: 'Assigned construction sites',
    assigned_construction_site: 'Assigned construction site',
    times: 'Times',
    not_assigned_yet: 'Not assigned yet',
    save_as_template: 'Save as template',
    edit_checklist_template: 'Edit checklist template',
    new_checklist_template: 'New checklist template',
    checklist_template_not_deleted: 'Checklist template not deleted',
    checklist_template_not_saved: 'Checklist template not saved',
    checklist_template_saved_successfully: 'Checklist template saved successfully',
    checklist_template_deleted_successfully: 'Checklist template deleted successfully',
    observation: 'Observation',
    who: 'Who',
    expiredAt: 'Expired at',
    readyAt: 'Ready at',

    // Schedules
    workers: 'Workers',
    report: 'Report',
    notes: 'Notes',
    activities: 'Activities',
    schedules: 'Schedules',
    schedule: 'Schedule',
    edit_schedule: 'Edit schedule',
    new_schedule: 'New schedule',
    add_schedule: 'Add schedule',
    add_schedule_template: 'Add schedule template',
    // categories: 'Categories',
    schedule_saved_successfully: 'Schedule saved successfully',
    schedule_deleted_successfully: 'Schedule deleted successfully',
    schedule_not_deleted: 'Schedule not deleted',
    schedule_not_saved: 'Schedule not saved',
    task: 'Task',
    tasks: 'Tasks',
    add_task: 'Add task',
    remove_task: 'Remove task',
    edit_schedule_category: 'Edit schedule category',
    new_schedule_category: 'New schedule category',
    schedule_category_not_deleted: 'Schedule category not deleted',
    schedule_category_not_saved: 'Schedule category not saved',
    schedule_category_saved_successfully: 'Schedule category saved successfully',
    schedule_category_deleted_successfully: 'Schedule category deleted successfully',
    subcontractors: `Subcontractors`,
    planimeters: `Planimeters`,

    // Quick data
    subject: 'Subject',
    balance: 'Invoices uncovered',
    totalCost: 'Total cost',
    total: 'Total',
    offer: 'OFFER',
    totalCostDifferences: 'OFFER minus cost',
    laborCost: 'Labor cost',
    cashedAdvances: 'Cashed advances',
    requiredAdvances: 'Required advances',
    supplierCost: 'Supplier cost',
    supplierDebit: 'Supplier debit',
    goodsCost: 'Material Cost',
    othersCost: 'Others Cost',

    // Roles
    ADMIN: 'Admin',
    // HR: 'HR',
    EMPLOYEE: 'Employee',
    CLIENT: 'Client',
    ASSOCIATE: 'Associate',
    INTERIM: 'Interim',
    interim: 'Interim',

    // Files
    download: 'Download',
    folders_not_present: 'Folders not present',
    folders: 'Folders',
    last_modified: 'Last modified',
    size: 'Size',

    // Countries
    switzerland: 'Switzerland',
    italy: 'Italy',

    search: 'Search...',

    all_users: 'Add users',
    apply_to: 'Apply to',
    user_list: 'Users',
    new_working_hours: `Add working hours`,
    save_working_hours: `Save working hours`,
    create_working_hours: `Create working hours`,
    edit_working_hours: 'Edit working hours',
    working_hours_not_saved: 'Working hours not saved',
    working_hours_saved_successfully: `Working hours saved successfully`,

    no_options: 'No options available',
    contacts_not_present: 'Contacts not present',
    journal_not_present: 'Journal not present',

    // day: 'Day',
    today: 'Today',
    month: 'Month',
    week: 'Week',
    all_day: 'All day',
    stampingLog: `Stamping log`,

    total_holidays_accrued: `Total holidays accrued`,
    total_holidays_enjoyed: `Total holidays enjoyed`,
    holiday_balance: `Holiday balance to:`,

    personal_mobile: `Private mobile`,
    work_mobile: `Work mobile`,
    show_receipt: `Show receipt`,
    show_document: `Show Document`,

    project_function: `Function`,
    worker: `Worker`,
    technician: `Technician`,
    foreman: `Foreman`,
    favorites: `Favorites`,
    all: `All`,
    alle: `All`,
    show_image: `Show image`,
    edit_site: `Edit construction site`,
    attach_site_image: `Attach construction site image`,
    site_saved_successfully: `Site saved successfully`,

    noFilesAvailable: `There are no files for this site`,
    folder_created_successfully: `Folder created successfully`,
    folder_deleted_successfully: `Folder deleted successfully`,
    folder_renamed_successfully: `Folder renamed successfully`,
    folder_name: `Folder name`,
    new_folder: `New folder`,
    shared_folder: `Shared folder`,

    show_more_comments: `Show more comments...`,
    element_copied_in_the_clipboard: `Element copied in the clipboard`,

    meters: 'Meters',
    pieces: 'Pieces',
    kg: 'Kg',
    liters: 'Liters',
    unit: 'Unit',

    page: 'Page',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',

    export_stamping_logs: 'Export stamping logs',
    new_stamping_log: 'New stamping log',
    edit_stamping_log: 'Edit stamping log',
    timestamp: 'Timestamp',
    stamping_log_created_successfully: 'Stamping log created successfully',
    stamping_log_saved_successfully: 'Stamping log saved successfully',

    share_copy: 'Copy link in the clipboard',
    share_email: 'Email link',
    share_download_pdf: 'Download pdf',

    settings: 'Settings',

    non_reversible_operation: 'Non-reversible operation',

    quicksalId: 'Quicksal Id',

    functionCompany: 'Function',
    job: 'Job',
    department: 'Department',
    workPercentage: 'Work percentage',

    total_month: 'Total monthly',

    signUrl: 'Sign:',
    view_pdf: 'View PDF',
    journal_new_user: 'New user',
    journal_auto_description: `User <USER> has just been added to the site 🥳`
};

export default en;
