import { createSlice } from '@reduxjs/toolkit';
import { requestLogout } from './sliceAuth';

const initialState = {
    routes: [],
    title: undefined,
    item: undefined,
};

const sliceNavigation = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setItem: (state, { payload }) => {
            state.item = payload;
        },
        setRoutes: (state, { payload }) => {
            state.routes = payload;
        },
    },
    extraReducers: {
        [requestLogout]: () => initialState,
    },
});

export default sliceNavigation;

// ACTIONS
export const { setRoutes, setItem } = sliceNavigation.actions;

// SELECTORS

export const selectItem = state => state.navigation.item;
export const selectItemDefault = state => state.navigation.item || {};
