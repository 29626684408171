import { useSelector } from 'react-redux';
import { selectPageNumber, selectPageSize, selectPageSizeOption } from '../store/slices/sliceFiltering';

const usePagination = name => {
    const pageSizeOption = useSelector(selectPageSizeOption);
    const pageNumber = useSelector(selectPageNumber(name));
    const pageSize = useSelector(selectPageSize(name));

    return { pageNumber, pageSize, pageSizeOption };
};

export default usePagination;
