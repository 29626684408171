import { makeStyles, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AccessTime from '@material-ui/icons/AccessTime';
import Lens from '@material-ui/icons/Lens';
import { Map } from 'mdi-material-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import useTranslate from '../../hooks/useTranslate';
import history from '../routings/history';
import { selectUserId } from '../../store/slices/sliceSession';

export const getAppointmentBackground = (app, propertykey) => {
    let color = 'grey';
    const style = {};

    switch (app?.eventType) {
        case 'festivity':
            color = 'red';
            break;
        case 'events':
            color = 'green';
            break;
        case 'reports':
            color = 'blue';
            break;
        default:
            color = 'grey';
            break;
    }

    style[propertykey] = color;

    return style;
};

const useStyles = makeStyles(() => ({
    textCenter: {
        textAlign: 'center',
    },
    isGrey: {
        color: 'grey',
    },
}));

const TooltipContent = ({ appointmentData, formatDate }) => {
    const { eventId, description, place, startDate, endDate, eventType, color } = appointmentData;
    const classes = useStyles();
    const translate = useTranslate();
    const userId = useSelector(selectUserId)

    return (
        <Box height={200} pb={2} display='flex' flexDirection="column">
            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={2} className={classes.textCenter}>
                    <Lens style={{ color }} />
                </Grid>
                <Grid item xs={9}>
                    {description || ` ${translate(`eventTypes.${eventType}`)}`}
                </Grid>

                {place && (
                    <>
                        <Grid item xs={2} className={classes.textCenter}>
                            <Map className={classes.isGrey} />
                        </Grid>
                        <Grid item xs={9}>
                            {place}
                        </Grid>
                    </>
                )}

                <Grid item xs={2} className={classes.textCenter}>
                    <AccessTime className={classes.isGrey} />
                </Grid>
                {eventType === 'stampingLogs' && (
                    <Grid item xs={10} container>
                        <Grid item xs={2}>
                            {translate('date.from')}
                        </Grid>
                        <Grid item xs={10}>
                            <Box display="flex" justifyContent="space-beween">
                                {formatDate(startDate, {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    second: 'numeric',
                                })}
                            </Box>
                        </Grid>
                    </Grid>
                )}
                {eventType !== 'stampingLogs' && (
                    <Grid item xs={10} container>
                        <Grid item xs={2}>
                            {translate('date.from')}
                        </Grid>
                        <Grid item xs={10}>
                            {formatDate(startDate, {
                                day: 'numeric',
                                weekday: 'long',
                                month: 'long',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                second: 'numeric',
                            })}
                        </Grid>
                        <Grid item xs={2}>
                            {translate('date.to')}
                        </Grid>
                        <Grid item xs={10}>
                            {formatDate(endDate, {
                                day: 'numeric',
                                weekday: 'long',
                                month: 'long',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                second: 'numeric',
                            })}
                        </Grid>
                    </Grid>
                )}
               
            </Grid>
            <Box flex={1} display="flex" alignItems="flex-end" justifyContent="center">
                {eventType === 'personRequests' && <Button onClick={()=> history.push(`/app/hr/users/${userId}/leaves/${eventId}/edit`)}>{translate('edit')}</Button>}
            </Box>
        </Box>
    );
};

TooltipContent.propTypes = {
    appointmentData: PropTypes.shape({
        color: PropTypes.any,
        description: PropTypes.any,
        endDate: PropTypes.any,
        eventType: PropTypes.string,
        eventId: PropTypes.number,
        item: PropTypes.any,
        place: PropTypes.any,
        startDate: PropTypes.any,
    }),
    formatDate: PropTypes.func.isRequired,
};
TooltipContent.defaultProps = {
    appointmentData: {},
};

export default TooltipContent;
