import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../components/Actions';
import Filters from '../../../components/Filters';
import Search from '../../../components/Search';
import EnhancedTable from '../../../components/table/EnhancedTable';
import EnhancedCard from '../../../components/utilities/EnhancedCard';
import EnhancedPagination from '../../../components/utilities/EnhancedPagination';
import { checklistRequestsColumns } from '../../../configuration/columns';
import useFetch from '../../../hooks/useFetch';
import usePagination from '../../../hooks/usePagination';
import useSorting from '../../../hooks/useSorting';
import { getChecklistRequests, getChecklistsSite } from '../../../store/proxies/tigestimProxies';
import { selectUserId } from '../../../store/slices/sliceAuth';
import { setOrder } from '../../../store/slices/sliceFiltering';
import { invokeOrReturn } from '../../../utils/common';


const SiteCheckListsTableView = ({ actions, columns, filters, proxy, params, handleResponse, onItemClick, keyComposition, id, defaultValue }) => {

    const dispatch = useDispatch();
    const { pageNumber, pageSize } = usePagination(proxy.name);
    const { sortBy, sortDirection } = useSorting(proxy.name);
    const { pageNumber: pageNumberFav, pageSize: pageSizeFav } = usePagination(getChecklistRequests.name);
    const { sortBy: sortByFav, sortDirection: sortDirectionFav } = useSorting(getChecklistRequests.name);

    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState({});
    const userId = useSelector(selectUserId);

    const [response, isLoading] = useFetch(
        proxy,
        {
            pageNumber: pageNumber - 1,
            pageSize,
            orderBy: sortBy,
            orderDirection: sortDirection,
            ...invokeOrReturn(params, id),
            ...filter,
        },
        [pageNumber, pageSize, sortBy, sortDirection, filter],
        [],
        defaultValue,
        handleResponse
    );
  
    const [responseCheckListRequest, isLoadingCheckListRequest] = useFetch(
        getChecklistRequests,
        {
            pageNumber: pageNumberFav - 1,
            pageSize: pageSizeFav,
            orderBy: sortByFav,
            orderDirection: sortDirectionFav,
            ...invokeOrReturn(params, id),
            filter: 'forUser',
        },
        [pageNumberFav, pageSizeFav, sortByFav, sortDirectionFav, userId],
        [userId],
        defaultValue,
        handleResponse
    );


    return (
        <Box height={1} width={1}>
            <Box display="flex" justifyContent="space-between">
                <Box pb={2}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Search onChange={setSearch} value={search} />
                        </Grid>
                        {filters && (
                            <Grid item>
                                <Filters filter={filter} onChange={setFilter} filters={filters} />
                            </Grid>
                        )}
                    </Grid>
                </Box>
                {actions && <Actions actions={actions} />}
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <EnhancedCard
                        pb={1}
                        title="checklists"
                        ComponentBottom={<EnhancedPagination name={getChecklistsSite.name} pages={response.totalPages} />}
                    >
                        <EnhancedTable
                            items={response.data}
                            columns={columns}
                            isLoading={isLoading}
                            onItemClick={onItemClick && (item => onItemClick(item, dispatch))}
                            keyComposition={({ favouriteSiteId }) => favouriteSiteId}
                            sortBy={sortByFav}
                            sortDirection={sortDirectionFav}
                            handleRequestSort={order => {
                                dispatch(setOrder({ view: getChecklistsSite.name, order }));
                            }}
                        />
                    </EnhancedCard>
                </Grid> 
                <Grid item xs={12}>
                    <EnhancedCard
                        pb={1}
                        title="checklist_requests"
                        ComponentBottom={<EnhancedPagination name={getChecklistRequests.name} pages={response.totalPages} />}
                    >
                        <EnhancedTable
                            items={responseCheckListRequest.data}
                            columns={checklistRequestsColumns}
                            isLoading={isLoadingCheckListRequest}
                            onItemClick={onItemClick && (item => onItemClick(item, dispatch))}
                            keyComposition={keyComposition}
                            sortBy={sortByFav}
                            sortDirection={sortDirectionFav}
                            handleRequestSort={order => {
                                dispatch(setOrder({ view: getChecklistRequests.name, order }));
                            }}
                        />
                    </EnhancedCard>
                </Grid> 

                
            </Grid>
        </Box>
    )
}


SiteCheckListsTableView.propTypes = {
    id: PropTypes.number,
    actions: PropTypes.array,
    columns: PropTypes.array,
    filters: PropTypes.array,
    proxy: PropTypes.object,
    params: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onItemClick: PropTypes.func,
    handleResponse: PropTypes.func,
    keyComposition: PropTypes.func,
    defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
SiteCheckListsTableView.defaultProps = {
    id: undefined,
    actions: [],
    columns: [],
    filters: undefined,
    proxy: {},
    params: () => {},
    onItemClick: undefined,
    handleResponse: undefined,
    keyComposition: undefined,
    defaultValue: {},
};

export default SiteCheckListsTableView;