/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Grid, makeStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MAX_WIDTH_CONTENT } from '../../constants/dimensionConstants';
import useTranslate from '../../hooks/useTranslate';
import { createFeedback } from '../../store/proxies/tigestimProxies';
import { openDialog } from '../../store/slices/sliceDialog';
import { setErrorNotification, setSuccessNotification } from '../../store/slices/sliceNotification';
import { selectUsername } from '../../store/slices/sliceSession';
import EditDialog from '../dialog/EditDialog';

const useStyles = makeStyles(() => ({
    whiteSpace: {
        whiteSpace: 'nowrap',
    },
}));

const Footer = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const preventDefault = event => event.preventDefault();
    const username = useSelector(selectUsername);

    return (
        <Box width={1} color="text.secondary">
            <Box width={1} flex={1} pt={1} px={2} display="flex" justifyContent="center">
                <Box
                    width={1}
                    maxWidth={MAX_WIDTH_CONTENT}
                    display="flex"
                    justifyContent="space-between"
                    fontSize={12}
                    borderTop={2}
                    borderColor="primary.main"
                >
                    <Box className={classes.whiteSpace}>{`Copyright © 2017-${moment().year()} Tigestim SA. ${translate('all_rights_reserved')}`}</Box>

                    <Box className={classes.whiteSpace} p={0} m={0} color="text.secondary">
                        <Grid container spacing={1}>
                            <Grid item>
                                <Link
                                    to="#"
                                    onClick={event => {
                                        preventDefault(event);
                                        dispatch(
                                            openDialog({
                                                data: {},
                                                component: EditDialog,
                                                componentProps: {
                                                    form: [
                                                        { id: 'title', label: 'title', required: true },
                                                        { id: 'message', label: 'message', required: true, multiline: true, rows: 5 },
                                                    ],
                                                    title: 'support_request',
                                                },
                                                cancelLabel: 'cancel',
                                                confirmationLabel: 'send',
                                                confirmationEnable: ({ title, message }) => title && message,
                                                confirmationCallback: async value => {
                                                    try {
                                                        const data = {
                                                            title: `${value.title} - Tigestim webapp ${process.env.REACT_APP_VERSION}`,
                                                            message: `${value.message}\n\n${username}`,
                                                        };
                                                        await createFeedback.call(data);
                                                        return setSuccessNotification('feedback_sended_successfully');
                                                    } catch (e) {
                                                        return setErrorNotification(`${e?.response?.status} : ${e?.response?.statusText}`);
                                                    }
                                                },
                                            })
                                        );
                                    }}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <Box color="text.secondary">{`${translate('support')}`}</Box>
                                </Link>
                            </Grid>
                            <Grid item>|</Grid>
                            <Grid item>
                                <a href="mailto:ricky.artioli@tigestim.com" style={{ textDecoration: 'none' }}>
                                    <Box color="text.secondary">{`${translate('contact')}`}</Box>
                                </a>
                            </Grid>
                            <Grid item>|</Grid>
                            <Grid item>
                                <Box className={classes.whiteSpace}>{`${translate('version')} ${process.env.REACT_APP_VERSION}`}</Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
