import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import it from '../i18n/it';

const localeContext = React.createContext({});

const getBrowserLanguage = () => {
    const [language] = (navigator.language || navigator.userLanguage)?.split?.('-') || [];
    return language;
};

const { Provider } = localeContext;

export const LocaleProvider = ({ dictionaries, children }) => {
    const [locale, setLocale] = useState(localStorage.getItem('locale') || getBrowserLanguage() || 'it');

    useEffect(() => {
        localStorage.setItem('locale', locale);
    }, [locale]);

    return <Provider value={{ locale, setLocale, dictionary: dictionaries[locale] || it }}>{children}</Provider>;
};

LocaleProvider.propTypes = {
    dictionaries: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
};
LocaleProvider.defaultProps = {};

export default localeContext;
