import { Event } from '@material-ui/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import EnhancedCalendar from '../../components/calendar/EnhancedCalendar';
import useFetch from '../../hooks/useFetch';
import { getSiteCalendar } from '../../store/proxies/tigestimProxies';
import { formatServerDate } from '../../utils/formats';
import Actions from '../../components/Actions';

const handleResponseSiteCalendar = response => {
    const colors = response.colors.reduce((accumulator, { eventType, color }) => ({ ...accumulator, [eventType]: color }), {});
    const events = (eventType, items) =>
        items
            .map(({ id, title, description = '', startDate, endDate, ...rest }) => ({
                ...rest,
                id: `${id}_${eventType}`,
                startDate: new Date(startDate),
                endDate: endDate ? new Date(endDate) : new Date(startDate),
                eventType,
                title,
                description,
                color: colors[9], // [10] is similat to  pink, [9] (blue) seems better
            }))
            .filter(({ startDate, endDate }) => startDate <= endDate);

    const festivity = (eventType, items) =>
        items
            .map(({ id, title, description = '', startDate, endDate, place, ...rest }) => ({
                ...rest,
                id: `${id}_${eventType}`,
                startDate: new Date(startDate),
                endDate: endDate ? new Date(endDate) : new Date(startDate),
                eventType,
                title,
                description,
                place: description !== 'Festività Svizzera' && place !== description ? place : '',
                color: colors[12],
            }))
            .filter(({ startDate, endDate }) => startDate <= endDate);

    const map = { events, festivity };

    return Object.keys(response ?? {})
        .filter(key => map[key])
        .reduce((accu, key) => [...accu, ...map[key](key, response[key])], []);
};

const SiteCalendar = ({ id, actions }) => {
    const [date, setDate] = useState(moment());
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    useEffect(() => {
        if (date) {
            setStartDate(formatServerDate(date.clone().startOf('month').subtract(7, 'days'), 'YYYY-MM-DD'));
            setEndDate(formatServerDate(date.clone().endOf('month').add(7, 'days'), 'YYYY-MM-DD'));
        }
    }, [date]);

    const [response, isLoading] = useFetch(
        getSiteCalendar,
        { id, startDate, endDate },
        [id, startDate, endDate],
        [id, startDate, endDate],
        [],
        handleResponseSiteCalendar
    );

    return (
        <>
            <Actions actions={actions} data={{ date, id, ...response }} />
            <EnhancedCalendar
                icon={Event}
                isLoading={isLoading}
                title="calendar"
                selectedDay={date.toDate()}
                schedulerData={response}
                onChange={value => {
                    setDate(moment(value));
                }}
            />
        </>
    );
};

SiteCalendar.propTypes = {
    actions: PropTypes.array,
    id: PropTypes.number,
};
SiteCalendar.defaultProps = {
    id: 0,
    actions: [],
};

export default SiteCalendar;
