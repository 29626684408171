/* eslint-disable react/jsx-props-no-spreading */
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { footerHeight, headerHeight, headerTabHeight, MAX_WIDTH_CONTENT } from '../../constants/dimensionConstants';
import { selectUser } from '../../store/slices/sliceSession';
import { invokeOrReturn, isNotDef } from '../../utils/common';
import Footer from '../dashboard/Footer';
import EnhancedTabsHeader from '../tabs/EnhancedTabsHeader';
import EnhancedFabs from '../utilities/EnhancedFabs';
import LoadingFullScreen from '../utilities/LoadingFullScreen';

const getLast = pathname => {
    const [last] = pathname.split('/').reverse();
    return last;
};

const BuildComponent = ({ component: Component, components, fabs, showTabs, root, tabs, componentProps, routerProps }) => {
    const { params } = routerProps.match;
    const user = useSelector(selectUser);
    const [ids] = useState(Object.keys(params).reduce((acc, key) => ({ ...acc, [key]: Number(params[key]) }), {}));

    if (isNotDef(user.id)) return <LoadingFullScreen />;

    return (
        <Box width={1} height={1} flex={1}>
            {showTabs && tabs && (
                <EnhancedTabsHeader
                    tabs={tabs}
                    tabPath={getLast(routerProps.location.pathname)}
                    setPath={pathName => {
                        routerProps.history.push(pathName || root);
                    }}
                />
            )}

            <Box
                display="flex"
                justifyContent="center"
                className='wrapperBuildComponent'
                p={2}
                style={{
                    overflow: 'auto',
                    height: `calc(100vh - ${showTabs && tabs ? headerTabHeight : headerHeight}px - ${footerHeight}px)`,
                }}
            >
                {components.map(({ key, component: ComponentStacked, componentStackedProps }) => (
                    <ComponentStacked key={key} {...componentStackedProps} />
                ))}
                {Component && (
                    <Box width={1} maxWidth={MAX_WIDTH_CONTENT}>
                        <Component {...invokeOrReturn(componentProps, ids, user)} {...ids} ids={ids}  />
                    </Box>
                )}
                {fabs && <EnhancedFabs fabs={fabs} /> }
            </Box>
            <Footer />
        </Box>
    );
};

BuildComponent.propTypes = {
    tabs: PropTypes.array,
    fabs: PropTypes.array,
    component: PropTypes.func,
    showTabs: PropTypes.bool,
    components: PropTypes.array,
    componentProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    routerProps: PropTypes.object,
    root: PropTypes.string,
};

BuildComponent.defaultProps = {
    tabs: undefined,
    fabs: undefined,
    component: undefined,
    componentProps: {},
    showTabs: true,
    components: [],
    root: '',
    routerProps: {},
};

export default BuildComponent;
