import { Box, Collapse, Grid } from '@material-ui/core';
import { Close, ExpandLess, ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import EnhancedAutocomplete from '../../components/utilities/EnhancedAutocomplete';
import EnhancedCheckbox from '../../components/utilities/EnhancedCheckbox';
import EnhancedDatePicker from '../../components/utilities/EnhancedDatePicker';
import EnhancedIcon from '../../components/utilities/EnhancedIcon';
import EnhancedTextFiled from '../../components/utilities/EnhancedTextFiled';
import { getAllPersons } from '../../store/proxies/tigestimProxies';

function ChecklistQuestionRow({ label, evaded, questionId, itemId, onChange, observation, personId, expiredAt, readOnly }) {
    const [open, setOpen] = useState(readOnly);
    return (
        <Box pl={2} pt={1}>
            <Box display="flex" alignItems="center">
                <EnhancedCheckbox 
                    value={evaded} 
                    disabled={readOnly} 
                    onChange={value => {
                        onChange(prev => ({
                            ...prev,
                            questions: prev.questions.map(question => {
                                if (question.id === questionId)
                                    return {
                                        ...question,
                                        items: question.items.map(item => {
                                            if (item.id === itemId) return { ...item, evaded: value };
                                            return item;
                                        }),
                                    };
                                return question;
                            }),
                        }));
                    }}/>
                <EnhancedTextFiled
                    value={label}
                    readOnly={readOnly}
                    onChange={value => {
                        onChange(prev => ({
                            ...prev,
                            questions: prev.questions.map(question => {
                                if (question.id === questionId)
                                    return {
                                        ...question,
                                        items: question.items.map(item => {
                                            if (item.id === itemId) return { ...item, label: value };
                                            return item;
                                        }),
                                    };
                                return question;
                            }),
                        }));
                    }}
                />
                <Box display="flex" alignItems="center" pl={1}>
                    <EnhancedIcon
                        icon={open ? ExpandLess : ExpandMore}
                        onClick={() => {
                            setOpen(prev => !prev);
                        }}
                    />
                </Box>
                {!readOnly && (
                    <Box display="flex" alignItems="center" pl={1}>
                        <EnhancedIcon
                            icon={Close}
                            onClick={() => {
                                onChange(prev => ({
                                    ...prev,
                                    questions: prev.questions.map(question => {
                                        if (question.id === questionId)
                                            return { ...question, items: question.items.filter(item => item.id !== itemId) };
                                        return question;
                                    }),
                                }));
                            }}
                        />
                    </Box>
                )}
            </Box>
            <Collapse in={open}>
                <Box pl={6} pr={7} display="flex" alignItems="center">
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <EnhancedTextFiled
                                label="observation"
                                readOnly={readOnly}
                                value={observation}
                                onChange={value => {
                                    onChange(prev => ({
                                        ...prev,
                                        questions: prev.questions.map(question => {
                                            if (question.id === questionId)
                                                return {
                                                    ...question,
                                                    items: question.items.map(item => {
                                                        if (item.id === itemId) return { ...item, observation: value }; // todo usare sempre id
                                                        return item;
                                                    }),
                                                };
                                            return question;
                                        }),
                                    }));
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <EnhancedAutocomplete
                                value={personId}
                                label="person"
                                variant="standard"
                                readOnly={readOnly}
                                onChange={value => {
                                    onChange(prev => ({
                                        ...prev,
                                        questions: prev.questions.map(question => {
                                            if (question.id === questionId)
                                                return {
                                                    ...question,
                                                    items: question.items.map(item => {
                                                        if (item.id === itemId) return { ...item, personId: value };
                                                        return item;
                                                    }),
                                                };
                                            return question;
                                        }),
                                    }));
                                }}
                                optionsFetch={{
                                    proxy: getAllPersons,
                                    defaultValue: null,
                                    params: {
                                        filter: 'forName',
                                    },
                                    handleResponse: data =>
                                        data.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })),
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <EnhancedDatePicker
                                variant="standard"
                                label="expiredAt"
                                value={expiredAt}
                                formatData="YYYY-MM-DD HH:mm:ss"
                                readOnly={readOnly}
                                onChange={value => {
                                    onChange(prev => ({
                                        ...prev,
                                        questions: prev.questions.map(question => {
                                            if (question.id === questionId)
                                                return {
                                                    ...question,
                                                    items: question.items.map(item => {
                                                        if (item.id === itemId) return { ...item, expiredAt: value };
                                                        return item;
                                                    }),
                                                };
                                            return question;
                                        }),
                                    }));
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>
        </Box>
    );
}

ChecklistQuestionRow.propTypes = {
    expiredAt: PropTypes.string,
    itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string,
    observation: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    personId: PropTypes.number,
    questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    readOnly: PropTypes.bool,
    evaded: PropTypes.bool,
};

ChecklistQuestionRow.defaultProps = {
    label: '',
    evaded: undefined,
    observation: undefined,
    personId: undefined,
    expiredAt: undefined,
    readOnly: undefined,
};

export default ChecklistQuestionRow;
