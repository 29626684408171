import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from '@material-ui/core';
import { selectFilter, setFilter as setStoreFilter, setPage } from '../store/slices/sliceFiltering';

const useFilters = (proxy ) => {
    const dispatch = useDispatch();
    const storeFilter = useSelector(selectFilter(proxy.name))
    const [filter, setFilter] = useState(storeFilter);

    const debounced = useRef(debounce((filter) => {
        dispatch(setStoreFilter({ filter, view: proxy.name}));
        dispatch(setPage({ view: proxy.name, page: { pageNumber: 1 } }));
    }, 1000));
    
    const externalSetFilter = useCallback((filter) => {
        setFilter(filter)
        debounced.current(filter());
    }, [])

    return [filter, externalSetFilter];
};

export default useFilters;
