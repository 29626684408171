import { Box } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { baseUrlApp } from '../../constants/originUrlConstants';
import { selectRoutes } from '../../store/selectors';
import EnhancedSwitch from '../routings/EnhancedSwitch';
import HomePage from '../utilities/HomePage';

const Content = () => {
    const routes = useSelector(selectRoutes);
    return (
        <Box height={1} width={1}>
            <EnhancedSwitch root={`${baseUrlApp}`} routes={routes} />
            <Route path={`${baseUrlApp}`} exact component={HomePage} />
        </Box>
    );
};

Content.propTypes = {};

Content.defaultProps = {};

export default Content;
