/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import { Box, Divider, Link, Paper } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import moment from 'moment';
import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snowfall from 'react-snowfall';
import Fireworks from '../../components/Fireworks';
import Spiders from '../../components/spiders/Spiders';
import EnhancedTextFiled from '../../components/utilities/EnhancedTextFiled';
import LoadingButton from '../../components/utilities/LoadingButton';
import { EDEMAR, METALPIEGA_COLOR, METALSKY, SITAF, TIGESTIM } from '../../constants/options';
import localeContext from '../../context/localeContext';
import useQueryString from '../../hooks/useQueryString';
import useTranslate from '../../hooks/useTranslate';
import santaHat from '../../resources/santaHat.png';
import { selectDarkMode } from '../../store/slices/sliceAppearance';
import { requestLogin } from '../../store/slices/sliceAuth';
import { selectIsLoading } from '../../store/slices/sliceFetching';
import '../../styles/festivity-title.css';
import darkTheme from '../../themes/darkTheme';
import lightTheme from '../../themes/lightTheme';
import { logoSelector } from '../../utils/common';


const useStyles = makeStyles(theme => ({
    logo: {
        width: '250px',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
    },         
    hat: ({ top, left}) => ({
        top,
        right: '40px',
        width: '60px',
        height: '55px',
        display: 'block',
        zIndex: 5,
        position: 'absolute',
        left: `calc(50% + ${left}px)`,
    }), 
    icon: {
        backgroundColor: theme.palette.primary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    languageSwitcher: {
        padding: '0 1em 1em 1em',
    },
    checkbox: ({darkMode}) => ({
        width: '100%',
        color: darkMode ? 'white' : undefined
    }),
    passwordButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        padding: '0 1em 1em 1em',
    },
    forgotText: {
        width: '100%',
        textAlign: 'right',
    },
}));

const isFestivity = (festivity) => {
    const date = moment().format('DD.MM.yyyy')
    switch (festivity ?? date.slice(0, 5)) {
        case '29.10': 
        case '30.10': 
        case '31.10': 
        case '01.10':  
        case '02.10':  
        case 'halloween':  
            return  { label: "Buon Halloween!", type: 'halloween', effect: Spiders }  
        case '23.12': 
        case '24.12': 
        case '25.12': 
        case '26.12':  
        case '27.12':  
        case 'christmas':
            return  { label: "Buon Natale!", type: 'christmas', effect: Snowfall } 
        case '01.01':  
        case '02.01':  
        case '03.01':  
        case '04.01':  
        case '05.01':  
        case '06.01':  
        case '07.01':  
        case '08.01':  
        case '09.01':  
        case '10.01':  
        case 'new-year':  
            return  { label: "Buon Anno!", type: 'new-year', effect: Fireworks } 
        default:
            return { }  
    }
}

const hatSelector = (companyName) => {
    switch (companyName) {
        case TIGESTIM: 
            return { top: 110, left: 17 };
        case EDEMAR:  
            return { top: 115, left: 83 };
        case METALSKY:  
            return { top: 110, left: 80 };
        case SITAF:  
            return { top: 106, left: -93 };
        case METALPIEGA_COLOR:  
            return { top: 110, left: 17 };
        default:
            return { top: 110, left: 17 };
    }
}

const Login = () => {
    const dispatch = useDispatch();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const loading = useSelector(selectIsLoading('login'));
   
    const translate = useTranslate();
    const { locale, setLocale } = useContext(localeContext);
    const { companyName, festivity } = useQueryString();
    const [{ label, type, effect: Effect }] = useState(isFestivity(festivity));
    const darkMode = useSelector(state => selectDarkMode(state)  || !!type); 
    const classes = useStyles({...hatSelector(companyName), darkMode});

    const handleLogin = useCallback(async () => {
        dispatch(requestLogin({ username, password }));
    }, [dispatch, username, password]);

    const handleInputKeyPress = event => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    const company = logoSelector(decodeURIComponent(companyName), darkMode)

    return (
        <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
            {Effect && <Effect />}
            <Box width={1} height={1} display='flex' flexDirection='column' alignItems='center' bgcolor={darkMode ? "#222" : undefined}>
                <Box width={500} pt={15}>
                    <Box mb={5} width={500} display='flex' flexDirection='column' >
                        <img src={company} className={classes.logo} alt={companyName} />
                        {type === 'christmas' && <img src={santaHat} className={classes.hat} alt="santaHat" /> }
                    </Box>
                    <div className={classes.form}>
                        <div className={classes.input}>
                            <Paper>
                                <EnhancedTextFiled
                                    name="username"
                                    disabled={loading}
                                    variant="outlined"
                                    size="medium"
                                    onChange={setUsername}
                                    value={username}
                                    autoComplete="username"
                                />
                            </Paper>
                        </div>
                        <div className={classes.input}>
                            <Paper>

                                <EnhancedTextFiled
                                    name="password"
                                    size="medium"
                                    type="password"
                                    autoComplete="password"
                                    disabled={loading}
                                    variant="outlined"
                                    onChange={setPassword}
                                    value={password}
                                    onKeyPress={handleInputKeyPress}
                                />
                            </Paper>
                        </div>
                    </div>
                    <Box className={classes.passwordButton}>
                        <FormControlLabel
                            color="textPrimary"
                            className={classes.checkbox}
                            control={<Checkbox name="checkedB" color="primary" />}
                            label={translate('remember_me')}
                        />
                        <Link className={classes.forgotText} component="button" color="textPrimary" variant="body2">
                            {translate('forgotten_password')}
                        </Link>
                    </Box>
                    <CardActions className={classes.actions}>
                        <Box display="flex" flexDirection="row-reverse" width={1}>
                            <LoadingButton isLoading={loading} label={translate('sign_in')} onClick={handleLogin} />
                        </Box>
                    </CardActions>
                    <div className={classes.languageSwitcher}>
                        <Box p={2} display="flex" width={1} justifyContent="space-between">
                            <Link
                                component="button"
                                color={locale === 'it' ? 'primary' : 'textSecondary'}
                                variant="body2"
                                onClick={() => setLocale('it')}
                            >
                                IT
                            </Link>

                            <Divider orientation="vertical" flexItem />
                            <Link
                                component="button"
                                color={locale === 'de' ? 'primary' : 'textSecondary'}
                                variant="body2"
                                onClick={() => setLocale('de')}
                            >
                                DE
                            </Link>
                            <Divider orientation="vertical" flexItem />
                            <Link
                                component="button"
                                color={locale === 'fr' ? 'primary' : 'textSecondary'}
                                variant="body2"
                                onClick={() => setLocale('fr')}
                            >
                                FR
                            </Link>
                            <Divider orientation="vertical" flexItem />
                            <Link
                                component="button"
                                color={locale === 'en' ? 'primary' : 'textSecondary'}
                                variant="body2"
                                onClick={() => setLocale('en')}
                            >
                                EN
                            </Link>
                        </Box>
                       
                    </div>
                </Box>
                <Box pt={0} width={1} display='flex' justifyContent='center' alignContent='center' className={type}>
                    {label && <h1> {label}</h1>}
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default Login;
