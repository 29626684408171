import PropTypes from 'prop-types';
import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import useTranslate from '../../hooks/useTranslate';

const LoadingFullScreen = ({ message, height, boxProps }) => {
    const translate = useTranslate();
    return (
        <Box height={height} width={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center" {...boxProps}>
            <CircularProgress size={30} color="primary" />
            <Box fontSize={16} fontWeight={500} pt={2}>
                {translate(message)}
            </Box>
        </Box>
    );
};

LoadingFullScreen.propTypes = {
    boxProps: PropTypes.object,
    message: PropTypes.string,
    height: PropTypes.number,
};

LoadingFullScreen.defaultProps = {
    message: 'loading',
    height: 1,
    boxProps: undefined,
};

export default LoadingFullScreen;
