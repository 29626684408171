/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { Avatar,  Box, Collapse, Paper, Tooltip } from '@material-ui/core';
import { Close, ModeComment, Share, ThumbDown, ThumbUp } from '@material-ui/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState ,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jsPDF from 'jspdf';
import Action from '../../../components/Action';
import FlexColumn from '../../../components/flex/FlexColumn';
import FlexRow from '../../../components/flex/FlexRow';
import EnhancedBadge from '../../../components/utilities/EnhancedBadge';
import EnhancedIcon from '../../../components/utilities/EnhancedIcon';
import EnhancedMenu from '../../../components/utilities/EnhancedMenu';
import EnhancedTextFiled from '../../../components/utilities/EnhancedTextFiled';
import Loader from '../../../components/utilities/Loader';
import useFetch from '../../../hooks/useFetch';
import {
    createJournalComment,
    createJournalLikes,
    deleteJournalComment,
    deleteJournalLike,
    getJournal,
    getJournalComments,
} from '../../../store/proxies/tigestimProxies';
import { selectUserId } from '../../../store/slices/sliceAuth';
import {selectUser} from '../../../store/slices/sliceSession';
import { refetch } from '../../../store/slices/sliceFetching';
import { setSuccessNotification } from '../../../store/slices/sliceNotification';
import { buildUrl, logoSelector } from '../../../utils/common';

const max = {height:297,width:210}

async function addImageProcess(src) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = `${src}?not-from-cache`;
        img.onload = () => resolve(img);
        img.onerror = reject;
    });
}

function imageResize(img) {
    if (!img) {
        return;
    }
    let { height } = img;
    let { width } = img;

    const ratio = img.height / img.width;
    if (height > max.height || width > max.width) {
        if (height > width) {
            height = max.height / ratio;
            width = height * (1 / ratio);
        } else if (width > height) {
            width = max.width * ratio;
            height = width * ratio;
        }
    }
    // eslint-disable-next-line consistent-return
    return [width, height];
}


const handleComments = response => {
    const { data = [], size, totalElements } = response || {};
    return { data: data.reverse(), more: size !== totalElements };
};

const SiteJournalSocial = ({ journal, addPathView, openComments }) => {
    const dispatch = useDispatch();
    const { commentCount, likeCount, dislikeCount, likePeopleList } = journal;
    const [open, setOpen] = useState(openComments);
    const userId = useSelector(selectUserId);
    const [text, setText] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const user = useSelector(selectUser);
    const companyLogo = logoSelector(user.companyName,false);
   

    const [comments, isLoading, reload] = useFetch(
        getJournalComments,
        { journalId: journal.id, filter: 'forJournalId', pageSize },
        [journal.id, open, pageSize],
        [journal.id, open],
        {},
        handleComments
    );
    let iLiked = false;
    iLiked = likePeopleList?.filter(item => item?.personId === userId).length > 0;
    

    // pdfs images converted
    const [pdfImages, setPdfImages] = useState([]);
   

    const exportToPdf = async  (journal) => {
      
        const newImages = [];
        for (const [, item] of journal.journalImageList.entries()) {
            const img = await addImageProcess(buildUrl(item.journalUrl)); // new Image();
            if (img) {
                const [width, height] = imageResize(img);
                img.width = width;
                img.height = height;
                newImages.push(img);
            }
        }
        setPdfImages(newImages); 
    }
  
    useEffect(() => {
        if (pdfImages.length > 0) {
            const doc = new jsPDF();
            doc.setFontSize(10);
            doc.addImage(companyLogo, "PNG", 5, 5, 35, 15);
            doc.text(`${journal.createdAt} ${journal.firstName}`,5,30);
            doc.text(journal?.description || '',5,40);            
            for (const [i, ctx] of pdfImages.entries()) {
                if (ctx) {
                    const ext = ctx.src.substr(ctx.src.lastIndexOf('.')+1);
                    doc.addImage(ctx, ext, 20, 50, ctx.width, ctx.height);
                    if (i < pdfImages.length - 1) {
                        doc.addPage();
                    } 
                }
            }
            doc.save('journal.pdf');
            setPdfImages([]);
        }        
        
    },[pdfImages,companyLogo,journal]);    

    return (
        <>
          
            <FlexRow alignItems="flex-start" justifyContent="space-between" bgcolor="action.hover">
                <FlexRow>
                    <EnhancedBadge badgeContent={commentCount}>
                        <Action label="see_all_comments" color="text.secondary" onClick={() => setOpen(prev => !prev)} disabled={!commentCount} />
                    </EnhancedBadge>
                </FlexRow>
                {/* <img src={companyLogo} alt='kenjy' /> */}
                <FlexRow >
                    <FlexRow mr={2}>
                        <EnhancedBadge badgeContent={likeCount}>
                            <Action
                                icon={ThumbUp}
                                label="like"
                                color={iLiked ? "primary.main" : "text.secondary"}
                                onClick={iLiked ? (() => {}) : (async (data, isLoading) => {
                                    isLoading(true);
                                    try {
                                        await createJournalLikes.call({
                                            personId: userId,
                                            journalId: journal.id,
                                            createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
                                        });
                                        isLoading(false);
                                    } catch (e) {
                                        try {
                                            await deleteJournalLike.call(userId, journal.id);
                                        } catch (e) {
                                            isLoading(false);
                                        }
                                        isLoading(false);
                                    }
                                    return refetch(getJournal.name);
                                })}
                            />
                        </EnhancedBadge>
                    </FlexRow>
                    {false && (
                        <FlexRow>
                            <EnhancedBadge badgeContent={dislikeCount}>
                                <Action icon={ThumbDown} label="dislike" color="text.secondary" />
                            </EnhancedBadge>
                        </FlexRow>
                    )}

                    <Action icon={ModeComment} label="add_comment" color="text.secondary" minWidth={205} onClick={() => setOpen(true)} />
                    
                    
                    <EnhancedMenu
                        icon={Share}
                        label="share"
                        menus={[
                            {
                                label: 'share_copy',
                                onClick: () => {
                                    if (addPathView) navigator.clipboard.writeText(`${window.location.href}/${journal.id}/view`);
                                    else navigator.clipboard.writeText(`${window.location.href}`);
                                    dispatch(setSuccessNotification('element_copied_in_the_clipboard'));
                                },
                            },
                            {
                                label: 'share_email',
                                onClick: () => {
                                    window.open(`mailto:?subject=${journal.title}&body=${`${window.location.href}/${journal.id}/view`}`);
                                },
                            },
                            {
                                label: 'share_download_pdf',
                                onClick:() => exportToPdf(journal)
                            },
                        ]}
                    />
                </FlexRow>
            </FlexRow>
            <Collapse in={open}>
                <FlexColumn alignItems="flex-start" justifyContent="space-between">
                    <FlexRow width={1} alignItems="start" justifyContent="star" pr={1}>
                        { likeCount > 0 &&
                                journal.likePeopleList?.map(element => {
                                    return (
                                        <Tooltip title={`${element?.firstName || ''} ${element?.lastName || ''}`} >
                                            <Box style={{marginRight: 20, marginTop: 10}}>
                                                <Avatar
                                                    key={`av_${element?.personId}`}
                                                    style={{width: 50, height: 50 }}
                                                    src={buildUrl(element?.avatarUrl)}
                                                    alt={`${element?.firstName || ''} ${element?.lastName || ''}`}  />
                                                <Box
                                                    color="primary.main"
                                                    display="flex"
                                                    height={1}
                                                    alignContent="flex-end"
                                                    justifyContent="flex-end"
                                                    pb={0.5}
                                                    style={{marginTop: -12, marginRight: -5}}>
                                                    <ThumbUp style={{ fontSize: 16 }} />
                                                </Box>
                                            </Box>
                                        </Tooltip>
                                    )
                                })
                        }
                    </FlexRow>
                </FlexColumn>
                <FlexColumn alignItems="flex-start" justifyContent="space-between">
                    <Loader loading={isLoading} items={comments.data}>
                        <Box p={2} width={1}>
                            {comments.more && (
                                <Box width={1} display="flex" justifyContent="center">
                                    <Action
                                        label="show_more_comments"
                                        onClick={() => {
                                            setPageSize(prev => prev + 10);
                                        }}
                                    />
                                </Box>
                            )}
                            {comments.data?.map?.(({ id, authorId, comment, firstName, lastName }) => {
                                const myComment = authorId === userId;
                                return (
                                    comment && (
                                        <FlexRow key={id} justifyContent={myComment ? 'flex-end' : 'flex-start'} p={0.5}>
                                            <FlexColumn borderRadius={10} bgcolor={myComment ? 'primary.main' : 'action.hover'} p={1}>
                                                <FlexRow width={1} justifyContent="space-between">
                                                    <Box fontWeight={600} fontSize={12}>{`${firstName} ${lastName}`}</Box>
                                                    {myComment && (
                                                        <EnhancedIcon
                                                            onClick={async (data, setLoading) => {
                                                                setLoading(true);
                                                                try {
                                                                    await deleteJournalComment.call(id);
                                                                    setLoading(false);
                                                                } catch (e) {
                                                                    setLoading(false);
                                                                }
                                                                reload();
                                                                return refetch(getJournal.name);
                                                            }}
                                                            icon={Close}
                                                            size={16}
                                                        />
                                                    )}
                                                </FlexRow>
                                                <Box> {comment}</Box>
                                            </FlexColumn>
                                        </FlexRow>
                                    )
                                );
                            })}
                        </Box>
                    </Loader>

                    <FlexRow width={1} alignItems="center" justifyContent="center" bgcolor="action.hover" pr={1}>
                        <Box flexGrow={1} mx={2} my={1}>
                            <Paper>
                                <EnhancedTextFiled value={text} onChange={setText} variant="outlined" fullWidth multiline />
                            </Paper>
                        </Box>
                        <Action
                            label="send"
                            color="primary.main"
                            onClick={async (data, isLoading) => {
                                isLoading(true);
                                try {
                                    await createJournalComment.call({
                                        authorId: userId,
                                        journalId: journal.id,
                                        languageId: 1,
                                        comment: text,
                                        updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
                                    });
                                    setText();
                                    isLoading(false);
                                } catch (e) {
                                    isLoading(false);
                                }
                                reload();
                                return refetch(getJournal.name);
                            }}
                            disabled={!text}
                        />
                    </FlexRow>
                </FlexColumn>
            </Collapse>
        </>
    );
};

SiteJournalSocial.propTypes = {
    addPathView: PropTypes.bool,
    journal: PropTypes.object,
    openComments: PropTypes.bool,
};
SiteJournalSocial.defaultProps = {
    journal: {},
    addPathView: undefined,
    openComments: false,
};

export default SiteJournalSocial;
