import { createSlice } from '@reduxjs/toolkit';

const sliceInitialization = createSlice({
    name: 'initialization',
    initialState: {},
    reducers: {
        receiveInizialization: (state, { payload }) => {
            const { key, value } = payload;
            state[key] = value;
        },
    },
});

export default sliceInitialization;

// ACTIONS
export const { receiveInizialization } = sliceInitialization.actions;

// SELECTORS
export const selectInizializaion = key => state => state.initialization[key] || {};
