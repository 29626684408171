/* eslint-disable react/prop-types */
import { Box } from '@material-ui/core';
import React from 'react';
import { isNotDef } from '../../utils/common';
import LoadingFullScreen from './LoadingFullScreen';

const Loader = ({ children, loading, items, noItemsMessage }) => {
    if (isNotDef(loading)) return <Box>{noItemsMessage}</Box>;
    if (loading && isNotDef(items)) return <LoadingFullScreen boxProps={{ py: 2 }} />;
    return children;
};

Loader.propTypes = {};
Loader.defaultProps = {};

export default Loader;
