import { Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { getLastField } from '../../utils/common';

const PhoneCell = ({ item, id }) => {
    const value = getLastField(item, id);
    const theme = useTheme();
    return (
        <a href={`tel:${value}`} style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
            <Box>{value}</Box>
        </a>
    );
};

PhoneCell.propTypes = {
    item: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
};
PhoneCell.defaultProps = {};

export default PhoneCell;
