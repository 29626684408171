/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Box, CircularProgress, MenuItem } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import useTranslate from '../../hooks/useTranslate';
import { selectUser } from '../../store/slices/sliceSession';
import { buildUrl } from '../../utils/common';
import history from '../routings/history';

const MenuProfileInfo = () => {
    const translate = useTranslate();
    const user = useSelector(selectUser);

    return (
        <MenuItem dense onClick={() => history.push('/app/profile/dashboard')} className="profile-info__wrapper">
            <Box width={1}>
                <Box py={1} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                    {user ? (
                        <Fragment>
                            <Avatar src={buildUrl(user.url)}>{`${user.firstName?.charAt(0) || ''}${user.lastName?.charAt(0) || ''}`}</Avatar>
                            <Box pl={2} display="flex" flexDirection="column" className="profile-info__name">
                                <Box display="flex" flexWrap="wrap">
                                    <Box fontSize={16} pr={1}>
                                        {user.firstName}
                                    </Box>
                                    <Box fontSize={16}>{user.lastName}</Box>
                                </Box>
                                <Box fontWeight="fontWeightLight" color="gray" fontSize={12}>
                                    {translate(user.role)}
                                </Box>
                            </Box>
                        </Fragment>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>
            </Box>
        </MenuItem>
    );
};

export default MenuProfileInfo;
