export const VACATION = 1;
export const MILITARY_SERVICE = 2;
export const SICKNESS_LEAVE = 3;
export const MATERNITY_LEAVE = 4;
export const MEDICAL_EXAMINATION = 5;
export const PROFESSIONAL_ACCIDENT = 6;
export const NON_PROFESSIONAL_ACCIDENT = 7;
export const TRAINING_COURSE = 8;
export const WEATHER_CONDITION = 9;
export const OTHER_REASON = 10;

export const leaveTypeLabel = leaveTypeLabel => {
    return {
        [VACATION]: 'vacation',
        [MILITARY_SERVICE]: 'military',
        [SICKNESS_LEAVE]: 'sickness',
        [MATERNITY_LEAVE]: 'maternity',
        [MEDICAL_EXAMINATION]: 'medical',
        [PROFESSIONAL_ACCIDENT]: 'professionalAccident',
        [NON_PROFESSIONAL_ACCIDENT]: 'nonProfessionalAccident',
        [TRAINING_COURSE]: 'training',
        [WEATHER_CONDITION]: 'weather',
        [OTHER_REASON]: 'others',
    }[Number(leaveTypeLabel)];
};

export const APPROVED_LEAVE = '1';
export const PENDING_LEAVE = '2';
export const REJECTED_LEAVE = '3';

export const APPROVED_CHECKLIST = 1;
export const PENDING_CHECKLIST = 2;
export const REJECTED_CHECKLIST = 3;

export const APPROVED_EXPENSE = 'APPROVED';
export const PENDING_EXPENSE = 'PENDING';
export const REJECTED_EXPENSE = 'REJECTED';

export const STAMPING_LOG_ENTER = 'ENTRATA';
export const STAMPING_LOG_OUT = 'USCITA';

export const stampingLogLabel = value => {
    return {
        [STAMPING_LOG_ENTER]: 'enter',
        [STAMPING_LOG_OUT]: 'exit',
    }[value];
};
