import axios from 'axios';

export const getVoid = {
    call: (params, cancelToken) => axios.get(`getVoid`, { params, cancelToken }),
    name: 'getVoid',
};

export const postVoid = {
    call: (data, params, cancelToken) => axios.post(`postVoid`, data, { params, cancelToken }),
    name: 'postVoid',
};

///  Sites ///////////////////////////////////////////////////
export const getSites = {
    call: (params, cancelToken) => axios.get(`sites/sites`, { params, cancelToken }),
    name: 'sites/sites',
};

export const getSitesSearch = {
    call: (params, cancelToken) => axios.get(`sites/sites/title`, { params, cancelToken }),
    name: 'sites/sites/title',
};

export const getSite = {
    call: (id, cancelToken) => axios.get(`sites/sites/${id}`, { cancelToken }),
    name: 'sites/sites',
};
export const saveSite = {
    call: (id, data, cancelToken) => axios.put(`sites/sites/${id}`, data, { cancelToken }),
    name: 'saveSiteFav',
};

export const getSiteForUser = {
    call: ({ id, ...params }, cancelToken) => axios.get(`sites/sites/${id}`, { params, cancelToken }),
    name: 'sites/sites',
};

export const getSitesFav = {
    call: (params, cancelToken) => axios.get(`sites/sites/fav`, { params, cancelToken }),
    name: 'sites/sites/fav',
};

export const getSiteFav = {
    call: (id, cancelToken) => axios.get(`sites/sites/fav/${id}`, { cancelToken }),
    name: 'sites/sites/fav',
};

export const createSiteFav = {
    call: (data, cancelToken) => axios.post(`sites/sites/fav`, data, { cancelToken }),
    name: 'sites/sites/fav',
};

export const saveSiteFav = {
    call: (id, data, cancelToken) => axios.put(`sites/sites/fav/${id}`, data, { cancelToken }),
    name: 'sites/sites/fav',
};

export const deleteSiteFav = {
    call: (id, cancelToken) => axios.delete(`sites/sites/fav/${id}`, { cancelToken }),
    name: 'sites/sites/fav',
};

export const getSiteCalendar = {
    call: (params, cancelToken) => axios.get(`journal/calendar`, { params, cancelToken }),
    name: 'journal/calendar',
};

export const getJournals = {
    call: (params, cancelToken) => axios.get(`journal/journals`, { params, cancelToken }),
    name: 'journal/journals',
};

export const getJournal = {
    call: (id, cancelToken) => axios.get(`journal/journals/${id}`, { cancelToken }),
    name: 'journal/journals',
};

export const deleteJournal = {
    call: (id, cancelToken) => axios.delete(`journal/journals/${id}`, { cancelToken }),
    name: 'journal/journals',
};

export const createJournal = {
    call: (data, cancelToken) => axios.post(`journal/journals`, data, { cancelToken }),
    name: 'journal/journals',
};

export const saveJournal = {
    call: (id, data, cancelToken) => axios.put(`journal/journals/${id}`, data, { cancelToken }),
    name: 'journal/journals',
};

// Social
export const getJournalComments = {
    call: (params, cancelToken) => axios.get(`journal/journals/comments`, { params, cancelToken }),
    name: 'journal/journals/comments',
};

export const createJournalComment = {
    call: (data, cancelToken) => axios.post(`journal/journals/comments`, data, { cancelToken }),
    name: 'journal/journals/comments',
};

export const deleteJournalComment = {
    call: (id, cancelToken) => axios.delete(`journal/journals/comments/${id}`, { cancelToken }),
    name: 'journal/journals/comments',
};

export const createJournalLikes = {
    call: (data, cancelToken) => axios.post(`/journal/journals/likes`, data, { cancelToken }),
    name: '/journal/journals/likes',
};

export const deleteJournalLike = {
    call: (personId, journalId, cancelToken) => axios.delete(`/journal/journals/likes/${personId}/${journalId}`, { cancelToken }),
    name: '/journal/journals/likes',
};

// Site contacts
export const getSiteContacts = {
    call: (params, cancelToken) => axios.get(`sites/siteWorkTeams`, { params, cancelToken }),
    name: 'sites/siteWorkTeams',
};

export const createSiteContact = {
    call: (data, cancelToken) => axios.post(`sites/siteWorkTeams`, data, { cancelToken }),
    name: 'sites/siteWorkTeams',
};

export const deleteSiteContact = {
    call: (id, cancelToken) => axios.delete(`sites/siteWorkTeams/${id}`, { cancelToken }),
    name: 'sites/siteWorkTeams',
};

export const getChecklistRequests = {
    call: (params, cancelToken) => axios.get(`journal/checklists/requests`, { params, cancelToken }),
    name: 'journal/checklists/requests',
};

export const putChecklistRequests = {
    call: (id, data, cancelToken) => axios.put(`/journal/checklists/requests/${id}`, data, { cancelToken }),
    name: 'journal/checklists/requests',
};

// HR ////////////////////////////////////////////////////////
export const getPersons = {
    call: (params, cancelToken) => axios.get(`user/persons`, { params, cancelToken }),
    name: 'user/persons',
};

export const getPerson = {
    call: (id, cancelToken) => axios.get(`user/persons/${id}`, { cancelToken }),
    name: 'user/persons',
};

export const createPerson = {
    call: (data, cancelToken) => axios.post(`user/persons`, data, { cancelToken }),
    name: 'user/persons',
};

export const savePerson = {
    call: (id, data, cancelToken) => axios.put(`user/persons/${id}`, data, { cancelToken }),
    name: 'user/persons',
};

export const deletePerson = {
    call: (id, cancelToken) => axios.delete(`user/persons/${id}`, { cancelToken }),
    name: 'user​/persons',
};

export const getUser = {
    call: (id, cancelToken) => axios.get(`user/users/${id}`, { cancelToken }),
    name: 'user/users',
};

export const saveUser = {
    call: (id, data, cancelToken) => axios.put(`user/users/${id}`, data, { cancelToken }),
    name: 'user/users',
};

export const personLeaveRequests = {
    call: (params, cancelToken) => axios.get(`sites/personLeaveRequests`, { params, cancelToken }),
    name: 'sites/personLeaveRequests',
};

export const savePersonLeave = {
    call: (id, data, cancelToken) => axios.put(`sites/personLeaveRequests/${id}`, data, { cancelToken }),
    name: 'sites/personLeaveRequests',
};

export const getLeave = {
    call: (id, cancelToken) => axios.get(`sites/leaves/${id}`, { cancelToken }),
    name: 'sites/leaves',
};

export const saveLeave = {
    call: (id, data, cancelToken) => axios.put(`sites/leaves/${id}`, data, { cancelToken }),
    name: 'sites/leaves',
};

export const createLeave = {
    call: (data, cancelToken) => axios.post(`sites/leaves`, data, { cancelToken }),
    name: 'sites/leaves',
};

export const deleteLeave = {
    call: (id, cancelToken) => axios.delete(`sites/leaves/${id}`, { cancelToken }),
    name: 'sites/leaves',
};

export const leaveRequestReview = {
    call: (params, cancelToken) => axios.get(`sites/leaveRequestReview`, { params, cancelToken }),
    name: 'sites/leaveRequestReview',
};

export const dailyHoursService ={
    get: {
        call: (params, cancelToken) => axios.get(`sites/dailyHours`, { params, cancelToken }),
        name: 'sites/dailyHours',
    },
    create: {
        call: (data, cancelToken) => axios.post(`sites/dailyHours`, data, { cancelToken }),
        name: 'sites/dailyHours',
    },
    calendar:{
        call: (params, cancelToken) => axios.get(`sites/dailyHours/calendar`, { params, cancelToken }),
        name: 'sites/dailyHours/calendar',
    }
} ;

export const userExpenses = {
    call: (params, cancelToken) => axios.get(`sites/userExpenses`, { params, cancelToken }),
    name: 'sites/userExpenses',
};

export const saveUserExpense = {
    call: (id, data, cancelToken) => axios.put(`sites/userExpenses/${id}`, data, { cancelToken }),
    name: 'sites/userExpenses',
};

export const deleteUserExpense = {
    call: (id, cancelToken) => axios.delete(`sites/userExpenses/${id}`, { cancelToken }),
    name: 'sites/userExpenses',
};

export const getUsers = {
    call: (params, cancelToken) => axios.get(`user/users`, { params, cancelToken }),
    name: 'user/users',
};

export const getHrPersonalCalendar = {
    call: (params, cancelToken) => axios.get(`journal/hrPersonCalendar`, { params, cancelToken }),
    name: 'journal/HrPersonCalendar',
};

export const getHrLeaveRequests = {
    call: (params, cancelToken) => axios.get(`sites/hrLeaveRequests`, { params, cancelToken }),
    name: 'sites/hrLeaveRequests',
};

export const getExpense = {
    call: (id, cancelToken) => axios.get(`sites/expenses/${id}`, { cancelToken }),
    name: 'sites/expenses',
};

export const saveExpense = {
    call: (id, data, cancelToken) => axios.put(`sites/expenses/${id}`, data, { cancelToken }),
    name: 'sites/expenses',
};

export const createExpense = {
    call: (data, cancelToken) => axios.post(`sites/expenses`, data, { cancelToken }),
    name: 'sites/expenses',
};

export const getHrExpenses = {
    call: (params, cancelToken) => axios.get(`sites/hrExpenses`, { params, cancelToken }),
    name: 'sites/hrExpenses',
};

// Event

export const getEvents = {
    call: (params, cancelToken) => axios.get(`journal/events`, { params, cancelToken }),
    name: 'journal/events',
};

export const getEvent = {
    call: (id, cancelToken) => axios.get(`journal/events/${id}`, { cancelToken }),
    name: 'journal/events',
};

export const createEvent = {
    call: (data, cancelToken) => axios.post(`journal/events`, data, { cancelToken }),
    name: 'journal/events',
};

export const saveEvent = {
    call: (id, data, cancelToken) => axios.put(`journal/events/${id}`, data, { cancelToken }),
    name: 'journal/events',
};

export const deleteEvent = {
    call: (id, cancelToken) => axios.delete(`journal/events/${id}`, { cancelToken }),
    name: 'journal/events',
};

// Festivity

export const getFestivities = {
    call: (params, cancelToken) => axios.get(`journal/festivities`, { params, cancelToken }),
    name: 'journal/festivities',
};

export const getFestivity = {
    call: (id, cancelToken) => axios.get(`journal/festivities/${id}`, { cancelToken }),
    name: 'journal/festivities',
};

export const createFestivity = {
    call: (data, cancelToken) => axios.post(`journal/festivities`, data, { cancelToken }),
    name: 'journal/festivities',
};

export const saveFestivity = {
    call: (id, data, cancelToken) => axios.put(`journal/festivities/${id}`, data, { cancelToken }),
    name: 'journal/festivities',
};

export const deleteFestivity = {
    call: (id, cancelToken) => axios.delete(`journal/festivities/${id}`, { cancelToken }),
    name: 'journal/festivities',
};

// StampingsLogs

export const getStampingsLogs = {
    call: (params, cancelToken) => axios.get(`user/stampingsLogs`, { params, cancelToken }),
    name: 'user/stampingsLogs',
};

export const getStampingLog = {
    call: (id, cancelToken) => axios.get(`user/stampingsLogs/${id}`, { cancelToken }),
    name: 'user/stampingsLogs',
};

export const createStampingLog = {
    call: (data, cancelToken) => axios.post(`user/stampingsLogs`, data, { cancelToken }),
    name: 'user/stampingsLogs',
};

export const saveStampingLog = {
    call: (id, data, cancelToken) => axios.put(`user/stampingsLogs/${id}`, data, { cancelToken }),
    name: 'user/stampingsLogs',
};

export const deleteStampingLog = {
    call: (id, cancelToken) => axios.delete(`user/stampingsLogs/${id}`, { cancelToken }),
    name: 'user/stampingsLogs',
};

// Working hours

export const getWorkingHoursList = {
    call: (params, cancelToken) => axios.get(`user/workingHours`, { params, cancelToken }),
    name: 'user/workingHours',
};

export const getWorkingHours = {
    call: (id, cancelToken) => axios.get(`user/workingHours/${id}`, { cancelToken }),
    name: 'user/workingHours',
};

export const saveWorkingHours = {
    call: (id, data, cancelToken) => axios.put(`user/workingHours/${id}`, data, { cancelToken }),
    name: 'user/workingHours',
};

export const createWorkingHours = {
    call: (data, cancelToken) => axios.put(`user/workingHours/`, data, { cancelToken }),
    name: 'user/workingHours',
};

export const UpdateUser = {
    call: (data, cancelToken) => axios.put(`user/persons/${data.user.id}`, { body: data }, { cancelToken }),
    name: 'user/UpdateUser',
};

/// utility
export const uploadImage = {
    call: (data, cancelToken) => axios.post(`utility/uploadImage`, data, { cancelToken }),
    name: 'utility/UploadImage',
};

export const getImage = (url, params, cancelToken) => axios.get(url, { crossdomain: true, params, cancelToken });

// Admin
export const getTools = {
    call: (params, cancelToken) => axios.get(`user/adminTools`, { params, cancelToken }),
    name: 'user​/adminTools',
};

export const getToolsQuantity = {
    call: (params, cancelToken) => axios.get(`user/adminTools/quantities`, { params, cancelToken }),
    name: 'user​/adminTools/quantities',
};

export const getTool = {
    call: (id, cancelToken) => axios.get(`user/adminTools/${id}`, { cancelToken }),
    name: 'user​/adminTools',
};

export const createTool = {
    call: (data, cancelToken) => axios.post(`user/adminTools`, data, { cancelToken }),
    name: 'user​/adminTools',
};

export const saveTool = {
    call: (id, data, cancelToken) => axios.put(`user/adminTools/${id}`, data, { cancelToken }),
    name: 'user​/adminTools',
};

export const deleteTool = {
    call: (id, cancelToken) => axios.delete(`user/adminTools/${id}`, { cancelToken }),
    name: 'user​/adminTools',
};

export const getToolsCategories = {
    call: (params, cancelToken) => axios.get(`user/adminTools/categories`, { params, cancelToken }),
    name: 'user/adminTools/categories',
};

export const getToolsCategory = {
    call: (id, cancelToken) => axios.get(`user/adminTools/categories/${id}`, { cancelToken }),
    name: 'user/adminTools/categories',
};

export const createToolsCategory = {
    call: (data, cancelToken) => axios.post(`user/adminTools/categories`, data, { cancelToken }),
    name: 'user/adminTools/categories',
};

export const saveToolsCategory = {
    call: (id, data, cancelToken) => axios.put(`user/adminTools/categories/${id}`, data, { cancelToken }),
    name: 'user/adminTools/categories',
};

export const deleteToolsCategory = {
    call: (id, cancelToken) => axios.delete(`user/adminTools/categories/${id}`, { cancelToken }),
    name: 'user/adminTools/categories',
};

// Options
export const getAllPersons = {
    call: (params, cancelToken) => axios.get(`user/persons/all`, { params, cancelToken }),
    name: 'user​/persons/all',
};

export const getAllSites = {
    call: (params, cancelToken) => axios.get(`sites/sites/all`, { params, cancelToken }),
    name: 'sites/sites/all',
};

export const createFeedback = {
    call: (data, cancelToken) => axios.post(`user/contacts`, data, { cancelToken }),
    name: 'user/contacts',
};

// Checklist

export const getChecklists = {
    call: (params, cancelToken) => axios.get(`journal/checklists`, { params, cancelToken }),
    name: 'getChecklists',
};

export const getChecklistsSite = {
    call: (params, cancelToken) => axios.get(`journal/checklists`, { params, cancelToken }),
    name: 'getChecklistsSite',
};

export const getChecklistsTemplate = {
    call: (params, cancelToken) => axios.get(`journal/checklists`, { params, cancelToken }),
    name: 'getChecklistsTemplate',
};

export const getChecklist = {
    call: (id, cancelToken) => axios.get(`journal/checklists/${id}`, { cancelToken }),
    name: 'getChecklist',
};

export const getChecklistResponse = {
    call: (id, cancelToken) => axios.get(`journal/checklists/responses/${id}`, { cancelToken }),
    name: 'getChecklist',
};

export const getChecklistComposite = {
    call: async (id, cancelToken) => {
        const { checklistId, response, signId, signUrl } = await getChecklistResponse.call(id, cancelToken);
        const checklist = await getChecklist.call(checklistId);

        const { responses } = JSON.parse(response);

        const questions = checklist.questions.map(question => {
            const find = responses.find(({ questionId }) => questionId === question.id);
            if (find) {
                const { items } = find;
                return {
                    ...question,
                    items: question.items.map(item => {
                        const findItem = items.find(({ id }) => id === item.id);
                        if (findItem) return { ...item, ...findItem };
                        return item;
                    }),
                };
            }
            return question;
        });
        return { ...checklist, questions, signId, signUrl };
    },
    name: 'getChecklistComposite',
};

export const createChecklist = {
    call: (data, cancelToken) => axios.post(`journal/checklists`, data, { cancelToken }),
    name: 'createChecklist',
};

export const saveChecklist = {
    call: (id, data, cancelToken) => axios.put(`journal/checklists/${id}`, data, { cancelToken }),
    name: 'saveChecklist',
};

export const deleteChecklist = {
    call: (id, cancelToken) => axios.delete(`journal/checklists/${id}`, { cancelToken }),
    name: 'deleteChecklist',
};

// Schedules

export const getSchedules = {
    call: (params, cancelToken) => axios.get(`sites/schedules`, { params, cancelToken }),
    name: 'getSchedules',
};

export const getSchedule = {
    call: (id, cancelToken) => axios.get(`sites/schedules/${id}`, { cancelToken }),
    name: 'getSchedule',
};

export const createSchedule = {
    call: (data, cancelToken) => axios.post(`sites/schedules`, data, { cancelToken }),
    name: 'createSchedule',
};

export const saveSchedule = {
    call: (id, data, cancelToken) => axios.put(`sites/schedules/${id}`, data, { cancelToken }),
    name: 'saveSchedule',
};

export const deleteSchedule = {
    call: (id, cancelToken) => axios.delete(`sites/schedules/${id}`, { cancelToken }),
    name: 'deleteSchedule',
};

// Schedules categories

export const getScheduleCategories = {
    call: (params, cancelToken) => axios.get(`sites/schedules`, { params, cancelToken }),
    name: 'sites​/checklists',
};

export const getScheduleCategory = {
    call: (id, cancelToken) => axios.get(`sites/schedules/${id}`, { cancelToken }),
    name: 'sites​/schedules',
};

export const createScheduleCategory = {
    call: (data, cancelToken) => axios.post(`sites/schedules`, data, { cancelToken }),
    name: 'sites​/schedules',
};

export const saveScheduleCategory = {
    call: (id, data, cancelToken) => axios.put(`sites/schedules/${id}`, data, { cancelToken }),
    name: 'sites​/schedules',
};

export const deleteScheduleCategory = {
    call: (id, cancelToken) => axios.delete(`sites/schedules/${id}`, { cancelToken }),
    name: 'sites​/schedules',
};
