/* eslint-disable no-case-declarations */
import { Box } from '@material-ui/core';
import { Add, Folder, People } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i from '../../../ability/ability';
import Action from '../../../components/Action';
import EditDialog from '../../../components/dialog/EditDialog';
import SimpleCard from '../../../components/SimpleCard';
import EnhancedTable from '../../../components/table/EnhancedTable';
import EnhancedFileUpload from '../../../components/utilities/EnhancedFileUpload';
import { nameFolderForm } from '../../../configuration/forms';
import useSorting from '../../../hooks/useSorting';
import { openDialog } from '../../../store/slices/sliceDialog';
import { refetch, selectRefresh } from '../../../store/slices/sliceFetching';
import { setOrder } from '../../../store/slices/sliceFiltering';
import { selectItem } from '../../../store/slices/sliceNavigation';
import { setErrorNotification, setSuccessNotification } from '../../../store/slices/sliceNotification';
import { bucketGetObjectUrl, bucketList, bucketNewDirectory } from '../../../utils/bucketUtils';
import { compareValues, formValidator } from '../../../utils/common';
import { formatBackFolder, formatFileType, formatLastFolder } from '../../../utils/formats';
import { filesColumns } from './filesColumns';

const SHARED = 'SHARED';

const name = 'files';

const SiteFilesTableView = () => {
    const dispatch = useDispatch();
    const site = useSelector(selectItem);
    const [data, setData] = useState([]);
    const [root, setRoot] = useState('');
    const [path, setPath] = useState('');
    const [bucket, setBucket] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { sortBy, sortDirection } = useSorting(name);
    const refresh = useSelector(selectRefresh(name));

    useEffect(() => {
        if (site?.filePath && site?.fileBucket) {
            const rootFolder = i.can('read', 'files') ? site?.filePath : `${site?.filePath}${SHARED}/`;
            setRoot(rootFolder);
            setBucket(site?.fileBucket);
            setPath(rootFolder);
        }
    }, [site]);

    useEffect(() => {
        const fetchBucket = async () => {
            try {
                setIsLoading(true);

                const { Contents, CommonPrefixes, Prefix } = await bucketList({ bucketSite: bucket, path });

                let files = Contents.map(item => ({ ...item, type: formatFileType(item) }));
                const [first, ...rest] = files;
                files = Prefix === first?.Key ? rest : files;

                setData([...CommonPrefixes.map(({ Prefix }) => ({ Prefix, Key: formatLastFolder(Prefix), type: 'folder' })), ...files]);

                if (root !== path) {
                    setData(prev => [
                        {
                            Key: formatBackFolder(path),
                            Prefix: `${formatBackFolder(path)}/`,
                            type: 'root',
                        },
                        ...prev,
                    ]);
                }
            } catch (err) {
                setData([]);
            } finally {
                setIsLoading(false);
            }
        };
        if (path) fetchBucket();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, refresh]);

    useEffect(() => {
        if (sortBy && sortDirection) {
            setData(prev => {
                const sorted = prev.sort(compareValues({ sortBy, sortDirection }));
                return sorted;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortBy, sortDirection]);

    return (
        <Box height={1} width={1}>
            <Box display="flex" flexDirection="row-reverse" pb={1} gridGap={8}>
                {i.can('update', 'files') && (
                    <EnhancedFileUpload
                        disabled={!bucket || !root}
                        random={false}
                        folder={path}
                        bucketSite={bucket}
                        label="load_file"
                        icon={Add}
                        onSuccess={() => refetch(name)}
                    />
                )}
                <Action
                    label="new_folder"
                    icon={Folder}
                    disabled={!bucket}
                    role={i => i.can('update', 'files')}
                    onClick={() =>
                        openDialog({
                            cancelLabel: 'cancel',
                            confirmationLabel: 'create',
                            component: EditDialog,
                            componentProps: {
                                form: nameFolderForm,
                                title: 'folder_name',
                            },
                            data: {},
                            confirmationEnable: data => formValidator(nameFolderForm, data),
                            confirmationCallback: async ({ folderName }) => {
                                try {
                                    await bucketNewDirectory({ bucketSite: bucket, folderPath: path, folderName });
                                    dispatch(refetch('files'));
                                    return setSuccessNotification('folder_created_successfully');
                                } catch (e) {
                                    return setErrorNotification(`${e?.response?.status} : ${e?.response?.statusText}`);
                                }
                            },
                        })
                    }
                />
                <Action
                    label="shared_folder"
                    disabled={!bucket}
                    role={i => i.can('update', 'files')}
                    show={() => root === path}
                    icon={People}
                    onClick={async () => {
                        try {
                            await bucketNewDirectory({ bucketSite: bucket, folderPath: path, folderName: SHARED });
                            dispatch(refetch('files'));
                            return setSuccessNotification('folder_created_successfully');
                        } catch (e) {
                            return setErrorNotification(`${e?.response?.status} : ${e?.response?.statusText}`);
                        }
                    }}
                />
            </Box>
            <SimpleCard title={formatLastFolder(path)}>
                <EnhancedTable
                    items={data}
                    columns={filesColumns(bucket)}
                    messageNoItems="noFilesAvailable"
                    isLoading={isLoading}
                    handleRequestSort={order => dispatch(setOrder({ view: name, order }))}
                    keyComposition={({ Key }) => Key}
                    sortBy="LastModified"
                    onItemClick={async ({ Key, Prefix, type }) => {
                        switch (type) {
                            case 'root':
                                setPath(Prefix);
                                break;
                            case 'folder':
                                setPath(Prefix);
                                break;
                            default:
                                const link = await bucketGetObjectUrl({ bucketSite: bucket, Key });
                                window.open(link);
                        }
                    }}
                />
            </SimpleCard>
        </Box>
    );
};

SiteFilesTableView.propTypes = {};
SiteFilesTableView.defaultProps = {};

export default SiteFilesTableView;
