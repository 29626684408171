import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { baseUrlApp } from '../constants/originUrlConstants';
import { selectItem } from '../store/slices/sliceNavigation';
import useTranslate from './useTranslate';

const titles = {
    sites: 'construction_sites',
    hr: 'hr',
    admin: 'admin',
    permission: 'permission',
    'file-log': 'file_log',
    profile: 'user_profile',
    notification: 'notification',
};

const subtitles = {
    users: 'menu.employees',
    sites: 'construction_sites',
    'leave-requests': 'all_leave_requests',
    checklist: 'checklist_requests',
    'expense-requests': 'all_expense_requests',
    festivities: 'all_festivities',
    'working-hours': 'all_working_hours',
    'tool-manager': 'tool_manager',
    schedules: 'all_schedules',
    checklists: 'all_checklists',
    dashboard: 'dashboard',
    calendar: 'calendar',
    expenses: 'expenses',
    leaves: 'leaves_and_holidays',
    others: 'others',
    journal: 'journal',
    files: 'files',
    contacts: 'contacts',
};

const dynamicTitle = {
    sites: ({ title, quickcomId }) => `${quickcomId}: ${title}`,
    users: ({ firstName, lastName }) => `${firstName} ${lastName}`,
};

const getDynamicTile = (path, item) => {
    if (path && item) return dynamicTitle[path]?.(item) || '';
    return undefined;
};

const getTitleNew = (first, second, third, item, translate) => {
    switch (first) {
        case 'sites':
            if (Number.isInteger(Number(third))) return `${getDynamicTile(second, item) ?? translate('loading')}`;
            return translate(subtitles[second]);
        case 'admin':
            if (second === 'users' && Number.isInteger(Number(third))) return `${third}: ${getDynamicTile(second, item) ?? translate('loading')}`;
            if (second === 'users') return translate('users');
            return translate(subtitles[second]);
        case 'hr':
            if (second === 'users' && Number.isInteger(Number(third))) return `${third}: ${getDynamicTile(second, item) ?? translate('loading')}`;
            return translate(subtitles[second]);
        case 'permission':
            return translate(titles[first]);
        default:
            return translate(second ? subtitles[second] : titles[first]);
    }
};

const useLocationTitle = () => {
    const [title, setTitle] = useState('');
    // const [subtitle, setSubtitle] = useState('');
    const location = useLocation();
    const translate = useTranslate();
    const item = useSelector(selectItem);

    useEffect(() => {
        const [first, second, third] = location.pathname.replace(`${baseUrlApp}/`, '').split('/');

        setTitle(getTitleNew(first, second, third, item, translate));
        // setSubtitle('');

        // if (first === 'sites' && Number.isInteger(Number(second))) {
        //     setTitle(`${second}: ${getTile(first, item) ?? translate('loading')}`);
        //     setSubtitle(translate(subtitles[thirds]) || '');
        // } else {
        //     if (second || first) setTitle(translate(second ? subtitles[second] : titles[first]));
        //     else setTitle(getTile(first, item));

        //     if (second) setSubtitle(translate(subtitles[second]));
        //     // else setSubtitle(getTile(first, item));
        // }
    }, [item, location.pathname, translate]);

    return [title];
};

export default useLocationTitle;
