import { Document, Font, Page, PDFViewer, StyleSheet } from '@react-pdf/renderer';
import React from 'react';
import { useSelector } from 'react-redux';
import gothic from '../../../resources/GOTHIC.TTF';
import gothicBold from '../../../resources/GOTHICB.TTF';
import { selectDialogData } from '../../../store/slices/sliceDialog';
import PageHeader from './LeaveHeader';
import PageTable from './LeaveTable';


Font.register({
    family: 'Open Sans',
    fonts: [
        { src: gothic },
        { src: gothicBold, fontWeight: 700 },
    ],
});

const styles = StyleSheet.create({
    page: {
        padding: 20,
        paddingTop: 25,
        paddingBottom: 50,
        fontFamily: 'Open Sans',
    },
    viewerStyle: {
        width: '100vw',
        height: '100%',
        border: 0,
    },
});

const LeaveDocument = () => {
    const data = useSelector(selectDialogData);

    return (
        <PDFViewer style={styles.viewerStyle} >
            <Document>
                <Page size="A4" orientation="portrait" style={styles.page} wrap >
                    <PageHeader companyName={data.companyName}/>
                    <PageTable data={data}/>
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default LeaveDocument;
