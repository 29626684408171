import { StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React, {useState, useEffect, useRef} from 'react';
import { formatDate } from '../../../utils/formats';
import logoTig from '../../../resources/LogoTigestimLight.png';
import logoEdemar from '../../../resources/LogoEdemarLight.png';
import logoSitaf from '../../../resources/LogoSitafLight.png';
import logoMetalsky from '../../../resources/LogoMetalskyLight.png';
import logoMetalpiega from '../../../resources/LogoMetalPiegaLight.png';

const styles = StyleSheet.create({
    pageHeader: {
        marginBottom: 8,
        alignItems: 'flex-start',
        fontSize: 9,
    },
});


const PageHeader = ({ data }) => {
    const { date, title, assignedConstructionSites } = data || {};
    const [assignedConstructionSite ] = assignedConstructionSites || [];
    const [logo, setLogo] = useState(logoTig);
    const isMountedComponent = useRef(true);


    useEffect(() => {
        if (isMountedComponent.current) {
            if (assignedConstructionSite?.constructionSiteCompanyName?.toLowerCase().includes('metalsky')) {
                setLogo(logoMetalsky);
            } else if (assignedConstructionSite?.constructionSiteCompanyName?.toLowerCase().includes('edemar')) {
                setLogo(logoEdemar);
            } else if (assignedConstructionSite?.constructionSiteCompanyName?.toLowerCase().includes('sitaf')) {
                setLogo(logoSitaf);
            } else if (assignedConstructionSite?.constructionSiteCompanyName?.toLowerCase().includes('metalpiega')) {
                setLogo(logoMetalpiega);
            }
        }
        return () => { isMountedComponent.current = false; }
    }, [assignedConstructionSite, data]);

    return (
        <View style={styles.pageHeader}>
            <View
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingBottom: 10 
                }}
            >
                <Image src={logo} style={{ width: '100px'}} />
                <Text style={{ fontWeight: 700, fontSize: 14 }}>{`Lista di controllo «${title}»`}</Text>
                <View style={{ width: '100px' }}/>
            </View>
            <View
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingBottom: 10,
                }}
            >
                <View style={{ flexDirection: 'row' }}>
                    <Text style={{ paddingRight: 4 }}>Progetto:</Text>
                    <Text style={{ fontWeight: 700 }}>{`${assignedConstructionSite?.constructionSiteTitle}`}</Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <Text style={{ paddingRight: 4 }}>Firma capo squadra:</Text>
                    <Text style={{ fontWeight: 700, width: 180 }} />
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <Text style={{ paddingRight: 4 }}>Data:</Text>
                    <Text style={{ fontWeight: 700 }}>{`${formatDate(date)}`}</Text>
                </View>
            </View>
        </View>
    );
};

PageHeader.propTypes = {
    data: PropTypes.object,
};

PageHeader.defaultProps = {
    data: {},
};

export default PageHeader;
