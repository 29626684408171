import PropTypes from 'prop-types';
import React, { memo } from 'react';
import MenuItem from './MenuItem';

const MenuList = ({ menu }) => {
    return menu.map(menuItem => <MenuItem menuItem={menuItem} key={menuItem.key} />);
};

MenuList.propTypes = {
    menu: PropTypes.array.isRequired,
};

MenuList.defaultProps = {};

export default memo(MenuList);
