/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Button, IconButton, InputBase, Link, makeStyles, useMediaQuery } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { useTheme } from '@material-ui/core/styles';
import { ArrowLeft, Search } from '@material-ui/icons';
import React, { Fragment, memo, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import localeContext from '../../context/localeContext';
import useTranslate from '../../hooks/useTranslate';
import { selectRoutes } from '../../store/selectors';
import { selectDarkMode } from '../../store/slices/sliceAppearance';
import { selectUser } from '../../store/slices/sliceSession';
import { openSideBar, toggleSideBar } from '../../store/slices/sliceSideBar';
import { logoSelector, smallLogoSelector } from '../../utils/common';
import history from '../routings/history';
import MenuList from './MenuList';
import MenuProfileInfo from './MenuProfileInfo';


const useStyles = makeStyles(theme => ({
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: theme.spacing(1),
    },
    drawerPaper: {
        height: '100%',
    },
}));

const Menu = ({ open }) => {
    const classes = useStyles();
    const routes = useSelector(selectRoutes);

    const translate = useTranslate();
    const { locale, setLocale } = useContext(localeContext);
    const dispatch = useDispatch();
    const darkMode = useSelector(selectDarkMode);
    const user = useSelector(selectUser);

    const theme = useTheme();
    const matchSm = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        dispatch(openSideBar(!matchSm));
    }, [dispatch, matchSm]);


    return (
        <Box height={1} display="flex" flexDirection="column">
            <Box>

                <Box px={7} py={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center" className="logo__wrapper">
                    <Link onClick={() => history.push(`/app/sites/sites`)}>
                        <img src={open ? logoSelector(user.companyName, darkMode) : smallLogoSelector(user.companyName)} alt="" className="logo__image" height={52} />
                    </Link>
                </Box>

                <Divider light />
                <MenuProfileInfo />
                <Divider light />
                <Box p={2} className="searchbar__wrapper">
                    <Box border={1} borderRadius={4} borderColor={grey['300']} width={1} display="flex" alignItems="center" py={1 / 4} px={1 / 2}>
                        <InputBase className={classes.input} placeholder={translate("search")} />
                        <IconButton className={classes.iconButton} aria-label="search">
                            <Search />
                        </IconButton>
                    </Box>
                </Box>
                <Divider light />
           
            </Box>

            <Box style={{ overflow: 'auto' }} className="menu-items__wrapper">
                <List>
                    <MenuList menu={routes} />
                </List>
            </Box>

            <Box flex={1} flexGrow={1} />

            <Fragment>
                <Box p={2} display="flex" width={1} justifyContent="space-between" className="languages__wrapper">
                    <Link
                        component="button"
                        color={locale === 'it' ? 'primary' : 'textSecondary'}
                        variant="body2"
                        onClick={() => setLocale('it')}
                    >
                        IT
                    </Link>

                    <Divider orientation="vertical" flexItem />
                    <Link
                        component="button"
                        color={locale === 'de' ? 'primary' : 'textSecondary'}
                        variant="body2"
                        onClick={() => setLocale('de')}
                    >
                        DE
                    </Link>
                    <Divider orientation="vertical" flexItem />
                    <Link
                        component="button"
                        color={locale === 'fr' ? 'primary' : 'textSecondary'}
                        variant="body2"
                        onClick={() => setLocale('fr')}
                    >
                        FR
                    </Link>
                    <Divider orientation="vertical" flexItem />
                    <Link
                        component="button"
                        color={locale === 'en' ? 'primary' : 'textSecondary'}
                        variant="body2"
                        onClick={() => setLocale('en')}
                    >
                        EN
                    </Link>
                </Box>
                <Button
                    variant="text"
                    size="small"
                    color="default"
                    className="toggle-button"
                    startIcon={<ArrowLeft />}
                    onClick={() => dispatch(toggleSideBar())}
                >
                    <p>{translate('menu.collapse')}</p>
                </Button>
            </Fragment>
        </Box>
    );
};

Menu.propTypes = {
    open: PropTypes.bool
};

Menu.defaultProps = {
    open: undefined
};

export default memo(Menu);
