const de = {
    // General
    name: `Name`,
    identity: `Identität`,
    user_profile: `Benutzerprofil`,
    username: `Nutzername`,

    password: `Passwort`,
    confirm_password: `Conferma Passwort`,
    change_password: `Passwort ändern`,
    login: `Anmeldung`,
    logout: `Ausloggen`,
    sign_in: `Anmeldung`,
    remember_me: `Mich erinnern`,
    forgotten_password: `Passwort vergessen`,
    project_manager: `Projektmanager`,
    admin: `Administrator`,
    action: `Aktion`,
    rows: `Reihen`,
    male: `Männlich`,
    neutral: `Neutral`,
    female: `Weiblich`,
    loading: `Wird geladen....`,
    go_back: `Geh zurück`,
    remove: `Entfernen`,
    add: `Hinzufügen`,
    enter: `Eingeben`,
    exit: `Ausgang`,
    // Menu
    construction_sites: `Baustellen`,
    construction_site: `Baustellen`,
    construction_sites_list: 'Liste der Baustellen',
    dashboard: `Instrumententafel`,
    journal: `Tagebuch`,
    calendar: `Kalender`,
    files: `Dateien`,
    contacts: `Kontakte`,
    hr: `HR`,
    HR: `HR`,
    profile: `Profil`,
    edit: `bearbeiten`,
    new: `Neu`,
    leaves: `Blätter`,
    expenses: `Kosten`,
    checklist_requests : "Checklistenanfragen",
    // Action
    'menu.collapse': `Menü schließen`,
    'menu.employees': `Mitarbeiterliste`,
    'menu.employees.beta': `Mitarbeiterliste (Beta)`,
    grid_display: `Rasteranzeige`,
    list_display: `Listenanzeige`,
    new_site: `Neue Site erstellen`,
    new_user: `Neuen Benutzer erstellen`,
    new_working_hours: `Arbeitsstunden hinzufügen`,
    save_working_hours: `Arbeitsstunden sparen`,
    create_working_hours: `Arbeitszeiten erstellen`,
    add_favorite: `Zu Favoriten hinzufügen`,
    notifications_settings: `Benachrichtigungen und Einstellungen`,
    mandatory_notification: 'Zwangskompilierung mit Benachrichtigungen',
    // edit: `Bearbeiten`,
    ///  Sites
    quickcomId: `Quick Id`,
    title: `Titel`,
    address: `Adresse`,
    city: `Stadt`,
    zip: `Postleitzahl`,
    country: `Land`,
    createdAt: `Hergestellt in`,
    favorite: `Favorit`,
    // Tab
    list: `Liste`,
    quick: `Schnelle Daten`,
    // journal: `Tagebuch`,
    // calendar: `Kalender`,
    // files: `Unterlagen`,
    // contacts: `Kontakte`,
    all_leave_requests: `Alle Urlaubsanträge`,
    all_expense_requests: `Alle Spesenanfragen`,
    all_festivities: `Alle Feste`,
    all_working_hours: `Alle Arbeitszeiten`,
    // admin: `Administrator`,
    tool_manager: `Werkzeugmanager`,
    all_schedules: `Alle Zeitpläne`,
    all_checklists: `Alle Checklisten`,
    permission: `Genehmigung`,
    file_log: `Dateiprotokoll`,
    notification: `Benachrichtigung`,
    ///  Users list
    lastName: `Nachname`,
    firstName: `Name`,
    phoneNumberList: `Telefonnummer`,
    email: `Email`,
    role: `Rolle`,
    // Leaves list
    user: `Nutzer`,
    type: `Art`,
    startAt: `Fange an bei`,
    endAt: `Ende um`,
    duration: `Dauer`,
    status: `Status`,
    days: `Tage`,
    approve: `Genehmigt`,
    approved: `Genehmigt`,
    rejecte: `Abgelehnt`,
    rejected: `Abgelehnt`,
    pending: `steht aus`,

    holiday: `Urlaub`,
    military: `Militär`,
    sickness: `Krankheit`,
    maternity: `Mutterschaft`,
    medical: `Medizinisch`,
    professionalAccident: `Berufsunfall`,
    nonProfessionalAccident: `Nicht beruflicher Unfall`,
    training: `Ausbildung`,
    weather: `Wetter`,
    others: `Andere`,

    // Expenses
    new_expense: `Neue Ausgaben`,
    edit_expense: `Kosten bearbeiten`,
    amount: `Menge`,
    date: `Datum`,
    reason: `Grund`,
    place: `Platz`,
    // Working hours
    winter: `Winter`,
    summer: `Sommer`,
    // Profile
    edit_user: `Benutzer bearbeiten`,
    // profile: `Profil`,
    identify: `Identität`,
    personal_calendar: `Persönlicher Kalender`,
    // expenses: `Kosten`,
    leaves_and_holidays: `Blätter und Feiertage`,
    upload_file: `Datei wählen`,
    file_name: `Dateinamen`,
    // Profile Edit
    discard_changes: `Änderungen verwerfen`,
    deactivate_user: `Benutzer deaktivieren`,

    personal_contacts: `Persönliche Kontakte`,
    personal_address: `Persönliche Adresse`,
    personal_e_mail: `Persönliche E-Mail`,
    personal_phone_number: `Persönliche Telefonnummer`,
    birth_date: `Geburtstag`,
    work_contacts: `Arbeitskontakte`,
    work_email: `Arbeits Email`,
    work_phone_number: `Arbeitstelefonnummer`,
    change_picture: `Bild ändern`,

    appearance: `Personalisierung`,
    notifications: `Benachrichtigungen`,
    'switches.dark_mode': `Wechseln Sie in den Dunkelmodus`,
    'switches.light_mode': `Wechseln Sie in den Lichtmodus`,

    // others: `Andere`,
    // User Profile HR
    hr_data: `HR-Daten`,
    badge_id: `Ausweis-ID`,
    civil_status: `Zivilstand`,
    children_number: `Kindernummer`,
    mother_language: `Muttersprache`,
    company: `Unternehmen`,
    associated_company: `Assoziiertes Unternehmen`,
    hired_at: `Angestellt bei`,
    terminated_at: `Beendet bei`,
    //
    basic_data: `Grundinformationen`,
    birthday: `Geburtstag`,
    gender: `Geschlecht`,
    personal_email: `Persönliche E-Mail`,
    personal_mobile_number: `Persönliche Handynummer`,
    personal_home_number: `Persönliche Privatnummer`,
    work_mobile_number: `Handynummer arbeiten`,
    work_office_number: `Arbeitsbüro Nummer`,
    work_c_3_x_number: `3CX-Nummer arbeiten`,
    //
    working_hours: `Arbeitszeit`,
    work_percentage: `Arbeitsprozentsatz`,
    holidays_count_days: `Feiertage zählen`,
    break_time_h: `Pause`,
    day: `Tag`,
    winter_start: `Winterstart`,
    winter_end: `Winterende`,
    summer_start: `Sommerstart`,
    summer_end: `Sommerende`,
    //
    legal_documents: `Legale Dokumente`,
    edit_legal_document: `Rechtsdokument bearbeiten`,
    new_legal_document: `Neues Rechtsdokument`,
    expireat: `Verfallsdatum`,
    numberDoc: `Hinweis`,
    //
    signed_documents: `Signierte Dokumente`,
    document_not_present: `Es gibt keine Dokumente`,
    at_least_one_document: `Es muss mindestens ein Dokument vorhanden sein *`,
    edit_signed_document: `Signiertes Dokument bearbeiten`,
    new_signed_document: `Neues signiertes Dokument`,

    the_search_did_not_return_any_results: `Die Suche ergab keine Ergebnisse`,

    // User Leaves HR
    // leaves: `Blätter`,
    new_leave: `Neuer Urlaub`,
    edit_leave: `Urlaub bearbeiten`,
    leaves_request: `Verlässt Anfrage`,
    my_leaves_request: `Meine Urlaubsanfrage`,
    request_reimbursement_expenses: `Antrag auf Kostenerstattung`,
    export_my_expense: `Meine Spesenabrechnung exportieren`,
    holidays_allowance: `Urlaubsgeld`,
    remaining: `Verbleibend`,
    taken: `Genommen`,
    pending_requests: `Offene Anfragen`,
    // sickness: `Krankheit`,
    // military: `Militär`,
    paternity: `Vaterschaft`,
    medical_examination: `Ärztliche Untersuchung`,
    professional_accident: `Berufsunfall`,
    non_professional_accident: `Nicht beruflicher Unfall`,
    training_course: `Trainingskurs`,
    weather_conditions: `Wetterverhältnisse`,
    other: `Andere`,

    from: `Von`,
    to: `Zu`,
    // type: `Art`,
    description: `Beschreibung`,
    // duration: `Dauer`,
    // status: `Status`,
    // User Expenses HR

    expenses_request: `Kostenanfrage`,
    my_expenses_request: `Meine Spesenanfrage`,
    submit_new_leave: `Neuen Urlaub einreichen`,
    submit_new_expense: `Neue Kosten einreichen`,

    currency: `Währung`,
    changedChf: `Geändert`,
    quantity: `Menge`,
    isEquipment: `Ist Ausrüstung`,
    whoApproveId: `Wer hat zugestimmt?`,
    chf: `CHF`,
    usd: `US Dollar`,
    // legalMaritalStatus
    married: `Verheiratet`,
    widowed: `Verwitwet`,
    separated: `Getrennt`,
    divorced: `Geschieden`,
    single: `Single`,
    // Languages
    german: `Deutsche`,
    french: `Französisch`,
    italian: `Italienisch`,
    english: `Englisch`,
    // Companies
    tigestim: `Tigestim`,
    metalsky: `Metalsky`,
    sitaf: `Sitaf`,
    metalpiega_color: `Metal Piega & Color`,
    edemar: `Edemar`,
    tmwood: `Tm Wood`,
    electrogestim: `Electrogestim`,
    // Messages
    user_saved_successfully: `Benutzer erfolgreich gespeichert`,
    working_hours_saved_successfully: `Arbeitsstunden erfolgreich gespeichert`,
    uploaded_successfully: `Hochladen erfolgreich`,
    upload_failed: `Upload fehlgeschlagen`,
    user_already_exists: `Benutzer existiert bereits`,
    passwords_do_not_match: `Passwörter stimmen nicht überein`,
    // Calendar
    'date.from': `Von:`,
    'date.to': `Zu:`,
    'filters.all': `Alle ansehen`,
    'filters.festivity': `Feierlichkeiten`,
    'filters.events': `Veranstaltungen`,
    'eventTypes.festivity': `Fest`,
    'eventTypes.events': `Veranstaltung`,
    'eventTypes.reports': `Bericht`,
    'eventTypes.personRequests': `Verlassen`,
    'eventTypes.stampingLogs': `Timbratura`,
    // Validation
    validationMessagePassword: `Enthalten mindestens: 8 alphanumerische Zeichen, 1 Zahl, 1 Kleinbuchstabe (a-z), 1 Großbuchstabe (A-Z). Das Passwort darf nur diese Sonderzeichen enthalten! @ # $% ^ &`,
    validationMessageEmail: `Ungültige E-Mail`,

    // Journals
    load_more_journal_posts: `Wird geladen...`,
    Journal: `Tagebuch`,
    edit_post: `Beitrag bearbeiten`,
    new_journal: `Neuer Journaleintrag`,
    create_journal: `Neuer Journaleintrag`,
    save_journal: `speichern`,
    delete_journal: `Löschen`,
    see_all_comments: `Alle Kommentare anzeigen`,
    see_all: `Alle anzeigen`,
    like: `Mögen`,
    add_comment: `Einen Kommentar hinzufügen`,
    share: `Aktie`,
    add_new_journal_entry: `Neuen Journaleintrag hinzufügen`,
    drag_and_drop_an_image_here_or_click: `"Ziehen Sie ein Bild hierher und legen Sie es ab oder klicken Sie auf"`,
    edit_journal_entry: `Journaleintrag bearbeiten`,
    message: `Botschaft`,
    thumbnail: `Miniaturansicht`,
    upload_picture: `Bild hochladen`,
    iva: `IVA`,
    es_3_15: `Es. 3,15%`,
    journal_saved_successfully: `Journalstunden erfolgreich gespeichert`,

    // Contact
    edit_contact: `Kontakte bearbeiten`,

    // Right
    all_rights_reserved: `Alle Rechte vorbehalten`,
    support: `Unterstützung`,
    contact: `Kontakt`,
    version: `Ausführung`,
    journal_new_user: 'Neuer Benutzer',
    journal_auto_description: `Der Benutzer <USER> wurde gerade zur Website hinzugefügt 🥳`
};

export default de;
