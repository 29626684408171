import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { typeIcons } from '../../constants/options';

const FileIconCell = ({ item, onItemClick }) => {
    const Icon = typeIcons(item.type);
    return (
        <Box width={1} height={1} display="flex" alignItems="center" justifyContent="flex-start" onClick={() => onItemClick(item)}>
            {Icon && <Icon />}
        </Box>
    );
};

FileIconCell.propTypes = {
    item: PropTypes.object.isRequired,
    onItemClick: PropTypes.func,
};
FileIconCell.defaultProps = {
    onItemClick: undefined,
};

export default FileIconCell;
