/* eslint-disable react/jsx-no-duplicate-props */
import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import { isDef } from '../../utils/common';
import useTranslate from '../../hooks/useTranslate';

const useStyles = makeStyles(theme => ({
    none: {
        padding: theme.spacing(0.5),
        width: '100%',
        textAlign: 'right',
    },
}));

const NumberFormatCustom = props => {
    const {
        inputRef,
        onChange,
        decimalScale,
        maxDigits,
        maxValue,
        minValue,
        allowNegative,
        fixedDecimalScale,
        thousandSeparator,
        padding,
        ...other
    } = props;
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();
    return (
        <NumberFormat
            {...other}
            style={padding === 'none' ? { padding: '4px', width: '100%', textAlign: 'right' } : undefined}
            getInputRef={inputRef}
            isAllowed={values => {
                const { value } = values;
                return value !== '-' && (!maxDigits || value.length <= maxDigits);
            }}
            onValueChange={values => {
                const { value, floatValue } = values;
                if (isDef(floatValue)) {
                    let finalValue = floatValue;
                    if (maxValue) finalValue = floatValue <= maxValue ? finalValue : maxValue;
                    if (minValue) finalValue = floatValue >= minValue ? finalValue : minValue;
                    onChange(finalValue);
                } else {
                    onChange(value);
                }
            }}
            allowNegative={allowNegative}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            thousandSeparator={thousandSeparator}
            isNumericString
        />
    );
};

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    decimalScale: PropTypes.number,
    fixedDecimalScale: PropTypes.bool,
    thousandSeparator: PropTypes.string,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    maxDigits: PropTypes.number,
    allowNegative: PropTypes.bool,
    padding: PropTypes.string,
};

NumberFormatCustom.defaultProps = {
    decimalScale: undefined,
    fixedDecimalScale: undefined,
    thousandSeparator: undefined,
    maxValue: undefined,
    minValue: undefined,
    maxDigits: undefined,
    padding: 'normal',
    allowNegative: false,
};

const EnhancedNumberField = ({
    className,
    value,
    onChange,
    label,
    name,
    disabled,
    unit,
    readOnly,
    maxDigits,
    maxValue,
    minValue,
    decimalScale,
    fixedDecimalScale,
    thousandSeparator,
    allowNegative,
    inputRef,
    error,
    onBlur,
    onKeyPress,
    fullWidth,
    variant,
    margin,
    padding,
    size,
    required,
}) => {
    const translate = useTranslate();
    return (
        <TextField
            className={className}
            label={translate(label)}
            name={name}
            inputProps={{ maxDigits, maxValue, minValue, decimalScale, fixedDecimalScale, thousandSeparator, allowNegative, onChange, padding }}
            InputProps={{
                readOnly,
                inputComponent: NumberFormatCustom,
                endAdornment: unit && <InputAdornment position="end">{translate(unit)}</InputAdornment>,
                // disableUnderline: readOnly,
            }}
            required={required}
            size={size}
            margin={margin}
            fullWidth={fullWidth}
            variant={readOnly ? 'standard' : variant}
            inputRef={inputRef}
            disabled={disabled}
            value={isDef(value) ? value : ''}
            error={error}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
        />
    );
};

EnhancedNumberField.propTypes = {
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    decimalScale: PropTypes.number,
    fixedDecimalScale: PropTypes.bool,
    thousandSeparator: PropTypes.string,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    maxDigits: PropTypes.number,
    unit: PropTypes.string,
    readOnly: PropTypes.bool,
    inputRef: PropTypes.object,
    error: PropTypes.bool,
    allowNegative: PropTypes.bool,
    onBlur: PropTypes.func,
    onKeyPress: PropTypes.func,
    fullWidth: PropTypes.bool,
    variant: PropTypes.string,
    size: PropTypes.string,
    margin: PropTypes.string,
    padding: PropTypes.string,
    required: PropTypes.bool,
};

EnhancedNumberField.defaultProps = {
    className: undefined,
    value: '',
    size: 'small',
    margin: 'none',
    padding: 'normal',
    name: undefined,
    onChange: undefined,
    disabled: undefined,
    decimalScale: undefined,
    fixedDecimalScale: undefined,
    thousandSeparator: undefined,
    maxValue: undefined,
    minValue: undefined,
    maxDigits: undefined,
    unit: undefined,
    readOnly: undefined,
    inputRef: undefined,
    error: undefined,
    allowNegative: undefined,
    onBlur: undefined,
    onKeyPress: undefined,
    fullWidth: true,
    variant: undefined,
    required: undefined,
};

export default EnhancedNumberField;
