import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import logo from '../../../resources/LogoTigestimLight.png';
import { startMonthsDate, endMonthsDate } from '../../../utils/formats';
import ViewColumn from '../../../components/pdf/ViewColumn';

const styles = StyleSheet.create({
    pageHeader: {
        marginBottom: 8,
        alignItems: 'flex-start',
        fontSize: 11,
    },
});
const width = 150;

const RequestExoensesPageHeader = ({ data, translate }) => {
    const { date, user } = data || {};
    const { firstName, lastName } = user || {};

    return (
        <View style={styles.pageHeader}>
            <View
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingBottom: 10,
                    paddingTop: 10,
                }}
            >
                <ViewColumn width={width * 3}>
                    <Text style={{ flexDirection: 'row', paddingRight: 4, fontWeight: 700, paddingBottom: 10, textTransform: "uppercase" }}>{`${translate('request_reimbursement_expenses')}`}</Text>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={{ paddingRight: 4, paddingBottom: 10 }}>{`${translate('date')}:`}</Text>
                        <Text style={{ fontWeight: 700, paddingBottom: 10 }}>{`${startMonthsDate(date, translate)} - ${endMonthsDate(date, translate)}`}</Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={{ paddingRight: 4, paddingBottom: 10 }}>Dipendente nome/cognome:</Text>
                        <Text style={{ fontWeight: 700, paddingBottom: 10 }}>{`${firstName} ${lastName}`}</Text>
                    </View>
                </ViewColumn>
                <Image src={logo} style={{ width: '185px' }} />
            </View>
        </View>
    );
};

RequestExoensesPageHeader.propTypes = {
    data: PropTypes.object,
    translate: PropTypes.func.isRequired
};

RequestExoensesPageHeader.defaultProps = {
    data: {},
};

export default RequestExoensesPageHeader;
