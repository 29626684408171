import Amplify, { Auth } from 'aws-amplify';
import axios from 'axios';
import config from '../configuration/amplify';

Amplify.configure(config);

export default () => ({
    login: async ({ username, password }) => {
        const cognitoData = await Auth.signIn({
            username,
            password,
        });

        const { jwtToken } = cognitoData?.signInUserSession?.idToken || {};

        axios.defaults.headers.common = {
            Authorization: `Bearer ${jwtToken}`,
        };
        // window.localStorage.setItem('token', jwtToken);
        // window.localStorage.setItem('role', 'admin');
        // window.localStorage.removeItem('not_authenticated');
        return Promise.resolve();
    },
    logout: async () => {
        await Auth.signOut();
        // window.localStorage.setItem('not_authenticated', true);
        // window.localStorage.removeItem('role');
        // window.localStorage.removeItem('token');
        return Promise.resolve();
    },
    checkError: error => {
        const { status } = error || {};
        return status === 401 || status === 403 ? Promise.reject() : Promise.resolve();
    },
    checkAuth: async () => {
        await Auth.currentAuthenticatedUser();
        return Promise.resolve();
    },
    getPermissions: () => {
        const role = window.localStorage.getItem('role');
        return Promise.resolve(role);
    },
});
