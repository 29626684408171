import React from 'react'
import './spiders.css'

const spiders = Array.from(Array(6).keys())

const Spiders = () => {
    return (
        <>
            {spiders.map((spider) => ( 
                <div className={`spider_${spider}`}>
                    <div className="eye left" />
                    <div className="eye right" />

                    <span className="leg left" />
                    <span className="leg left" />
                    <span className="leg left" />
                    <span className="leg left" />

                    <span className="leg right" />
                    <span className="leg right" />
                    <span className="leg right" />
                    <span className="leg right" />
                </div>
            ))}
        </>
    )
}

export default Spiders
