import { Grid } from '@material-ui/core';
import { Event } from '@material-ui/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Actions from '../../components/Actions';
import EnhancedCalendar from '../../components/calendar/EnhancedCalendar';
import useFetch from '../../hooks/useFetch';
import { dailyHoursService } from '../../store/proxies/tigestimProxies';
import { selectItem } from '../../store/slices/sliceNavigation';
import { selectUser } from '../../store/slices/sliceSession';
import { formatServerDate } from '../../utils/formats';


const UserDailyHoursCalendarView = ({ id, actions }) => {
    const user = useSelector(id ? selectItem : selectUser);
    const [data, setData] = useState([]);
    const [date, setDate] = useState(moment());
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    useEffect(() => {
        if (date) {
            setStartDate(formatServerDate(date.clone().startOf('month').subtract(7, 'days'), 'YYYY-MM-DD'));
            setEndDate(formatServerDate(date.clone().endOf('month').add(7, 'days'), 'YYYY-MM-DD'));
        }
    }, [date]);

    const [response, isLoading] = useFetch(
        dailyHoursService.calendar,
        { id: id || user.id, startDate, endDate },
        [id || user.id, startDate, endDate],
        [id || user.id, startDate, endDate],
        []
    );

    useEffect(() => {
        if(response.length){
            setData(response);
        }
    }, [response]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Actions actions={actions} />
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <EnhancedCalendar
                        icon={Event}
                        isLoading={isLoading}
                        title="personal_calendar"
                        selectedDay={date.toDate()}
                        schedulerData={data}
                        onChange={value => {
                            setDate(moment(value));
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

UserDailyHoursCalendarView.propTypes = {
    actions: PropTypes.array,
    id: PropTypes.number,
};

UserDailyHoursCalendarView.defaultProps = {
    id: 0,
    actions: [],
};

export default UserDailyHoursCalendarView;
