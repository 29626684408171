import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const FlexColumn = ({children, ...props}) => {
    return (
        <Box display="flex" flexDirection="column" {...props}>
            {children}
        </Box>
    );
};

FlexColumn.propTypes = {
    children: PropTypes.node.isRequired,
    props: PropTypes.object,
};
FlexColumn.defaultProps = {
    props: {},
};

export default FlexColumn;
