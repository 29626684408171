import 'moment/locale/it-ch';
import React, { Fragment } from 'react';
import { ThemeProvider } from '@material-ui/styles';

import { useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import Layout from './components/dashboard/Layout';
import EnhancedDialog from './components/dialog/EnhancedDialog';
import EnhancedNotification from './components/notification/EnhancedNotification';
import history from './components/routings/history';
import PrivateRoute from './components/routings/PrivateRoute';
import PublicRoute from './components/routings/PublicRoute';
import ErrorPage from './components/utilities/ErrorPage';
import { baseUrlApp, baseUrlAuth } from './constants/originUrlConstants';
import Login from './view/authentication/Login';

import lightTheme from './themes/lightTheme';
import darkTheme from './themes/darkTheme';
import { selectDarkMode } from './store/slices/sliceAppearance';

function App() {
    const darkMode = useSelector(selectDarkMode);

    return (
        <Fragment>
            <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
                <CssBaseline />
                <Router history={history}>
                    <Switch>
                        <Route exact path="/" render={() => <Redirect to={`${baseUrlApp}`} />} />
                        <PrivateRoute path={`${baseUrlApp}`} component={Layout} />
                        <PublicRoute exact path={`${baseUrlAuth}/login`} component={Login} />
                        <Route component={ErrorPage} />
                    </Switch>
                </Router>
                <EnhancedDialog />
                <EnhancedNotification />
            </ThemeProvider>
        </Fragment>
    );
}

export default App;
