
import constructionSites from './routes/construction_sites';
import hr from './routes/hr';
import permission from './routes/permission';
import settings from './routes/settings';

export const routes = [
    constructionSites,
    hr,
    settings,
    permission,
    // {
    //     title: 'file_log',
    //     key: uuid(),
    //     path: 'file-log',
    //     to: 'file-log',
    //     icon: FormatListBulleted,
    // },
    // {
    //     title: 'notification',
    //     key: uuid(),
    //     path: 'notification',
    //     to: 'notification',
    //     icon: Notifications,
    // },
];
