/* eslint-disable react/jsx-curly-newline */
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { baseUrlAuth } from '../../constants/originUrlConstants';
import useAuth from '../../hooks/useAuth';
import cookie from './cookie';

const PrivateRoute = ({ component: Component, path, exact, location }) => {
    useAuth();

    return (
        <Route
            path={path}
            exact={exact}
            render={() =>
                cookie.present() ? (
                    <Component />
                ) : (
                    <Redirect
                        to={{
                            pathname: `${baseUrlAuth}/login`,
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

PrivateRoute.propTypes = {
    path: PropTypes.string,
    exact: PropTypes.bool,
    component: PropTypes.func.isRequired,
    location: PropTypes.object,
};
PrivateRoute.defaultProps = {
    location: undefined,
    path: undefined,
    exact: undefined,
};

export default PrivateRoute;
