import { Box, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { baseUrlApp } from '../../constants/originUrlConstants';
import useLocationMenu from '../../hooks/useLocationMenu';
import history from '../routings/history';
import useTranslate from '../../hooks/useTranslate';

const useStyles = makeStyles(theme => ({
    item: paddingLefth => ({
        paddingTop: paddingLefth > 2 ? theme.spacing(1 / 2) : theme.spacing(1),
        paddingBottom: paddingLefth > 2 ? theme.spacing(1 / 2) : theme.spacing(1),
    }),
    icon: {
        minWidth: 32,
        color: theme.palette.menu.icon,
    },
    selected: {
        backgroundColor: theme.palette.menu.selected,
        borderLeft: `3px solid ${theme.palette.primary.main}`,
    },
}));

const iconPaddingLeft = 4;
const stePaddingLeft = 2;

const MenuItem = ({
    menuItem: {
        icon: Icon,
        title,
        children,
        path,
        rootPath,
        to,
        selectable = true,
        clickable = true,
        tooltip,
        accordion = false,
        AccordionComponent = false,
        accordionComponentProps = {},
        visible = true,
    },
    paddingLeft = 0,
}) => {
    const classes = useStyles(paddingLeft);
    const selected = useLocationMenu(rootPath ? `${rootPath}/${path}` : path);
    const translate = useTranslate();

    return visible ? (
        <Box borderBottom={paddingLeft === 0 ? '1px solid rgba(0, 0, 0, 0.13)' : undefined}>
            {accordion ? (
                <AccordionComponent
                    {...{
                        ...accordionComponentProps,
                        paddingLeft: paddingLeft + 44,
                        title,
                    }}
                />
            ) : (
                <ListItem
                    classes={{ root: classes.item, selected: classes.selected }}
                    selected={selectable && selected && !children}
                    button
                    onClick={() => {
                        if (clickable) {
                            history.push(`${baseUrlApp}/${to}`);
                        }
                    }}
                >
                    <Box pl={paddingLeft + (Icon ? 0 : iconPaddingLeft)} display="flex" flexDirection="row">
                        {Icon && (
                            <Box display="flex" alignItems="center" pr={1}>
                                <ListItemIcon className={classes.icon}>
                                    <Icon className='menu-icon'/>
                                </ListItemIcon>
                            </Box>
                        )}
                        {tooltip ? (
                            <Tooltip title={tooltip} placement="right" enterDelay={1000}>
                                <ListItemText
                                    type="dense"
                                    primary={translate(title)}
                                    primaryTypographyProps={{
                                        variant: 'body2',
                                        style: {
                                            fontWeight: selected ? 'bold' : undefined,
                                        },
                                    }}
                                />
                            </Tooltip>
                        ) : (
                            <ListItemText
                                type="dense"
                                primary={translate(title)}
                                primaryTypographyProps={{
                                    variant: 'body2',
                                    style: {
                                        fontWeight: selected ? 'bold' : undefined,
                                    },
                                }}
                            />
                        )}
                    </Box>
                </ListItem>
            )}

            {children && (
                <List component="div" disablePadding>
                    {children.map(child => {
                        return (
                            <MenuItem
                                paddingLeft={paddingLeft + stePaddingLeft}
                                menuItem={{ ...child, rootPath: path }}
                                key={child.key}
                                accordion={accordion}
                                AccordionComponent={AccordionComponent}
                                accordionComponentProps={accordionComponentProps}
                            />
                        );
                    })}
                </List>
            )}
        </Box>
    ) : (
        ''
    );
};

MenuItem.propTypes = {
    menuItem: PropTypes.object.isRequired,
    paddingLeft: PropTypes.number,
};

MenuItem.defaultProps = {
    paddingLeft: 0,
};

export default MenuItem;
