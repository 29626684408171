import { createSlice } from '@reduxjs/toolkit';

const sliceAppearance = createSlice({
    name: 'appearance',
    initialState: {
        darkMode: localStorage.getItem('darkMode') === 'true' || false,
    },
    reducers: {
        toggleDarkMode: state => {
            localStorage.setItem('darkMode', !state.darkMode);
            state.darkMode = !state.darkMode;
        },
    },
});

export default sliceAppearance;

// ACTIONS
export const { toggleDarkMode } = sliceAppearance.actions;

export const selectDarkMode = state => state.appearance.darkMode;
