import { ArrowBackIos, Check, Delete, Edit, ViewList } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import useDataFetch from '../../hooks/useDataFetch';
import { invokeCreate, invokeDelete, invokeOrReturn, invokeSave } from '../../utils/common';
import Actions from '../Actions';
import EnhancedForm from '../EnhancedForm';
import FlexColumn from '../flex/FlexColumn';
import history from '../routings/history';

const resolveForm = form => {
    if (Array.isArray(form)) return { columns: 1, items: form };
    return form;
};

const defaultActions = (form, create, save, remove) =>
    [
        create && {
            label: 'create',
            icon: Check,
            validation: form,
            onClick: (value, setLoading) =>
                invokeCreate(create.proxy, create.filter ? create.filter(value) : value, setLoading, create.success, create.error),
        },
        save && {
            label: 'save_changes',
            icon: Check,
            validation: form,
            onClick: (value, setLoading) => invokeSave(save.proxy, save.filter ? save.filter(value) : value, setLoading, save.success, save.error),
        },
        remove && {
            label: 'delete',
            icon: Delete,
            color: 'error.main',
            onClick: (value, setLoading) =>
                invokeDelete(remove.proxy, value, {
                    setLoading,
                    successMessage: remove.success,
                    errorMessage: remove.error,
                    back: true,
                }),
        },
        { label: 'discard_changes', icon: ArrowBackIos, onClick: () => history.goBack() },
    ].filter(item => !!item);

const FormView = ({ id, title, proxy, get, create, save, delete: remove, actions, form, readOnly, handleResponse, defaultValue, ids }) => {
    const [data, onChange, isLoading, setData] = useDataFetch(proxy ?? get?.proxy, id, [id], [id], defaultValue, handleResponse);

    // useEffect(() => {
    //     if (data) console.log('file: FormView.js ~ line 41 ~ data', data);
    // }, [data]);

    return (
        <FlexColumn>
            <Actions actions={actions ?? defaultActions(resolveForm(form).items, create, save, remove)} data={data} />
            <EnhancedForm
                title={invokeOrReturn(title, data)}
                icon={readOnly ? ViewList : Edit}
                data={data}
                isLoading={isLoading}
                readOnly={readOnly}
                onChange={onChange}
                setData={setData}
                ids={ids}
                {...resolveForm(form)}
            />
        </FlexColumn>
    );
};

FormView.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    form: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    actions: PropTypes.array,
    defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    get: PropTypes.object,
    create: PropTypes.object,
    delete: PropTypes.object,
    save: PropTypes.object,
    handleResponse: PropTypes.func,
    id: PropTypes.number,
    ids: PropTypes.object,
    proxy: PropTypes.object,
    readOnly: PropTypes.bool,
};

FormView.defaultProps = {
    ids: undefined,
    id: undefined,
    proxy: undefined,
    create: undefined,
    get: undefined,
    save: undefined,
    delete: undefined,
    actions: undefined,
    readOnly: undefined,
    handleResponse: undefined,
    defaultValue: {},
};

export default FormView;
