import { makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from '../../hooks/useTranslate';

const useStyles = makeStyles(() => ({
    toolbarContent: {
        flex: '1 0 0',
    },
    emptyToolbar: {
        flex: '0 0 0',
        marginLeft: 'auto',
    },
    filtersWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexDirection: 'row',
    },
}));

const ToolbarContent = ({ filter, setFilter, eventTypes }) => {
    const classes = useStyles();
    const translate = useTranslate();

    const handleChangeValue = e => {
        setFilter(e.target.value);
    };

    return (
        <div className={classes.toolbarContent}>
            <div className={classes.filtersWrapper}>
                <FormControl variant="outlined" size="small">
                    <Select value={filter} onChange={handleChangeValue}>
                        <MenuItem value="all">
                            <em>{translate('filters.all')}</em>
                        </MenuItem>
                        {eventTypes.map(eventType => (
                            <MenuItem key={`${eventType}}`} value={eventType}>
                                {translate(`filters.${eventType}`)}{' '}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};

ToolbarContent.propTypes = {
    eventTypes: PropTypes.array,
    filter: PropTypes.string,
    setFilter: PropTypes.func,
};

ToolbarContent.defaultProps = {
    eventTypes: [],
    filter: 'all',
    setFilter: () => {},
};

export default ToolbarContent;
