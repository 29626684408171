import { Avatar, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { buildUrl } from '../../utils/common';

const useStyles = makeStyles(theme => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    normal: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    large: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
}));

const AvatarCell = ({ size, item, id }) => {
    const classes = useStyles();
    return <Avatar className={classes[size]} src={buildUrl(item[id])} />;
};

AvatarCell.propTypes = {
    size: PropTypes.oneOf(['small', 'normal', 'large']),
    item: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
};
AvatarCell.defaultProps = {
    size: 'normal',
};

export default AvatarCell;
