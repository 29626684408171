import React from "react";

export default function useIntersectionObserver(
    rootRef,
    targetRef,
    onIntersectCallback,
    isLoading
) {
    React.useEffect(() => {
        if (rootRef?.current && targetRef?.current && !isLoading) {
            const interceptConfig = {
                root: rootRef.current,
                rootMargin: "10px",
                threshold: 0.1,
            };

            const observer = new IntersectionObserver((entries) => {
                if (entries.every((entry) => entry.isIntersecting)) {
                    onIntersectCallback();
                }
            }, interceptConfig);

            observer.observe(targetRef.current);
            return () => {
                observer.disconnect();
            };
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rootRef?.current, targetRef?.current]);
}
