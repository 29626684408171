import { Box, LinearProgress, TextField, Chip } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useFetch from '../../hooks/useFetch';
import useTranslate from '../../hooks/useTranslate';
import { selectIsLoading } from '../../store/slices/sliceFetching';
import { isNotDef, resolveValue } from '../../utils/common';

const useStyles = makeStyles(() => ({
    input: {
        padding: 0,
        paddingLeft: 5,
        paddingRight: 5,
    },
}));

const styles = {
    none: {
        paddingTop: 1,
        paddingBottom: 3,
        paddingLeft: 5,
        paddingRight: 5,
    },
};

const EnhancedAutocomplete = ({
    value,
    label,
    options: externalOptions,
    onChange,
    getOptionLabel,
    getOptionSelected,
    getOptionValue,
    multiple,
    size,
    margin,
    freeSolo,
    noOptionsText,
    disable,
    disabled,
    error,
    disableClearable,
    onInputChange,
    renderOption,
    placeholder,
    getOptionReturnValue,
    getItemValue,
    readOnly,
    urlLoading,
    groupBy,
    variant,
    required,
    optionsFetch,
    inputPadding,
    ...props
}) => {
    const classes = useStyles();
    const [option, setOption] = useState();
    const [options, setOptions] = useState(externalOptions);
    const isLoading = useSelector(selectIsLoading(urlLoading));
    const translate = useTranslate();

    const [fetchedOptions, isOptionsLoading] = useFetch(
        optionsFetch.proxy,
        optionsFetch.params,
        optionsFetch.updates,
        optionsFetch.start,
        optionsFetch.defaultValue ?? null,
        optionsFetch.handleResponse
    );

    useEffect(() => {
        setOptions(fetchedOptions || externalOptions);
    }, [fetchedOptions, externalOptions]);

    useEffect(() => {
        if (multiple) {
            setOption(options.filter(option => (value || []).some(item => getOptionValue(option) === getItemValue(item))));
        } else {
            setOption(options.find(option => getOptionValue(option) === getItemValue(value)));
        }
    }, [getItemValue, getOptionValue, multiple, options, value]);

    return (
        <Autocomplete
            readOnly={readOnly}
            // disabledUnderline={disabledUnderline}
            popupIcon={readOnly ? null : undefined}
            disableClearable={disableClearable ?? readOnly}
            fullWidth
            open={readOnly ? false : undefined}
            value={resolveValue(option, multiple ? [] : null)}
            multiple={multiple}
            freeSolo={freeSolo}
            disabled={disabled || disable(resolveValue(option, multiple ? [] : {}))}
            noOptionsText={translate(noOptionsText)}
            options={options}
            filterOptions={createFilterOptions({
                ignoreAccents: true,
                stringify: props => getOptionLabel(props, translate),
            })}
            onChange={(event, selectedOption) => {
                if (selectedOption) {
                    if (multiple) {
                        onChange(selectedOption.map(selected => (getOptionReturnValue || getOptionValue)?.(selected)));
                    } else {
                        onChange((getOptionReturnValue || getOptionValue)?.(selectedOption));
                    }
                } else {
                    onChange(selectedOption);
                }
            }}
            getOptionLabel={optionToShow => {
                if (isNotDef(optionToShow)) return '';
                return getOptionLabel(optionToShow, translate);
            }}
            getOptionSelected={getOptionSelected}
            ChipProps={{ size }}
            renderTags={(value, getTagProps) => {
                return value.map((item, index) => {
                    const { onDelete, ...propsChip } = getTagProps({ index });
                    return <Chip {...propsChip} size="small" label={getOptionLabel(item, translate)} onDelete={readOnly ? undefined : onDelete} />;
                });
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    fullWidth
                    label={translate(label)}
                    margin={margin}
                    size={size}
                    required={required}
                    error={error}
                    placeholder={translate(placeholder)}
                    variant={readOnly ? 'standard' : variant}
                    className={classes.root}
                    InputProps={{
                        ...params.InputProps,
                        readOnly,
                        // disableUnderline: disableUnderline || readOnly,
                        // className: classes.input,
                        style: styles[inputPadding],
                    }}
                    InputLabelProps={{
                        ...params.InputLabelProps,
                        shrink: readOnly ? true : undefined,
                    }}
                />
            )}
            renderOption={renderOption}
            onInputChange={event => {
                const { value } = event?.target || {};
                if (value) onInputChange(value);
            }}
            groupBy={groupBy}
            loading={isLoading || isOptionsLoading}
            loadingText={
                <Box>
                    <LinearProgress />
                    {translate('loading')}
                </Box>
            }
            autoHighlight
            autoSelect
            {...props}
        />
    );
};

EnhancedAutocomplete.propTypes = {
    disable: PropTypes.func,
    disableClearable: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    freeSolo: PropTypes.bool,
    getItemValue: PropTypes.func,
    getOptionLabel: PropTypes.func,
    getOptionReturnValue: PropTypes.func,
    getOptionSelected: PropTypes.func,
    getOptionValue: PropTypes.func,
    groupBy: PropTypes.func,
    inputPadding: PropTypes.string,
    label: PropTypes.string,
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),
    multiple: PropTypes.bool,
    noOptionsText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onInputChange: PropTypes.func,
    options: PropTypes.array,
    optionsFetch: PropTypes.object,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    renderOption: PropTypes.func,
    required: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium']),
    urlLoading: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array]),
    variant: PropTypes.string,
};

EnhancedAutocomplete.defaultProps = {
    options: [],
    multiple: false,
    value: undefined,
    variant: 'outlined',
    label: '',
    size: 'small',
    margin: 'none',
    noOptionsText: 'no_options',
    freeSolo: false,
    getOptionLabel: ({ label }, translate) => `${translate(label)}`,
    getOptionValue: ({ value }) => value,
    getItemValue: value => value,
    getOptionSelected: undefined, // (option, item) => option?.value === item.value,
    disable: () => false,
    error: false,
    disableClearable: undefined,
    onInputChange: () => {},
    renderOption: undefined,
    getOptionReturnValue: undefined,
    disabled: undefined,
    readOnly: undefined,
    placeholder: undefined,
    urlLoading: undefined,
    groupBy: undefined,
    required: undefined,
    inputPadding: undefined,
    optionsFetch: {},
};

export default EnhancedAutocomplete;
