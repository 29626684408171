import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { baseUrlApp } from '../constants/originUrlConstants';
import { getPerson, getSite } from '../store/proxies/tigestimProxies';
import { selectItem, setItem } from '../store/slices/sliceNavigation';
import useFetch from './useFetch';

const handleResponsePerson = response => {
    const { users = {}, employees = [] } = response || {};
    const [employee = {}] = employees;
    const { summerstarthr, summerendthr, winterstarthr, winterendthr, breakTime } = employee;

    return {
        ...users,
        badgeId: employee.badgeId,
        breakTime: Number.parseFloat(breakTime, 10),
        summerstarthr,
        summerendthr,
        winterstarthr,
        winterendthr,
    };
};

const fetch = (first, second) => {
    switch (first) {
        case 'sites':
            return { proxy: getSite };
        case 'hr':
        case 'admin':
            if (second === 'users') return { proxy: getPerson, handleResponse: handleResponsePerson };
            return undefined;
        default:
            return undefined;
    }
};

const useLocationItem = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [first, second, thirt] = location.pathname.replace(`${baseUrlApp}/`, '').split('/');

    const [id, setId] = useState();
    const item = useSelector(selectItem);

    const { proxy, handleResponse } = fetch(first, second) || {};

    const [response] = useFetch(proxy, id, [id, proxy], [id, item?.id !== id, proxy], {}, handleResponse);

    useEffect(() => {
        if (proxy) {
            const thirtNumber = Number(thirt);
            if (Number.isInteger(thirtNumber)) setId(thirtNumber);
        } else {
            setId(undefined);
            setItem(undefined);
        }
    }, [proxy, thirt]);

    useEffect(() => {
        if (Object.keys(response).length > 0) dispatch(setItem(response));
    }, [dispatch, response]);

    useEffect(() => {
        if (item?.id !== id) dispatch(setItem(undefined));
    }, [dispatch, id, item]);
};

export default useLocationItem;
