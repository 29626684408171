import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Actions from '../../../components/Actions';
import EnhancedTable from '../../../components/table/EnhancedTable';
import EnhancedCard from '../../../components/utilities/EnhancedCard';
import EnhancedPagination from '../../../components/utilities/EnhancedPagination';
import { siteContactsEditColumns } from '../../../configuration/columns';
import useFetch from '../../../hooks/useFetch';
import usePagination from '../../../hooks/usePagination';
import useSorting from '../../../hooks/useSorting';
import { getPersons, getSiteContacts, getUsers } from '../../../store/proxies/tigestimProxies';
import { setOrder } from '../../../store/slices/sliceFiltering';
import Search from '../../../components/Search';

const handleResponseContacts = response => {
    return response.data.reduce((accumulator, { personId, workTeamId }) => ({ ...accumulator, [personId]: workTeamId }), {});
};

const SiteContactsTableView = ({ id, actions, searchable }) => {
    const dispatch = useDispatch();
    const { pageNumber, pageSize } = usePagination(getPersons.name);
    const { sortBy, sortDirection } = useSorting(getPersons.name);
    const [search, setSearch] = useState();

    const [response, isLoading] = useFetch(
        getUsers,
        { pageNumber: pageNumber - 1, pageSize, orderBy: sortBy, orderDirection: sortDirection, search },
        [pageNumber, pageSize, sortBy, sortDirection, search],
        [],
        {}
    );

    const [contacts] = useFetch(getSiteContacts, { filter: 'forSiteId', id }, [id], [id], {}, handleResponseContacts);

    return (
        <Box height={1} width={1}>
            <Box display="flex" justifyContent="space-between">
                <Box pb={searchable ? 2 : 0}>
                    <Grid container spacing={1}>
                        {searchable && (
                            <Grid item>
                                <Search onChange={setSearch} value={search} />
                            </Grid>
                        )}
                    </Grid>
                </Box>
                {actions && <Actions actions={actions} />}
            </Box>

            <EnhancedCard pb={1} ComponentBottom={<EnhancedPagination name={getPersons.name} pages={response.totalPages} />}>
                <EnhancedTable
                    items={response.data}
                    columns={siteContactsEditColumns(contacts, id)}
                    isLoading={isLoading}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    handleRequestSort={order => {
                        dispatch(setOrder({ view: getPersons.name, order }));
                    }}
                />
            </EnhancedCard>
        </Box>
    );
};

SiteContactsTableView.propTypes = {
    actions: PropTypes.array,
    id: PropTypes.number,
    searchable: PropTypes.bool,
};
SiteContactsTableView.defaultProps = {
    id: undefined,
    actions: undefined,
    searchable: true,
};

export default SiteContactsTableView;
