import { Fab, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { drawerWidth, fabwWidth } from '../../constants/dimensionConstants';
import useTranslate from '../../hooks/useTranslate';
import { invoke } from '../../utils/common';

const useStyles = makeStyles(theme => ({
    left: {
        position: 'absolute',
        bottom: theme.spacing(2),
        center: theme.spacing(2),
    },
    center: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: `calc(50% - ${drawerWidth - fabwWidth * 2}px)`,
    },
    right: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

const EnhancedFab = ({ fab: { color, icon: Icon, label, position, callbacks = [], callback, disable = () => false } }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const translate = useTranslate();

    const disabled = useSelector(disable);

    return (
        <Fab
            disabled={disabled}
            aria-label={label}
            size="medium"
            className={classes[position]}
            color={color}
            onClick={() => {
                [...callbacks, callback].forEach(callback => {
                    if (callback) invoke(callback, dispatch);
                });
            }}
        >
            <Tooltip title={translate('tooltip')}>
                <Icon />
            </Tooltip>
        </Fab>
    );
};

EnhancedFab.propTypes = {
    fab: PropTypes.object,
};

EnhancedFab.defaultProps = {
    fab: {},
};

export default EnhancedFab;
