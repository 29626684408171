import { createSlice } from '@reduxjs/toolkit';

const LIST = 'list';
const GRID = 'grid';

const initialState = {
    showTab: false,
    display: LIST,
    tabIndex: 0,
    tabs: [],
    site: undefined,
};

const sliceSites = createSlice({
    name: 'sites',
    initialState,
    reducers: {
        setSite: (state, { payload }) => {
            state.site = payload;
        },
        setDisplay: (state, { payload }) => {
            state.showTab = payload;
        },
        toggleDisplay: state => {
            state.display = state.display === LIST ? GRID : LIST;
        },
    },
});

export default sliceSites;
// ACTIONS
export const { setSite, setDisplay, toggleDisplay } = sliceSites.actions;

// SELECTORS

export const selectSite = state => state.sites.site;
export const selectDisplay = state => state.sites.display;
export const selectIsList = state => state.sites.display === LIST;
export const selectIsGrid = state => state.sites.display === GRID;
