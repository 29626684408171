import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { isNotDef } from '../../utils/common';
import useTranslate from '../../hooks/useTranslate';

const useStyles = makeStyles(() => ({
    data: {
        marginTop: '4px',
        marginBottom: '0px',
    },
}));

const EnhancedTimePicker = ({ value, onChange, label, formatView, formatData, required, readOnly, inputVariant }) => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker
                className={classes.data}
                fullWidth
                ampm={false}
                allowKeyboardControl
                label={translate(label)}
                invalidDateMessage="Formato orario non valido"
                cancelLabel="Annulla"
                format={formatView}
                minDate={undefined}
                minDateMessage=""
                maxDate={undefined}
                maxDateMessage=""
                clearLabel="Cancella"
                size="small"
                autoOk
                clearable
                value={value ? moment(value, formatData) : value}
                required={required}
                readOnly={readOnly}
                inputProps={{
                    readOnly,
                    // disableUnderline: readOnly,
                }}
                inputVariant={readOnly ? 'standard' : inputVariant}
                onChange={newValue => {
                    if (newValue?.isValid()) {
                        onChange(newValue.format(formatData));
                    } else if (isNotDef(newValue)) {
                        onChange(newValue);
                    }
                }}
                minutesStep={5}
            />
        </MuiPickersUtilsProvider>
    );
};

EnhancedTimePicker.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    formatView: PropTypes.string,
    formatData: PropTypes.string,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    inputVariant: PropTypes.string,
};
EnhancedTimePicker.defaultProps = {
    value: null,
    label: null,
    formatView: 'HH:mm',
    formatData: 'HH:mm:ss',
    required: undefined,
    readOnly: undefined,
    inputVariant: 'outlined',
};

export default EnhancedTimePicker;
