import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from '../../hooks/useTranslate';

const useStyles = makeStyles(() => ({
    root: ({ height }) => ({
        height,
    }),
}));

const EnhancedButton = ({ icon: Icon, label, onChange, onClick, variant, fullWidth, size, height, readOnly }) => {
    const classes = useStyles({ height });
    const translate = useTranslate();
    return (
        <Button startIcon={Icon && <Icon />} classes={classes} variant={variant} fullWidth={fullWidth} size={size} onClick={() => onChange(onClick)} disabled={readOnly}>
            {translate(label)}
        </Button>
    );
};

EnhancedButton.propTypes = {
    fullWidth: PropTypes.bool,
    readOnly: PropTypes.bool,
    icon: PropTypes.object,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
    size: PropTypes.oneOf(['small', 'medium']),
    height: PropTypes.number,
};
EnhancedButton.defaultProps = {
    variant: 'text',
    icon: undefined,
    readOnly: undefined,
    fullWidth: true,
    size: 'medium',
    height: undefined,
};

export default EnhancedButton;
