import { Add, PermMedia, Photo } from '@material-ui/icons';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import EnhancedAutocomplete from '../components/utilities/EnhancedAutocomplete';
import EnhancedButton from '../components/utilities/EnhancedButton';
import EnhancedCheckbox from '../components/utilities/EnhancedCheckbox';
import EnhancedDatePicker from '../components/utilities/EnhancedDatePicker';
import EnhancedDateTimePicker from '../components/utilities/EnhancedDateTimePicker';
import EnhancedFileUpload from '../components/utilities/EnhancedFileUpload';
import EnhancedLink from '../components/utilities/EnhancedLink';
import EnhancedList from '../components/utilities/EnhancedList';
import EnhancedMapPlace from '../components/utilities/EnhancedMapPlace';
import EnhancedNumberField from '../components/utilities/EnhancedNumberField';
import EnhancedTimePicker from '../components/utilities/EnhancedTimePicker';
import EnhancedImage from '../components/utilities/EnhancedImage';
import {
    ASSOCIATE,
    breakTimeOptions,
    notifyOptions,
    capabilities,
    CLIENT,
    companies,
    countries,
    currencies,
    genders,
    hoursOptions,
    leaveTypes,
    measurementUnits,
    minutesOptions,
    NO_STAMPING_LOG,
    roles,
    timestampLogTypes,
    typeIcons,
    placeTypesOptions,
} from '../constants/options';
import { getAllPersons, getAllSites, getChecklistsTemplate, getScheduleCategories, getToolsCategories } from '../store/proxies/tigestimProxies';
import { buildUrl, isDef } from '../utils/common';
import { formatDateAndHours } from '../utils/formats';
import ChecklistQuestions from '../view/checklist/ChecklistQuestions';
import AllUsersButton from '../view/hr/AllUsersButton';
import ScheduleTasks from '../view/schedules/ScheduleTasks';
import ClearAssignment from '../view/tools/ClearAssignment';
import { mockReports, mockScheduleCatagories } from './mocks';
import { emailValidation, passwordValidation } from './validations';

export const siteEditForm = {
    columns: 2,
    items: [
        {
            label: 'address',
            id: 'address',
            spanColumns: 2,
        },
        // {
        //     id: 'place',
        //     label: 'place',
        //     spanColumns: 2,
        //     component: EnhancedMap,
        //     componentProps: ({ latitude, longitude }) => {
        //         if (latitude && longitude) {
        //             return {
        //                 center: { lat: Number(latitude), lng: Number(longitude) },
        //                 position: { lat: Number(latitude), lng: Number(longitude) },
        //             };
        //         }
        //         return {
        //             center: { lat: 46.020099, lng: 8.916511 },
        //             position: { lat: 46.020099, lng: 8.916511 },
        //         };
        //     },
        // },
        {
            id: 'avatarUrl',
            required: true,
            component: EnhancedFileUpload,
            spanColumns: 1,
            componentProps: {
                folder: 'public/sites',
                label: 'attach_site_image',
                icon: PermMedia,
                saveItem: (fileUploadedId, id, avatarUrl) => ({
                    avatarUrl,
                    avatar: fileUploadedId,
                }),
            },
        },
        {
            id: 'avatarUrl',
            label: 'show_image',
            component: EnhancedLink,
            componentProps: {
                onClick: value => {
                    window.open(buildUrl(value));
                },
            },
        },
    ],
};
export const userLeaveEditForm = {
    columns: 2,
    items: [
        {
            label: 'type',
            id: 'type',
            autoFocus: true,
            required: true,
            spanColumns: 2,
            component: EnhancedAutocomplete,
            componentProps: {
                options: leaveTypes,
            },
        },
        {
            label: 'description',
            id: 'description',
            spanColumns: 2,
        },
        {
            label: 'all_day',
            id: 'allDay',
            component: EnhancedCheckbox,
            onChangeEffect: () => ({
                startAt: undefined,
                endAt: undefined,
                date: undefined,
            }),
        },
        {
            label: 'date',
            id: 'startAt',
            show: ({ allDay }) => !!allDay,
            required: ({ allDay }) => !!allDay,
            component: EnhancedDatePicker,
            onChangeEffect: (date, { summerstarthr, summerendthr, winterendthr, winterstarthr }) => {
                const month = new Date().getMonth();
                const isWinter = month > 10 && month < 6;
                return {
                    startAt: `${date} ${isWinter ? winterstarthr : summerstarthr}`,
                    endAt: `${date} ${isWinter ? winterendthr : summerendthr}`,
                };
            },
        },
        {
            label: 'startAt',
            id: 'startAt',
            spanColumns: 2,
            show: ({ allDay }) => !allDay,
            required: ({ allDay }) => !allDay,
            component: EnhancedDateTimePicker,
            componentProps: ({ endAt }) => ({
                maxDate: endAt ?? undefined,
            }),
        },
        {
            label: 'endAt',
            id: 'endAt',
            spanColumns: 2,
            show: ({ allDay }) => !allDay,
            required: ({ allDay }) => !allDay,
            component: EnhancedDateTimePicker,
            componentProps: ({ startAt }) => ({
                minDate: startAt ?? undefined,
            }),
        },
    ],
};

export const userAddDailyHoursForm = {
    columns: 2,
    items: [
        {
            label: 'construction_site',
            id: 'constructionSiteId',
            required: true,
            spanColumns: 2,
            component: EnhancedAutocomplete,
            componentProps: {
                optionsFetch: {
                    proxy: getAllSites,
                    defaultValue: null,
                    params: {
                        filter: 'forTitle',
                    },
                    handleResponse: data =>
                        data.map(({ constructionSiteId, title, quickcomId }) => ({ value: constructionSiteId, label: `${quickcomId} | ${title}` })),
                },
            },
        },
        {
            label: 'description',
            id: 'description',
            spanColumns: 2,
        },
        {
            label: 'startAt',
            id: 'startAt',
            spanColumns: 2,
            show: ({ allDay }) => !allDay,
            required: ({ allDay }) => !allDay,
            component: EnhancedDateTimePicker,
            componentProps: ({ endAt }) => ({
                maxDate: endAt ?? undefined,
            }),
        },
        {
            label: 'endAt',
            id: 'endAt',
            spanColumns: 2,
            show: ({ allDay }) => !allDay,
            required: ({ allDay }) => !allDay,
            component: EnhancedDateTimePicker,
            componentProps: ({ startAt }) => ({
                minDate: startAt ?? undefined,
            }),
        },
    ],
};

export const userLeaveNewForm = {
    columns: userLeaveEditForm.columns,
    items: [
        {
            label: 'user',
            id: 'personId',
            required: true,
            component: EnhancedAutocomplete,
            spanColumns: 2,
            componentProps: {
                getOptionValue: ({ id }) => id,
                getItemValue: id => id,
                getOptionReturnValue: ({ id }) => id,
                getOptionLabel: ({ firstName, lastName }) => `${lastName} ${firstName} `,
                optionsFetch: {
                    proxy: getAllPersons,
                    defaultValue: null,
                    params: {
                        filter: 'forName',
                    },
                },
            },
        },
        ...userLeaveEditForm.items,
    ],
};

export const userExpenseEditForm = {
    columns: 2,
    items: [
        { label: 'reason', id: 'reason', spanColumns: 2, required: true },
        {
            label: 'amount',
            id: 'amount',
            component: EnhancedNumberField,
            required: true,
            componentProps: ({ currency }) => ({
                unit: currency,
                thousandSeparator: "'",
                decimalScale: 2,
                fixedDecimalScale: true,
            }),
        },
        {
            label: 'currency',
            id: 'currency',
            required: true,
            component: EnhancedAutocomplete,
            componentProps: {
                options: currencies,
            },
        },
        {
            label: 'quantity',
            id: 'quantity',
            component: EnhancedNumberField,
            required: true,
            componentProps: ({ unit }) => ({
                // minValue: 1,
                unit,
                decimalScale: 3,
            }),
        },
        {
            label: 'unit',
            id: 'unit',
            required: true,
            component: EnhancedAutocomplete,
            componentProps: {
                options: measurementUnits,
            },
        },
        {
            label: 'construction_site',
            id: 'constructionSiteId',
            required: true,
            spanColumns: 2,
            component: EnhancedAutocomplete,
            componentProps: {
                optionsFetch: {
                    proxy: getAllSites,
                    defaultValue: null,
                    params: {
                        filter: 'forTitle',
                    },
                    handleResponse: data =>
                        data.map(({ constructionSiteId, title, quickcomId }) => ({ value: constructionSiteId, label: `${quickcomId} | ${title}` })),
                },
            },
        },
        { label: 'place', id: 'place', spanColumns: 2, required: true },
        { label: 'date', id: 'date', spanColumns: 2, required: true, component: EnhancedDatePicker },
        {
            label: 'whoApproveId',
            id: 'whoApproveId',
            component: EnhancedAutocomplete,
            spanColumns: 2,
            show: ({ id }) => !!id,
            componentProps: {
                disabled: true,
                optionsFetch: {
                    proxy: getAllPersons,
                    defaultValue: null,
                    params: {
                        filter: 'forName',
                    },
                    handleResponse: data => data.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })),
                },
            },
        },
        {
            id: 'receiptImageId',
            required: true,
            component: EnhancedFileUpload,
            spanColumns: 1,
            componentProps: {
                folder: 'public/expenses',
                label: 'attach_receipt',
                icon: PermMedia,
                saveItem: (receiptImageId, id, url) => ({
                    receiptImageId,
                    recieptImage: url,
                    imageList: [receiptImageId],
                }),
            },
        },
        {
            id: 'recieptImage',
            label: 'show_receipt',
            component: EnhancedLink,
            componentProps: {
                onClick: value => {
                    window.open(buildUrl(value));
                },
            },
        },

        {
            label: 'isEquipment',
            required: true,
            id: 'isEquipment',
            spanColumns: 2,
            component: EnhancedCheckbox,
            // onChangeEffect: () => ({
            //     quantity: undefined,
            //     constructionSiteId: undefined,
            // }),
        },
        // {
        //     label: 'quantity',
        //     id: 'quantity',
        //     required: ({ isEquipment }) => isEquipment,
        //     spanColumns: 2,
        //     show: ({ isEquipment }) => isEquipment,
        //     component: EnhancedNumberField,
        //     componentProps: {
        //         minValue: 1,
        //         decimalScale: 0,
        //     },
        // },
        // { section: 'images' },
        // {
        //     label: 'imagesList',
        //     id: 'imagesList',
        //     component: EnhancedList,
        //     componentProps: {
        //         getOptionId: ({ fentryId }) => fentryId,
        //         getOptionUrl: ({ journalUrl }) => journalUrl,
        //         getOptionLabel: ({ createdAt }) => createdAt,
        //         getOptionIcon: () => Photo,
        //         messageNoItems: 'images_not_present',
        //     },
        // },
        // {
        //     id: 'imagesList',
        //     component: EnhancedFileUpload,
        //     componentProps: {
        //         folder: 'public/journal',
        //         label: 'attach_new_picture',
        //         icon: Add,
        //         saveItem: (itemId, dataId, url) => ({
        //             fentryId: itemId,
        //             journalId: dataId,
        //             journalUrl: url,
        //             updatedAt: moment().format('YYYY-MM-DDD HH:mm:ss'),
        //             createdAt: moment().format('YYYY-MM-DDD HH:mm:ss'),
        //         }),
        //     },
        // },

        // { label: 'imageList', id: 'imageList' },
    ],
};

export const userExpenseNewForm = {
    columns: 2,
    items: [
        {
            label: 'user',
            id: 'personId',
            required: true,
            spanColumns: 2,
            component: EnhancedAutocomplete,
            componentProps: {
                getOptionValue: ({ id }) => id,
                getItemValue: id => id,
                getOptionReturnValue: ({ id }) => id,
                getOptionLabel: ({ firstName, lastName }) => `${lastName} ${firstName} `,
                optionsFetch: {
                    proxy: getAllPersons,
                    defaultValue: null,
                    params: {
                        filter: 'forName',
                    },
                },
            },
        },
        ...userExpenseEditForm.items,
    ],
};

export const quickDataForm = {
    columns: 1,
    items: [
        {
            label: 'quicksalId',
            id: 'user.quicksalId',
            show: ({ user }) => user?.role !== CLIENT,
        },
    ],
};

export const basicDataForm = {
    columns: 2,
    items: [
        { label: 'firstName', id: 'user.firstName', required: true },
        { label: 'lastName', id: 'user.lastName', required: true },
        {
            label: 'birthday',
            id: 'user.birthday',
            show: ({ user }) => user?.role !== CLIENT,
            component: EnhancedDatePicker,
        },
        {
            label: 'gender',
            id: 'user.gender',
            component: EnhancedAutocomplete,
            show: ({ user }) => user?.role !== CLIENT,
            required: ({ user }) => user?.role !== CLIENT && !user?.capabilities?.includes(NO_STAMPING_LOG),
            componentProps: {
                options: genders,
            },
        },

        { label: 'zip', id: 'user.zip' },
        { label: 'city', id: 'user.city' },
        { label: 'address', id: 'user.address' },
        {
            label: 'country',
            id: 'user.countryId',
            required: true,
            component: EnhancedAutocomplete,
            componentProps: {
                options: countries,
            },
        },
        {
            label: 'account',
            id: 'user.email',
            validation: emailValidation,
            validationMessage: 'validationMessageEmail',
            required: true,
            disable: ({ user }) => !!user?.id,
        },
        {
            label: 'personal_mobile_number',
            id: 'user.phoneNumberList.mobile.number',
            show: ({ user }) => user?.role !== CLIENT,
        },
        {
            label: 'personal_email',
            id: 'user.personalMail',
            validation: emailValidation,
            validationMessage: 'validationMessageEmail',
            show: ({ user }) => user?.role !== CLIENT,
        },
        {
            label: 'personal_home_number',
            id: 'user.phoneNumberList.home.number',
            show: ({ user }) => user?.role !== CLIENT,
        },
        {
            label: 'office_email',
            id: 'user.officeMail',
            validation: emailValidation,
            validationMessage: 'validationMessageEmail',
        },
        {
            label: 'work_mobile_number',
            id: 'user.phoneNumberList.workmobile.number',
        },
        {
            label: 'company',
            id: 'user.companyName',
            show: ({ user }) => user?.role !== CLIENT,
            required: ({ user }) => user?.role !== CLIENT,
            component: EnhancedAutocomplete,
            componentProps: {
                options: companies,
            },
        },
        { label: 'work_office_number', id: 'user.phoneNumberList.workoffice.number' },
        {
            label: 'badge_id',
            id: 'employee.badgeId',
            show: ({ user }) => user?.role !== CLIENT && !user?.capabilities?.includes(NO_STAMPING_LOG),
            required: ({ user }) => user?.role !== CLIENT && !user?.capabilities?.includes(NO_STAMPING_LOG),
            component: EnhancedNumberField,
            componentProps: {
                minValue: 1,
                decimalScale: 0,
            },
        },
        {
            label: 'work_c_3_x_number',
            id: 'user.phoneNumberList.workc3x.number',
            spanColumns: ({ user }) => (user?.role === CLIENT || user?.capabilities?.includes(NO_STAMPING_LOG) ? 2 : 1),
        },
        {
            label: 'hired_at',
            id: 'employee.hiredat',
            format: formatDateAndHours,
            show: ({ user }) => user?.role !== CLIENT && !user?.capabilities?.includes(NO_STAMPING_LOG),
            required: ({ user }) => user?.role !== CLIENT && !user?.capabilities?.includes(NO_STAMPING_LOG),
            component: EnhancedDatePicker,
            componentProps: ({ employee }) => ({
                formatData: 'YYYY-MM-DD HH:mm:ss',
                maxDate: employee?.terminatedat,
            }),
        },
        {
            label: 'work_percentage',
            id: 'employee.workPercentage',
            unit: '%',
            show: ({ user }) => user?.role !== CLIENT && !user?.capabilities?.includes(NO_STAMPING_LOG),
            required: ({ user }) => user?.role !== CLIENT && !user?.capabilities?.includes(NO_STAMPING_LOG),
            component: EnhancedNumberField,
            componentProps: {
                minValue: 1,
                maxValue: 100,
                decimalScale: 0,
            },
        },

        {
            label: 'terminated_at',
            id: 'employee.terminatedat',
            show: ({ user }) => user?.role !== CLIENT && !user?.capabilities?.includes(NO_STAMPING_LOG),
            format: formatDateAndHours,
            component: EnhancedDatePicker,
            componentProps: ({ employee }) => ({
                formatData: 'YYYY-MM-DD HH:mm:ss',
                minDate: employee?.hiredat,
            }),
        },

        {
            label: 'holidays_count_days',
            id: 'employee.holidaysCount',
            unit: 'days',
            show: ({ user }) => user?.role !== CLIENT && !user?.capabilities?.includes(NO_STAMPING_LOG),
            required: ({ user }) => user?.role !== CLIENT && !user?.capabilities?.includes(NO_STAMPING_LOG),
            component: EnhancedNumberField,
            componentProps: {
                minValue: 1,
                maxValue: 365,
                decimalScale: 0,
            },
        },
        {
            label: 'password',
            id: 'newPassword',
            validation: passwordValidation,
            show: ({ id }) => !!id,
            validationMessage: 'validationMessagePassword',
            componentProps: { type: 'password' },
        },
        {
            label: 'confirm_password',
            id: 'confirmPassword',
            show: ({ id }) => !!id,
            required: ({ newPassword }) => !!newPassword,
            validation: passwordValidation,
            validationMessage: 'validationMessagePassword',
            componentProps: { type: 'password' },
        },
        {
            label: 'password',
            id: 'user.password',
            show: ({ id }) => !id,
            required: ({ id }) => !id,
            validation: passwordValidation,
            validationMessage: 'validationMessagePassword',
            componentProps: { type: 'password' },
        },
        {
            label: 'confirm_password',
            id: 'user.confirmPassword',
            show: ({ id }) => !id,
            required: ({ id }) => !id,
            validation: passwordValidation,
            validationMessage: 'validationMessagePassword',
            componentProps: { type: 'password' },
        },
    ],
};

export const workingHoursForm = {
    columns: 2,
    items: [
        {
            id: 'employee.breakTime',
            label: 'break_time_h',
            required: ({ user }) => user?.role !== CLIENT && !user?.capabilities?.includes(NO_STAMPING_LOG),
            component: EnhancedAutocomplete,
            spanColumns: 2,
            componentProps: {
                options: breakTimeOptions,
            },
        },
        {
            label: 'winter_start',
            id: 'employee.winterstarthr',
            component: EnhancedTimePicker,
            required: ({ user }) => user?.role !== CLIENT && !user?.capabilities?.includes(NO_STAMPING_LOG),
        },
        {
            label: 'winter_end',
            id: 'employee.winterendthr',
            component: EnhancedTimePicker,
            required: ({ user }) => user?.role !== CLIENT && !user?.capabilities?.includes(NO_STAMPING_LOG),
        },
        {
            label: 'summer_start',
            id: 'employee.summerstarthr',
            component: EnhancedTimePicker,
            required: ({ user }) => user?.role !== CLIENT && !user?.capabilities?.includes(NO_STAMPING_LOG),
        },
        {
            label: 'summer_end',
            id: 'employee.summerendthr',
            component: EnhancedTimePicker,
            required: ({ user }) => user?.role !== CLIENT && !user?.capabilities?.includes(NO_STAMPING_LOG),
        },
    ],
};

export const legalDocumentForm = {
    columns: 2,
    items: [
        { label: 'title', id: 'title', spanColumns: 2, required: true },
        {
            label: 'numberDoc',
            id: 'numberDoc',
            spanColumns: 2,
        },
        {
            id: 'legalDocumentId',
            required: true,
            component: EnhancedFileUpload,
            spanColumns: 1,
            componentProps: {
                folder: 'public/legal-documents',
                label: 'upload_file',
                icon: PermMedia,
                saveItem: (legalDocumentId, id, url) => ({
                    legalDocumentId,
                    url,
                }),
            },
        },
        {
            id: 'url',
            label: 'show_document',
            component: EnhancedLink,
            componentProps: {
                onClick: value => {
                    window.open(buildUrl(value));
                },
            },
        },
        {
            label: 'expireat',
            id: 'expireat',
            spanColumns: 2,
            component: EnhancedDatePicker,
            componentProps: {
                formatData: 'YYYY-MM-DD HH:MM:SS',
            },
        },
    ],
};

export const userProfileForm = [
    { section: 'identity', spanColumns: 2 },
    { label: 'firstName', id: 'firstName', required: true },
    { label: 'lastName', id: 'lastName', required: true },
    {
        label: 'email',
        id: 'email',
        spanColumns: 2,
        required: true,
        show: (data, readOnly) => !!readOnly,
        componentProps: { id: 'username' },
    },
    { label: 'birthday', id: 'birthday', component: EnhancedDatePicker, spanColumns: 2, required: true },
    { section: 'address', spanColumns: 2, required: true },
    { label: 'address', id: 'address', required: true },
    { label: 'city', id: 'city', required: true },
    { label: 'zip', id: 'zip', required: true },
    {
        label: 'country',
        id: 'countryId',
        required: true,
        component: EnhancedAutocomplete,
        componentProps: {
            options: countries,
        },
    },
    { section: 'contacts', spanColumns: 2 },
    { label: 'personal_mobile_number', id: 'phoneNumberList.mobile.number' },
    { label: 'personal_home_number', id: 'phoneNumberList.home.number' },
    { section: 'change_password', spanColumns: 2, show: (data, readOnly) => !readOnly },
    {
        label: 'old_password',
        id: 'oldPassword',
        spanColumns: 2,
        show: (data, readOnly) => !readOnly,
        validation: passwordValidation,
        required: ({ oldPassword, newPassword, confirmPassword }) => !!oldPassword || !!newPassword || !!confirmPassword,
        validationMessage: 'validationMessagePassword',
        componentProps: { type: 'password' },
    },
    {
        label: 'new_password',
        id: 'newPassword',
        show: (data, readOnly) => !readOnly,
        validation: passwordValidation,
        required: ({ oldPassword, newPassword, confirmPassword }) => !!oldPassword || !!newPassword || !!confirmPassword,
        validationMessage: 'validationMessagePassword',
        componentProps: { type: 'password' },
    },
    {
        label: 'confirm_password',
        id: 'confirmPassword',
        show: (data, readOnly) => !readOnly,
        validation: passwordValidation,
        required: ({ oldPassword, newPassword, confirmPassword }) => !!oldPassword || !!newPassword || !!confirmPassword,
        validationMessage: 'validationMessagePassword',
        componentProps: { type: 'password' },
    },
];

export const editUserProfilePasswordForm = [
    { section: 'change_password', spanColumns: 2, show: (data, readOnly) => !readOnly },
    {
        label: 'old_password',
        id: 'oldPassword',
        spanColumns: 2,
        show: (data, readOnly) => !readOnly,
        validation: passwordValidation,
        required: ({ oldPassword, newPassword, confirmPassword }) => !!oldPassword || !!newPassword || !!confirmPassword,
        validationMessage: 'validationMessagePassword',
        componentProps: { type: 'password' },
    },
    {
        label: 'new_password',
        id: 'newPassword',
        show: (data, readOnly) => !readOnly,
        validation: passwordValidation,
        required: ({ oldPassword, newPassword, confirmPassword }) => !!oldPassword || !!newPassword || !!confirmPassword,
        validationMessage: 'validationMessagePassword',
        componentProps: { type: 'password' },
    },
    {
        label: 'confirm_password',
        id: 'confirmPassword',
        show: (data, readOnly) => !readOnly,
        validation: passwordValidation,
        required: ({ oldPassword, newPassword, confirmPassword }) => !!oldPassword || !!newPassword || !!confirmPassword,
        validationMessage: 'validationMessagePassword',
        componentProps: { type: 'password' },
    },
];

export const siteJournalForm = [
    { label: 'date', id: 'createdAt', spanColumns: 2, /* required: true, */ component: EnhancedDateTimePicker },
    { label: 'title', id: 'title', required: true },
    { label: 'description', id: 'description', multiline: true, rows: 5, required: true },
    { section: 'images' },
    {
        label: 'images',
        id: 'journalImageList',
        component: EnhancedList,
        componentProps: {
            getOptionId: ({ fentryId }) => fentryId,
            getOptionUrl: ({ journalUrl }) => journalUrl,
            getOptionLabel: ({ createdAt }) => createdAt,
            getOptionIcon: () => Photo,
            messageNoItems: 'images_not_present',
        },
    },
    {
        id: 'journalImageList',
        component: EnhancedFileUpload,
        componentProps: {
            folder: 'public/journal',
            label: 'attach_new_picture',
            icon: Add,
            saveItem: (itemId, dataId, url) => prev => ({
                ...prev,
                journalImageList: [
                    ...(prev.journalImageList ?? []),
                    {
                        fentryId: itemId,
                        journalId: dataId,
                        journalUrl: url,
                        updatedAt: moment().format('YYYY-MM-DDD HH:mm:ss'),
                        createdAt: moment().format('YYYY-MM-DDD HH:mm:ss'),
                    },
                ],
            }),
        },
    },
];

export const siteJournalFormNew = [
    { label: 'title', id: 'title', required: true },
    { label: 'description', id: 'description', multiline: true, rows: 5, required: true },
    { section: 'images' },
    {
        label: 'images',
        id: 'journalImageList',
        component: EnhancedList,
        componentProps: {
            getOptionId: ({ fentryId }) => fentryId,
            getOptionUrl: ({ journalUrl }) => journalUrl,
            getOptionLabel: ({ createdAt }) => createdAt,
            getOptionIcon: () => Photo,
            messageNoItems: 'images_not_present',
        },
    },
    {
        id: 'journalImageList',
        component: EnhancedFileUpload,
        componentProps: {
            folder: 'public/journal',
            label: 'attach_new_picture',
            icon: Add,
            saveItem: (itemId, dataId, url) => prev => ({
                ...prev,
                journalImageList: [
                    ...(prev.journalImageList ?? []),
                    {
                        fentryId: itemId,
                        journalId: dataId,
                        journalUrl: url,
                        updatedAt: moment().format('YYYY-MM-DDD HH:mm:ss'),
                        createdAt: moment().format('YYYY-MM-DDD HH:mm:ss'),
                    },
                ],
            }),
        },
    },
];

export const toolForm = {
    columns: 3,
    items: [
        { label: 'tool_name', id: 'toolName', spanColumns: 3, required: true },
        {
            id: 'categoryId',
            label: 'category',
            spanColumns: 3,
            required: true,
            component: EnhancedAutocomplete,
            componentProps: {
                optionsFetch: {
                    proxy: getToolsCategories,
                    defaultValue: null,
                    params: {},
                    handleResponse: ({ data }) => data.map(({ id, categoryName }) => ({ value: id, label: categoryName })),
                },
            },
        },
        {
            label: 'quantity',
            id: 'quantity',
            required: true,
            component: EnhancedNumberField,
            componentProps: ({ id }) => ({
                minValue: 1,
                decimalScale: 0,
                readOnly: !!id,
            }),
        },
        { label: 'unit_cost', id: 'unitCost' },
        {
            label: 'currency',
            id: 'currency',
            required: ({ unitCost }) => isDef(unitCost),
            component: EnhancedAutocomplete,
            componentProps: {
                options: currencies,
            },
        },
        { section: 'documents', spanColumns: 3 },
        {
            label: 'documents',
            id: 'documents',
            spanColumns: 3,
            component: EnhancedList,
            componentProps: ({ id }) => ({
                getOptionId: ({ docId }) => docId,
                getOptionUrl: ({ url }) => url,
                getOptionLabel: ({ name, type }) => `${name.replace(type, '')}.${type}`,
                getOptionIcon: ({ type }) => typeIcons(type),
                params: {
                    journalId: id,
                },
            }),
        },
        {
            id: 'documents',
            component: EnhancedFileUpload,
            componentProps: {
                folder: 'public/documents',
                label: 'attach_new_file_or_picture',
                icon: Add,
                saveItem: (itemId, dataId, url, fileName, fileExt) => prev => ({
                    ...prev,
                    documents: [
                        ...(prev.documents ?? []),
                        {
                            docId: itemId,
                            toolId: dataId,
                            url,
                            type: fileExt,
                            name: fileName,
                        },
                    ],
                }),
            },
        },
        { section: 'assignment', spanColumns: 3, show: ({ id }) => !!id },
        {
            label: 'startAt',
            id: 'startAt',
            show: ({ id }) => !!id,
            spanColumns: 1.5,
            component: EnhancedDatePicker,
            required: ({ startAt, endAt, personId }) => !!startAt || !!endAt || !!personId,
            componentProps: ({ endAt }) => ({
                maxDate: endAt,
            }),
        },
        {
            label: 'endAt',
            id: 'endAt',
            spanColumns: 1.5,
            show: ({ id }) => !!id,
            required: ({ startAt, endAt, personId }) => !!startAt || !!endAt || !!personId,
            component: EnhancedDatePicker,
            componentProps: ({ startAt }) => ({
                minDate: startAt,
            }),
        },

        {
            id: 'personId',
            label: 'person',
            required: ({ startAt, endAt, personId }) => !!startAt || !!endAt || !!personId,
            spanColumns: 1.5,
            show: ({ id }) => !!id,
            component: EnhancedAutocomplete,
            componentProps: {
                optionsFetch: {
                    proxy: getAllPersons,
                    defaultValue: null,
                    params: {
                        filter: 'forName',
                    },
                    handleResponse: data => data.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })),
                },
            },
        },
        {
            id: 'clear_assignment',
            label: 'clear_assignment',
            spanColumns: 1.5,
            show: ({ id }) => !!id,
            component: ClearAssignment,
        },
    ],
};

export const toolsCategoryForm = [{ label: 'category_name', id: 'categoryName', required: true }];

export const permissionForm = [
    {
        label: 'role',
        id: 'role',
        required: true,
        component: EnhancedAutocomplete,
        componentProps: {
            options: roles,
        },
        onChangeEffect: () => ({
            assignedConstructionSites: undefined,
        }),
    },
    {
        label: 'capabilities',
        id: 'capabilities',
        component: EnhancedAutocomplete,
        componentProps: {
            multiple: true,
            options: capabilities,
        },
    },
    {
        label: 'assigned_construction_sites',
        id: 'assignedConstructionSites',
        // required: true,
        show: ({ role }) => [CLIENT, ASSOCIATE].includes(role),
        component: EnhancedAutocomplete,
        componentProps: {
            multiple: true,
            getOptionValue: ({ constructionSiteId }) => constructionSiteId,
            getItemValue: constructionSiteId => constructionSiteId,
            getOptionReturnValue: ({ constructionSiteId }) => constructionSiteId,
            getOptionLabel: ({ title }) => `${title}`,
            optionsFetch: {
                proxy: getAllSites,
                defaultValue: null,
                params: {
                    filter: 'forTitle',
                },
                handleResponse: data =>
                    data.map(({ constructionSiteId, title, quickcomId }) => ({ constructionSiteId, title: `${quickcomId} | ${title}` })),
            },
        },
    },
];

export const checklistForm = [
    {
        label: 'template',
        id: 'typeId',
        required: true,
        component: EnhancedAutocomplete,
        componentProps: ({ id }) => ({
            disabled: !!id,
            optionsFetch: {
                proxy: getChecklistsTemplate,
                params: {
                    isTemplate: 1,
                    pageNumber: 0,
                    pageSize: 1000,
                },
                handleResponse: response => response.data,
            },
            getOptionLabel: ({ name }) => name,
            getOptionValue: ({ typeId }) => typeId,
            getItemValue: value => value,
            getOptionReturnValue: item => ({
                ...item,
                id: undefined,
                assignedConstructionSites: undefined,
            }),
        }),
    },
    {
        label: 'title',
        id: 'title',
        required: true,
    },
    {
        label: 'assigned_construction_site',
        id: 'assignedConstructionSites',
        required: true,
        component: EnhancedAutocomplete,
        componentProps: ({ id }) => ({
            disabled: !!id,
            getOptionValue: ({ constructionSiteId }) => constructionSiteId,
            getItemValue: item => {
                const [first] = item || [];
                return first?.constructionSiteId;
            },
            getOptionReturnValue: ({ constructionSiteId }) => [{ constructionSiteId }],
            getOptionLabel: ({ title }) => `${title}`,
            optionsFetch: {
                proxy: getAllSites,
                defaultValue: null,
                params: {
                    filter: 'forTitle',
                },
                handleResponse: data =>
                    data.map(({ constructionSiteId, title, quickcomId }) => ({ constructionSiteId, title: `${quickcomId} | ${title}` })),
            },
        }),
    },
    {
        label: 'date',
        id: 'date',
        required: true,
        component: EnhancedDatePicker,
        componentProps: {
            formatData: 'YYYY-MM-DD HH:mm:ss',
        },
    },
    {
        label: 'readyAt',
        id: 'readyAt',
        required: true,
        component: EnhancedDatePicker,
        componentProps: {
            formatData: 'YYYY-MM-DD HH:mm:ss',
        },
    },
    {
        label: 'questions',
        id: 'questions',
        required: true,
        component: ChecklistQuestions,
    },
    {
        id: 'add_new_question',
        label: 'add_new_question',
        component: EnhancedButton,
        componentProps: {
            height: 40,
            onClick: prev => ({
                ...prev,
                questions: [
                    ...(prev.questions || []),
                    {
                        id: uuid(),
                        title: ``,
                        items: [
                            {
                                id: uuid(),
                                label: '',
                            },
                        ],
                    },
                ],
            }),
        },
    },
];

export const checklistTemplateForm = {
    columns: 2,
    items: [
        { label: 'mandatory_notification', id: 'isMandatoryForWorkers', component: EnhancedCheckbox },
        {
            id: 'notifyEach',
            label: 'Notifica',
            component: EnhancedAutocomplete,
            componentProps: ({ isMandatoryForWorkers, notifyEach }) => ({
                disabled: !isMandatoryForWorkers,
                options: notifyOptions,
                value: !isMandatoryForWorkers ? undefined : notifyEach,
            }),
        },
        {
            label: 'title',
            id: 'name',
            required: true,
            spanColumns: 2,
        },
        // {
        //     label: 'assigned_construction_sites',
        //     id: 'assignedConstructionSites',
        //     component: EnhancedAutocomplete,
        //     spanColumns: 2,
        //     componentProps: {
        //         multiple: true,
        //         disableClearable: true,
        //         getOptionValue: ({ constructionSiteId }) => constructionSiteId,
        //         getItemValue: ({ constructionSiteId }) => constructionSiteId,
        //         getOptionReturnValue: ({ constructionSiteId }) => ({ constructionSiteId }),
        //         getOptionLabel: ({ title }) => `${title}`,
        //         optionsFetch: {
        //             proxy: getAllSites,
        //             defaultValue: null,
        //             params: {
        //                 filter: 'forTitle',
        //             },
        //             handleResponse: data =>
        //                 data.map(({ constructionSiteId, title, quickcomId }) => ({ constructionSiteId, title: `${quickcomId} | ${title}` })),
        //         },
        //     },
        // },
        // {
        //     label: 'date',
        //     id: 'date',
        //     required: true,
        //     component: EnhancedDatePicker,
        //     componentProps: {
        //         formatData: 'YYYY-MM-DD HH:mm:ss',
        //     },
        // },
        // {
        //     label: 'readyAt',
        //     id: 'readyAt',
        //     required: true,
        //     component: EnhancedDatePicker,
        //     componentProps: {
        //         formatData: 'YYYY-MM-DD HH:mm:ss',
        //     },
        // },
        {
            label: 'questions',
            id: 'questions',
            required: true,
            component: ChecklistQuestions,
            spanColumns: 2,
        },
        {
            id: 'add_new_question',
            label: 'add_new_question',
            component: EnhancedButton,
            spanColumns: 2,
            componentProps: {
                height: 40,
                onClick: prev => ({
                    ...prev,
                    questions: [
                        ...(prev.questions || []),
                        {
                            id: uuid(),
                            title: ``,
                            items: [
                                {
                                    id: uuid(),
                                    label: '',
                                },
                            ],
                        },
                    ],
                }),
            },
        },
    ],
};

export const siteChecklistForm = [
    {
        label: 'title',
        id: 'title',
        required: true,
    },
    {
        label: 'assigned_construction_sites',
        id: 'assignedConstructionSites',
        required: true,
        component: EnhancedAutocomplete,
        componentProps: {
            multiple: true,
            getOptionValue: ({ constructionSiteId }) => constructionSiteId,
            getItemValue: ({ constructionSiteId }) => constructionSiteId,
            getOptionReturnValue: ({ constructionSiteId }) => ({ constructionSiteId }),
            getOptionLabel: ({ title }) => `${title}`,
            optionsFetch: {
                proxy: getAllSites,
                defaultValue: null,
                params: {
                    filter: 'forTitle',
                },
                handleResponse: data =>
                    data.map(({ constructionSiteId, title, quickcomId }) => ({ constructionSiteId, title: `${quickcomId} | ${title}` })),
            },
        },
    },
    {
        label: 'date',
        id: 'date',
        required: true,
        component: EnhancedDatePicker,
        componentProps: {
            formatData: 'YYYY-MM-DD HH:mm:ss',
        },
    },
    {
        label: 'questions',
        id: 'questions',
        required: true,
        component: ChecklistQuestions,
    },
];

export const requestChecklistForm = [
    {
        label: 'title',
        id: 'title',
        required: true,
    },
    {
        label: 'assigned_construction_sites',
        id: 'assignedConstructionSites',
        required: true,
        component: EnhancedAutocomplete,
        componentProps: {
            multiple: true,
            getOptionValue: ({ constructionSiteId }) => constructionSiteId,
            getItemValue: ({ constructionSiteId }) => constructionSiteId,
            getOptionReturnValue: ({ constructionSiteId }) => ({ constructionSiteId }),
            getOptionLabel: ({ title }) => `${title}`,
            optionsFetch: {
                proxy: getAllSites,
                defaultValue: null,
                params: {
                    filter: 'forTitle',
                },
                handleResponse: data =>
                    data.map(({ constructionSiteId, title, quickcomId }) => ({ constructionSiteId, title: `${quickcomId} | ${title}` })),
            },
        },
    },
    {
        label: 'date',
        id: 'date',
        required: true,
        component: EnhancedDatePicker,
        componentProps: {
            formatData: 'YYYY-MM-DD HH:mm:ss',
        },
    },
    {
        label: 'questions',
        id: 'questions',
        required: true,
        component: ChecklistQuestions,
    },
    {
        label: 'signUrl',
        id: 'signUrl',
        required: true,
        component: EnhancedImage,
        componentProps: {
            height: '100%',
            width: 200,
            objectFit: 'contain',
            justifyContent: 'flex-end',
        },
    },
];

export const scheduleForm = {
    columns: 2,
    items: [
        {
            label: 'title',
            id: 'title',
            required: true,
            spanColumns: 2,
        },
        {
            label: 'description',
            id: 'description',
            spanColumns: 2,
            required: true,
        },
        {
            id: 'categoryId',
            label: 'category',
            spanColumns: 2,
            required: true,
            component: EnhancedAutocomplete,
            componentProps: {
                optionsFetch: {
                    proxy: getScheduleCategories,
                    // defaultValue: null,
                    params: {},
                    defaultValue: mockScheduleCatagories.data.map(({ id, categoryName }) => ({ value: id, label: categoryName })),
                },
            },
        },
        {
            label: 'startAt',
            id: 'startAt',
            required: true,
            component: EnhancedDatePicker,
        },
        {
            label: 'endAt',
            id: 'endAt',
            required: true,
            component: EnhancedDatePicker,
        },
        // {
        //     label: 'workers',
        //     id: 'workers',
        //     spanColumns: 2,
        //     required: true,
        //     component: EnhancedAutocomplete,
        //     componentProps: {
        //         multiple: true,
        //         getOptionValue: ({ id }) => id,
        //         getItemValue: ({ id }) => id,
        //         getOptionReturnValue: ({ id }) => ({ id }),
        //         getOptionLabel: ({ firstName, lastName }) => `${lastName} ${firstName}`,
        //         optionsFetch: {
        //             proxy: getAllPersons,
        //             defaultValue: null,
        //             params: {
        //                 filter: 'forName',
        //             },
        //         },
        //     },
        // },
        {
            label: 'construction_site',
            id: 'constructionSiteId',
            spanColumns: 2,
            required: true,
            component: EnhancedAutocomplete,
            componentProps: {
                optionsFetch: {
                    proxy: getAllSites,
                    defaultValue: null,
                    params: {
                        filter: 'forTitle',
                    },
                    handleResponse: data =>
                        data.map(({ constructionSiteId, title, quickcomId }) => ({ value: constructionSiteId, label: `${quickcomId} | ${title}` })),
                },
            },
        },
        {
            label: 'tasks',
            id: 'tasks',
            required: true,
            spanColumns: 2,
            component: ScheduleTasks,
        },
        {
            id: 'add_task',
            label: 'add_task',
            spanColumns: 2,
            component: EnhancedButton,
            componentProps: {
                height: 40,
                onClick: prev => ({
                    ...prev,
                    tasks: [
                        ...(prev.tasks || []),
                        {
                            id: uuid(),
                        },
                    ],
                }),
            },
        },
        {
            label: 'notes',
            id: 'notes',
            required: true,
            spanColumns: 2,
            multiline: true,
            rows: 5,
        },
        {
            label: 'report',
            id: 'reportId',
            required: true,
            spanColumns: 2,
            component: EnhancedAutocomplete,
            componentProps: {
                options: mockReports,
            },
        },
    ],
};

export const siteScheduleForm = {
    columns: 2,
    items: [
        {
            label: 'title',
            id: 'title',
            required: true,
            spanColumns: 2,
        },
        {
            label: 'description',
            id: 'description',
            spanColumns: 2,
            required: true,
        },
        {
            id: 'categoryId',
            label: 'category',
            spanColumns: 2,
            required: true,
            component: EnhancedAutocomplete,
            componentProps: {
                optionsFetch: {
                    proxy: getScheduleCategories,
                    // defaultValue: null,
                    params: {},
                    defaultValue: mockScheduleCatagories.data.map(({ id, categoryName }) => ({ value: id, label: categoryName })),
                },
            },
        },
        {
            label: 'startAt',
            id: 'startAt',
            required: true,
            component: EnhancedDatePicker,
        },
        {
            label: 'endAt',
            id: 'endAt',
            required: true,
            component: EnhancedDatePicker,
        },
        {
            label: 'workers',
            id: 'workers',
            spanColumns: 2,
            required: true,
            component: EnhancedAutocomplete,
            componentProps: {
                multiple: true,
                getOptionValue: ({ id }) => id,
                getItemValue: ({ id }) => id,
                getOptionReturnValue: ({ id }) => ({ id }),
                getOptionLabel: ({ firstName, lastName }) => `${lastName} ${firstName}`,
                optionsFetch: {
                    proxy: getAllPersons,
                    defaultValue: null,
                    params: {
                        filter: 'forName',
                    },
                },
            },
        },
        {
            label: 'construction_site',
            id: 'constructionSiteId',
            spanColumns: 2,
            required: true,
            component: EnhancedAutocomplete,
            componentProps: {
                optionsFetch: {
                    proxy: getAllSites,
                    defaultValue: null,
                    params: {
                        filter: 'forTitle',
                    },
                    handleResponse: data =>
                        data.map(({ constructionSiteId, title, quickcomId }) => ({ value: constructionSiteId, label: `${quickcomId} | ${title}` })),
                },
            },
        },
        {
            label: 'tasks',
            id: 'tasks',
            required: true,
            spanColumns: 2,
            component: ScheduleTasks,
        },
        {
            label: 'notes',
            id: 'notes',
            required: true,
            spanColumns: 2,
            multiline: true,
        },
        {
            label: 'report',
            id: 'reportId',
            required: true,
            spanColumns: 2,
            component: EnhancedAutocomplete,
            componentProps: {
                options: mockReports,
            },
        },
    ],
};

export const scheduleCategoryForm = [{ label: 'category_name', id: 'categoryName', required: true }];

export const addWorkingHoursForm = {
    columns: 3,
    items: [
        { section: 'winter', spanColumns: 3 },
        { label: 'winter_start', id: 'winterstarthr', spanColumns: 1.5, component: EnhancedTimePicker, required: true },
        { label: 'winter_end', id: 'winterendthr', spanColumns: 1.5, component: EnhancedTimePicker, required: true },
        { section: 'summer', spanColumns: 3 },
        { label: 'summer_start', id: 'summerstarthr', spanColumns: 1.5, component: EnhancedTimePicker, required: true },
        { label: 'summer_end', id: 'summerendthr', spanColumns: 1.5, component: EnhancedTimePicker, required: true },
        { section: 'apply_to', spanColumns: 3 },
        {
            id: 'allUsers',
            label: 'all_users',
            spanColumns: 3,
            component: AllUsersButton,
        },
        // {
        //     label: 'role',
        //     id: 'role',
        //     component: EnhancedAutocomplete,
        //     componentProps: {
        //         options: roles,
        //     },
        // },
        // {
        //     label: 'company',
        //     id: 'company',
        //     component: EnhancedAutocomplete,
        //     componentProps: {
        //         options: companies,
        //     },
        // },
        {
            label: 'user_list',
            id: 'userList',
            spanColumns: 3,
            required: true,
            component: EnhancedAutocomplete,
            componentProps: {
                multiple: true,
                getOptionValue: ({ id }) => id,
                getItemValue: id => id,
                getOptionReturnValue: ({ id }) => id,
                getOptionLabel: ({ firstName, lastName }) => `${lastName} ${firstName} `,
                optionsFetch: {
                    proxy: getAllPersons,
                    defaultValue: null,
                    params: {
                        filter: 'forName',
                    },
                },
            },
        },
    ],
};

export const editWorkingHourForm = {
    columns: 2,
    items: [
        { section: 'winter', spanColumns: 2 },
        { label: 'winter_start', id: 'winterstarthr', component: EnhancedTimePicker, required: true },
        { label: 'winter_end', id: 'winterendthr', component: EnhancedTimePicker, required: true },
        { section: 'summer', spanColumns: 2 },
        { label: 'summer_start', id: 'summerstarthr', component: EnhancedTimePicker, required: true },
        { label: 'summer_end', id: 'summerendthr', component: EnhancedTimePicker, required: true },
    ],
};

export const editEventForm = {
    columns: 2,
    items: [
        { id: 'title', label: 'title', required: true, spanColumns: 2 },
        { id: 'description', label: 'description', spanColumns: 2 },
        { id: 'place', label: 'place', multiline: true, spanColumns: 2 },
        {
            id: 'startDate',
            label: 'startDate',
            required: true,
            component: EnhancedDateTimePicker,
            componentProps: {
                formatData: 'YYYY-MM-DD HH:mm:ss',
            },
        },
        {
            id: 'endDate',
            label: 'endDate',
            show: ({ duration }) => !duration,
            required: ({ duration }) => !duration,
            component: EnhancedDateTimePicker,
            componentProps: {
                formatData: 'YYYY-MM-DD HH:mm:ss',
            },
        },
        {
            label: 'duration',
            id: 'duration',
            spanColumns: 2,
            component: EnhancedCheckbox,
        },
        {
            id: 'hours',
            label: 'hours',
            show: ({ duration }) => !!duration,
            required: ({ duration }) => !!duration,
            component: EnhancedAutocomplete,
            componentProps: {
                options: hoursOptions,
            },
        },
        {
            id: 'minutes',
            label: 'minutes',
            component: EnhancedAutocomplete,
            show: ({ duration }) => !!duration,
            required: ({ duration }) => !!duration,
            componentProps: {
                options: minutesOptions,
            },
        },
    ],
};

export const festivityForm = {
    columns: 1,
    items: [
        { id: 'title', label: 'title', required: true },
        { id: 'description', label: 'description', required: true },
        { id: 'place', label: 'place', required: true, multiline: true },
        {
            id: 'startDate',
            label: 'startDate',
            required: true,
            component: EnhancedDatePicker,
            componentProps: {
                formatData: 'YYYY-MM-DD HH:mm:ss',
            },
        },
        {
            id: 'endDate',
            label: 'endDate',
            required: true,
            component: EnhancedDatePicker,
            componentProps: {
                formatData: 'YYYY-MM-DD HH:mm:ss',
            },
        },
    ],
};

export const editStampingLogForm = {
    columns: 1,
    items: [
        {
            id: 'type',
            label: 'type',
            required: true,
            component: EnhancedAutocomplete,
            componentProps: {
                options: timestampLogTypes,
            },
        },
        {
            id: 'place',
            label: 'companyName',
            required: false,
            component: EnhancedAutocomplete,
            componentProps: {
                options: placeTypesOptions,
            },
        },

        { id: 'description', label: 'description', multiline: true, rows: 3 },
        {
            id: 'timestamp',
            label: 'timestamp',
            required: true,
            component: EnhancedDateTimePicker,
            componentProps: {
                formatData: 'YYYY-MM-DD HH:mm:ss',
            },
        },
        {
            id: 'place',
            label: 'place',
            component: EnhancedMapPlace,
            componentProps: ({ place }) => {
                if (place) {
                    const jsonObjectPlace = JSON.parse(place);
                    const { latitude, longitude } = jsonObjectPlace.coords;
                    if (latitude && longitude) {
                        return {
                            center: { lat: Number(latitude), lng: Number(longitude) },
                            position: { lat: Number(latitude), lng: Number(longitude) },
                        };
                    }
                }
                return {
                    center: { lat: 46.020099, lng: 8.916511 },
                    position: { lat: 46.020099, lng: 8.916511 },
                };
            },
        },
    ],
};

export const renameFileForm = {
    columns: 1,
    items: [
        {
            label: 'file_name',
            id: 'fileName',
            autoFocus: true,
            required: true,
        },
    ],
};

export const nameFolderForm = {
    columns: 1,
    items: [
        {
            label: 'folder_name',
            id: 'folderName',
            autoFocus: true,
            required: true,
        },
    ],
};

export const authorizationForm = {
    columns: 1,
    items: [
        {
            label: 'role',
            id: 'user.role',
            required: true,
            component: EnhancedAutocomplete,
            componentProps: {
                options: roles,
            },
        },
        {
            label: 'capabilities',
            id: 'user.capabilities',
            component: EnhancedAutocomplete,
            componentProps: {
                multiple: true,
                options: capabilities,
            },
        },
    ],
};
