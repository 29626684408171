import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import EnhancedAutocomplete from '../../components/utilities/EnhancedAutocomplete';
import { companies } from '../../constants/options';
import useFetch from '../../hooks/useFetch';
import useTranslate from '../../hooks/useTranslate';
import { getAllPersons } from '../../store/proxies/tigestimProxies';

const useStyles = makeStyles(() => ({
    root: {
        height: 40,
    },
}));

const handleResponse = response => response.map(({ id }) => id);

const AllUsersButton = ({ onChange }) => {
    const classes = useStyles();
    const [companyName, setCompanyName] = useState()
    const [data] = useFetch(getAllPersons, { filter: 'forName',  companyName }, [companyName], [], [], handleResponse);
    const translate = useTranslate();
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <EnhancedAutocomplete value={companyName} options={[...companies, { value: undefined, label: 'alle' }]} onChange={setCompanyName} label="companyName" />
            </Grid>
            <Grid item xs={6}>
                <Button classes={classes} variant="outlined" fullWidth size="medium" onClick={() => onChange({ userList: data })}>
                    {translate('all_users')}
                </Button>
            </Grid>
           
        </Grid>
    );
};

AllUsersButton.propTypes = {
    onChange: PropTypes.func.isRequired,
};
AllUsersButton.defaultProps = {};

export default AllUsersButton;
