import { Avatar, Box, Grid } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Actions from '../../../components/Actions';
import EnhancedCarousel from '../../../components/EnhancedCarousel';
import FlexColumn from '../../../components/flex/FlexColumn';
import FlexRow from '../../../components/flex/FlexRow';
import LoadingFullScreen from '../../../components/utilities/LoadingFullScreen';
import useDataFetch from '../../../hooks/useDataFetch';
import { selectUserId } from '../../../store/slices/sliceAuth';
import { buildUrl } from '../../../utils/common';
// import { formatDate } from '../../../utils/formats';
import SiteJournalSocial from './SiteJournalSocial';

const SiteJournalView = ({ proxy, siteId, journalId, actions }) => {
    const [journal, onChange, isLoading] = useDataFetch(proxy, journalId, [journalId], [journalId], {});
    const authorId = useSelector(selectUserId);

    useEffect(() => {
        if (siteId && authorId) {
            onChange('siteId', siteId);
            onChange('authorId', authorId);
        }
    }, [authorId, onChange, siteId]);

    if (isLoading) return <LoadingFullScreen />;

    return (
        <FlexColumn>
            <Actions actions={actions} data={journal} />
            <FlexColumn pt={1} pb={2} key={journal.id}>
                <Box p={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FlexRow alignItems="flex-start" justifyContent="space-between">
                                <FlexRow>
                                    <Box>
                                        <Avatar style={{ width: 50, height: 50 }} src={buildUrl(journal.userUrl)}>
                                            {`${journal.firstName?.charAt(0) || ''}${journal.lastName?.charAt(0) || ''}`}
                                        </Avatar>
                                    </Box>

                                    <FlexColumn pl={2}>
                                        <Box fontSize={16} fontWeight={500}>
                                            {`${journal.firstName} ${journal.lastName}`}
                                        </Box>
                                        <Box color={grey[400]} fontSize={14} pt={1}>
                                            {/* {formatDate(journal.createdAt)} */}
                                            {journal.createdAt}
                                        </Box>
                                    </FlexColumn>
                                </FlexRow>
                            </FlexRow>
                        </Grid>
                        <Grid item xs={8}>
                            <EnhancedCarousel items={journal.journalImageList} getItemUrl={({ journalUrl }) => journalUrl} height={800} fit="contain" />
                        </Grid>
                        <Grid item xs={4}>
                            <FlexColumn>
                                <Box fontSize={16} fontWeight={500}>
                                    {journal.title}
                                </Box>
                                <Box fontSize={14} pt={2}>
                                    {journal.description}
                                </Box>
                            </FlexColumn>
                        </Grid>
                    </Grid>
                </Box>
                <SiteJournalSocial journal={journal} openComments />
            </FlexColumn>
        </FlexColumn>
    );
};

SiteJournalView.propTypes = {
    proxy: PropTypes.object,
    siteId: PropTypes.number.isRequired,
    journalId: PropTypes.number,
    actions: PropTypes.array.isRequired,
};
SiteJournalView.defaultProps = {
    journalId: undefined,
    proxy: undefined,
};

export default SiteJournalView;
