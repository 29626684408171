import { Box, Grid, Switch, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleCard from '../../components/SimpleCard';
import useTranslate from '../../hooks/useTranslate';
import { toggleDarkMode, selectDarkMode } from '../../store/slices/sliceAppearance';

const SettingsView = () => {
    const darkMode = useSelector(selectDarkMode);
    const notifications = ['construction_sites', 'files', 'stamping_hours'];
    const translate = useTranslate();
    const dispatch = useDispatch();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <SimpleCard title="appearance">
                    <Grid container>
                        <Grid item xs={12}>
                            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                <Typography>{translate(`switches.${darkMode ? 'light_mode' : 'dark_mode'}`)}</Typography>
                                <Switch color="primary" value={darkMode} checked={darkMode} onClick={() => dispatch(toggleDarkMode())} />
                            </Box>
                        </Grid>
                    </Grid>
                </SimpleCard>
            </Grid>
            <Grid item xs={12} md={6}>
                <SimpleCard title="notification_management">
                    <Grid container>
                        {notifications.map(notification => (
                            <Grid item xs={12} key={notification}>
                                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                    <Typography>{translate(notification)}</Typography>
                                    <Switch color="primary" onClick={() => {}} />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </SimpleCard>
            </Grid>
        </Grid>
    );
};

SettingsView.propTypes = {};
SettingsView.defaultProps = {};

export default SettingsView;
