import { Box, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import useTranslate from '../../hooks/useTranslate';
import { setErrorNotification, setSuccessNotification } from '../../store/slices/sliceNotification';
import { bucketUploader } from '../../utils/bucketUtils';
import { selectUserId } from '../../store/slices/sliceAuth';
import { buildUrl } from '../../utils/common';

const useStyles = makeStyles(() => ({
    button: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
    },
}));

const EnhancedFileUpload = ({ data, onChange, color, label, folder, icon: Icon, enabled, disabled, saveItem, random, bucketSite, onSuccess }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useDispatch();
    const userId = useSelector(selectUserId);
    const [loading, setLoading] = useState(false);
    let InputRef;

    const pressButton = () => {
        InputRef.click();
    };

    const uploadImage = async event => {
        try {
            setLoading(true);

            Array.from(event.target.files).forEach(async file => {
                const fileExt = file.type.split('/')[1];
                const originalFileName = uuid();
                // const filePath = random ? `${folder}/${originalFileName}_${uuid()}.${fileExt}` : `${folder}/${originalFileName}.${fileExt}`;
                // todo: ken -> Ricordarsi di modificare la chiama nel props di random non viene più usata

                const filePath = random ? `${folder}/${originalFileName}.${fileExt}` : `${folder}/${originalFileName}.${fileExt}`;
                const uploadedFiledId = await bucketUploader({ bucketSite, file, filePath: filePath.replace('//', '/'), userId }).then(res => res);

                if (uploadedFiledId) {
                    if (onChange && saveItem) onChange(saveItem(uploadedFiledId, data?.id, buildUrl(filePath), originalFileName, fileExt));

                    setLoading(false);
                    if (onSuccess) dispatch(onSuccess());
                    dispatch(setSuccessNotification('uploaded_successfully'));
                } else {
                    throw Error();
                }
            });
        } catch (error) {
            setLoading(false);
            dispatch(setErrorNotification('upload_failed'));
        }
    };

    return (
        <Box display="flex">
            <Button
                className={classes.button}
                variant="text"
                color="inherit"
                startIcon={loading ? <CircularProgress size={20} color={color} /> : <Icon />}
                onClick={() => pressButton()}
                disabled={!enabled || disabled}
                fullWidth
            >
                <Box pl={1} fontSize={12} fontWeight="bold" color="inherit">
                    {translate(label)}
                </Box>
            </Button>

            <input
                ref={input => {
                    InputRef = input;
                }}
                type="file"
                id="file-input"
                multiple
                style={{ display: 'none' }}
                onChange={uploadImage}
            />
        </Box>
    );
};

EnhancedFileUpload.propTypes = {
    color: PropTypes.string,
    data: PropTypes.object,
    onChange: PropTypes.func,
    onSuccess: PropTypes.func,
    label: PropTypes.string,
    folder: PropTypes.string.isRequired,
    icon: PropTypes.object,
    enabled: PropTypes.bool,
    disabled: PropTypes.bool,
    saveItem: PropTypes.func,
    random: PropTypes.bool,
    bucketSite: PropTypes.string,
};

EnhancedFileUpload.defaultProps = {
    data: undefined,
    onChange: undefined,
    saveItem: undefined,
    onSuccess: undefined,
    color: 'primary',
    label: '',
    icon: undefined,
    enabled: true,
    disabled: false,
    random: true,
    bucketSite: undefined,
};

export default EnhancedFileUpload;
