import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import { Box } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stampingLogLabel } from '../../constants/statesConstants';
import useTranslate from '../../hooks/useTranslate';
import { deleteEvent, deleteStampingLog, getHrPersonalCalendar, getSiteCalendar } from '../../store/proxies/tigestimProxies';
import { refetch } from '../../store/slices/sliceFetching';
import { selectItemDefault } from '../../store/slices/sliceNavigation';
import { invokeDelete } from '../../utils/common';
import EnhancedIcon from '../utilities/EnhancedIcon';
import i from '../../ability/ability';

const switchContent = (data, type, user, translate) => {
    switch (data.eventType) {
        case 'stampingLogs':
            return (
                <Box height={1} width={1} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" fontSize={12}>
                    <Box display="flex" flexDirection="row">
                        <Box width={60}>{`${translate(stampingLogLabel(data.item.type))}`}</Box>
                        <Box>{`${moment(data.startDate).format('HH:mm')}`}</Box>
                    </Box>
                    {i.can('update', 'profile') &&
                        <EnhancedIcon
                            size={14}
                            icon={Close}
                            padding="none"
                            onClick={() => invokeDelete(deleteStampingLog, data.item, { onSuccess: () => refetch(getHrPersonalCalendar.name) })}
                        />
                    }
                </Box>
            );
        case 'events':
            return (
                <Box height={1} width={1} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" fontSize={12}>
                    <Box display="flex" flexDirection="column">
                        <Box>{`${translate(data.title)} - ${translate(`eventTypes.${data.eventType}`)}`}</Box>
                        <Box>{data.description}</Box>
                        <Box>{data.place}</Box>
                    </Box>
                    {i.can('update', 'profile') &&
                        <EnhancedIcon
                            size={14}
                            icon={Close}
                            padding="none"
                            onClick={() =>
                                invokeDelete(deleteEvent, { ...data, id: data.id.split('_')[0] }, { onSuccess: () => refetch(getSiteCalendar.name) })
                            }
                        />
                    }
                </Box>
            );
        default:
            return `${translate(data.title || data.description)} - ${translate(`eventTypes.${data.eventType}`)}`;
    }
};

const AppointmentContent = ({ data, ...props }) => {
    const translate = useTranslate();
    const user = useSelector(selectItemDefault);
    const dispatch = useDispatch();
    return (
        <Appointments.AppointmentContent {...props} data={data} durationType="short">
            <div style={{ width: '100%', overflowX: 'hidden', color: 'white' }}>{switchContent(data, props.type, user, translate, dispatch)}</div>
        </Appointments.AppointmentContent>
    );
};

AppointmentContent.propTypes = {
    data: PropTypes.object,
    type: PropTypes.string.isRequired,
};
AppointmentContent.defaultProps = {
    data: {},
};

export default AppointmentContent;
