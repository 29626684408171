import { Box, Divider, Grid } from '@material-ui/core';
import { Add, Close, PermMedia, PhotoCamera } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { v4 as uuid } from 'uuid';
import EnhancedButton from '../../components/utilities/EnhancedButton';
import EnhancedFileUpload from '../../components/utilities/EnhancedFileUpload';
import EnhancedIcon from '../../components/utilities/EnhancedIcon';
import EnhancedTextFiled from '../../components/utilities/EnhancedTextFiled';
import ChecklistQuestionImages from './ChecklistQuestionImages';
import ChecklistQuestionRow from './ChecklistQuestionRow';

function ChecklistQuestion({ questionId, onChange, title, items, images, attachment, readOnly }) {
    return (
        <Box pt={1} pb={3}>
            <Box display="flex" pb={1}>
                <EnhancedTextFiled
                    value={title}
                    variant="outlined"
                    readOnly={readOnly}
                    onChange={value => {
                        onChange(prev => ({
                            ...prev,
                            questions: prev.questions.map(question => {
                                if (question.id === questionId)
                                    return {
                                        ...question,
                                        title: value,
                                    };
                                return question;
                            }),
                        }));
                    }}
                />
                {!readOnly && (
                    <Box display="flex" alignItems="center" pl={1}>
                        <EnhancedIcon
                            icon={Close}
                            onClick={() => {
                                onChange(prev => ({ ...prev, questions: prev.questions.filter(question => question.id !== questionId) }));
                            }}
                        />
                    </Box>
                )}
            </Box>
            <Box py={1}>
                <Grid container spacing={2}>
                    {images?.map(({ imageId, ...props }) => (
                        <ChecklistQuestionImages
                            key={imageId}
                            questionId={questionId}
                            imageId={imageId}
                            onChange={onChange}
                            readOnly={readOnly}
                            {...props}
                        />
                    ))}
                </Grid>
            </Box>
            {items?.map(({ id: itemId, ...props }) => (
                <ChecklistQuestionRow
                    key={itemId}
                    questionId={questionId}
                    itemId={itemId}
                    onChange={onChange}
                    readOnly={readOnly}
                    {...props}
                />
            ))}
            <Box pl={2} pt={1}>
                <Box display="flex" alignItems="center">
                    {attachment &&
                        <>
                            <EnhancedButton
                                icon={PhotoCamera}
                                label="upload_picture"
                                onClick={()=>{}}
                                onChange={()=>{}}
                                disabled={readOnly}
                            />
                            {!readOnly && (
                                <Box display="flex" alignItems="center" pl={1}>
                                    <EnhancedIcon
                                        icon={Close}
                                        onClick={() => {
                                            onChange(prev => ({
                                                ...prev,
                                                questions: prev.questions.map(question => {
                                                    if (question.id === questionId)
                                                        return { ...question, attachment: undefined };
                                                    return question;
                                                }),
                                            }))}
                                        }
                                    />
                                </Box>
                            )}
                        </>
                    }
                </Box>
            </Box>
            {!readOnly && (
                <Grid container spacing={3}>
                    <Grid item xs>
                        <EnhancedButton
                            icon={Add}
                            label="add_checkbox"
                            size="small"
                            disabled={readOnly}
                            onChange={onChange}
                            onClick={prev => ({
                                ...prev,
                                questions: prev.questions.map(question => {
                                    if (question.id === questionId)
                                        return {
                                            ...question,
                                            items: [
                                                ...(question.items || []),
                                                {
                                                    id: uuid(),
                                                    label: '',
                                                },
                                            ],
                                        };
                                    return question;
                                }),
                            })}
                        />
                    </Grid>
                    <Grid item xs>
                        <EnhancedButton
                            icon={PhotoCamera}
                            label="add_btn_attach"
                            size="small"
                            disabled={readOnly}
                            onChange={onChange}
                            onClick={prev => ({
                                ...prev,
                                questions: prev.questions.map(question => {
                                    if (question.id === questionId)
                                        return {
                                            ...question,
                                            attachment:
                                                {
                                                    attachId: uuid(),
                                                    attachUrl: '',
                                                },
                                        };
                                    return question;
                                }),
                            })}
                        />
                    </Grid>
                    <Grid item xs>
                        <EnhancedFileUpload
                            id="images"
                            value={images}
                            folder="public/checklist"
                            label="add_image"
                            disabled={readOnly}
                            icon={PermMedia}
                            saveItem={(imageId, dataId, imageUrl) => ({ imageId, imageUrl })}
                            onChange={({ imageId, imageUrl }) => {
                                onChange(prev => ({
                                    ...prev,
                                    questions: prev.questions?.map(question => {
                                        if (question.id === questionId)
                                            return {
                                                ...question,
                                                images: [
                                                    ...(question.images || []),
                                                    {
                                                        imageId,
                                                        imageUrl,
                                                    },
                                                ],
                                            };
                                        return question;
                                    }),
                                }));
                            }}
                        />
                    </Grid>
                </Grid>
            )}

            <Box pt={1}>
                <Divider />
            </Box>
        </Box>
    );
}

ChecklistQuestion.propTypes = {
    questionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    images: PropTypes.array,
    attachment: PropTypes.any,
    items: PropTypes.array,
    title: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
};
ChecklistQuestion.defaultProps = {
    images: [],
    items: [],
    attachment: undefined,
    readOnly: undefined,
};

export default ChecklistQuestion;
