import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NotificationContent from './NotificationContent';
import {
    toggleNotification,
    selectNotificationOpen,
    selectNotificationMessage,
    selectNotificationVariant,
    selectNotificationAutoHideDuration,
} from '../../store/slices/sliceNotification';
import useTranslate from '../../hooks/useTranslate';

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1),
    },
    snackbar: {
        bottom: theme.spacing(2),
    },
}));

const EnhancedNotification = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const translate = useTranslate();

    const open = useSelector(selectNotificationOpen);
    const message = useSelector(selectNotificationMessage);
    const variant = useSelector(selectNotificationVariant);
    const autoHideDuration = useSelector(selectNotificationAutoHideDuration);

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                className={classes.snackbar}
                autoHideDuration={autoHideDuration}
                onClose={() => {
                    dispatch(toggleNotification());
                }}
            >
                <NotificationContent
                    onClose={() => {
                        dispatch(toggleNotification());
                    }}
                    variant={variant}
                    message={translate(message)}
                />
            </Snackbar>
        </div>
    );
};

EnhancedNotification.defaultProps = {};

EnhancedNotification.propTypes = {};

export default EnhancedNotification;
