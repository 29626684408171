const filterAttachment = (attachment) => {
    if(attachment){
        const { attachId, ...rest } = attachment;
        return rest;
    }
    return null;
}

export const clearChecklistForPost = ({ id, questions, ...checklist }) => ({
    ...checklist,
    id: undefined,
    isTemplate: 0,
    isFullFilled: 0,
    questions: questions?.map(({ id, items, attachment, ...question }) => ({ ...question, attachment: filterAttachment(attachment), items: items?.map(({ id, ...item }) => item) })),
});

export const clearChecklistTemplateForPost = ({ id, questions, ...checklist }) => ({
    ...checklist,
    isTemplate: 1,
    isFullFilled: 0,
    title: checklist.name,
    questions: questions?.map(({ id, items, attachment, ...question }) => ({ ...question, attachment: filterAttachment(attachment), items: items?.map(({ id, ...item }) => item) })),
});

export const clearChecklistForPut = ({ questions, ...checklist }) => ({
    ...checklist,
    questions: questions?.map(({ id, items, attachment, ...question }) => ({ ...question, attachment: filterAttachment(attachment), items: items?.map(({ id, ...item }) => item) })),
});
