import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import EnhancedIcon from '../../components/utilities/EnhancedIcon';
import EnhancedImage from '../../components/utilities/EnhancedImage';

function ChecklistQuestionImages({ imageId, imageUrl, questionId, onChange, readOnly }) {
    return (
        <Grid item xs={6}>
            <Box key={imageId} display="flex" flexDirection="row" alignItems="center">
                <EnhancedImage value={imageUrl} />
                {!readOnly && (
                    <Box display="flex" alignItems="center" pl={1}>
                        <EnhancedIcon
                            icon={Close}
                            onClick={() => {
                                onChange(prev => ({
                                    ...prev,
                                    questions: prev.questions.map(question => {
                                        if (question.id === questionId)
                                            return { ...question, images: question.images.filter(image => image.imageId !== imageId) };
                                        return question;
                                    }),
                                }));
                            }}
                        />
                    </Box>
                )}
            </Box>
        </Grid>
    );
}

ChecklistQuestionImages.propTypes = {
    imageId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    questionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    imageUrl: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.any,
};

ChecklistQuestionImages.defaultProps = {
    readOnly: undefined,
};

export default ChecklistQuestionImages;
