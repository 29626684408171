import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import useTranslate from '../hooks/useTranslate';
import { selectItem } from '../store/slices/sliceNavigation';
import { invokeOrReturn, isObject } from '../utils/common';
import SimpleCard from './SimpleCard';
import EnhancedTextFiled from './utilities/EnhancedTextFiled';
import { selectUser } from '../store/slices/sliceSession';
import i from '../ability/ability';

const EnhancedForm = ({ title, icon, items, columns, data, onChange, readOnly, isLoading, setData, ids }) => {
    const translate = useTranslate();
    const item = useSelector(selectItem);
    const user = useSelector(selectUser);

    return (
        <SimpleCard icon={icon} title={title}>
            <Grid container spacing={2}>
                {items
                    .filter(({ show = true, role = () => true }) => !!invokeOrReturn(show, data, readOnly) && role(i))
                    .map(
                        ({
                            label,
                            section,
                            id = '',
                            unit,
                            autoFocus,
                            spanColumns,
                            validation,
                            validationMessage,
                            required,
                            onChangeEffect,
                            component: Component,
                            componentProps,
                            show,
                            disable,
                            ...props
                        }) => {
                            const key = section ? `section-${section}` : `${id}-${label}`;
                            const value = id.split('.').reduce((acc, item) => acc?.[item], data || {});
                            let helperText;
                            if (value && validation) {
                                helperText = validation.test(value) ? undefined : translate(validationMessage);
                            }

                            const spanColumnsResolved = invokeOrReturn(spanColumns, data) || 1;
                            let xs = (12 / columns) * spanColumnsResolved;
                            if (spanColumnsResolved === 'stretch') xs = true;
                            else if (spanColumnsResolved === 'fix') xs = false;
                            else xs = (12 / columns) * spanColumnsResolved;

                            if (isLoading)
                                return (
                                    <Grid item xs={xs} key={key}>
                                        <Skeleton height={40} />
                                    </Grid>
                                );

                            return (
                                <Grid item xs={xs} key={key}>
                                    {Component ? (
                                        <Component
                                            id={id}
                                            label={label}
                                            variant="outlined"
                                            // value={format ? format(form[id], translate) : form[id]}
                                            value={value}
                                            unit={unit}
                                            onChange={value => {
                                                if (typeof value === 'function') {
                                                    if (setData) setData(value);
                                                } else if (!Array.isArray(value) && isObject(value) && !moment.isMoment(value)) {
                                                    Object.keys(value).forEach(key => {
                                                        if (onChange) onChange(key, value[key]);
                                                    });
                                                } else if (Array.isArray(value) && !isObject(value)) {
                                                    if (setData) setData(prev => ({ ...prev, [id]: value }));
                                                } else if (onChange) onChange(id, value);

                                                if (onChangeEffect && (item || user)) {
                                                    const sideEffect = onChangeEffect(value, item || user);
                                                    Object.keys(sideEffect).forEach(key => {
                                                        if (onChange) onChange(key, sideEffect[key]);
                                                    });
                                                }
                                            }}
                                            required={readOnly ? undefined : !!invokeOrReturn(required, data)}
                                            readOnly={readOnly}
                                            data={data}
                                            {...invokeOrReturn(componentProps, data, ids)}
                                        />
                                    ) : (
                                        <>
                                            {section && (
                                                <Box fontSize={20} fontWeight="bold" mb={-0.5}>
                                                    {translate(section)}
                                                </Box>
                                            )}
                                            {label && (
                                                <EnhancedTextFiled
                                                    id={id}
                                                    label={label}
                                                    variant="outlined"
                                                    disabled={disable?.(data)}
                                                    value={value}
                                                    unit={unit}
                                                    autoFocus={autoFocus}
                                                    error={!!helperText}
                                                    helperText={helperText}
                                                    required={readOnly ? undefined : !!invokeOrReturn(required, data)}
                                                    onChange={value => {
                                                        if (onChange) onChange(id, value || undefined);
                                                    }}
                                                    readOnly={readOnly}
                                                    {...invokeOrReturn(componentProps, data, ids)}
                                                    {...props}
                                                />
                                            )}
                                        </>
                                    )}
                                </Grid>
                            );
                        }
                    )}
            </Grid>
        </SimpleCard>
    );
};

EnhancedForm.propTypes = {
    data: PropTypes.object,
    ids: PropTypes.object,
    title: PropTypes.string.isRequired,
    icon: PropTypes.object,
    items: PropTypes.array,
    columns: PropTypes.oneOf([1, 2, 3, 4, 6, 12]),
    onChange: PropTypes.func,
    setData: PropTypes.func,
    readOnly: PropTypes.bool,
    isLoading: PropTypes.bool,
};
EnhancedForm.defaultProps = {
    data: {},
    ids: {},
    icon: undefined,
    items: [],
    columns: 1,
    onChange: undefined,
    setData: undefined,
    readOnly: undefined,
    isLoading: undefined,
};

export default EnhancedForm;
