import { createSlice } from '@reduxjs/toolkit';

const sliceFetching = createSlice({
    name: 'fetching',
    initialState: {},
    reducers: {
        setIsLoading: (state, { payload }) => {
            const { name, loading } = payload;
            state[name] = { ...state[name], loading };
        },
        setResponse: (state, { payload }) => {
            const { name, response } = payload;
            state[name] = { ...state[name], response };
        },
        refetch: (state, { payload }) => {
            const requests = Array.isArray(payload) ? payload : [payload]
            requests.forEach((name) => {
                const refresh = ((state[name]?.refresh || 1) + 1) % 10;
                state[name] = { ...state[name], refresh };
            })
        },
    },
});

const compareValues =
    ({ sortBy, sortDirection = 'asc' }) =>
        (a, b) => {
            if (!a[sortBy] || !b[sortBy]) return 0;

            const varA = typeof a[sortBy] === 'string' ? a[sortBy].toUpperCase() : a[sortBy];
            const varB = typeof b[sortBy] === 'string' ? b[sortBy].toUpperCase() : b[sortBy];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return sortDirection === 'desc' ? comparison * -1 : comparison;
        };

export default sliceFetching;

// ACTIONS
export const { setIsLoading, setResponse, refetch } = sliceFetching.actions;

// SELECTORS
export const selectIsLoading = name => state => {
    if (!name) return undefined;
    return state.fetching[name]?.loading;
};
export const selectResponse =
    (name, defaultValue = {}) =>
        state => {
            if (!name) return undefined;
            return state.fetching[name]?.response || defaultValue;
        };
export const selectResponsePaginated =
    (name, { pageNumber, pageSize }, order, resolveResponse) =>
        state => {
            if (!name || !state.fetching[name]?.response) return undefined;
            let response = resolveResponse ? resolveResponse(state.fetching[name]?.response) : state.fetching[name]?.response;
            if (order.sortBy) response = response?.slice?.().sort(compareValues(order));
            return response?.slice?.((pageNumber - 1) * pageSize, (pageNumber - 1) * pageSize + pageSize) || [];
        };
export const selectResponseTotalPage = (name, resolveResponse) => state => {
    if (!name || !state.fetching[name]?.response) return undefined;
    if (resolveResponse) return resolveResponse(state.fetching[name]?.response)?.length || 0;
    return state.fetching[name]?.response?.length || 0;
};
export const selectRefresh = url => state => {
    if (!url) return undefined;
    return state.fetching[url]?.refresh || 1;
};
