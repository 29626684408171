import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { baseUrlApp } from '../constants/originUrlConstants';

const useLocationMenu = path => {
    const [selected, setSelected] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setSelected(location.pathname.replace(baseUrlApp, '').includes(path));
    }, [location.pathname, path]);
    return selected;
};

export default useLocationMenu;
