const recursiveSearch = menu => {
    const { clickable = true, children } = menu;
    if (clickable) return { to: menu.to, menu };
    if (!children) return undefined;
    const child = children.map(m => recursiveSearch(m)).find(p => p);
    if (child) return { to: child.to, menu: child.menu };
    return undefined;
};

export const findFirstClickablePath = menu => {
    const { to = '' } = menu.map(m => recursiveSearch(m)).find(p => p) || {};
    return to;
};

export const removeDisabledNode = menu => {
    return menu
        .filter(({ role = () => true }) => role())
        .map(({ role, children, tabs, fabs, ...rest }) => {
            if (children) return { ...rest, children: removeDisabledNode(children) };
            if (tabs) return { ...rest, tabs: removeDisabledNode(tabs) };
            if (fabs) return { ...rest, fabs: removeDisabledNode(fabs) };
            return rest;
        })
        .filter(elem => !!elem);
};
