import { useCallback, useEffect, useState } from 'react';
import { patchImmer } from '../utils/common';
import useFetch from './useFetch';

const useDataFetch = (proxy, params, updates, start, defaultValue, handleResponse) => {
    const [data, isLoading] = useFetch(proxy, params, updates, start, defaultValue, handleResponse);
    const [form, setDate] = useState(data);

    useEffect(() => {
        setDate(data);
    }, [data]);

    const onChange = useCallback((path, item) => {
        setDate(patchImmer(path, item));
    }, []);

    return [form, onChange, isLoading, setDate];
};

export default useDataFetch;
