import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from '@material-ui/core';
import { selectRefresh } from '../store/slices/sliceFetching';

const DEBOUNCE_TIME_MS = 500;

const useFetch = (proxy, params, updates = [], start = [], defaultValue = {}, handleResponse, debounced) => {
    const [result, setResult] = useState(defaultValue);
    const [isLoading, setIsLoading] = useState();
    const externalRefresh = useSelector(selectRefresh(proxy?.name));
    const [refresh, setRefresh] = useState(1);

    useEffect(() => {
        setRefresh(externalRefresh);
    }, [externalRefresh]);

    useEffect(() => {
        const source = axios.CancelToken.source();

        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await proxy.call(params, source.token);
                if (handleResponse) setResult(handleResponse(response ?? defaultValue) ?? defaultValue);
                else setResult(response);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    // dispatch(setErrorNotification(error.message)); // TODO deactivate for demo
                }
            } finally {
                setIsLoading(false);
            }
            setIsLoading(false);
        };
        if (proxy && refresh && start.reduce((acc, update) => acc && !!update, true)) {
            if (debounced) {
                debounce(fetchData, DEBOUNCE_TIME_MS)();
            } else {
                fetchData();
            }
        }

        return () => {
            source.cancel();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proxy, ...updates, refresh]);

    const reload = useCallback(() => {
        setRefresh(prev => (prev + 1) % 10);
    }, []);

    return [result, isLoading, reload];
};

export default useFetch;
