import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Actions from '../../../components/Actions';
import FlexColumn from '../../../components/flex/FlexColumn';
import SimpleCard from '../../../components/SimpleCard';
import EnhancedTable from '../../../components/table/EnhancedTable';
import { newEventAction } from '../../../configuration/actions';
import useFetch from '../../../hooks/useFetch';
import { getSiteCalendar } from '../../../store/proxies/tigestimProxies';
import { openDialog } from '../../../store/slices/sliceDialog';
import { formatDate, formatServerDate } from '../../../utils/formats';
import SiteDashboardCalendarDialog from './SiteDashboardCalendarDialog';


const handleResponseSiteCalendar = response => {
    const colors = response.colors.reduce((accumulator, { eventType, color }) => ({ ...accumulator, [eventType]: color }), {});
    const events = (eventType, items) =>
        items
            .map(({ id, title, description = '', startDate, endDate, ...rest }) => ({
                ...rest,
                id: `${id}_${eventType}`,
                startDate: new Date(startDate),
                endDate: endDate ? new Date(endDate) : new Date(startDate),
                eventType,
                title,
                description,
                color: colors[9], // [10] is similat to  pink, [9] (blue) seems better
            }))
            .filter(({ startDate, endDate }) => startDate <= endDate);

    const festivity = (eventType, items) =>
        items
            .map(({ id, title, description = '', startDate, endDate, place, ...rest }) => ({
                ...rest,
                id: `${id}_${eventType}`,
                startDate: new Date(startDate),
                endDate: endDate ? new Date(endDate) : new Date(startDate),
                eventType,
                title,
                description,
                place: description !== 'Festività Svizzera' && place !== description ? place : '',
                color: colors[12],
            }))
            .filter(({ startDate, endDate }) => startDate <= endDate);

    const map = { events, festivity };

    return Object.keys(response ?? {})
        .filter(key => map[key])
        .reduce((accu, key) => [...accu, ...map[key](key, response[key])], []);
};


const columns = [{ id: 'title' }, { id: 'description' }, { id: 'startDate', format: formatDate }, { id: 'endDate', format: formatDate }];

const SiteDashboardCalendar = ({ site }) => {
    const dispatch = useDispatch();
    const [date] = useState(moment());
    const [startDate] = useState(formatServerDate(moment().startOf('month').subtract(7, 'days'), 'YYYY-MM-DD'));
    const [endDate] = useState(formatServerDate(moment().endOf('month').add(7, 'days'), 'YYYY-MM-DD'));

    const [data, isLoading] = useFetch(
        getSiteCalendar,
        { id: site.id, startDate, endDate },
        [site.id, startDate, endDate],
        [site.id, startDate, endDate],
        [],
        handleResponseSiteCalendar
    );

    return (
        <SimpleCard title="calendar" info={formatDate(date)} icon={() => <Actions actions={newEventAction} data={site} pb={0}/>} actions={newEventAction} noColor>
            <FlexColumn>
                <EnhancedTable items={data} columns={columns} isLoading={isLoading} onItemClick={item => {
                    dispatch(openDialog({
                        fullWidth: true,
                        component: SiteDashboardCalendarDialog,
                        componentProps: {
                            item
                        },
                        maxWidth: 'xs',
                        cancelLabel: 'close',                     
                    })
                    )
                }}/>
            </FlexColumn>
        </SimpleCard>
    );
};

SiteDashboardCalendar.propTypes = {
    site: PropTypes.object,
};
SiteDashboardCalendar.defaultProps = {
    site: {},
};

export default SiteDashboardCalendar;
