import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { AuthProvider } from './context/authContext';
import { LocaleProvider } from './context/localeContext';
import dictionaries from './i18n';
import authProvider from './providers/authProvider';
import store from './store';
import './styles/index.css';
import * as serviceWorker from './utils/serviceWorker';

ReactDOM.render(
    <Provider store={store}>
        <LocaleProvider dictionaries={dictionaries}>
            <AuthProvider auth={authProvider()}>
                <App />
            </AuthProvider>
        </LocaleProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
