import { connectRouter } from 'connected-react-router';
import history from '../../components/routings/history';
import sliceDialog from './sliceDialog';
import sliceFetching from './sliceFetching';
import sliceFiltering from './sliceFiltering';
import sliceNotification from './sliceNotification';
import sliceNavigation from './sliceNavigation';

import sliceInitialization from './sliceInitialization';
import sliceSession from './sliceSession';
import sliceSites from '../../view/sites/sliceSites';
import sliceAuth from './sliceAuth';
import sliceSideBar from './sliceSideBar';
import sliceAppearance from './sliceAppearance';
import sliceInternalization from './sliceInternalization';

const slices = [
    { reducer: connectRouter(history), name: 'router' },
    sliceNavigation,
    sliceFetching,
    sliceNotification,
    sliceDialog,
    sliceFiltering,
    sliceInitialization,
    sliceSession,
    sliceSites,
    sliceAuth,
    sliceSideBar,
    sliceAppearance,
    sliceInternalization,
];

export default slices.reduce((total, { name, reducer }) => ({ ...total, [name]: reducer }), {});
