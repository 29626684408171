import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { logoSelector } from '../../../utils/common';
import { formatLiteralDate } from '../../../utils/formats';


const styles = StyleSheet.create({
    pageHeader: {
        marginBottom: 8,
        alignItems: 'flex-start',
        fontSize: 11,
    },
});

const PageHeader = ({ data, translate }) => {
    const { date, user } = data || {};
    const { firstName, lastName, badgeId } = user || {};

    return (
        <View style={styles.pageHeader}>
            <Image src={logoSelector(user.companyName, false)} style={{ width: '100px', paddingBottom: 10 }} />
            <View
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingBottom: 20,
                }}
            >
                <View style={{ flexDirection: 'row' }}>
                    <Text style={{ paddingRight: 4 }}>{`${translate('month')}:`}</Text>
                    <Text style={{ fontWeight: 700 }}>{`${formatLiteralDate(date, translate)}`}</Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <Text style={{ paddingRight: 4 }}>Badge:</Text>
                    <Text style={{ fontWeight: 700 }}>{badgeId ? `${badgeId}` : ''}</Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <Text style={{ paddingRight: 4 }}>{`${translate('name')}:`}</Text>
                    <Text style={{ fontWeight: 700 }}>{`${firstName} ${lastName}`}</Text>
                </View>
            </View>
        </View>
    );
};

PageHeader.propTypes = {
    data: PropTypes.object,
    translate: PropTypes.func.isRequired
};

PageHeader.defaultProps = {
    data: {},
};

export default PageHeader;
