import { AppBar, Box, Toolbar } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { memo } from 'react';
import { headerHeight, MAX_WIDTH_CONTENT } from '../../constants/dimensionConstants';
import useLocationItem from '../../hooks/useLocationItem';
import useLocationTitle from '../../hooks/useLocationTitle';
import { requestLogout } from '../../store/slices/sliceAuth';
import Action from '../Action';

const useStyles = makeStyles(theme => {
    return {
        appBar: {
            height: '100%',
        },
        toolbar: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            elevation: 0,
        },
        icon: {
            marginLeft: theme.spacing(1.5),
        },
    };
});

const Header = () => {
    const classes = useStyles();
    const [title, subtitle] = useLocationTitle();
    useLocationItem();

    return (
        <AppBar position="static" className={classes.appBar} elevation={0}>
            <Box display="flex" justifyContent="center">
                <Box maxWidth={MAX_WIDTH_CONTENT} width={1}>
                    <Toolbar variant="dense" className={classes.toolbar}>
                        <Box
                            pt={0.5}
                            pb={1}
                            height={headerHeight}
                            display="flex"
                            flexDirection="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Box fontWeight="fontWeightMedium" fontSize={24} color="inherit">
                                {title && title}
                            </Box>
                            {false && (
                                <Box fontWeight="fontWeightMedium" fontSize={14} color="inherit">
                                    {subtitle && `${subtitle}`}
                                </Box>
                            )}
                        </Box>

                        <Box height={1} display="flex" alignItems="flex-start" mr={-2}>
                            <Action label="logout" labelPosition="left" icon={ExitToApp} onClick={requestLogout} />
                        </Box>
                    </Toolbar>
                </Box>
            </Box>
        </AppBar>
    );
};

Header.propTypes = {};

Header.defaultProps = {};

export default memo(Header);
