import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cookie from '../components/routings/cookie';
import { requestAuth } from '../store/slices/sliceSession';

const useAuth = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (cookie.present()) dispatch(requestAuth());
    }, [dispatch]);
};

export default useAuth;
