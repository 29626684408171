/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import cookie from './cookie';

const PublicRoute = ({ component: Component, exact, path }) => {
    return (
        <Route
            exact={exact}
            path={path}
            render={props =>
                cookie.present() ? (
                    <Redirect
                        to={{
                            pathname: '/',
                        }}
                    />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

PublicRoute.propTypes = {
    path: PropTypes.string,
    exact: PropTypes.bool,
    component: PropTypes.func.isRequired,
};

PublicRoute.defaultProps = {
    path: undefined,
    exact: undefined,
};

export default PublicRoute;
