import { Grid } from '@material-ui/core';
import { Event } from '@material-ui/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../components/Actions';
import DisplayListDivider from '../../components/DisplayListDivider';
import FlexColumn from '../../components/flex/FlexColumn';
import history from '../../components/routings/history';
import TableViewCard from '../../components/view/TableViewCard';
import { profileLeavesRequestsColumns } from '../../configuration/columns';
import useFetch from '../../hooks/useFetch';
import { leaveRequestReview, personLeaveRequests } from '../../store/proxies/tigestimProxies';
import { setWarningNotification } from '../../store/slices/sliceNotification';
import { selectUser } from '../../store/slices/sliceSession';
import { convertSecondsHours, isNotDef } from '../../utils/common';
import { formatDate } from '../../utils/formats';
import UserAvatar from '../UserAvatar';

const leavesItems = [
    { label: 'holidays_allowance', id: 'totalHoliday', sign: '+' },
    { label: 'total_pending', id: 'totalPending', sign: '-' },
    { label: 'remaining', id: 'remaining', bold: true },
    { label: `holiday_balance`, id: 'totalLeaves', sign: '+', date: `${formatDate(moment().subtract(1, 'month').endOf('month'))}` },
];

const handleResponseLeave = response => {
    const { taken, pending, holiday, remainingHolidays, remainingHolidaysAt } = response || {};

    const totalLeaves = remainingHolidaysAt ? convertSecondsHours(remainingHolidays) : convertSecondsHours(taken)
    const totalPending = convertSecondsHours(pending);
    const totalHoliday = holiday * 8.00; // TODO: use ((summerendthr | winterendthr) - (summerstarthr | winterstarthr)) - pauseTime ,
    const remaining = totalHoliday - totalLeaves;
    return {
        totalLeaves,
        totalPending,
        remaining,
        totalHoliday
    };
};


const ProfileLeavesView = ({ actions }) => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const [leaveReview, isLoading] = useFetch(
        leaveRequestReview,
        { id: user.id, year: moment().year() },
        [user.id],
        [user.id],
        {},
        handleResponseLeave
    );

    return (
        <FlexColumn flex={1} width={1}>
            <Actions actions={actions} />
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <UserAvatar data={user} />
                        </Grid>
                        <Grid item xs={12}>
                            <DisplayListDivider title="leaves" items={leavesItems} data={leaveReview} isLoading={isLoading} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TableViewCard
                                icon={Event}
                                title="my_leaves_request"
                                proxy={personLeaveRequests}
                                params={{
                                    userId: user.id,
                                    year: moment().year(),
                                }}
                                columns={profileLeavesRequestsColumns}
                                messageNoItems="document_not_present"
                                onItemClick={({ id, status }) => {
                                    if (isNotDef(status) || status === '2' || status === 2) {
                                        history.push(`leaves/${id}/edit`);
                                    } else {
                                        dispatch(setWarningNotification('edit_allow_only_on_pending'));
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FlexColumn>
    );
};

ProfileLeavesView.propTypes = {
    actions: PropTypes.array,
};
ProfileLeavesView.defaultProps = {
    actions: [],
};

export default ProfileLeavesView;
