import { Box, IconButton, InputBase, Paper } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from '../hooks/useTranslate';

const useStyles = makeStyles(theme => ({
    iconButton: {
        padding: theme.spacing(1),
        margin: -theme.spacing(1),
        marginLeft: -theme.spacing(0.5),
    },
    overflow: {
        textOverflow: 'ellipsis',
    },
    input: {
        textOverflow: 'ellipsis',
    },
}));

const Search = ({ onChange, value }) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Paper>
            <Box display="flex" flexDirection="row" alignItems="center">
                <IconButton className={classes.iconButton} aria-label="search">
                    <SearchIcon />
                </IconButton>

                <Box flexGrow={1} className={classes.overflow}>
                    <InputBase
                        classes={{ input: classes.input }}
                        value={value}
                        autoFocus
                        placeholder={translate('search')}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={event => {
                            onChange(event.target.value);
                        }}
                    />
                </Box>
            </Box>
        </Paper>
    );
};

Search.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

Search.defaultProps = {
    value: '',
};

export default Search;
