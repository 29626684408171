import { Grid } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import Actions from '../../components/Actions';
import EnhancedForm from '../../components/EnhancedForm';
import FlexColumn from '../../components/flex/FlexColumn';
import useDataSelector from '../../hooks/useDataSelector';
import { selectUser } from '../../store/slices/sliceSession';
import UserAvatar from '../UserAvatar';

const ProfileEdit = ({ readOnly, actions, form }) => {
    const [data, setData] = useDataSelector(selectUser);

    return (
        <FlexColumn>
            <Actions actions={actions(data, form)} data={data} />
            <Grid container spacing={2}>
                <Grid item>
                    <UserAvatar
                        data={data}
                        edit={!readOnly}
                        width={300}
                        onChange={({ uploadedImageId, uploadedImageUrl }) => {
                            if (setData) {
                                setData('avatarId', uploadedImageId);
                                setData('url', uploadedImageUrl);
                            }
                        }}
                    />
                </Grid>

                <Grid item xs>
                    <EnhancedForm title="user_profile" icon={Person} items={form} data={data} columns={2} onChange={setData} readOnly={readOnly} />
                </Grid>
            </Grid>
        </FlexColumn>
    );
};

ProfileEdit.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    actions: PropTypes.func.isRequired,
    form: PropTypes.array.isRequired,
};
ProfileEdit.defaultProps = {};

export default ProfileEdit;
