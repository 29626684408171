/* eslint-disable no-param-reassign */
import { Grid } from '@material-ui/core';
import { Assignment, Event, Lock } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { FileDocument } from 'mdi-material-ui';
import Actions from '../../components/Actions';
import EditDialog from '../../components/dialog/EditDialog';
import DisplayTable from '../../components/DisplayTable';
import EnhancedForm from '../../components/EnhancedForm';
import FlexColumn from '../../components/flex/FlexColumn';
import { legalDocumentsColumns } from '../../configuration/columns';
import { authorizationForm, basicDataForm, legalDocumentForm, quickDataForm, workingHoursForm } from '../../configuration/forms';
import { handleResponsePerson } from '../../configuration/handlers';
import useDataFetch from '../../hooks/useDataFetch';
import { openDialog } from '../../store/slices/sliceDialog';
import UserAvatar from '../UserAvatar';
import { CLIENT , NO_STAMPING_LOG } from '../../constants/options'
import { buildUrl, formValidator } from '../../utils/common';
import i from '../../ability/ability'

const initialState = {
    user: {},
    employee: {},
    legalDocument: [],
    signedDocument: [],
};

const UserProfileEdit = ({ id, actions, proxy, readOnly }) => {
    const [data, onChange, isLoading, setData] = useDataFetch(proxy, id, [id], [id], initialState, handleResponsePerson);

    return (
        <FlexColumn flex={1} width={1}>
            <Actions actions={actions} data={data} />

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <UserAvatar
                                data={{ ...data.user, ...data.employee }}
                                edit={!readOnly}
                                onChange={({ uploadedImageId, uploadedImageUrl }) => {
                                    if (onChange) {
                                        onChange('user.avatarId', uploadedImageId);
                                        onChange('user.url', uploadedImageUrl);
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <EnhancedForm
                                title="authorization"
                                icon={Lock}
                                items={authorizationForm.items}
                                data={data}
                                columns={authorizationForm.columns}
                                onChange={onChange}
                                isLoading={isLoading}
                                readOnly={readOnly}
                            />
                        </Grid>   
                        {data.user?.role !== CLIENT && !data.user?.capabilities?.includes(NO_STAMPING_LOG)  && ( 
                            <Grid item xs={12}>
                                <EnhancedForm
                                    title="working_hours"
                                    icon={Event}
                                    items={workingHoursForm.items}
                                    data={data}
                                    columns={workingHoursForm.columns}
                                    onChange={onChange}
                                    isLoading={isLoading}
                                    readOnly={readOnly}
                                />
                            </Grid>
                        )}
                        {i.can('update', 'quickSalId') && (
                            <Grid item xs={12}>
                                <EnhancedForm
                                    title="quick"
                                    icon={Assignment}
                                    items={quickDataForm.items}
                                    data={data}
                                    columns={quickDataForm.columns}
                                    onChange={onChange}
                                    isLoading={isLoading}
                                    readOnly={readOnly}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                <Grid item xs={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <EnhancedForm
                                title="basic_data"
                                icon={Event}
                                items={basicDataForm.items}
                                data={data}
                                columns={basicDataForm.columns}
                                onChange={onChange}
                                isLoading={isLoading}
                                readOnly={readOnly}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DisplayTable
                                readOnly={readOnly}
                                name="userLegalDocuments"
                                icon={FileDocument}
                                title="legal_documents"
                                items={data.legalDocument}
                                columns={legalDocumentsColumns}
                                isLoading={isLoading}
                                messageNoItems="press_plus_to_add_a_document"
                                onItemClick={data => {
                                    if (readOnly) {
                                        window.open(buildUrl(data.url));
                                        return undefined;
                                    }                                     
                                    return openDialog( {
                                        data,
                                        component: EditDialog,
                                        componentProps: {
                                            form: legalDocumentForm,
                                            title: 'edit_legal_document',
                                        },
                                        cancelLabel: 'cancel',
                                        confirmationLabel: 'save',
                                        confirmationEnable: (data) => formValidator(legalDocumentForm, data),
                                        confirmationCallback: async newItem => {
                                            setData(prev => ({
                                                ...prev,
                                                legalDocument: prev.legalDocument.map(item => (item.id === newItem.id ? newItem : item)),
                                            }));
                                        },
                                        negationLabel: 'remove',
                                        negationCallback: deleted => {
                                            setData(prev => ({
                                                ...prev,
                                                legalDocument: prev.legalDocument.filter(item => item.id !== deleted.id),
                                            }));
                                        },
                                    });
                                    
                                }}
                                onAdd={readOnly ? undefined : openDialog({
                                    data: {},
                                    component: EditDialog,
                                    componentProps: {
                                        form: legalDocumentForm,
                                        title: 'new_legal_document',
                                    },
                                    cancelLabel: 'cancel',
                                    confirmationLabel: 'save',
                                    confirmationEnable: (data) => formValidator(legalDocumentForm, data),
                                    confirmationCallback: async ({ expireat, numberDoc, title, legalDocumentId }) => {
                                        setData(prev => ({
                                            ...prev,
                                            legalDocument: [
                                                ...prev.legalDocument,
                                                { id: uuid(), expireat, numberDoc, title, legalDocumentId, created: true },
                                            ],
                                        }));
                                    },
                                })}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FlexColumn>
    );
};

UserProfileEdit.propTypes = {
    actions: PropTypes.array,
    id: PropTypes.number,
    proxy: PropTypes.object,
    readOnly: PropTypes.bool,
};
UserProfileEdit.defaultProps = {
    proxy: undefined,
    id: undefined,
    readOnly: undefined,
    actions: [],
};

export default UserProfileEdit;
