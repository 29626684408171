import { Grid } from '@material-ui/core';
import { Event } from '@material-ui/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import Actions from '../../components/Actions';
import DisplayListDivider from '../../components/DisplayListDivider';
import FlexColumn from '../../components/flex/FlexColumn';
import history from '../../components/routings/history';
import TableViewCard from '../../components/view/TableViewCard';
import { leavesRequestsColumns } from '../../configuration/columns';
import useFetch from '../../hooks/useFetch';
import { leaveRequestReview, personLeaveRequests } from '../../store/proxies/tigestimProxies';
import { selectItem } from '../../store/slices/sliceNavigation';
import { convertSecondsHours } from '../../utils/common';
import { formatDate } from '../../utils/formats';
import UserAvatar from '../UserAvatar';

const leavesItems = [
    { label: 'holidays_allowance', id: 'totalHoliday', sign: '+' },
    { label: 'total_pending', id: 'totalPending', sign: '-' },
    { label: 'remaining', id: 'remaining', bold: true },
    { label: `holiday_balance`, id: 'totalLeaves', sign: '+', date: `${formatDate(moment().subtract(1, 'month').endOf('month'))}` },
];

const handleResponseLeave = response => {
    const { taken, pending, holiday, remainingHolidays, remainingHolidaysAt } = response || {};

    const totalLeaves = remainingHolidaysAt ? convertSecondsHours(remainingHolidays) : convertSecondsHours(taken)
    const totalPending = convertSecondsHours(pending);
    const totalHoliday = holiday * 8.00; // TODO: use ((summerendthr | winterendthr) - (summerstarthr | winterstarthr)) - pauseTime ,
    const remaining = totalHoliday - totalLeaves;
    return {
        totalLeaves,
        totalPending,
        remaining,
        totalHoliday
    };
};

const UserLeavesView = ({ id, actions }) => {
    const user = useSelector(selectItem);
    const [leavesReview, isLoading] = useFetch(leaveRequestReview, { id, year: moment().year() }, [id], [id], {}, handleResponseLeave);

    return (
        <FlexColumn flex={1} width={1}>
            <Actions actions={actions} />
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <UserAvatar data={user} />
                        </Grid>
                        <Grid item xs={12}>
                            <DisplayListDivider title="leaves" items={leavesItems} data={leavesReview} isLoading={isLoading} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TableViewCard
                                title="leaves_request"
                                icon={Event}
                                proxy={personLeaveRequests}
                                params={{
                                    userId: user?.id,
                                    year: moment().year(),
                                }}
                                columns={leavesRequestsColumns}
                                messageNoItems="document_not_present"
                                onItemClick={({ id }) => {
                                    history.push(`leaves/${id}/edit`);
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FlexColumn>
    );
};

UserLeavesView.propTypes = {
    id: PropTypes.number,
    actions: PropTypes.array,
};
UserLeavesView.defaultProps = {
    id: undefined,
    actions: [],
};

export default UserLeavesView;
