import React from 'react';
import { useSelector } from 'react-redux';
import { selectDialogData } from '../../store/slices/sliceDialog';

const FullscreenImage = () => {
    const url = useSelector(selectDialogData)
    return (
        <img src={url} alt="placeholder" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
    )
}

export default FullscreenImage
