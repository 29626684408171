import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import React from 'react';

const StyledBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            right: -3,
            top: 16,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
        },
    })
)(Badge);

export default function EnhancedBadge({ badgeContent, children }) {
    return (
        <StyledBadge badgeContent={badgeContent} color="primary">
            {children}
        </StyledBadge>
    );
}

EnhancedBadge.propTypes = {
    badgeContent: PropTypes.number,
    children: PropTypes.node.isRequired,
};

EnhancedBadge.defaultProps = {
    badgeContent: undefined,
};
