import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { drawerWidth } from '../../constants/dimensionConstants';
import { selectUserId } from '../../store/slices/sliceSession';
import { isNotDef } from '../../utils/common';
import LoadingFullScreen from '../utilities/LoadingFullScreen';
import Content from './Content';
import Header from './Header';
import Menu from './Menu';
import { selectSideBarOpen } from '../../store/slices/sliceSideBar';

const useStyles = makeStyles(theme => ({
    sideBar: {
        backgroundColor: theme.palette.menu.background,
        transition: 'all 0.3s ease-in-out',
    },
    hiddenSideBar: {
        backgroundColor: theme.palette.menu.background,
        width: 80,
        transition: 'all 0.3s ease-in-out',
        '& .logo__wrapper': {
            padding: '8px 0px',
        },
        // '& .logo__image': {
        //     maxWidth: 50,
        //     height: 'auto!important',
        // },
        '& .profile-info__wrapper .profile-info__name': {
            display: 'none',
        },
        '& .menu-items__wrapper': {
            '& .MuiBox-root .MuiList-root, .MuiBox-root .MuiListItemText-root': {
                display: 'none',
            },
        },
        '& .searchbar__wrapper': {
            display: 'none',
        },
        '& .languages__wrapper': {
            display: 'none',
        },
        '& .toggle-button': {
            '& .MuiButton-startIcon': {
                transition: 'all 0.3s ease-in-out',
                transform: 'rotate( 180deg )',
            },
            '& p': {
                display: 'none',
            },
        },
        '& .menu-icon': {
            marginLeft: 8
        }
    },
}));

function Layout() {
    const userId = useSelector(selectUserId);
    const sideBarOpen = useSelector(selectSideBarOpen);
    const classes = useStyles();

    if (isNotDef(userId)) return <LoadingFullScreen />;

    return (
        <Box display="flex" height={1}>
            <Box height={1} width={drawerWidth} style={{ overflow: 'auto' }} className={sideBarOpen ? classes.sideBar : classes.hiddenSideBar}>
                <Menu open={sideBarOpen} />
            </Box>
            <Box flex={1} display="flex" flexDirection="column" style={{ width: `calc(100vw - ${drawerWidth}px` }}>
                <Box>
                    <Header />
                </Box>
                <Box flex={1}>
                    <Content />
                </Box>
            </Box>
        </Box>
    );
}

export default Layout;
