import { Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React from 'react';
import { batch, useDispatch } from 'react-redux';
import usePagination from '../../hooks/usePagination';
import useTranslate from '../../hooks/useTranslate';
import { setPage } from '../../store/slices/sliceFiltering';
import { isNotDef } from '../../utils/common';
import EnhancedSelect from './EnhancedSelect';

const EnhancedPagination = ({ name, pages, callback, showRows, forcedPageSize }) => {
    const dispatch = useDispatch();
    const { pageNumber = 1, pageSize, pageSizeOption = [] } = usePagination(name);
    const translate = useTranslate();

    if (isNotDef(pages)) return null;

    return (
        <Box display="flex" flexDirection="row" height={1} alignItems="flex-end">
            <Box>
                <Pagination
                    page={pageNumber}
                    count={pages}
                    showFirstButton
                    showLastButton
                    size="small"
                    onChange={(event, newPage) => {
                        batch(() => {
                            dispatch(setPage({ view: name, page: { pageNumber: newPage } }));
                            if (callback) {
                                const value = callback({ pageNumber: newPage }) || {};
                                if (value.type) dispatch(value);
                            }
                        });
                    }}
                />
            </Box>

            {showRows && (
                <Box display="flex" flexDirection="row" height={1} alignItems="center" mb={0} fontSize={14}>
                    <Box pr={1}>{translate('rows')}</Box>
                    <Box>
                        <EnhancedSelect
                            options={pageSizeOption}
                            value={forcedPageSize ?? pageSize}
                            getOptionValue={value => value}
                            getOptionLabel={value => value}
                            getOptionKey={value => value}
                            onChange={newPageSize => {
                                batch(() => {
                                    dispatch(setPage({ view: name, page: { pageSize: newPageSize, pageNumber: 1 } }));
                                    if (callback) {
                                        const value = callback({ pageSize: newPageSize, pageNumber: 1 }) || {};
                                        if (value.type) dispatch(value);
                                    }
                                });
                            }}
                            hideLine
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

EnhancedPagination.propTypes = {
    name: PropTypes.string,
    callback: PropTypes.func,
    pages: PropTypes.number,
    showRows: PropTypes.bool,
    forcedPageSize: PropTypes.number,
};
EnhancedPagination.defaultProps = {
    pages: undefined,
    name: undefined,
    callback: undefined,
    showRows: true,
    forcedPageSize: undefined,
};

export default EnhancedPagination;
