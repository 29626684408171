import { ArrowBack, Folder, PhotoAlbum } from '@material-ui/icons';
import { FileDocument, FileExcel, FilePdfBox } from 'mdi-material-ui';
import {
    leaveTypeLabel,
    MATERNITY_LEAVE,
    MEDICAL_EXAMINATION,
    MILITARY_SERVICE,
    NON_PROFESSIONAL_ACCIDENT,
    OTHER_REASON,
    PROFESSIONAL_ACCIDENT,
    SICKNESS_LEAVE,
    stampingLogLabel,
    TRAINING_COURSE,
    VACATION,
    WEATHER_CONDITION,
} from './statesConstants';

const buildOptions = options => options.map(option => ({ value: option, label: option.toLowerCase() }));

function isNumeric(value) {
    return /^-?\d+$/.test(value);
}

export function mapToOptions(item) {
    return Object.keys(item).map(key => ({ value: isNumeric(key) ? Number(key) : key, label: item[key] }));
}

export const leaveTypes = [
    { label: leaveTypeLabel(VACATION), value: `${VACATION}` },
    { label: leaveTypeLabel(MILITARY_SERVICE), value: `${MILITARY_SERVICE}` },
    { label: leaveTypeLabel(SICKNESS_LEAVE), value: `${SICKNESS_LEAVE}` },
    { label: leaveTypeLabel(MATERNITY_LEAVE), value: `${MATERNITY_LEAVE}` },
    { label: leaveTypeLabel(MEDICAL_EXAMINATION), value: `${MEDICAL_EXAMINATION}` },
    { label: leaveTypeLabel(PROFESSIONAL_ACCIDENT), value: `${PROFESSIONAL_ACCIDENT}` },
    { label: leaveTypeLabel(NON_PROFESSIONAL_ACCIDENT), value: `${NON_PROFESSIONAL_ACCIDENT}` },
    { label: leaveTypeLabel(TRAINING_COURSE), value: `${TRAINING_COURSE}` },
    { label: leaveTypeLabel(WEATHER_CONDITION), value: `${WEATHER_CONDITION}` },
    { label: leaveTypeLabel(OTHER_REASON), value: `${OTHER_REASON}` },
];

export const CHF = 'CHF';
export const USD = 'USD';
export const EUR = 'EUR';

export const currencies = [CHF, EUR, USD].map(option => ({ value: option, label: option }));

export const ADMIN = 'ADMIN';
export const HR = 'HR';
export const EMPLOYEE = 'EMPLOYEE';
export const ASSOCIATE = 'ASSOCIATE';
export const CLIENT = 'CLIENT';
export const INTERIM = 'INTERIM';
export const WORKER = 'WORKER';


export const roles = buildOptions([ADMIN, HR, EMPLOYEE, WORKER, CLIENT, ASSOCIATE, INTERIM]);

export const LIST_ALL_EMPLOYEE = 'LIST_ALL_EMPLOYEE';
export const NO_STAMPING_LOG = 'NO_STAMPINGLOG';
export const NO_LEAVE = 'NO_LEAVE';
export const SHOW_HR_MODULE = 'SHOW_HR_MODULE';
export const EDIT_INVENTORY = 'EDIT_INVENTORY';
export const LIST_FILE = 'LIST_FILE';
export const STAMPINGS_LOG_MANAGEMENT = 'STAMPINGS_LOG_MANAGEMENT';
export const LIST_IN_HR_MODULE = 'LIST_IN_HR_MODULE';
export const SHOW_SITAF = 'SHOW_SITAF';
export const SHOW_METALSKY = 'SHOW_METALSKY';
export const SHOW_METALPIEGA = 'SHOW_METALPIEGA';
export const SHOW_EDEMAR = 'SHOW_EDEMAR';
export const SHOW_TIGESTIM = 'SHOW_TIGESTIM';
export const SHOW_TMWOOD = 'SHOW_TMWOOD';
export const SHOW_ELECTROGESTIM = "SHOW_ELECTROGESTIM";
export const ALL_CHECKLISTS = 'ALL_CHECKLISTS';




export const capabilities = buildOptions([
    LIST_ALL_EMPLOYEE,
    NO_STAMPING_LOG,
    NO_LEAVE,
    SHOW_HR_MODULE,
    EDIT_INVENTORY,
    LIST_FILE,
    STAMPINGS_LOG_MANAGEMENT,
    LIST_IN_HR_MODULE,
    ALL_CHECKLISTS,
    SHOW_SITAF,
    SHOW_METALSKY,
    SHOW_METALPIEGA,
    SHOW_EDEMAR,
    SHOW_TIGESTIM,
    SHOW_TMWOOD,
    SHOW_ELECTROGESTIM
]);
export const rolesEmployee = buildOptions([HR, EMPLOYEE, ASSOCIATE]);

export const TECHNICIAN = 'TECHNICIAN';
export const FOREMAN = 'FOREMAN';
export const CUSTOMER = 'CUSTOMER';

export const functions = buildOptions([TECHNICIAN, FOREMAN, WORKER, CUSTOMER]);

export const SWITZERLAND = 1;
export const ITALIA = 2;

export const countriesMap = {
    [SWITZERLAND]: 'switzerland',
    [ITALIA]: 'italy',
};

export const countries = [
    {
        label: 'switzerland',
        value: SWITZERLAND,
    },
    {
        label: 'italy',
        value: ITALIA,
    },
];

export const legalMaritalStatus = ['married', 'widowed', 'separated', 'divorced', 'single'].map(option => ({ value: option, label: option }));

export const languages = [
    {
        label: 'german',
        value: 'GE',
    },
    {
        label: 'french',
        value: 'FR',
    },
    {
        label: 'italian',
        value: 'IT',
    },
    {
        label: 'english',
        value: 'EN',
    },
];

export const TIGESTIM = 'Tigestim';
export const TMWOOD = 'TM Wood';
export const METALSKY = 'Metalsky';
export const SITAF = 'Sitaf SA';
export const METALPIEGA_COLOR = 'Metal Piega & Color';
export const EDEMAR = 'Edemar';
export const ELECTROGESTIM = 'Electrogestim';

export const companies = [
    {
        label: 'tigestim',
        value: TIGESTIM,
    },
    {
        label: 'metalsky',
        value: METALSKY,
    },
    {
        label: 'sitaf',
        value: SITAF,
    },
    {
        label: 'metalpiega_color',
        value: METALPIEGA_COLOR,
    },
    {
        label: 'edemar',
        value: EDEMAR,
    },
    {
        label: 'tmwood',
        value: TMWOOD,
    },
    {
        label : 'electrogestim',
        value: ELECTROGESTIM,
    }
];

export const FEMALE = 0;
export const MALE = 1;

export const genderLabel = {
    [MALE]: 'male',
    [FEMALE]: 'female',
};

export const genders = [
    {
        label: genderLabel[FEMALE],
        value: FEMALE,
    },
    {
        label: genderLabel[MALE],
        value: MALE,
    },
];

export const TOOLS = 1;
export const VEHICLE = 2;
export const HEALTH = 3;
export const SAFETY_EQUIPMENT = 4;
export const CLOTHING = 5;

export const toolCategoriesLabel = {
    [TOOLS]: 'tools',
    [VEHICLE]: 'vehicle',
    [HEALTH]: 'health',
    [SAFETY_EQUIPMENT]: 'safety_equipment',
    [CLOTHING]: 'clothing',
};

export const formatToolCategory = (value, item, translate) => translate(toolCategoriesLabel[value] ?? value);

export const toolCategories = [
    {
        label: toolCategoriesLabel[TOOLS],
        value: TOOLS,
    },
    {
        label: toolCategoriesLabel[VEHICLE],
        value: VEHICLE,
    },
    {
        label: toolCategoriesLabel[HEALTH],
        value: HEALTH,
    },
    {
        label: toolCategoriesLabel[SAFETY_EQUIPMENT],
        value: SAFETY_EQUIPMENT,
    },
    {
        label: toolCategoriesLabel[CLOTHING],
        value: CLOTHING,
    },
];

const typeMap = {
    jpg: PhotoAlbum,
    png: PhotoAlbum,
    jpeg: PhotoAlbum,
    pdf: FilePdfBox,
    doc: FileDocument,
    docx: FileDocument,
    xls: FileExcel,
    xlsx: FileExcel,
    folder: Folder,
    root: ArrowBack,
};

export const typeIcons = type => {
    return typeMap[type] || FileDocument;
};

export const METERS = 'METERS';
export const PIECES = 'PIECES';
export const KG = 'KG';
export const LITERS = 'LITERS';

export const measurementUnits = buildOptions([METERS, PIECES, KG, LITERS]);

export const ENTER = 'ENTRATA';
export const EXIT = 'USCITA';

export const timestampLogTypes = [
    {
        label: stampingLogLabel(ENTER),
        value: ENTER,
    },
    {
        label: stampingLogLabel(EXIT),
        value: EXIT,
    },
];

export const placeTypesOptions = [
    { label: 'Tigestim', value: '{"coords":{"latitude":46.020180,"longitude":8.916567}}' },
    { label: 'MetalSky', value: '{"coords":{"latitude":45.841963,"longitude":8.922545}}' },
    { label: 'Metal Piega & Color', value: '{"coords":{"latitude":45.8429808,"longitude":8.9246703}}' },
    { label: 'Sitaf', value: '{"coords":{"latitude":46.034509,"longitude":8.977649}}' },
    { label: 'Edemar', value: '{"coords":{"latitude":46.034509,"longitude":8.977649}}' },
    { label: 'TM Wood', value: '{"coords":{"latitude":46.05374042746465,"longitude":8.928421085198236}}' },
    { label: 'Electrogestim', value: '{"coords":{"latitude":46.0201643,"longitude":8.9141037}}'}
];

export const hoursOptions = [
    {
        label: '1 ora',
        value: 1,
    },
    {
        label: '2 ore',
        value: 2,
    },
    {
        label: '3 ore',
        value: 3,
    },
    {
        label: '4 ore',
        value: 4,
    },
    {
        label: '5 ore',
        value: 5,
    },
    {
        label: '6 ore',
        value: 6,
    },
    {
        label: '7 ore',
        value: 7,
    },
    {
        label: '8 ore',
        value: 8,
    },
];

export const minutesOptions = [
    {
        label: '0 min',
        value: 0,
    },
    {
        label: '15 min',
        value: 15,
    },
    {
        label: '30 min',
        value: 30,
    },
    {
        label: '45 min',
        value: 45,
    },
];

export const BREAK_00H_00M = 0.0;
export const BREAK_00H_30M = 0.5;
export const BREAK_00H_45M = 0.75;
export const BREAK_01H_00M = 1.0;
export const BREAK_01H_15M = 1.25;
export const BREAK_01H_30M = 1.5;

// export const NOTIFY_00H_00M = 0;
// export const NOTIFY_00H_30M = 30;
export const NOTIFY_00H_45M = 45;
export const NOTIFY_01H_00M = 60;
export const NOTIFY_01H_15M = 75;
export const NOTIFY_01H_30M = 90;

export const breakTimeOptionsMap = {
    [BREAK_00H_00M]: '0 min',
    [BREAK_00H_30M]: '30 min',
    [BREAK_00H_45M]: '45 min',
    [BREAK_01H_00M]: '1 ora',
    [BREAK_01H_15M]: '1 ora e 15 min',
    [BREAK_01H_30M]: '1 ora e 30 min',
};

export const notifyOptionsMap = {
    // [NOTIFY_00H_00M]: '0 min',
    // [NOTIFY_00H_30M]: '30 min',
    [NOTIFY_00H_45M]: '45 min',
    [NOTIFY_01H_00M]: '1 ora',
    [NOTIFY_01H_15M]: '1 ora e 15 min',
    [NOTIFY_01H_30M]: '1 ora e 30 min',
};

export const breakTimeOptions = [
    { value: BREAK_00H_00M, label: breakTimeOptionsMap[BREAK_00H_00M] },
    { value: BREAK_00H_30M, label: breakTimeOptionsMap[BREAK_00H_30M] },
    { value: BREAK_00H_45M, label: breakTimeOptionsMap[BREAK_00H_45M] },
    { value: BREAK_01H_00M, label: breakTimeOptionsMap[BREAK_01H_00M] },
    { value: BREAK_01H_15M, label: breakTimeOptionsMap[BREAK_01H_15M] },
    { value: BREAK_01H_30M, label: breakTimeOptionsMap[BREAK_01H_30M] },
];

export const notifyOptions = [
    // { value: NOTIFY_00H_00M, label: notifyOptionsMap[NOTIFY_00H_00M] },
    // { value: NOTIFY_00H_30M, label: notifyOptionsMap[NOTIFY_00H_30M] },
    { value: NOTIFY_00H_45M, label: notifyOptionsMap[NOTIFY_00H_45M] },
    { value: NOTIFY_01H_00M, label: notifyOptionsMap[NOTIFY_01H_00M] },
    { value: NOTIFY_01H_15M, label: notifyOptionsMap[NOTIFY_01H_15M] },
    { value: NOTIFY_01H_30M, label: notifyOptionsMap[NOTIFY_01H_30M] },
];
