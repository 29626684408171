import { Document, Font, Page, PDFViewer, StyleSheet } from '@react-pdf/renderer';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectDialogData } from '../../../store/slices/sliceDialog';
import PageFooter from './RequestExpensesFooter';
import PageHeader from './RequestExpensesHeader';
import PageTable from './RequestExpensesTable';
import gothic from '../../../resources/GOTHIC.TTF'
import gothicBold from '../../../resources/GOTHICB.TTF'
import useTranslate from '../../../hooks/useTranslate';

Font.register({
    family: 'Open Sans',
    fonts: [
        { src: gothic },
        { src: gothicBold, fontWeight: 700 },
    ],
});

const styles = StyleSheet.create({
    page: {
        padding: 20,
        paddingTop: 25,
        paddingBottom: 50,
        fontFamily: 'Open Sans',
    },
    viewerStyle: {
        width: '100vw',
        height: '100%',
        border: 0,
    },
});

const RequestExpensesDocument = () => {
    const data = useSelector(selectDialogData);
    const translate = useTranslate();

    return (
        <PDFViewer style={styles.viewerStyle}>
            <Document>
                <Page size="A4" orientation="landscape" style={styles.page} wrap>
                    <PageHeader data={data} translate={translate} />
                    <PageTable data={data} translate={translate} />
                    <PageFooter />
                </Page>
            </Document>
        </PDFViewer>
    );
};
RequestExpensesDocument.propTypes = {};

export default RequestExpensesDocument;
