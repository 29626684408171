import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../components/Actions';
import Filters from '../../components/Filters';
import Search from '../../components/Search';
import EnhancedTable from '../../components/table/EnhancedTable';
import EnhancedCard from '../../components/utilities/EnhancedCard';
import EnhancedPagination from '../../components/utilities/EnhancedPagination';
import useFetch from '../../hooks/useFetch';
import usePagination from '../../hooks/usePagination';
import useSorting from '../../hooks/useSorting';
import useFilters from '../../hooks/useFilters';
import { getSitesFav, getSitesSearch } from '../../store/proxies/tigestimProxies';
import { selectUserId } from '../../store/slices/sliceAuth';
import { setOrder, setPage } from '../../store/slices/sliceFiltering';
import { invokeOrReturn } from '../../utils/common';

const SitesTableView = ({ actions, columns, filters, proxy, params, handleResponse, onItemClick, keyComposition, id, defaultValue }) => {
    const dispatch = useDispatch();
    const { pageNumber, pageSize } = usePagination(proxy.name);
    const { sortBy, sortDirection } = useSorting(proxy.name);
    const { pageNumber: pageNumberFav, pageSize: pageSizeFav } = usePagination(getSitesFav.name);
    const { sortBy: sortByFav, sortDirection: sortDirectionFav } = useSorting(getSitesFav.name);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useFilters(proxy);
    const userId = useSelector(selectUserId);

    const [response, isLoading] = useFetch(
        proxy,
        {
            pageNumber: pageNumber - 1,
            pageSize,
            orderBy: sortBy,
            orderDirection: sortDirection,
            ...invokeOrReturn(params, id),
            ...filter,
        },
        [pageNumber, pageSize, sortBy, sortDirection, filter],
        [],
        defaultValue,
        handleResponse
    );

    const [responseSearch, isLoadingSearch] = useFetch(
        getSitesSearch,
        { 
            title: search, 
            pageNumber: pageNumber - 1, 
            pageSize, orderBy: sortBy, 
            orderDirection: 
            sortDirection, 
            ...invokeOrReturn(params, id), 
            ...filter, 
        },
        [pageNumber, pageSize, sortBy, sortDirection, search, filter],
        [search.length],
        defaultValue,
        handleResponse
    );

    const [responseFavorites, isLoadingFavorites] = useFetch(
        getSitesFav,
        {
            pageNumber: pageNumberFav - 1,
            pageSize: pageSizeFav,
            orderBy: sortByFav,
            orderDirection: sortDirectionFav,
            userId,
            filter: 'forUser',
        },
        [pageNumberFav, pageSizeFav, sortByFav, sortDirectionFav, userId],
        [userId],
        defaultValue,
        handleResponse
    );

    useEffect(() => {
        dispatch(setPage({ view: proxy.name, page: { pageNumber: 1 } }));
    }, [dispatch, proxy.name, search]);

    return (
        <Box height={1} width={1}>
            <Box display="flex" justifyContent="space-between">
                <Box pb={2}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Search onChange={setSearch} value={search} />
                        </Grid>
                        {filters && (
                            <Grid item>
                                <Filters filter={filter} onChange={setFilter} filters={filters} />
                            </Grid>
                        )}
                    </Grid>
                </Box>
                {actions && <Actions actions={actions} />}
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <EnhancedCard
                        pb={1}
                        title="favorites"
                        ComponentBottom={<EnhancedPagination name={getSitesFav.name} pages={responseFavorites.totalPages} />}
                    >
                        <EnhancedTable
                            items={responseFavorites.data}
                            columns={columns}
                            isLoading={isLoadingFavorites}
                            onItemClick={onItemClick && (item => onItemClick(item, dispatch))}
                            keyComposition={({ favouriteSiteId }) => favouriteSiteId}
                            sortBy={sortByFav}
                            sortDirection={sortDirectionFav}
                            handleRequestSort={order => {
                                dispatch(setOrder({ view: getSitesFav.name, order }));
                            }}
                        />
                    </EnhancedCard>
                </Grid>
                <Grid item xs={12}>
                    <EnhancedCard
                        pb={1}
                        title="all"
                        ComponentBottom={<EnhancedPagination name={proxy.name} pages={(search ? responseSearch : response).totalPages} />}
                    >
                        <EnhancedTable
                            items={search ? responseSearch.sites : response.data}
                            columns={columns}
                            isLoading={search ? isLoadingSearch : isLoading}
                            onItemClick={onItemClick && (item => onItemClick(item, dispatch))}
                            keyComposition={keyComposition}
                            sortBy={sortBy}
                            sortDirection={sortDirection}
                            handleRequestSort={order => {
                                dispatch(setOrder({ view: proxy.name, order }));
                            }}
                        />
                    </EnhancedCard>
                </Grid>
            </Grid>
        </Box>
    );
};

SitesTableView.propTypes = {
    id: PropTypes.number,
    actions: PropTypes.array,
    columns: PropTypes.array,
    filters: PropTypes.array,
    proxy: PropTypes.object,
    params: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onItemClick: PropTypes.func,
    handleResponse: PropTypes.func,
    keyComposition: PropTypes.func,
    defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
SitesTableView.defaultProps = {
    id: undefined,
    actions: [],
    columns: [],
    filters: undefined,
    proxy: {},
    params: () => {},
    onItemClick: undefined,
    handleResponse: undefined,
    keyComposition: undefined,
    defaultValue: {},
};

export default SitesTableView;
