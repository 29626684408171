import { Box, Grid } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from '../hooks/useTranslate';
import FlexColumn from './flex/FlexColumn';
import FlexRow from './flex/FlexRow';
import SimpleCard from './SimpleCard';

const DisplayList = ({ title, icon, items, columns, data, isLoading }) => {
    const translate = useTranslate();

    return (
        <SimpleCard icon={icon} title={title}>
            <Grid container spacing={2}>
                {items.map(({ label, id, format, unit }) => {
                    const value = id.split('.').reduce((acc, item) => acc?.[item], data);
                    return (
                        <Grid item xs={12 / columns} key={id}>
                            {label && (
                                <FlexColumn>
                                    <Box color={grey[600]} fontSize={12}>
                                        {translate(label)}
                                    </Box>
                                    {isLoading ? (
                                        <Skeleton variant="text" height={22} />
                                    ) : (
                                        <FlexRow height={22}>
                                            <Box fontSize={16} fontWeight="fontWeightMedium">
                                                {format ? format(value, translate) : value}
                                            </Box>
                                            {unit && (
                                                <Box pl={0.5} fontSize={16} fontWeight="fontWeightRegular">
                                                    {`${translate(unit)}`}
                                                </Box>
                                            )}
                                        </FlexRow>
                                    )}
                                </FlexColumn>
                            )}
                        </Grid>
                    );
                })}
            </Grid>
        </SimpleCard>
    );
};

DisplayList.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    icon: PropTypes.object,
    items: PropTypes.array,
    columns: PropTypes.oneOf([1, 2, 3, 4, 6, 12]),
};
DisplayList.defaultProps = {
    title: '',
    data: undefined,
    isLoading: undefined,
    icon: undefined,
    items: [],
    columns: 1,
};

export default DisplayList;
