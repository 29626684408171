import { Auth } from 'aws-amplify';
import { call, put, takeEvery } from 'redux-saga/effects';
import EnhancedError from '../../components/utilities/EnhancedError';
import { handlePhoneNumberList } from '../../configuration/handlers';
import { routes } from '../../configuration/routes';
import abilityUpdate from '../../ability/abilityUpdate';
import { removeDisabledNode } from '../../utils/menu';
import { getPerson } from '../proxies/tigestimProxies';
import { receiveLogin, requestLogout } from '../slices/sliceAuth';
import { openDialog } from '../slices/sliceDialog';
import { setRoutes } from '../slices/sliceNavigation';
import { receiveUser, requestAuth, setSession } from '../slices/sliceSession';

function* requestUserRightsSaga() {
    try {
        const response = yield call([Auth, Auth.currentAuthenticatedUser]);
        const { users: user,  employees	} = yield call(getPerson.call, Number(response.attributes['custom:id'])) || {};

        const [employee] = employees || [];
        const { summerendthr, summerstarthr, winterendthr, winterstarthr } = employee || {};
        
        yield put(receiveUser({...handlePhoneNumberList(user), summerendthr, summerstarthr, winterendthr, winterstarthr}));

        const { role, capabilities = [] } = user || {};

        if (response) {
            yield call(abilityUpdate, [role, ...capabilities]);

            yield put(setSession(response));

            const menu = removeDisabledNode(routes);
            yield put(setRoutes(menu));

            yield put(receiveLogin(response));

            // yield fork(sagasInitialization);
        } else {
            throw new EnhancedError();
        }
    } catch (e) {
        yield put(openDialog({ title: 'session_ended', confirmationLabel: 'bring_me_to_login', confirmationCallback: requestLogout }));
    }
}

export default function* sagasSession() {
    yield takeEvery(requestAuth, requestUserRightsSaga);
}
