import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const sliceError = createSlice({
    name: 'error',
    initialState,
    reducers: {
        receiveError: (state, { payload }) => {
            state = payload;
        },
        clearError: () => initialState,
    },
});

export default sliceError;

// ACTIONS
export const { receiveError, clearError } = sliceError.actions;
