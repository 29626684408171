import PropTypes from 'prop-types';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import React from 'react';
import { Box } from '@material-ui/core';

const containerStyle = {
    width: '100%',
    height: '400px',
};

const EnhancedMap = ({ onChange, center, position }) => {
    return (
        <Box width={1} height={400}>
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={17}>
                    {position && (
                        <Marker
                            position={position}
                            draggable
                            onDragEnd={({ latLng: { lat, lng } }) => onChange?.({ latitude: lat(), longitude: lng() })}
                        />
                    )}
                </GoogleMap>
            </LoadScript>
        </Box>
    );
};

EnhancedMap.propTypes = {
    onChange: PropTypes.func,
    center: PropTypes.object.isRequired,
    position: PropTypes.object,
};
EnhancedMap.defaultProps = {
    position: undefined,
    onChange: undefined,
};

export default EnhancedMap;
