import { Edit } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { patchDialogData, selectDialogData } from '../../store/slices/sliceDialog';
import EnhancedForm from '../EnhancedForm';

const EditDialog = ({ title, form }) => {
    const dispatch = useDispatch();
    const data = useSelector(selectDialogData);
    return (
        <Box minWidth={400}>
            <EnhancedForm
                columns={form.columns ?? 1}
                title={title}
                icon={Edit}
                items={form.items ?? form}
                data={data}
                onChange={(id, value) => dispatch(patchDialogData({ [id]: value }))}
            />
        </Box>
    );
};

EditDialog.propTypes = {
    title: PropTypes.string.isRequired,
    form: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};
EditDialog.defaultProps = {};

export default EditDialog;
