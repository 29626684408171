import { Zoom } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import EnhancedFab from './EnhancedFab';

const EnhancedFabs = ({ fabs }) => {
    const theme = useTheme();

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    return (
        <Fragment>
            {fabs.map(fab => (
                <Zoom
                    key={`zoom${fab.key}`}
                    in
                    timeout={transitionDuration}
                    style={{
                        transitionDelay: `${transitionDuration.exit}ms`,
                    }}
                    unmountOnExit
                    size="medium"
                >
                    <EnhancedFab fab={fab} />
                </Zoom>
            ))}
        </Fragment>
    );
};

EnhancedFabs.propTypes = {
    fabs: PropTypes.array,
};

EnhancedFabs.defaultProps = {
    fabs: [],
};

export default EnhancedFabs;
