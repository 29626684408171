import { Box, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Skeleton } from '@material-ui/lab';
import FlexRow from './flex/FlexRow';
import SimpleCard from './SimpleCard';
import useTranslate from '../hooks/useTranslate';
import { isDef } from '../utils/common';

const useStyles = makeStyles(theme => ({
    cell: {
        paddingLeft: theme.spacing(0),
        paddingTop: theme.spacing(0.5),
        paddingRight: theme.spacing(0),
        paddingBottom: theme.spacing(0.5),
    },
}));


const DisplayListDivider = ({ title, icon, items, data, isLoading }) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <SimpleCard icon={icon} title={title}>
            <Box mt={-0.5}>
                <Table padding="none">
                    <TableBody>
                        {items.map(({ label, id, format, sign = '', bold, date }) => {
                            const duration = data[id]
                            return (
                                <TableRow key={id}>
                                    <TableCell variant="body" className={classes.cell}>
                                        <FlexRow>
                                            <Box fontSize={16} fontWeight={bold ? 'fontWeightBold' : 'fontWeightRegular'}>
                                                {translate(label)}
                                            </Box>
                                            {date && (
                                                <Box pl={1} fontSize={16} fontWeight={bold ? 'fontWeightBold' : 'fontWeightRegular'}>
                                                    {date}
                                                </Box>
                                            )}
                                        </FlexRow>
                                    </TableCell>
                                    <TableCell align="right" variant="body" className={classes.cell}>
                                        <FlexRow>
                                            {isDef(isLoading) ? (
                                                <Box fontSize={16} fontWeight={bold ? 'fontWeightBold' : 'fontWeightRegular'} flex={1}>
                                                    {format ? `${sign}${format(data[id], translate)}` : `${sign}${duration || 0}`}
                                                </Box>
                                            ) : (
                                                <Skeleton variant="text" height={22} width={22} />
                                            )}
                                            <Box pl={0.5} fontSize={16} fontWeight={bold ? 'fontWeightBold' : 'fontWeightRegular'}>
                                                {`${translate('hours')}`}
                                            </Box>
                                        
                                        </FlexRow>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>
        </SimpleCard>
    );
};

DisplayListDivider.propTypes = {
    data: PropTypes.object,
    icon: PropTypes.object,
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    title: PropTypes.string.isRequired,
};
DisplayListDivider.defaultProps = {
    data: {},
    icon: undefined,
    isLoading: undefined,
    items: [],
};

export default DisplayListDivider;
