import * as AWS from 'aws-sdk';
import { uploadImage } from '../store/proxies/tigestimProxies';

const ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
const ACCESS_SECRET = process.env.REACT_APP_ACCESS_SECRET;
const REGION = process.env.REACT_APP_REGION;
export const DEFAULT_BUCKET = process.env.REACT_APP_BUCKET;
const BUCKET_FILE = process.env.REACT_APP_BUCKET_FILE;
const DEFAULT_FOLDER = process.env.REACT_APP_DEFAULT_FOLDER;
const environment = process.env.REACT_APP_UPLOAD_ENVIRONMENT;

export const bucketUploader = ({ bucketSite, file, filePath, userId }) => {
    return new Promise((resolve, reject) => {
        try {
            AWS.config.update({
                region: `${REGION}`,
                credentials: {
                    accessKeyId: `${ACCESS_KEY_ID}`,
                    secretAccessKey: `${ACCESS_SECRET}`,
                },
            });

            const bucket = new AWS.S3();
            const fileName = filePath ?? DEFAULT_FOLDER;

            const params = {
                Bucket: bucketSite ?? `${DEFAULT_BUCKET}`,
                Key: `${fileName}`,
                Body: file,
                ContentType: `${file.type}`,
                ContentDisposition: `inline; filename=${fileName}`,
                ACL: 'public-read',
            };

            bucket.putObject(params, async error => {
                if (error) reject(error);

                const response = await uploadImage.call({ image: fileName, userId, environment });
                const result = response?.uploadedImageId;

                resolve(result);
            });
        } catch (error) {
            reject(error);
        }
    });
};

export const bucketNewDirectory = ({ bucketSite, folderName, folderPath }) => {
    return new Promise((resolve, reject) => {
        try {
            AWS.config.update({
                region: `${REGION}`,
                credentials: {
                    accessKeyId: `${ACCESS_KEY_ID}`,
                    secretAccessKey: `${ACCESS_SECRET}`,
                },
            });

            const bucket = new AWS.S3();

            const params = {
                Bucket: bucketSite ?? `${DEFAULT_BUCKET}`,
                Key: `${folderPath ?? DEFAULT_FOLDER }${folderName}/`,
                ACL: 'public-read',
                // Body: folderName,
                // ContentType: `${file.type}`,
                // ContentDisposition: `inline; filename=${fileName}`,
            };

            bucket.putObject(params, async (error, data) => {
                if (error) reject(error);
                resolve(data);
            });
        } catch (error) {
            reject(error);
        }
    });
};

export const bucketList = async ({ bucketSite, path, maxKeys: MaxKeys = 1000, continuationToken: ContinuationToken }) => {
    return new Promise((resolve, reject) => {
        try {
            AWS.config.update({
                region: `${REGION}`,
                credentials: {
                    accessKeyId: `${ACCESS_KEY_ID}`,
                    secretAccessKey: `${ACCESS_SECRET}`,
                },
            });

            const bucket = new AWS.S3();

            const bucketParams = {
                Bucket: bucketSite ?? `${BUCKET_FILE}`,
                Prefix: path,
                MaxKeys,
                ContinuationToken,
                Delimiter: '/',
            };

            bucket.listObjectsV2(bucketParams, (error, data) => {
                if (error) reject(error);
                resolve(data);
            });
        } catch (error) {
            reject(error);
        }
    });
};

export const bucketGetObject = async ({ Key, bucketSite }) => {
    return new Promise((resolve, reject) => {
        try {
            AWS.config.update({
                region: `${REGION}`,
                credentials: {
                    accessKeyId: `${ACCESS_KEY_ID}`,
                    secretAccessKey: `${ACCESS_SECRET}`,
                },
            });

            const bucket = new AWS.S3();

            const bucketParams = {
                Bucket: bucketSite || `${BUCKET_FILE}`,
                Key,
            };

            bucket.getObject(bucketParams, (error, data) => {
                if (error) reject(error);
                resolve(data);
            });
        } catch (error) {
            reject(error);
        }
    });
};

export async function bucketDeleteDirectory(Prefix, bucketSite) {
    const s3 = new AWS.S3();
    const listParams = {
        Bucket: `${BUCKET_FILE}`,
        Prefix,
    };

    const listedObjects = await s3.listObjectsV2(listParams).promise();

    if (listedObjects.Contents.length === 0) return;

    const deleteParams = {
        Bucket: bucketSite || `${BUCKET_FILE}`,
        Delete: { Objects: [] }
    };

    listedObjects.Contents.forEach(({ Key }) => {
        deleteParams.Delete.Objects.push({ Key });
    });

    await s3.deleteObjects(deleteParams).promise();

    if (listedObjects.IsTruncated) await bucketDeleteDirectory(Prefix, bucketSite);
}

export const bucketDeleteObject = async ({ Key, bucketSite }) => {
    return new Promise((resolve, reject) => {
        try {
            AWS.config.update({
                region: `${REGION}`,
                credentials: {
                    accessKeyId: `${ACCESS_KEY_ID}`,
                    secretAccessKey: `${ACCESS_SECRET}`,
                },
            });

            const bucket = new AWS.S3();

            const bucketParams = {
                Bucket: bucketSite || `${BUCKET_FILE}`,
                Key,
            };

            bucket.deleteObject(bucketParams, (error, data) => {
                if (error) reject(error);
                resolve(data);
            });
        } catch (error) {
            reject(error);
        }
    });
};

export const bucketRenameObject = async ({ oldKey, newKey, bucketSite }) => {
    return new Promise((resolve, reject) => {
        try {
            AWS.config.update({
                region: `${REGION}`,
                credentials: {
                    accessKeyId: `${ACCESS_KEY_ID}`,
                    secretAccessKey: `${ACCESS_SECRET}`,
                },
            });

            const bucket = new AWS.S3();

            bucket.copyObject(
                {
                    Bucket: bucketSite || `${BUCKET_FILE}`,
                    CopySource: `${BUCKET_FILE}/${oldKey}`,
                    Key: newKey,
                },
                (error, data) => {
                    if (error) reject(error);
                    bucket.deleteObject(
                        {
                            Bucket: `${BUCKET_FILE}`,
                            Key: oldKey,
                        },
                        error => {
                            if (error) reject(error);
                            resolve(data);
                        }
                    );
                }
            );
        } catch (error) {
            reject(error);
        }
    });
};

export const bucketGetObjectUrl = async ({ bucketSite, Key }) => {
    return new Promise((resolve, reject) => {
        try {
            AWS.config.update({
                region: `${REGION}`,
                credentials: {
                    accessKeyId: `${ACCESS_KEY_ID}`,
                    secretAccessKey: `${ACCESS_SECRET}`,
                },
            });

            const bucket = new AWS.S3();

            const Expires = 60 * 5;

            const bucketParams = {
                Bucket: bucketSite ?? `${BUCKET_FILE}`,
                Key,
                Expires,
            };

            bucket.getSignedUrl('getObject', bucketParams, (error, data) => {
                if (error) reject(error);
                resolve(data);
            });
        } catch (error) {
            reject(error);
        }
    });
};
