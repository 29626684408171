import { Box, LinearProgress, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import useTranslate from '../../hooks/useTranslate';

const useStyles = makeStyles(theme => ({
    cell: {
        whiteSpace: 'nowrap',
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        backgroundColor: 'transparent',
        border: 'none',
    },
    cellLoading: {
        padding: 0,
        backgroundColor: 'transparent',
    },
    checkbox: {
        padding: 0,
        paddingRight: theme.spacing(1),
    },
}));

const isChecked = items => (items.length > 0 ? items.reduce((acc, { checked }) => acc && checked, true) : false);
const isIndeterminate = items => items.reduce((acc, { checked }) => acc || checked, false) && !isChecked(items);

const EnhancedTableHead = ({ order, orderBy, columns, onRequestSort, items, isLoading }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const translate = useTranslate();

    return (
        <TableHead>
            <TableRow className={classes.row}>
                {columns.map(({ id, label, align, width, sortable = true, multiSelect = false, headerIcon: HeaderIcon, onClickAll }) => {
                    const element = HeaderIcon ? <HeaderIcon /> : translate(label);
                    return (
                        <TableCell
                            className={classes.cell}
                            key={id}
                            align={align || 'left'}
                            sortDirection={orderBy === id ? order : false}
                            style={{ width }}
                        >
                            {multiSelect && (
                                <Checkbox
                                    disabled={items?.length < 1}
                                    className={classes.checkbox}
                                    color="primary"
                                    indeterminate={isIndeterminate(items)}
                                    checked={isChecked(items) || false}
                                    onChange={(_, checked) => dispatch(onClickAll(checked))}
                                    inputProps={{ 'aria-label': 'select all' }}
                                />
                            )}
                            {sortable && !HeaderIcon ? (
                                <TableSortLabel
                                    active={orderBy === id}
                                    direction={order}
                                    onClick={() => {
                                        onRequestSort(id, order);
                                    }}
                                >
                                    {translate(label ?? id)}
                                </TableSortLabel>
                            ) : (
                                element
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
            {isLoading ? (
                <TableRow>
                    <TableCell className={classes.cell} colSpan={columns.length}>
                        <LinearProgress color="primary" />
                        {!items?.length && (
                            <Box
                                pt={0}
                                textAlign="bottom"
                                fontSize={16}
                                fontWeight="fontWeightMedium"
                                color="#999"
                                height={28}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {translate('loading')}
                            </Box>
                        )}
                    </TableCell>
                </TableRow>
            ) : (
                <TableRow>
                    <TableCell colSpan={columns.length} className={classes.cellLoading}>
                        <Box height={3} />
                    </TableCell>
                </TableRow>
            )}
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
};

EnhancedTableHead.defaultProps = {
    onRequestSort: undefined,
    isLoading: undefined,
};

export default EnhancedTableHead;
