/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import history from '../components/routings/history';
import { EDEMAR, METALPIEGA_COLOR, METALSKY, SITAF, TIGESTIM } from '../constants/options';
import LogoEdemarDark from '../resources/LogoEdemarDark.png';
import LogoEdemarLight from '../resources/LogoEdemarLight.png';
import LogoEdemarSmall from '../resources/LogoEdemarSmall.png';
import LogoMetalPiegaDark from '../resources/LogoMetalPiegaDark.png';
import LogoMetalPiegaLight from '../resources/LogoMetalPiegaLight.png';
import LogoMetalPiegaSmall from '../resources/LogoMetalPiegaSmall.png';
import LogoMetalskyDark from '../resources/LogoMetalskyDark.png';
import LogoMetalskyLight from '../resources/LogoMetalskyLight.png';
import LogoMetalskySmall from '../resources/LogoMetalskySmall.png';
import LogoSitafDark from '../resources/LogoSitafDark.png';
import LogoSitafLight from '../resources/LogoSitafLight.png';
import LogoSitafSmall from '../resources/LogoSitafSmall.png';
import LogoTigestimDark from '../resources/LogoTigestimDark.png';
import LogoTigestimLight from '../resources/LogoTigestimLight.png';
import LogoTigestimSmall from '../resources/LogoTigestimSmall.png';
import { openDialog } from '../store/slices/sliceDialog';
import { setErrorNotification, setSuccessNotification } from '../store/slices/sliceNotification';

export const isEmpty = value => !(Object.keys(value) || {}).length;

export const notEmpty = value => !!(Object.keys(value) || {}).length;

export const isDef = value => value !== undefined && value !== null;

export const isObject = value => typeof value === 'object' && value !== null;

export const isNotDef = value => !isDef(value);

export const resolveValue = (value, defaultValue) => (isDef(value) ? value : defaultValue);

export const invoke = async (callback, dispatch) => {
    if (callback) {
        const value = await callback();
        if (value?.type) dispatch(value);
    }
};

export const compareValues =
    ({ sortBy, sortDirection = 'asc' }) =>
        (a, b) => {
            let itemA = sortBy.split('.').reduce((acc, item) => acc?.[item], a);
            let itemB = sortBy.split('.').reduce((acc, item) => acc?.[item], b);

            if (isNotDef(itemB)) return -1;
            if (isNotDef(itemA)) return 1;

            itemA = typeof itemA === 'string' ? itemA.toUpperCase() : itemA;
            itemB = typeof itemB === 'string' ? itemB.toUpperCase() : itemB;

            let comparison = 0;
            if (itemA > itemB) {
                comparison = 1;
            } else if (itemA < itemB) {
                comparison = -1;
            }
            return sortDirection === 'desc' ? comparison * -1 : comparison;
        };

export const patchImmer = (path, item) => {
    return produce((draft = {}) => {
        const vector = path.split('.');
        const propName = vector.pop();
        if (propName) {
            draft = vector.reduce((it, prop) => {
                if (!it[prop]) it[prop] = {};
                return it[prop];
            }, draft);
            draft[propName] = item;
        }
    });
};

const BUCKET_PREFIX = process.env.REACT_APP_BUCKET_PREFIX;

export const buildUrl = url => {
    if (!url) return null;
    if (url.startsWith('http')) {
        return url;
    } if (url.includes('public'))  {
        return `${BUCKET_PREFIX}/${url}`;
    } 
    return `${BUCKET_PREFIX}/public/${url}`;
    
    // return url.startsWith('http') ? url : `${BUCKET_PREFIX}/${url}`.replace('public','');
  
};

export const random = (min, max) => Math.floor(Math.random() * max + 1) + min;

export const invokeCreate = async (proxy, data, setLoading = () => {}, successMessage, errorMessage) => {
    setLoading(true);
    try {
        await proxy.call(data);
        setLoading(false);
        history.goBack();
        return setSuccessNotification(successMessage);
    } catch (e) {
        setLoading(false);
        return setErrorNotification(errorMessage || `${e?.response?.status} : ${e?.response?.statusText}`);
    }
};

export const invokeSave = async (proxy, data, setLoading = () => {}, successMessage, errorMessage) => {
    setLoading(true);
    try {
        await proxy.call(data.id, data);
        setLoading(false);
        history.goBack();
        return setSuccessNotification(successMessage);
    } catch (e) {
        setLoading(false);
        return setErrorNotification(errorMessage || `${e?.response?.status} : ${e?.response?.statusText}`);
    }
};

export const internalInvokeDelete = async (proxy, data, { setLoading = () => {}, successMessage, errorMessage, back, onSuccess }) => {
    if (setLoading) setLoading(true);
    try {
        await proxy.call(data.id);
        if (setLoading) setLoading(false);
        if (back) history.goBack();
        return onSuccess ? onSuccess() : setSuccessNotification(successMessage);
    } catch (e) {
        if (setLoading) setLoading(false);
        return setErrorNotification(errorMessage || `${e?.response?.status} : ${e?.response?.statusText}`);
    }
};

export const invokeDelete = async (proxy, data, options = {}) => {
    return openDialog({
        title: 'confirm_delete',
        message: 'are_you_sure_you_want_to_delete_this_item',
        cancelLabel: 'cancel',
        confirmationLabel: 'delete',
        confirmationCallback: () => internalInvokeDelete(proxy, data, options),
    });
};

export const invokeOrReturn = (call, first, second) => {
    if (typeof call === 'function') return call(first ?? {}, second ?? {});
    return call;
};

export function isFolder(path = '') {
    return path.endsWith('/');
}

export function capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const getLastField = (item, id) => {
    const [first, ...rest] = id.split('.');
    if (!item) return undefined;
    if (rest.length) return getLastField(item[first], rest.join('.'));
    return item[first];
};

export const unitDays = days => (days === 1 ? 'day' : 'days');

export const convertDays = days => (days > 0 ? days / (60.0 * 60.0 * 24.0) : days);

export const convertSecondsHours = seconds => (seconds > 0 ? Math.floor(seconds / (60.0 * 60.0)) : 0);

export const getFirst = array => {
    const [first] = array;
    return first;
};

export const getLast = array => getFirst(array.reverse());

export const formValidator = (form, data) => !form.items.some(({ id, required }) => { 
    const item = getLastField(data, id)
    return id && invokeOrReturn(required, data) && isNotDef(item)
})

export const formValidators = (forms, data) => forms.reduce((acc, form) => acc && formValidator(form, data), true);

export const logoSelector = (companyName, dark) => {
    switch (companyName) {
        case TIGESTIM: 
            return dark ? LogoTigestimDark : LogoTigestimLight;
        case EDEMAR:  
            return  dark ? LogoEdemarDark : LogoEdemarLight;
        case METALSKY:  
            return  dark ? LogoMetalskyDark : LogoMetalskyLight; 
        case SITAF:  
            return  dark ? LogoSitafDark : LogoSitafLight;
        case METALPIEGA_COLOR:  
            return  dark ? LogoMetalPiegaDark : LogoMetalPiegaLight;
        default:
            return dark ? LogoTigestimDark : LogoTigestimLight;
    }
}

export const smallLogoSelector = (companyName) => {
    switch (companyName) {
        case TIGESTIM: 
            return LogoTigestimSmall;
        case EDEMAR:  
            return LogoEdemarSmall;
        case METALSKY:  
            return LogoMetalskySmall; 
        case SITAF:  
            return LogoSitafSmall;
        case METALPIEGA_COLOR:  
            return LogoMetalPiegaSmall;
        default:
            return LogoTigestimSmall;
    }
}

export const generateCompaniesString = (userCompany, companies, i) => {
    return `${userCompany}${ i.can('read', 'edemar-company') ? ','.concat(companies.filter((item)=>item.label==='edemar')[0].value) : ''}${ i.can('read', 'tigestim-company') ? ','.concat(companies.filter((item)=>item.label==='tigestim')[0].value) : ''}${ i.can('read', 'sitaf-company') ?  ','.concat(companies.filter((item)=>item.label==='sitaf')[0].value) : ''}${ i.can('read', 'metalsky-company') ? ','.concat(companies.filter((item)=>item.label==='metalsky')[0].value) : ''}${ i.can('read', 'metalpiega-company') ? ','.concat(companies.filter((item)=>item.label==='metalpiega_color')[0].value) : ''}`;
}