import { Checkbox, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from '../../hooks/useTranslate';

const EnhancedCheckbox = ({ value, label, disabled, onChange }) => {
    const translate = useTranslate();
    return (
        <FormControlLabel
            disabled={disabled}
            checked={!!value}
            control={<Checkbox color="primary" />}
            label={translate(label)}
            labelPlacement="end"
            onChange={event => {
                if (onChange) {
                    const { checked } = event.target;
                    onChange(checked ? 1 : 0);
                }
            }}
        />
    );
};

EnhancedCheckbox.propTypes = {
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    label: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};
EnhancedCheckbox.defaultProps = {
    value: false,
    label: undefined,
    disabled: undefined,
    onChange: undefined,
};

export default EnhancedCheckbox;
