import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
// import PropTypes from 'prop-types';

const styles = StyleSheet.create({
    footerContainer: {
        position: 'absolute',
        paddingRight: 20,
        paddingLeft: 20,
        paddingVertical: 10,
        fontSize: 9,
        bottom: 10,
        left: 0,
        right: 0,
    },
    footer: {
        fontSize: 9,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    fontSize: {
        fontSize: 9,
    },
});
const width = 150;

const PageFooter = () => (
    <View width={width * 4} style={{ flexDirection: 'row' }}>
        <View style={{ flexDirection: 'column' }}>
            <Text style={{...styles.fontSize, textDecoration: 'underline', paddingTop: 10}}>Firma del dipendente:</Text>
            <Text style={{...styles.fontSize, paddingTop: 10}}>_________________________</Text>
        </View>
        <View style={{ flexDirection: 'column', marginLeft: 150 }}>
            <Text style={{...styles.fontSize, textDecoration: 'underline', paddingTop: 10}}>Firma del supervisore:</Text>
            <Text style={{...styles.fontSize, paddingTop: 10}}>_________________________</Text>
        </View>
    </View>
);

PageFooter.propTypes = {
    // translate: PropTypes.func.isRequired
};

PageFooter.defaultProps = {};

export default PageFooter;