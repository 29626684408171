import { Avatar, Box, Grid, Paper, Button } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Edit, ImportContacts, Visibility } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import Actions from '../../../components/Actions';
import EnhancedCarousel from '../../../components/EnhancedCarousel';
import history from '../../../components/routings/history';
import SimpleCard from '../../../components/SimpleCard';
import LoadingFullScreen from '../../../components/utilities/LoadingFullScreen';
import { siteJournalActions } from '../../../configuration/actions';
import useFetch from '../../../hooks/useFetch';
import useTranslate from '../../../hooks/useTranslate';
import { getJournals } from '../../../store/proxies/tigestimProxies';
import { buildUrl } from '../../../utils/common';
// import { formatDate } from '../../../utils/formats';
import SiteJournalSocial from './SiteJournalSocial';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';

// const _SiteJournal = ({ id }) => {
//     const translate = useTranslate();
//     const [more, setMore] = useState(1);
//     const wrapperBuildRef = useRef(null);
//     const loadingRef = useRef(null);

//     const [response, isLoading] = useFetch(
//         getJournals,
//         {
//             id,
//             filter: 'forSiteId',
//             orderBy: 'createdAt',
//             orderDirection: 'DESC',
//             pageNumber: 0,
//             pageSize: 6 * more,
//         },
//         [id, more],
//         [id],
//         {}
//     );

//     const loadData = () => {
//         setMore(prev => prev + 1);
//     };

//     wrapperBuildRef.current = document.querySelector('.wrapperBuildComponent');

//     useIntersectionObserver(wrapperBuildRef, loadingRef, loadData, isLoading);

//     if (!response.size && isLoading) return <LoadingFullScreen />;

//     return (
//         <Box height={1} width={1}>
//             <Actions actions={siteJournalActions} />

//             <FlexColumn>
//                 {response.size ? (
//                     response.data.map(journal => {
//                         return (
//                             <FlexColumn pb={4} key={journal.id}>
//                                 <Paper>
//                                     <Box p={2}>
//                                         <Grid container spacing={2}>
//                                             <Grid item xs={12}>
//                                                 <FlexRow alignItems="flex-start" justifyContent="space-between">
//                                                     <FlexRow>
//                                                         <Box>
//                                                             <Avatar style={{ width: 50, height: 50 }} src={buildUrl(journal.userUrl)}>
//                                                                 {`${journal.firstName?.charAt(0) || ''}${journal.lastName?.charAt(0) || ''}`}
//                                                             </Avatar>
//                                                         </Box>

//                                                         <FlexColumn pl={2}>
//                                                             <Box fontSize={16} fontWeight={500}>
//                                                                 {`${journal.firstName} ${journal.lastName}`}
//                                                             </Box>
//                                                             <Box color={grey[400]} fontSize={14} pt={1}>
//                                                                 {/* {formatDate(journal.createdAt)} */}
//                                                                 {journal.createdAt}
//                                                             </Box>
//                                                         </FlexColumn>
//                                                     </FlexRow>

//                                                     <Actions
//                                                         actions={[
//                                                             {
//                                                                 icon: Visibility,
//                                                                 label: 'view',
//                                                                 onClick: () => history.push(`journal/${journal.id}/view`),
//                                                             },
//                                                             {
//                                                                 icon: Edit,
//                                                                 label: 'edit',
//                                                                 onClick: () => history.push(`journal/${journal.id}/edit`),
//                                                             },
//                                                         ]}
//                                                     />
//                                                 </FlexRow>
//                                             </Grid>
//                                             <Grid item xs={8}>
//                                                 <EnhancedCarousel
//                                                     items={journal.journalImageList}
//                                                     getItemUrl={({ journalUrl }) => journalUrl}
//                                                     height={400}
//                                                 />
//                                             </Grid>
//                                             <Grid item xs={4}>
//                                                 <FlexColumn>
//                                                     <Box fontSize={16} fontWeight={500}>
//                                                         {journal.title}
//                                                     </Box>
//                                                     <Box fontSize={14} pt={2}>
//                                                         {journal.description}
//                                                     </Box>
//                                                 </FlexColumn>
//                                             </Grid>
//                                         </Grid>
//                                     </Box>
//                                     <SiteJournalSocial journal={journal} addPathView />
//                                 </Paper>
//                             </FlexColumn>
//                         );
//                     })
//                 ) : (
//                     <SimpleCard icon={ImportContacts} title="journal" p={0}>
//                         <Box
//                             pt={2}
//                             fontSize={16}
//                             fontWeight="fontWeightMedium"
//                             color="#999"
//                             height={50}
//                             display="flex"
//                             alignItems="center"
//                             justifyContent="center"
//                         >
//                             {translate('journal_not_present')}
//                         </Box>
//                     </SimpleCard>
//                 )}
//             </FlexColumn>
//             <Box
//                 p={2}
//                 className="loadingBtn"
//                 ref={el => {
//                     loadingRef.current = !response.data || response.size === 0 || response.size === response.totalElements ? null : el;
//                 }}
//                 style={{ display: !response.data || response.size === 0 || response.size === response.totalElements ? 'none' : 'block' }}
//             >
//                 <Button
//                     variant="text"
//                     size="small"
//                     color="default"
//                     disable={!response.data || response.size === 0 || response.size === response.totalElements}
//                     isLoading={isLoading}
//                     fullWidth
//                     onClick={() => {}}
//                     // label={translate('load_more_journal_posts')}
//                 >
//                     {translate('load_more_journal_posts')}
//                 </Button>
//             </Box>
//         </Box>
//     );
// };

const SiteJournal = ({ id }) => {
    const translate = useTranslate();
    const [more, setMore] = useState(1);
    const wrapperBuildRef = useRef(null);
    const loadingRef = useRef(null);

    const [response, isLoading] = useFetch(
        getJournals,
        {
            id,
            filter: 'forSiteId',
            orderBy: 'createdAt',
            orderDirection: 'DESC',
            pageNumber: 0,
            pageSize: 6 * more,
        },
        [id, more],
        [id],
        {}
    );

    const loadData = () => {
        setMore(prev => prev + 1);
    };

    wrapperBuildRef.current = document.querySelector('.wrapperBuildComponent');

    useIntersectionObserver(wrapperBuildRef, loadingRef, loadData, isLoading);

    if (!response.size && isLoading) return <LoadingFullScreen />;

    const contentGrid = response.data?.map((journal, index) => {
        if (response.size) {
            return (
                <Box display="flex" flexDirection="column" pb={4} key={`${journal.id}`} id={index}>
                    <Paper>
                        <Box p={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-between">
                                        <Box display="flex" flexDirection="row">
                                            <Box>
                                                <Avatar style={{ width: 50, height: 50 }} src={buildUrl(journal.userUrl)}>
                                                    {`${journal.firstName?.charAt(0) || ''}${journal.lastName?.charAt(0) || ''}`}
                                                </Avatar>
                                            </Box>

                                            <Box display="flex" flexDirection="column" pl={2}>
                                                <Box fontSize={16} fontWeight={500}>
                                                    {`${journal.firstName} ${journal.lastName}`}
                                                </Box>
                                                <Box color={grey[400]} fontSize={14} pt={1}>
                                                    {/* {formatDate(journal.createdAt)} */}
                                                    {journal.createdAt}
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Actions
                                            actions={[
                                                {
                                                    icon: Visibility,
                                                    label: 'view',
                                                    onClick: () => history.push(`journal/${journal.id}/view`),
                                                },
                                                {
                                                    icon: Edit,
                                                    label: 'edit',
                                                    onClick: () => history.push(`journal/${journal.id}/edit`),
                                                },
                                            ]}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={8}>
                                    <EnhancedCarousel items={journal.journalImageList} getItemUrl={({ journalUrl }) => journalUrl} height={400} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display="flex" flexDirection="column">
                                        <Box fontSize={16} fontWeight={500}>
                                            {journal.title}
                                        </Box>
                                        <Box fontSize={14} pt={2}>
                                            {journal.description}
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <SiteJournalSocial journal={journal} addPathView />
                    </Paper>
                </Box>
            );
        }
        return (
            <SimpleCard icon={ImportContacts} title="journal" p={0}>
                <Box
                    pt={2}
                    fontSize={16}
                    fontWeight="fontWeightMedium"
                    color="#999"
                    height={50}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    {translate('journal_not_present')}
                </Box>
            </SimpleCard>
        );
    });
    return (
        <Box height={1} width={1}>
            <Actions actions={siteJournalActions} />

            <Box display="flex" flexDirection="column">
                {contentGrid}
            </Box>
            <Box
                p={2}
                className="loadingBtn"
                ref={el => {
                    loadingRef.current = !response.data || response.size === 0 || response.size === response.totalElements ? null : el;
                }}
                style={{ display: !response.data || response.size === 0 || response.size === response.totalElements ? 'none' : 'block' }}
            >
                <Button
                    variant="text"
                    size="small"
                    color="default"
                    disable={!response.data || response.size === 0 || response.size === response.totalElements}
                    isLoading={isLoading}
                    fullWidth
                    onClick={() => {}}
                    // label={translate('load_more_journal_posts')}
                >
                    {translate('load_more_journal_posts')}
                </Button>
            </Box>
        </Box>
    );
};
SiteJournal.propTypes = {
    id: PropTypes.number.isRequired,
};
SiteJournal.defaultProps = {};

export default SiteJournal;
