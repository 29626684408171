import { StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ViewColumn from '../../../components/pdf/ViewColumn';
import ViewRow from '../../../components/pdf/ViewRow';
import { leaveTypeLabel } from '../../../constants/statesConstants';
import { formatDate, formatDurationMsToHHmm, formatHour } from '../../../utils/formats';

const width = 150;

const styles = StyleSheet.create({
    root: {
        fontSize: 9,
        borderRight: 1,
        borderBottom: 1,
    },
    section: {
        // flexGrow: 1,
        fontSize: 9,
    },
    header: {
        flexDirection: 'row',
        alignContent: 'center',
    },
    footer: {
        flexDirection: 'row',
        alignContent: 'center',
    },
    row: {
        flexDirection: 'row',
        alignContent: 'center',
    },
    cell: {
        paddingHorizontal: 4,
        borderLeft: 1,
        borderTop: 1,
        borderColor: 'black',
        // fontWeight: 700,
        width,
    },
    cellBold: {
        paddingHorizontal: 4,
        borderLeft: 1,
        borderTop: 1,
        borderColor: 'black',
        fontWeight: 700,
        width,
    },
    cellRight: {
        paddingHorizontal: 4,
        borderLeft: 1,
        borderTop: 1,
        borderColor: 'black',
        // fontWeight: 700,
        width,
        textAlign: 'right',
    },
    cellRightBold: {
        paddingHorizontal: 4,
        borderLeft: 1,
        borderTop: 1,
        borderColor: 'black',
        fontWeight: 700,
        width,
        textAlign: 'right',
    },
    cellCenter: {
        paddingHorizontal: 4,
        borderLeft: 1,
        borderTop: 1,
        borderColor: 'black',
        // fontWeight: 700,
        width,
        textAlign: 'center',
    },
});

const description = '';

const calculateDifference = (key, end, start) => {
    if (!end || !start) return 0;
    return moment(`${key} ${end}:00`, 'DD.MM.YYYY HH:mm:ss').valueOf() - moment(`${key} ${start}:00`, 'DD.MM.YYYY HH:mm:ss').valueOf();
};

const PageTable = ({ data, translate }) => {
    const { date, stampingLogs = [], festivity = [], personRequests = [] } = data || {};

    const [total, setTotal] = useState(0);
    const [items, setItems] = useState([]);

    useEffect(() => {
        const month = moment(date).month() + 1;

        const year = moment(date).year();
        const days = Array.from({ length: moment(date).daysInMonth() }, (item, index) => index + 1);

        const stampingLogsMap = stampingLogs.reduce((accumulator, item) => {
            const key = formatDate(item.timestamp).slice(0, 11);
            return { ...accumulator, [key]: [...(accumulator[key] ?? []), formatHour(item.timestamp)] };
        }, {});

        const festivityMap = festivity.reduce((accumulator, { startDate, title }) => {
            const key = formatDate(startDate).slice(0, 11);
            return { ...accumulator, [key]: title };
        }, {});

        const personRequestsMap = personRequests.reduce((accumulator, { startAt, endAt, type }) => {
            const starDay = moment(startAt).day();
            const endDay = moment(endAt).day();

            const days = Array.from({ length: endDay - starDay + 1 }, (item, index) => index + starDay);

            return {
                ...accumulator,
                ...days.reduce((total, day) => ({ ...total, [formatDate(new Date(year, month - 1, day)).slice(0, 11)]: type }), {}),
            };
        }, {});

        setItems(
            days.map(day => {
                const key = `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year.toString().padStart(2, '0')}`;
                const [first = '', second = '', third = '', fourth = ''] = stampingLogsMap[key] || [];
                const duration1 = calculateDifference(key, second, first);
                const duration2 = calculateDifference(key, fourth, third);
                setTotal(prev => prev + duration1 + duration2);

                const weekend = [0, 6].includes(new Date(year, month - 1, day).getDay()) ? 'Fine settimana' : '';

                const title = translate(festivityMap[key]);

                const leave = translate(leaveTypeLabel(personRequestsMap[key]));

                return {
                    total: duration1 + duration2,
                    stampingLog: stampingLogsMap[key],
                    date: key,
                    description: [title, weekend, leave].filter(item => !!item).join(', '),
                };
            })
        );
    }, [date, festivity, personRequests, stampingLogs, translate]);

    return (
        <View style={styles.root}>
            <View style={{ ...styles.header }}>
                <Text style={styles.cellRightBold}>Data</Text>
                <ViewColumn width={width * 4}>
                    <Text style={{ ...styles.cellBold, width: '100%', textAlign: 'center' }}>Timbrature</Text>
                    <ViewRow>
                        <Text style={styles.cellRightBold}>hh:mm</Text>
                        <Text style={styles.cellRightBold}>hh:mm</Text>
                        <Text style={styles.cellRightBold}>hh:mm</Text>
                        <Text style={styles.cellRightBold}>hh:mm</Text>
                    </ViewRow>
                </ViewColumn>
                <Text style={styles.cellBold}>Totale ore</Text>
                <Text style={{ ...styles.cellBold, width: '100%' }}>Descrizione</Text>
            </View>
            <View>
                {items.map(({ date, total, stampingLog, description }) => {
                    const [first = '', second = '', third = '', fourth = ''] = stampingLog || [];
                    return (
                        <View key={date} style={styles.row}>
                            <Text style={styles.cellRight}>{date}</Text>

                            <Text style={styles.cellRight}>{first}</Text>
                            <Text style={styles.cellRight}>{second}</Text>
                            <Text style={styles.cellRight}>{third}</Text>
                            <Text style={styles.cellRight}>{fourth}</Text>

                            <Text style={styles.cellRightBold}>{formatDurationMsToHHmm(total)}</Text>
                            <Text style={{ ...styles.cell, width: '100%' }}>{description}</Text>
                        </View>
                    );
                })}
            </View>
            <View style={styles.footer}>
                <Text style={{ ...styles.cellRightBold, width: width * 6 }}>{formatDurationMsToHHmm(total)}</Text>
                <Text style={{ ...styles.cell, width: '100%' }}>{description}</Text>
            </View>
        </View>
    );
};

PageTable.propTypes = {
    data: PropTypes.object,
    translate: PropTypes.func.isRequired
};

PageTable.defaultProps = {
    data: {},
};

export default PageTable;
